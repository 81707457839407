import React from 'react';
import { useCheckSession } from 'components/Session';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as StarIcon } from 'images/ContentShowcase/ContentShowcaseTabIcons/StarIcon.svg';

const ContentShowcaseTab = ({ isActive }) => {
  const hasSession = useCheckSession();
  const classname = isActive ? 'content-showcase-tab active' : 'content-showcase-tab';

  const showcaseLink = hasSession ? '/campaigns/showcase' : '/showcase';

  return (
    <div className={classname}>
      <Link to={showcaseLink} className="showcase-link">
        <div className="link-wrapper">
          <span>
            <Icon component={StarIcon} style={{ fontSize: '30px', margin: 0, padding: 0 }} />
            <p className="tab-link-text">Content Showcase</p>
          </span>
        </div>
      </Link>
    </div>
  );
};

export default ContentShowcaseTab;
