import { Row } from 'antd';
import { Redirect, Switch, Route } from 'react-router-dom';
import React from 'react';
import {
  CAMPAIGN_CREATORS_LIST_PATH,
  CAMPAIGN_CREATORS_PATH,
  CAMPAIGN_CREATORS_SHIPMENTS_PATH,
  toCampaignCreatorsShipmentsPath,
} from 'constants/routes';
import { useProductData } from 'components/Products';
import EmptyInfluencers from 'components/Common/EmptyInfluencers';
import Tabs from './Tabs';
import CreatorList from './CreatorList';
import Shipments from './Shipments';

/**
 * Campaign Page: Creators
 *
 * @typedef {{}} CreatorsProps
 * @type {React.FC<CreatorsProps>}
 */
const Creators = () => {
  const { productUID, approvedPartnershipUIDs } = useProductData();
  if (approvedPartnershipUIDs.length === 0) {
    return <EmptyInfluencers />;
  }

  return (
    <>
      <div style={{ background: '#ffffff' }}>
        <Tabs />
      </div>
      <Row id="influencers-page">
        <Switch>
          <Route exact path={CAMPAIGN_CREATORS_LIST_PATH} component={CreatorList} />
          <Route exact path={CAMPAIGN_CREATORS_SHIPMENTS_PATH} component={Shipments} />
          <Redirect
            from={CAMPAIGN_CREATORS_PATH}
            to={toCampaignCreatorsShipmentsPath(productUID)}
          />
        </Switch>
      </Row>
    </>
  );
};

export default Creators;
