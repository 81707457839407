import { message, Icon, Button } from 'antd';
import { firebase } from 'lib/Firebase';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

/**
 * Layout Return Button
 *
 * @type {React.FC}
 */
const ReturnButton = () => {
  const [loading, setLoading] = useState(false);
  const [cookies, , removeCookie] = useCookies(['returnToken']);
  async function handleReturn() {
    setLoading(true);

    try {
      const { returnToken } = cookies;
      await firebase.auth.signInWithCustomToken(returnToken);
      removeCookie('returnToken');
    } catch (e) {
      message.error('Return token already expired');
    }

    setLoading(false);
  }

  return (
    <Button type="danger" loading={loading} onClick={handleReturn}>
      <Icon type="left" />
      Back to Safety
    </Button>
  );
};

export default ReturnButton;
