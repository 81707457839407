import { Col, Layout, Row, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { ReactComponent as IconBackMessage } from 'images/icon-back-arrow.svg';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { useHistory, useParams } from 'react-router-dom';
import Product from 'components/Products';
import Message from 'components/Messages';
import { useSession } from 'components/Session';
import { brandSendMessage, brandReadMessage } from 'lib/Firebase/callables/brands';
import { uploadMessageMedia } from 'helpers/uploadFIle';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import { useFirebase } from 'lib/Firebase';
import RehireInfluencer from 'components/RehireInfluencer';
import Container from 'components/Common/Container';
import CreatorProfileDrawer, {
  PrefetchCreatorProfilePages,
} from 'components/Influencers/Drawer/CreatorProfileDrawer';
import { brandInvitesCreator } from 'api/partnerships';

const { Header, Footer, Content } = Layout;

const FILE_TYPES = {
  'image/jpeg': 'image',
  'image/png': 'image',
  'video/mp4': 'video',
};
/**
 * Product Message
 *
 * @type {React.FC}
 */
const MessagePage = () => {
  const firebase = useFirebase();
  const [ref, setRef] = useState();
  const { brand } = useSession();
  const { standardCredits = 0 } = brand;
  const [influencerDrawer, setInfluencerDrawer] = useState({
    influencerUID: null,
    show: false,
  });
  const history = useHistory();
  const { messageId: conversationUID, campaignId } = useParams();
  const conversation = useSelector(selectors.getMessage(conversationUID));
  const influencers = useSelector(selectors.getInfluencers());
  const [sentMessages, setSentMessages] = useState([]);

  const { influencerUID, messages = {} } = conversation || {};
  const influencer = influencers[influencerUID] || {};
  function scrollBottom() {
    setTimeout(() => {
      ref.scrollIntoView({ behavior: 'smooth' });
      brandReadMessage(conversationUID);
    }, 200);
  }

  function removeSentMessages() {
    const uids = Object.keys(messages);
    setSentMessages(x => {
      const newMessages = [...x];

      return newMessages.filter(msg => {
        return !uids.includes(msg.uid);
      });
    });
  }

  useEffect(() => {
    removeSentMessages();
  }, [messages]);

  useEffect(() => {
    if (conversation && ref) {
      scrollBottom();
    }
    brandReadMessage(conversationUID);
  }, [ref]);

  async function handleSubmit(text, files = []) {
    const currentDate = moment().format(DATE_TIMEZONE_FORMAT);
    const promises = [];

    const newSentMessage = [];
    files.forEach(file => {
      const type = FILE_TYPES[file.type];
      const url = URL.createObjectURL(file);
      const messageId = firebase.messages().push().key;

      promises.push(async () => {
        const { type: fileType, url, thumbnail } = await uploadMessageMedia(conversationUID, file);
        await brandSendMessage({
          conversationUID,
          messageId,
          creatorId: influencerUID,
          text: 'attachment',
          type: FILE_TYPES[fileType],
          url,
          thumbnail,
        });
      });

      newSentMessage.push({
        text: 'attachment',
        type,
        url,
        own: true,
        status: 'sending',
        timestamp: currentDate,
        uid: messageId,
      });
    });

    if (text) {
      const messageId = firebase.messages().push().key;
      newSentMessage.push({
        type: 'text',
        text,
        status: 'sending',
        timestamp: currentDate,
        own: true,
        uid: messageId,
      });

      promises.push(() =>
        brandSendMessage({
          conversationUID,
          messageId,
          text,
          type: 'text',
          creatorId: influencerUID,
        })
      );
    }

    setSentMessages(x => [...x, ...newSentMessage]);
    await Promise.all(promises.map(p => p()));

    scrollBottom();
  }

  async function handleRehireSubmit(val) {
    if (standardCredits === 0) {
      throw new Error('Not enough credits to spend');
    }
    // TODO: Add Try Catch here for User Messages when things dont go well
    await brandInvitesCreator(val);
  }

  const renderMessages = Object.entries(messages).map(([id, chatMessage]) => (
    <Message.ChatItem
      key={id}
      rejection={chatMessage.rejection}
      item={{
        sender: conversation.users[brand.uid],
        own: chatMessage.sender === brand.uid,
        text: chatMessage.text,
        timestamp: chatMessage.timestamp,
        type: chatMessage.type,
        url: chatMessage.url,
        thumbnail: chatMessage.thumbnail,
        avatar: chatMessage.sender === brand.uid ? brand.storeImage : influencer.image,
      }}
    />
  ));

  const handleCloseProfileDrawer = () => setInfluencerDrawer(state => ({ ...state, show: false }));

  const renderSentMessage = sentMessages.map(row => <Message.ChatItem key={row.uid} item={row} />);

  return (
    <div id="message-page">
      <Product.Provider productUID={campaignId}>
        <RehireInfluencer.Provider onSubmit={handleRehireSubmit}>
          <Header
            style={{
              backgroundColor: '#fff',
              borderBottom: '1px solid #d6d6d6',
              height: '100px',
              position: 'fixed',
              top: '0',
              width: 'calc(100% - 210px)',
              zIndex: 1,
            }}
          >
            <Container width={767}>
              <Row style={{ marginTop: 26, marginBottom: 32 }}>
                <Col span={24}>
                  <Button className="back" onClick={history.goBack}>
                    <IconBackMessage style={{ display: 'inlineBlock' }} />
                    <span style={{ display: 'block' }}>Back</span>
                  </Button>
                  <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                    <div className="influencer-name">{influencer.name}</div>
                    <div className="influencer-profile">
                      <Button
                        type="link"
                        onClick={() =>
                          setInfluencerDrawer(x => ({ ...x, influencerUID, show: true }))
                        }
                      >
                        See Profile
                      </Button>
                    </div>
                  </div>
                  <RehireInfluencer.Button
                    awaitingLabel={
                      <div>
                        Awaiting
                        <br />
                        Rehire Request
                      </div>
                    }
                    influencerUID={influencerUID}
                  >
                    Rehire Creator
                  </RehireInfluencer.Button>
                </Col>
              </Row>
            </Container>
          </Header>
          <Content style={{ marginTop: 48, paddingTop: 24 }}>
            <Container width={767}>
              <div style={{ marginBottom: '106px' }}>
                {renderMessages}
                {renderSentMessage}
                <div style={{ float: 'left', clear: 'both' }} ref={r => setRef(r)} />
              </div>
            </Container>
          </Content>
          <Footer
            style={{
              backgroundColor: '#F1F5FD',
              position: 'fixed',
              bottom: '0',
              width: 'calc(100% - 210px)',
              borderTop: '1px solid #e8e8e8',
              margin: 0,
              padding: '13px 24px 13px 24px',
              zIndex: 1,
            }}
          >
            <Container width={767}>
              <Message.Form onSubmit={handleSubmit} />
            </Container>
          </Footer>
          <PrefetchCreatorProfilePages influencerUIDs={Object.keys(influencers)} />
          {influencerDrawer.show && (
            <CreatorProfileDrawer
              {...influencerDrawer}
              drawerType="preview"
              handleClose={handleCloseProfileDrawer}
            />
          )}
        </RehireInfluencer.Provider>
      </Product.Provider>
    </div>
  );
};

export default MessagePage;
