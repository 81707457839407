import Container from 'components/Common/Container';
import Product, { useProductData } from 'components/Products';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectors } from 'stores';

/**
 * @type {React.FC}
 */
const ShowProduct = () => {
  const {
    productUID,
    submittedPartnershipUIDs,
    approvedPartnershipUIDs,
    submittedPostUIDs,
    approvedPostUIDs,
    pendingPostUIDs,
  } = useProductData();
  const product = useSelector(selectors.getProduct(productUID));
  const partnerships = useSelector(selectors.getPartnerships());
  const posts = useSelector(selectors.getPosts());

  const submittedP = submittedPartnershipUIDs.map(p => {
    const partnership = partnerships[p];
    const { influencerUID } = partnership;

    return ((
      <div className="partnership-item">
        <div>
          InfluencerUID: [{influencerUID}] PartnershipUID: [{p}]
        </div>
      </div>
    ));
  });

  const approvedP = approvedPartnershipUIDs.map(p => {
    const partnership = partnerships[p];
    const { influencerUID } = partnership;

    return ((
      <div className="partnership-item">
        <div>
          [{partnership.status}]: [{p}] - InfluencerUID: [{influencerUID}]
        </div>
      </div>
    ));
  });

  const allpost = [...submittedPostUIDs, ...pendingPostUIDs, ...approvedPostUIDs].map(postUID => {
    const post = posts[postUID];

    return ((
      <div className="post-item">
        <div>
          [{post.status}]: [{postUID}] - InfluencerUID: [{post.influencerUID}] PartnershipUID: [
          {post.partnershipUID}]
        </div>
      </div>
    ));
  });

  return ((
    <div>
      <h1>
        {product.name} [{productUID}]
      </h1>
      <div className="submitted-application">
        <h2>Submitted Applications</h2>
        <div>{submittedP}</div>
      </div>
      <div className="active-partnerships">
        <h2>Partnerships</h2>
        <div className="partnership-items">{approvedP}</div>
      </div>
      <div className="posts">
        <h2>Posts</h2>
        <div className="post-items">{allpost}</div>
      </div>
    </div>
  ));
};

/**
 * Admin V2 Active campaign show page
 * @type {React.FC}
 */
const ShowPage = () => {
  const { id } = useParams();
  return (
    <Container width={940}>
      <Product.Provider productUID={id} isAdmin>
        <ShowProduct />
      </Product.Provider>
    </Container>
  );
};

export default ShowPage;
