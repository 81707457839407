import { createContext } from 'react';

/**
 * Media Viewer Context
 *
 * @typedef {{ show: boolean, type: 'video', src: string }} MediaContextProps
 * @type {React.Context<null | { setModal: (value: MediaContextProps) => any}>}
 */
const MediaViewerContext = createContext();

export default MediaViewerContext;
