import { addReviews } from './reviews';

/**
 * @typedef {ReturnType<addInfluencerReviews>} EntityInfluencerReviewsActions
 */
export const ADD_INFLUENCER_REVIEWS = 'ADD_INFLUENCER_REVIEWS';

/**
 * Entity Add Influencer Review Action
 *
 * @param {string} influencerUID
 * @param {Record<string, import("types").Review>} reviews
 * @returns {{ type: ADD_INFLUENCER_REVIEWS, payload: { influencerUID: string, reviewUIDs: string[] }}}
 */
export const addInfluencerReviews = (influencerUID, reviews) =>
  /**
   * @param {import("redux").Dispatch} dispatch
   */
  dispatch => {
    dispatch(addReviews(reviews));
    const reviewUIDs = Object.keys(reviews);
    dispatch({
      type: ADD_INFLUENCER_REVIEWS,
      payload: {
        influencerUID,
        reviewUIDs,
      },
    });
  };
