/**
 * @typedef {ReturnType<addPartnerships> | ReturnType<updatePartnerships>} EntityPartnershipActions
 */
export const ADD_PARTNERSHIPS = 'ADD_PARTNERSHIPS';

/**
 * Entity Add Action fro partnerships
 *
 * @param {Record<string, import("types").Partnership>} partnerships
 * @returns {{type: ADD_PARTNERSHIPS, payload: partnerships}}
 */
export const addPartnerships = partnerships => ({
  type: ADD_PARTNERSHIPS,
  payload: partnerships,
});

export const UPDATE_PARTNERSHIPS = 'UPDATE_PARTNERSHIPS';
/**
 * Entity Update Action for partnership
 *
 * @param {Record<string, import("types").Partnership>} partnerships
 * @returns {{type: UPDATE_PARTNERSHIPS,  payload: partnerships}}
 */
export const updatePartnerships = partnerships => ({
  type: UPDATE_PARTNERSHIPS,
  payload: partnerships,
});
