import { setAdminBrands } from './actions/adminPages';
import { setInitialBrandContext } from './actions/brandContexts';
import { addBrands } from './actions/entities/brands';
import { writeCreatorContents } from './actions/entities/creatorContents';
import { addInfluencerReviews } from './actions/entities/influencerReviews';
import { addInfluencers } from './actions/entities/influencers';
import { addInfluencerPartnerships } from './actions/entities/influencersPartnerships';
import { writeMessages } from './actions/entities/message';
import { addPartnerships, updatePartnerships } from './actions/entities/partnerships';
import { addPosts, updatePosts } from './actions/entities/posts';
import { addPostsStatistics } from './actions/entities/postsStatistics';
import { addProducts, updateProducts, clearProducts } from './actions/entities/products';
import { addReviews } from './actions/entities/reviews';
import { setExampleCampaigns } from './actions/exampleCampaigns';
import * as productContextActions from './actions/productContexts';

import {
  addActiveProductsSession,
  addCompletedPartnershipInfluencers,
  addCompletedProductsSession,
  initializeSession,
  removeActiveProductsSesssion,
  removeCompletedProductSession,
  removeSession,
  updateBrandSession,
  updateNotificationSession,
} from './actions/session';
import createStore from './configureStore';
import { getAdminBrands } from './selector/adminPages';
import { getBrandContext } from './selector/brand';
import {
  getPosts,
  getInfluencer,
  getProduct,
  getPartnership,
  getPartnerships,
  getInfluencers,
  getInfluencerReviews,
  getReviews,
  getProducts,
  getMessages,
  getMessage,
  getProductPostsStatistics,
  getPost,
  getBrands,
  getBrand,
  getInfluencerPartnerships,
  getPurchasedHistories,
  getCreatorContents,
  getCreatorContent,
} from './selector/entities';
import { getExampleCampaigns } from './selector/exampleCampaigns';
import {
  getProductApplications,
  getProductMessageByInfluencer,
  getProductPage,
  getProductMessages,
  getNumPendingSubmissions,
  getProductInfluencersCreatorContents,
  getProductCreatorContents,
  getProductPosts,
  getProductContexts,
} from './selector/productContext';
import { getSession } from './selector/session';

export const store = createStore();
export const actions = {
  entities: {
    products: {
      addProducts,
      updateProducts,
      clearProducts,
    },
    posts: {
      addPosts,
      updatePosts,
    },
    partnerships: {
      addPartnerships,
      updatePartnerships,
    },
    influencers: {
      addInfluencers,
    },
    influencerReviews: {
      addInfluencerReviews,
    },
    influencersPartnerships: {
      addInfluencerPartnerships,
    },
    reviews: {
      addReviews,
    },
    messages: {
      writeMessages,
    },
    postsStatistics: {
      addPostsStatistics,
    },
    brands: {
      addBrands,
    },
    creatorContents: {
      writeCreatorContents,
    },
  },
  exampleCampaigns: {
    setExampleCampaigns,
  },
  productContexts: productContextActions,
  brandContexts: {
    setInitialBrandContext,
  },
  session: {
    removeSession,
    initializeSession,
    updateBrandSession,
    addActiveProductsSession,
    addCompletedProductsSession,
    addCompletedPartnershipInfluencers,
    removeActiveProductsSesssion,
    removeCompletedProductSession,
    updateNotificationSession,
  },
  adminPages: {
    setAdminBrands,
  },
};

export const selectors = {
  getPost,
  getProductPostsStatistics,
  getMessages,
  getMessage,
  getProductMessageByInfluencer,
  getProductPage,
  getProductApplications,
  getProduct,
  getProductMessages,
  getInfluencer,
  getInfluencerPartnerships,
  getInfluencers,
  getPartnership,
  getPartnerships,
  getPosts,
  getInfluencerReviews,
  getReviews,
  getSession,
  getProducts,
  getExampleCampaigns,
  getBrands,
  getBrand,
  getAdminBrands,
  getBrandContext,
  getPurchasedHistories,
  getCreatorContents,
  getCreatorContent,
  getProductCreatorContents,
  getProductInfluencersCreatorContents,
  getProductPosts,
  getProductContexts,
  getNumPendingSubmissions,
};

export default store;
