import { firebaseService } from '../context';

const firebase = firebaseService;

/**
 * @param {{
 * state: string,
 * usernameLow: string,
 * star: number,
 * type: string,
 * gender: string,
 * lastLoginDate: number,
 * completedPartnershipCount: number,
 * }} filters
 * @return firebase.firestore.Query<firebase.firestore.DocumentData>
 */
export default function(filters = {}, includeSuspendedCreators = false) {
  let query = firebase.firestore.collection('influencers');

  if (!includeSuspendedCreators) {
    query.where('isSuspended', '==', false);
  }

  if (filters.state) {
    query = query.where('state', '==', filters.state);
  }

  if (filters.search) {
    query = query.where('usernameLow', '==', filters.search);
  }

  if (filters.star) {
    query = query.where('star', '==', filters.star);
  }

  if (filters.type) {
    query = query.where('isVIP', '==', filters.type === 'vip');
  }

  if (filters.gender) {
    query = query.where('gender', '==', filters.gender);
  }

  if (filters.id) {
    query = query.doc(filters.id);
    return query;
  }

  if (filters.lastLoginDate) {
    query = query
      .where('lastLoginDate', '>', filters.lastLoginDate)
      .orderBy('lastLoginDate', 'desc');
  } else if (filters.completedPartnershipCount) {
    query = query
      .where('completedPartnershipCount', '<', filters.completedPartnershipCount)
      .orderBy('completedPartnershipCount', 'desc');
  } else {
    query = query.orderBy('lastAppliedDate', 'desc');
  }

  return query;
}
