import Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import React, { useEffect } from 'react';
import { EXAMPLE_CAMPAIGNS_PATH, SHOW_EXAMPLE_CAMPAIGN_PATH } from 'constants/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'stores';
import Loading from 'components/Loading';
import ShowPage from './ShowPage';
import ListPage from './ListPage';
// TODO: Is this dead code?
/**
 * Example Campaigns
 *
 * @returns
 */
const ExampleCampaigns = () => {
  const dispatch = useDispatch();
  const productUIDs = useSelector(selectors.getExampleCampaigns());

  async function fetchData() {
    const exampleUIDsSnap = await firebase.db.ref('admin/exampleCampaigns').once('value');
    const exampleUIDs = exampleUIDsSnap.val();

    const exampleCampaigns = {};
    const brandUIDs = [];
    await Promise.map(
      exampleUIDs,
      async exampleUID => {
        const productDoc = await firebase.firestore
          .collection('products')
          .doc(exampleUID)
          .get();

        const product = productDoc.data();
        exampleCampaigns[exampleUID] = product;
        brandUIDs.push(product.brandUID);
      },
      { concurrency: 3 }
    );

    const brands = {};
    await Promise.map(
      brandUIDs,
      async brandUID => {
        const brandDoc = await firebase.firestore
          .collection('brands')
          .doc(brandUID)
          .get();
        brands[brandUID] = brandDoc.data();
      },
      { concurrency: 3 }
    );
    dispatch(actions.entities.brands.addBrands(brands));
    dispatch(actions.exampleCampaigns.setExampleCampaigns(exampleCampaigns));
  }

  useEffect(() => {
    if (productUIDs.length === 0) {
      fetchData();
    }
  }, []);

  if (productUIDs.length === 0) {
    return <Loading.ExampleList />;
  }

  return (
    <Switch>
      <Route path={EXAMPLE_CAMPAIGNS_PATH} component={ListPage} exact />
      <Route path={SHOW_EXAMPLE_CAMPAIGN_PATH} component={ShowPage} />
      <Redirect to={EXAMPLE_CAMPAIGNS_PATH} />
    </Switch>
  );
};

export default ExampleCampaigns;
