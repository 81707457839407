import React from 'react';
import DesktopImage from 'images/trend-desktop.png';

const MobileView = () => {
  return (
    <div id="mobile-view-page">
      <div className="text-center image">
        <img width={248} src={DesktopImage} alt="mobile-view" />
      </div>
      <h1>
        Trend is built for desktop use only. Log in to app.trend.io from your computer to use the
        platform.
      </h1>
    </div>
  );
};

export default MobileView;
