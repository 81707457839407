/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable radix */
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row, Table } from 'antd';
import MinLayout from 'components/Common/MinLayout';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import Influencer from 'components/Influencers';
import { useSession } from 'components/Session';
import notesIcon from 'images/icons/notes.svg';
import { calculatePartnershipCreditCost } from 'utils/content';
import formatSocialData, { formatSocialDataMin } from '../Helpers/numeral';
import ApplicationActions from './ApplicationActions';
import { sortApplications } from './utils';
import ApplicationFilter from './ApplicationFilter';
import CreatorContentStyle from './CreatorContentStyle';
import isSocialCampaign from '../../lib/Common/isSocialCampaign';
import FavoriteCreatorButton from './FavoriteCreators/FavoriteCreatorButton';
import { NotesModal } from '../Common/NotesModal';

const SOCIAL_CAMPAIGN_COLUMNS = [
  {
    title: 'FOLLOWERS',
    dataIndex: 'followedByCount',
    key: 'followedByCount',
    className: 'followers text-right',
    rowClassName: 'followers',
    sorter: true,
    render: formatSocialData,
  },
  {
    title: 'LIKES/POST',
    dataIndex: 'avgLikeCount',
    key: 'avgLikeCount',
    className: 'like-count text-right',
    rowClassName: 'like-count',
    sorter: true,
    render: formatSocialData,
  },
  {
    title: 'COMS/POST',
    dataIndex: 'avgCommentCount',
    key: 'avgCommentCount',
    className: 'comment-count text-right',
    rowClassName: 'comment-count',
    sorter: true,
    render: formatSocialData,
  },
  {
    title: 'ENGAGEMENT',
    dataIndex: 'engagementRate',
    key: 'engagementRate',
    className: 'engagement-rate text-right',
    rowClassName: 'engagement-rate',
    sorter: true,
    render: val => `${formatSocialDataMin(val)}%`,
  },
];

const CONTENT_CAMPAIGN_COLUMNS = [
  {
    title: 'CONTENT STYLE',
    dataIndex: 'portfolio',
    key: 'portfolio',
    className: 'content-styles',
    rowClassName: 'content-style',
    sorter: false,
    render: (val = { videos: [], photos: [] }, data) => {
      const campaignType = data.type;
      const videos = (val.videos || []).map(url => ({ url, type: 'video' }));
      const photos = (val.photos || []).map(url => ({ url, type: 'image' }));

      const portfolio = campaignType === 'image' ? [...photos, ...videos] : [...videos, ...photos];

      if (portfolio.length < 4) {
        // If portfolio is less than 4 add social images
        // This is for handling legacy users
        const { images } = data;
        const instagramImages = Object.values(images || {}).map(url => ({ url, type: 'image' }));
        portfolio.push(...instagramImages);
      }

      return <CreatorContentStyle portfolio={portfolio.slice(0, 4)} />;
    },
  },
];

const ACTION_COLUMN = {
  title: '',
  dataIndex: 'actions',
  key: 'actions',
  className: 'actions',
  render: val => {
    return <ApplicationActions {...val} />;
  },
};

/**
 * @typedef {{
 * data: import("types").Partnership[],
 * approveCallback: (partnershipUID: string) => void,
 * rejectCallback: (partnershipUID: string) => void,
 * viewProfileCallback: (partnershipUID: string) => void
 * }} ApplicationProps
 *
 * @type {React.FC<ApplicationProps>}
 */
const Applications = ({ data, approveCallback, rejectCallback, viewProfileCallback }) => {
  const campaignType = data[0].type;
  const [showModalNote, setShowModalNote] = useState(false);
  const [notesData, setNotesData] = useState('');
  const [userVal, setUserVal] = useState('');

  const DEFAULT_COLUMNS = showNotesModal => [
    {
      dataIndex: 'partnershipUID',
      key: 'favoriteCreator',
      className: 'favorite-creator',
      render: uid => {
        return <FavoriteCreatorButton partnershipUID={uid} />;
      },
    },
    {
      title: 'CREATOR',
      dataIndex: 'influencerUID',
      key: 'influencerUID',
      render: (val, data) => (
        <>
          <Influencer.Avatar
            influencerUID={val}
            showWorkBefore
            isSocialCampaign={isSocialCampaign(data.type)}
          />

          {data.writtenApplication && (
            <div
              style={{ marginTop: '1rem' }}
              onClick={() => {
                showNotesModal();
                setNotesData(data);
                setUserVal(val);
              }}
            >
              <img
                src={notesIcon}
                alt="notes icon"
                width="24"
                height="24"
                style={{ display: 'inline-block' }}
              />
              <span
                style={{
                  color: 'black',
                  marginLeft: '4px',
                  fontFamily: 'SFProDisplay',
                  fontSize: '12px',
                }}
              >
                Creator Application Notes
              </span>
            </div>
          )}
        </>
      ),
      className: 'influencer-details',
    },
    {
      title: 'CREDIT COST',
      dataIndex: 'creditCost',
      className: 'creator-credits',
      sorter: true,
      key: 'creditCost',
      render: (obj, data) => <div className="credits-circle">{data.creditCost}</div>,
    },
    {
      title: 'AGE',
      dataIndex: 'ageRange',
      className: 'age-range text-right',
      key: 'ageRange',
      rowClassName: 'age-range',
      sorter: true,
    },
    {
      title: 'APPLIED',
      dataIndex: 'dateApplied',
      className: 'date-applied text-right',
      key: 'createdAt',
      rowClassName: 'date-applied',
      sorter: true,
    },
  ];

  const columns = [
    ...DEFAULT_COLUMNS(() => {
      setShowModalNote(true);
    }),
    ...(campaignType === 'social' ? SOCIAL_CAMPAIGN_COLUMNS : CONTENT_CAMPAIGN_COLUMNS),
    ACTION_COLUMN,
  ];

  const influencers = useSelector(selectors.getInfluencers());
  const { brand } = useSession();
  const [filterAndSorters, setFilterAndSorters] = useState({
    sortBy: {
      field: 'dateApplied',
      order: 'asc',
    },
  });

  const handleTableChange = async (pagination, filters, { field, order }) => {
    setFilterAndSorters(state => ({ ...state, sortBy: { field, order } }));
  };

  const handleFilterChange = async (filters = {}) => {
    setFilterAndSorters(state => ({ ...state, ...filters }));
  };

  let source = data.map(campaign => {
    const influencer = influencers[campaign.influencerUID];
    //
    // NOTE: We calculate the credit cost for a partnership w/o API
    const creditCost = calculatePartnershipCreditCost(influencer);
    //
    //
    //

    return {
      ...campaign,
      ...influencer,
      creditCost,
      isFavorite: brand.favorites && brand.favorites.includes(campaign.influencerUID),
      partnershipUID: campaign.uid,
      appliedAt: campaign.createdAt,
      key: campaign.influencerUID,
      dateApplied: moment.unix(campaign.createdAt).format('MMM DD'),
      actions: {
        partnershipUID: campaign.uid,
        approveCallback: () => approveCallback(campaign.uid),
        rejectCallback: () => rejectCallback(campaign.uid),
        viewProfileCallback: () => viewProfileCallback(campaign.uid),
      },
    };
  });

  source = sortApplications(source, filterAndSorters);

  // eslint-disable-next-line consistent-return
  function requiredDeliverableContent() {
    const { type } = data[0];
    // eslint-disable-next-line default-case
    switch (type) {
      case 'image':
        return 'Each creator will deliver 5 photos';
      case 'video':
        return 'Each creator will deliver 2 videos';
      case 'social':
        return 'Each creator will post to their Instagram feeds';
    }
  }

  function openDrawerTableCells(target) {
    const parentClasses = [
      'influencer-details',
      'influencer-info-username',
      'influencer-info-state',
      'age-range text-right',
      'age-range',
      'date-applied text-right',
      'date-applied',
      'followers text-right',
      'followers',
      'like-count text-right',
      'like-count',
      'comment-count text-right',
      'comment-count',
      'engagement-rate text-right',
      'engagement-rate',
      'creator-credits',
      'influencer-avatar-image',
    ];

    let open = false;
    if (typeof target === 'string') {
      parentClasses.forEach(name => {
        if (target.includes(name)) {
          open = true;
        }
      });
    }
    return open;
  }

  return (
    <>
      <div
        style={{
          background: '#fcfcfc',
          borderBottom: '1px solid #e8e8e8',
          padding: '15px 0',
        }}
      >
        <Row type="flex" justify="space-around">
          <MinLayout>
            <Col span={14} className="pt-1">
              <Row>
                <span
                  className="type-sfpro-regular size-14px"
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '15px',
                    color: '#5B6572',
                    float: 'left',
                  }}
                >
                  {requiredDeliverableContent()}
                </span>
              </Row>
              <Row>
                <span
                  className="type-sfpro-regular size-11px"
                  style={{
                    fontStyle: 'italic',
                    fontWeight: '700',
                    color: '#5B6572',
                    float: 'left',
                    lineHeight: '18px',
                  }}
                >
                  Applications will be automatically rejected after 45 days
                </span>
              </Row>
            </Col>
            <Col span={10}>
              <ApplicationFilter onChange={handleFilterChange} />
            </Col>
          </MinLayout>
        </Row>
      </div>
      <div className="application-container" id="applicant-lists">
        <Row type="flex" justify="space-around">
          <MinLayout>
            <Table
              columns={columns}
              dataSource={source}
              className="trend-table"
              cellpadding="0"
              cellspacing="0"
              sortDirections={['descend', 'ascend']}
              onChange={handleTableChange}
              pagination={false}
              onRow={record => ({
                onClick: e => {
                  return (
                    openDrawerTableCells(e.target.className) &&
                    !['APPROVE', 'PASS'].includes(e.target.innerText) &&
                    viewProfileCallback(record.partnershipUID)
                  );
                },
              })}
            />
          </MinLayout>
        </Row>
      </div>

      {showModalNote && (
        <NotesModal
          userId={userVal}
          contentValue={notesData.writtenApplication}
          showModal={showModalNote}
          closeModal={() => setShowModalNote(false)}
        />
      )}
    </>
  );
};

export default Applications;
