import moment from 'moment';
import { Input, Button, Row, Col, Modal, Avatar, Icon } from 'antd';
import Container from 'components/Common/Container';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import Countdown from 'antd/lib/statistic/Countdown';
import { CREATOR_CONTENT_STATUS } from 'constants/creatorContents';
import { ReactComponent as CloseIcon } from 'images/Modal/icon-close.svg';
import { CAMPAIGN_STYLE_CATEGORY_LABEL, CAMPAIGN_TYPE } from 'constants/campaigns';
import { ReactComponent as ReviseIcon } from 'images/Post/PostReject.svg';
import ApprovalContentCard from './ApprovalContentCard';

const { TextArea } = Input;

/**
 * Creator Content Approval Modal Component
 *
 * @typedef {{
 * creatorContentUID: string,
 * show: boolean,
 * onApprove: (creatorContentUID: string, contentUIDs: []) => Proise<void>,
 * onClose: () => void,
 * onReject: (creatorContentUID: string, approvedContentUIDs: [], reason ) => void
 * }} ApprovalModalProps
 * @type {React.FC<ApprovalModalProps>}
 */
const ApprovalModal = ({ creatorContentUID, show, onClose, onApprove, onReject }) => {
  const {
    influencerUID,
    productUID,
    contents = {},
    requiredContentCount,
    category,
    submittedAt,
    type,
    rejectedAt,
  } = useSelector(selectors.getCreatorContent(creatorContentUID)) || {};
  const { type: productType } = useSelector(selectors.getProduct(productUID)) || {};

  const approvedAt = submittedAt && moment.unix(submittedAt).add(96, 'hours');
  const { name, username, image, firstName, lastName, fullName } =
    useSelector(selectors.getInfluencer(influencerUID)) || {};
  const [approvedContentUIDs, setApprovedContentUIDs] = useState([]);
  const [showPartialModal, setShowPartialModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const totalCount = Object.keys(contents).length;
  useEffect(() => {
    if (creatorContentUID) {
      setApprovedContentUIDs([
        ...Object.keys(contents).filter(
          x => contents[x].status === CREATOR_CONTENT_STATUS.APPROVED
        ),
      ]);
    } else {
      setApprovedContentUIDs([]);
    }
  }, [creatorContentUID]);

  function canSubmitReason(str) {
    setReason(str);
    if (reason.trim().length !== 0 && reason.length >= 20) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  function handleContentApprove(contentUID) {
    setApprovedContentUIDs(x => {
      if (x.includes(contentUID)) {
        return x.filter(a => a !== contentUID);
      }

      if (
        productType === CAMPAIGN_TYPE.SOCIAL ||
        approvedContentUIDs.length < requiredContentCount
      ) {
        return [...x, contentUID];
      }

      return x;
    });
  }

  async function handleApprove() {
    setSubmitting(true);

    if (
      productType === CAMPAIGN_TYPE.SOCIAL ||
      approvedContentUIDs.length === requiredContentCount
    ) {
      await onApprove(creatorContentUID, approvedContentUIDs);
      setApprovedContentUIDs([]);
    } else if (!rejectedAt) {
      setShowPartialModal(true);
    }

    setSubmitting(false);
  }

  async function handleReject() {
    setSubmitting(true);
    await onReject(creatorContentUID, approvedContentUIDs, reason);
    setSubmitting(false);
    setShowPartialModal(false);
    setShowRejectModal(false);
    setApprovedContentUIDs([]);
  }

  async function handlePassContent() {
    setApprovedContentUIDs([]);
    setShowRejectModal(true);
  }

  const sortedContentUIDs = Object.keys(contents).sort(
    (a, b) => contents[a].position - contents[b].position
  );

  const renderApprovalContents = sortedContentUIDs.map(contentUID => {
    const content = contents[contentUID];
    const imageUrl = content.watermarkUrl || content.standardResolutionImage;
    const videoUrl = content.watermarkUrl || content.highResolutionVideo;
    const contentUrl = content.type === 'image' ? imageUrl : videoUrl;

    return (
      <ApprovalContentCard
        key={contentUID}
        type={content.type}
        url={contentUrl}
        isApproved={approvedContentUIDs.includes(contentUID)}
        onClick={() => handleContentApprove(contentUID)}
      />
    );
  });

  const contentType =
    type && typeof type === 'string' && type !== 'image'
      ? type[0].toUpperCase() + type.substring(1, type.length)
      : 'Photo';

  return (
    <>
      <Modal
        className="trend-modal content-approval-modal"
        visible={show}
        width={742}
        maskClosable
        closable={false}
        footer={null}
      >
        <div className="modal-content">
          <Button className="close" onClick={onClose}>
            <Icon component={CloseIcon} />
          </Button>
          <div className="header">
            <Row type="flex">
              <Col xs={16}>
                <div className="influencer-details">
                  <Avatar src={image} size={60} />
                </div>
                <div className="influencer-details">
                  <div className="influencer-details-username">
                    {productType === CAMPAIGN_TYPE.SOCIAL
                      ? `@${username}`
                      : name || fullName || `${firstName} ${lastName}`}
                  </div>
                  <div className="content-stats">
                    {productType === CAMPAIGN_TYPE.SOCIAL
                      ? `${contentType} Approval`
                      : `Content Type: ${CAMPAIGN_STYLE_CATEGORY_LABEL[category]}`}
                  </div>
                </div>
              </Col>
              <Col className="content-count" xs={8}>
                <div className="content-count-number">
                  {productType === CAMPAIGN_TYPE.SOCIAL
                    ? approvedContentUIDs.length
                    : `${approvedContentUIDs.length}/${requiredContentCount}`}
                </div>
                <div className="content-count-label">Selected for approval</div>
              </Col>
            </Row>
          </div>

          <div
            className="submitted-contents"
            style={{ overflowX: totalCount > 3 ? 'scroll' : 'hidden' }}
          >
            <Container width={600}>
              <div style={{ minWidth: '100%', width: (200 + 20) * totalCount }}>
                {renderApprovalContents}
              </div>
            </Container>
          </div>
        </div>
        <Row className="footer" type="flex" justify="space-between">
          <Col style={{ width: 446 }}>
            <h3>Select your favorite content for approval</h3>
            <p>
              If you like multiple pieces, select them and allow the creator to choose which one to
              post. You’re allowed 1 round of revisions per creator.
            </p>
          </Col>
          <Col style={{ width: 183, textAlign: 'center' }}>
            <Button
              className="confirm"
              disabled={
                approvedContentUIDs.length < 1 ||
                (rejectedAt &&
                  approvedContentUIDs.length !== requiredContentCount &&
                  requiredContentCount &&
                  productType !== 'social')
              }
              loading={submitting}
              onClick={handleApprove}
            >
              Confirm Selection
              <br />
              <span className="timer">
                Time until auto-approval:{' '}
                {approvedAt && <Countdown value={approvedAt} format="HH:mm:ss" />}
              </span>
            </Button>
            <br />
            {!rejectedAt && (
              <Button className="pass" type="link" onClick={handlePassContent}>
                Pass On All Content
              </Button>
            )}
          </Col>
        </Row>
      </Modal>

      <Modal
        className="trend-modal reject-creator-content"
        visible={showPartialModal}
        width={742}
        maskClosable
        closable={false}
        footer={null}
      >
        <Button className="close-modal" onClick={() => setShowPartialModal(false)}>
          <Icon component={CloseIcon} />
        </Button>
        <Container width={616}>
          <div className="center-horizontally">
            <div className="modal-icon">
              <Icon component={ReviseIcon} />
            </div>
          </div>

          <h2 className="text-center">
            {approvedContentUIDs.length}/{requiredContentCount} pieces of content approved. Request
            revision for {requiredContentCount - approvedContentUIDs.length} piece of content
          </h2>
          <p className="text-center">
            You’re allowed 1 round of rejections per creator. Please add all feedback that you’d
            like to see addressed here! Make sure to use the message section to work out the ideas
            before the creator submits again.
          </p>
        </Container>
        <Container width={448}>
          <TextArea
            value={reason}
            onChange={e => canSubmitReason(e.target.value)}
            placeholder="Add your rejection feedback here. This will show up in your message thread with this creator so you can work together..."
          />
          <div className="text-center" style={{ marginTop: 24 }}>
            <Button
              className="submit"
              disabled={buttonDisabled}
              loading={submitting}
              type="primary"
              onClick={handleReject}
            >
              Send feedback for revision
            </Button>
            <p className="text-center" style={{ color: 'gray', margin: '1.5em 0 0' }}>
              Must include at least 20 characters.
            </p>
          </div>
        </Container>
      </Modal>

      <Modal
        visible={showRejectModal}
        width={742}
        maskCloseable
        closable={false}
        footer={null}
        className="trend-modal reject-creator-content"
      >
        <Button className="close-modal" onClick={() => setShowRejectModal(false)}>
          <Icon component={CloseIcon} />
        </Button>
        <Container width={616}>
          <div className="center-horizontally">
            <div className="reject-icon">
              <div className="dash" />
            </div>
          </div>

          <h2 className="text-center">Why did you pass on all this content?</h2>
          <p className="text-center">
            You’re allowed 1 round of rejections per creator. Please add all feedback that you’d
            like to see addressed here! Make sure to use the message section to work out the ideas
            before the creator submits again.
          </p>
        </Container>
        <Container width={448}>
          <TextArea
            value={reason}
            onChange={e => canSubmitReason(e.target.value)}
            placeholder="Add your rejection feedback here. This will show up in your message thread with this creator so you can work together..."
          />
          <div className="text-center" style={{ marginTop: 24 }}>
            <Button
              className="submit"
              disabled={buttonDisabled}
              loading={submitting}
              type="primary"
              onClick={handleReject}
            >
              Send feedback for revision
            </Button>
            <p className="text-center" style={{ color: 'gray', margin: '1.5em 0 0' }}>
              Must include at least 20 characters.
            </p>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default ApprovalModal;
