import { Button, Col, Row } from 'antd';
import * as routes from 'constants/routes';
import React from 'react';
import mixpanel from 'lib/Analytics/Mixpanel';

const ContentCredit = ({ standardCredits }) => {
  function goTo() {
    if (mixpanel) {
      mixpanel.track('Brand Visits Add Credits');
    }
    routes.toAddCredits();
  }

  return (
    <div className="content-credit-text">
      <Row>
        <Col
          lg={30}
          className="credit-title-block size-12px text-white text-center"
          style={{ paddingTop: '0px' }}
        >
          <span className="credit-value">{standardCredits || 0}</span>
          <br />
          <div className="credit-title">Creator Credits</div>
          <p className="credit-desc">Add credits to hire creators</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <Button className="credit-add" type="primary" block onClick={goTo}>
            Add Creator Credits +
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ContentCredit;
