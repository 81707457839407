import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';

const {
  listenProductApprovePartnerships,
  listenProductSubmittedPartnerships,
  listenProductMessage,
  listenProduct,
  listenProductCreatorContent,
  listenProductPosts,
  listenProductInvitedPartnerships,
} = require('listeners/products');
const { useEffect } = require('react');

class ProductListeners {
  constructor() {
    this.listeners = [];
  }

  push(listener) {
    this.listeners.push(listener);
  }

  killAll() {
    this.listeners.forEach(listener => {
      listener();
    });
    this.listeners = [];
  }
}

export const listeners = new ProductListeners();

/**
 * Product Listener Hook
 *
 * @param {string} productUID
 * @param {boolean} enabled
 */
export const useListenProduct = (productUID, enabled) => {
  const dispatch = useDispatch();
  const productData = useSelector(selectors.getProductPage(productUID));

  useEffect(() => {
    if (enabled && productData && !productData.listenPosts) {
      listeners.push(
        listenProduct(productUID, product => {
          dispatch(actions.entities.products.updateProducts({ [productUID]: product }));
        })
      );
    }
  }, [productData, enabled]);
};

/**
 * Product Partnerships Hook
 *
 * @param {string} productUID
 * @param {boolean} enabled
 */
export const useListenProductPartnerships = (productUID, enabled) => {
  const dispatch = useDispatch();
  const productData = useSelector(selectors.getProductPage(productUID));

  useEffect(() => {
    if (enabled && productData && !productData.listenPartnerships) {
      dispatch(actions.productContexts.setPartnershipListener(productUID));
      listeners.push(
        listenProductApprovePartnerships(productUID, changes => {
          if (changes.added) {
            dispatch(
              actions.productContexts.addProductApprovedPartnerships(productUID, changes.added)
            );
          }
          if (changes.modified) {
            dispatch(actions.entities.partnerships.updatePartnerships(changes.modified));
          }
          if (changes.removed) {
            dispatch(
              actions.productContexts.removeProductApprovedPartnerships(
                productUID,
                Object.keys(changes.removed)
              )
            );
          }
        })
      );

      listeners.push(
        listenProductInvitedPartnerships(productUID, changes => {
          if (changes.added) {
            dispatch(
              actions.productContexts.addProductInvitedPartnerships(productUID, changes.added)
            );
          }
          if (changes.modified) {
            dispatch(actions.entities.partnerships.updatePartnerships(changes.modified));
          }
          if (changes.removed) {
            dispatch(
              actions.productContexts.removeProductInvittedPartnerships(productUID, changes.removed)
            );
          }
        })
      );

      listeners.push(
        listenProductSubmittedPartnerships(productUID, changes => {
          if (changes.added) {
            dispatch(
              actions.productContexts.addProductSubmittedPartnerships(productUID, changes.added)
            );
          }

          if (changes.modified) {
            dispatch(actions.entities.partnerships.updatePartnerships(changes.modified));
          }

          if (changes.removed) {
            dispatch(
              actions.productContexts.removeProductSubmittedPartnerships(
                productUID,
                Object.keys(changes.removed)
              )
            );
          }
        })
      );
    }
  }, [productData, enabled]);
};

/**
 * Product Posts Listener Hook
 *
 * @param {string} productUID
 * @param {boolean} enabled
 */
export const useListenProductPosts = (productUID, enabled) => {
  const dispatch = useDispatch();
  const productData = useSelector(selectors.getProductPage(productUID));

  useEffect(() => {
    if (enabled && productData && !productData.listenPosts) {
      dispatch(actions.productContexts.setProductPostsListener(productUID));
      listeners.push(
        listenProductPosts(productUID, changes => {
          if (changes.added) {
            dispatch(actions.productContexts.addProductPosts(productUID, changes.added));
          }

          if (changes.modified) {
            dispatch(actions.entities.posts.updatePosts(changes.modified));
          }

          if (changes.removed) {
            dispatch(actions.productContexts.removeProductPosts(productUID, changes.removed));
          }
        })
      );
    }
  }, [productData, enabled]);
};

/**
 * Product Message Listener Hook
 *
 * @param {string} productUID
 * @param {boolean} enabled
 */
export const useListenProductMessage = (productUID, enabled) => {
  const dispatch = useDispatch();
  const productData = useSelector(selectors.getProductPage(productUID));

  useEffect(() => {
    if (enabled && productData && !productData.listenMessage) {
      dispatch(actions.productContexts.setProductMessageListener(productUID));

      listeners.push(
        ...listenProductMessage(productUID, (action, messages) => {
          if (action === 'modified') {
            dispatch(actions.entities.messages.writeMessages(messages));
          }

          if (action === 'added') {
            dispatch(actions.productContexts.addProductMessage(productUID, messages));
          }
        })
      );
    }
  }, [productData, enabled]);
};

export const useListenProductCreatorContents = (productUID, enabled) => {
  const dispatch = useDispatch();
  const productData = useSelector(selectors.getProductPage(productUID));

  useEffect(() => {
    if (enabled && productData && !productData.listenCreatorContents) {
      dispatch(actions.productContexts.setProductCreatorContentsListener(productUID));
      listeners.push(
        listenProductCreatorContent(productUID, changes => {
          if (changes.added) {
            dispatch(actions.productContexts.addProductCreatorContents(productUID, changes.added));
          } else if (changes.modified) {
            dispatch(actions.entities.creatorContents.writeCreatorContents(changes.modified));
          } else if (changes.removed) {
            dispatch(
              actions.productContexts.removeProductCreatorContents(
                productUID,
                Object.keys(changes.removed)
              )
            );
          }
        })
      );
    }
  }, [productData, enabled]);
};
