import { Spin } from 'antd';
import * as ROUTES from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { actions, selectors } from 'stores';
import { firebase } from 'lib/Firebase';
import MediaProvider from 'components/Common/MediaViewer/Provider';
import InfluencersPage from './InfluencersPage';
import ReviewsPage from './ReviewsPage';
import ReferralsPage from './ReferralsPage';
import PayoutsPage from './PayoutsPage';
import BusinessApprovalPage from './BusinessApprovalPage';
import ExpiredCreditsPage from './ExpiredCreditsPage';
import PendingInfluencersPage from './PendingInfluencersPage';
import SubscriptionsPage from './SubscriptionsPage';
import ActiveCampaigns from './ActiveCampaigns';
import Brands from './Brands';
import ApiQueues from './ApiQueues';
import Contents from './Contents';

/**
 * Admin V2 Page
 *
 * @type {React.FC}
 */
const AdminV2 = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const brandUIDs = useSelector(selectors.getAdminBrands());

  const fetchBrands = async () => {
    setLoading(true);
    const brandDocs = await firebase.firestore
      .collection('brands')
      .where('status', '==', 'approved')
      .get();
    const brands = {};

    brandDocs.forEach(doc => {
      brands[doc.id] = doc.data();
    });

    dispatch(actions.adminPages.setAdminBrands(brands));
    setLoading(false);
  };

  useEffect(() => {
    if (!brandUIDs) {
      fetchBrands();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <MediaProvider>
      <Switch>
        <Route path={ROUTES.ADMINV2_ACTIVE_CAMPAIGNS_PATH} component={ActiveCampaigns} />
        <Route path={ROUTES.ADMINV2_ACTIVE_INFLUENCERS_PATH} component={InfluencersPage} exact />
        <Route path={ROUTES.ADMINV2_BRANDS_PATH} component={Brands} />
        <Route path={ROUTES.ADMINV2_REVIEWS_PATH} component={ReviewsPage} exact />
        <Route path={ROUTES.ADMINV2_REFERRALS_PATH} component={ReferralsPage} exact />
        <Route path={ROUTES.ADMINV2_PAYOUTS_PATH} component={PayoutsPage} />
        <Route path={ROUTES.ADMINV2_BUSINESS_APPROVAL_PATH} component={BusinessApprovalPage} />
        <Route path={ROUTES.ADMINV2_EXPIRED_CREDITS_PATH} component={ExpiredCreditsPage} />
        <Route path={ROUTES.ADMINV2_PENDING_INFLUENCERS_PATH} component={PendingInfluencersPage} />
        <Route path={ROUTES.ADMINV2_SUBSCRIPTIONS_PATH} component={SubscriptionsPage} />
        <Route path={ROUTES.ADMINV2_API_QUEUES} component={ApiQueues} />
        <Route path={ROUTES.ADMINV2_CONTENTS_PATH} component={Contents} />

        <Redirect from={ROUTES.ADMINV2_PATH} to="/adminv2/activeCampaigns" />
      </Switch>
    </MediaProvider>
  );
};
export default AdminV2;
