const sortOnDetails = (field, order) => (a, b) => {
  const [first, second] = order === 'ascend' ? [b, a] : [a, b];
  const infA = first[field];
  const infB = second[field];

  if (infA > infB) {
    return -1;
  }
  if (infA < infB) {
    return 1;
  }
  return 0;
};

/**
 * @param {(import('types').Partnership & import('types').Influencer & { appliedAt: number })[]} partnershipsWithInfluencer
 * @param {{ field: string, order: string}} sortBy
 * @returns
 */
const sortedFilter = (partnershipsWithInfluencer, sortBy) => {
  const { field, order } = sortBy;

  if (field === 'ageRange') {
    return partnershipsWithInfluencer.sort(sortOnDetails('ageRange', order));
  }
  return partnershipsWithInfluencer.sort(sortOnDetails(field, order));
};

/**
 * Srot Application
 *
 * @param {(import('types').Partnership & import('types').Influencer & { appliedAt: number })[]} partnershipsWithInfluencer
 * @param {{
 * category: string,
 * location: string,
 * sortBy: { field: string, order: string },
 * influencerType: "vip"| "standard"}} filters
 * @returns
 */
export const sortApplications = (partnershipsWithInfluencer = [], filters) => {
  const { category, location, sortBy, isFavorite } = filters;
  const { order, field } = sortBy;

  // NOTE: This is an array of both the partnership and the influencer
  let filteredPartnerships = partnershipsWithInfluencer.filter(partnership => {
    // Handle the location filter
    const stateKeySelected = location;
    const matchLocation =
      location && stateKeySelected.toLowerCase() !== 'all'
        ? partnership.state && partnership.state.toLowerCase() === stateKeySelected.toLowerCase()
        : true;

    // Handle the category filter
    let matchCategory = true;
    const creatorCategories = partnership.socialCategories || []; // influencer categories
    if (category && creatorCategories.length > 0) {
      matchCategory = creatorCategories.includes(category);
    }

    // Handle the favorite filter
    const matchFavorite = isFavorite ? partnership.isFavorite : true;

    return matchCategory && matchLocation && matchFavorite;
  });

  if (!field || field === 'dateApplied') {
    filteredPartnerships = filteredPartnerships.sort((a, b) => a.appliedAt - b.appliedAt);

    return !field || order === 'descend' ? filteredPartnerships : filteredPartnerships.reverse();
  }

  return sortedFilter(filteredPartnerships, sortBy);
};
