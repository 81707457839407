import React, { useState } from 'react';
import ContentPreviewModal from 'components/Common/ContentPreviewModal';
import './CreatorContentStyle.less';

// interface CreatorContentStyleProps {
//   portfolio: Array<{
//     type: 'video' | 'image',
//     url: string,
//   }>;
// }

const CreatorContentStyle = ({ portfolio }) => {
  const [visible, setVisible] = useState(false); // false | contentUrl
  const handleModalClick = (updateVisible, contentUrl) => {
    if (!updateVisible) {
      setVisible(false);
    } else {
      setVisible(contentUrl);
    }
  };

  return (
    <div className="content-style-container">
      {portfolio.map(({ type, url }) => (
        <React.Fragment key={url}>
          <ContentPreviewModal
            contentType={type}
            url={url}
            visible={visible === url}
            setVisible={updateVisible => handleModalClick(updateVisible, url)}
            imageKitPreview
            ikTransformations={[
              {
                height: '120',
                width: '120',
              },
            ]}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default CreatorContentStyle;
