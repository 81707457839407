import ApprovalCard from '../../pages/Campaigns/ShowPage/Approvals/ApprovalCard/ApprovalCard';
import Avatar from './Avatar';
import Table from './Table';
import ApprovalModal from './ApprovalModal';
import Card from './Card';
import Filters from './Filter';
import RecentCard from './RecentCard';
import ContentAvatar from './ContentAvatar';
import AdminFilters from './AdminFilters';
import AdminCard from './AdminCard';

const CreatorContent = {
  RecentCard,
  Filters,
  Card,
  ApprovalModal,
  ApprovalCard,
  Table,
  Avatar,
  ContentAvatar,
  AdminFilters,
  AdminCard,
};

export default CreatorContent;
