/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Image from 'components/Common/Image';
import ImagePreviewModal from 'components/Common/ImagePreviewModal';
// import Video from 'components/Common/Video';
import VideoPreviewModal from 'components/Common/VideoPreviewModal';
import './ContentPreviewModal.less';
// import { isFileImage } from 'helpers/isImage';
import ImageKitImage from '../ImageKitImage';

const ContentPreviewModal = ({
  contentType,
  url,
  visible,
  setVisible,
  thumbnailUrl,
  imageKitPreview,
  ikTransformations,
}) => {
  const [fallbackType, setFallbackType] = useState();
  const openModal = () => {
    setVisible(true);
  };

  const onImageError = () => {
    if (!fallbackType) {
      setFallbackType('video');
    }
  };
  const onVideoError = () => {
    if (!fallbackType) {
      setFallbackType('image');
    }
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <span onClick={openModal} className="content-preview-click">
        {imageKitPreview && (
          <ImageKitImage
            path={url}
            transformation={ikTransformations}
            videoPreview={contentType === 'video'}
          />
        )}
        {imageKitPreview ? (
          <></>
        ) : thumbnailUrl ? (
          <Image src={thumbnailUrl} />
        ) : fallbackType === 'image' || contentType === 'image' ? (
          <Image src={url} onError={onImageError} />
        ) : fallbackType === 'video' || contentType === 'video' ? (
          <div className="video-loader">
            <video
              src={url}
              autoPlay={false}
              preload="auto"
              controls={false}
              onError={onVideoError}
            />
          </div>
        ) : (
          <></>
        )}
      </span>
      {contentType === 'image' ? (
        <ImagePreviewModal visible={visible} setVisible={setVisible} imageLink={url} />
      ) : (
        <VideoPreviewModal visible={visible} setVisible={setVisible} videoLink={url} autoPlay />
      )}
    </>
  );
};

export default ContentPreviewModal;
