import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { ReactComponent as InstagramIcon } from 'images/components/Products/Details/Instagram.svg';
import { ReactComponent as TiktokIcon } from 'images/components/Products/Details/Tiktok.svg';
import { ReactComponent as FacebookIcon } from 'images/components/Products/Details/Facebook.svg';
import { ReactComponent as AmazonIcon } from 'images/components/Products/Details/Amazon.svg';
import { ReactComponent as GeneralUseIcon } from 'images/components/Products/Details/GeneralUse.svg';
import { ReactComponent as YoutubeIcon } from 'images/components/Products/Details/Youtube.svg';
import { CAMPAIGN_PLATFORM, CAMPAIGN_TYPE } from 'constants/campaigns';
import Gender from './Gender';

const PLATFORM_ICON = {
  [CAMPAIGN_PLATFORM.INSTAGRAM]: InstagramIcon,
  [CAMPAIGN_PLATFORM.TIKTOK]: TiktokIcon,
  [CAMPAIGN_PLATFORM.FACEBOOK]: FacebookIcon,
  [CAMPAIGN_PLATFORM.AMAZON]: AmazonIcon,
  [CAMPAIGN_PLATFORM.YOUTUBE]: YoutubeIcon,
  [CAMPAIGN_PLATFORM.GENERAL_USE]: GeneralUseIcon,
};

const appendHttpProtocol = link => (/http/.test(link) ? link : `http://${link}`);

/**
 * @typedef {{product: import('components/Session/Context').Product}} DetailsProps
 * @type {React.FC<DetailsProps>}
 */
const Details = ({ product }) => {
  const { url, platform, value, category, type, styleCategory } = product;

  const PlatFormIcon = PLATFORM_ICON[platform];

  return (
    <div id="campaignDetails">
      <div className="side-header-text">Campaign Details</div>

      {type === CAMPAIGN_TYPE.IMAGE && (
        <div className="type-description">
          This is a photography campaign. Each creator you activate will produce{' '}
          {styleCategory.length} photos for you.
        </div>
      )}

      {type === CAMPAIGN_TYPE.VIDEO && (
        <div className="type-description">
          This is a video content campaign. Each creator you activate will produce{' '}
          {styleCategory.length} videos for you.
        </div>
      )}

      <div className="side-sub-header-text">Website</div>
      <div className="side-text-description">
        <a
          href={appendHttpProtocol(url)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black' }}
        >
          {url}
        </a>
      </div>

      <div className="side-sub-header-text" style={{ marginTop: '17px' }}>
        product value
      </div>
      <div className="side-text-description">
        <CurrencyFormat value={value} displayType="text" thousandSeparator prefix="$" />
      </div>

      <div className="side-sub-header-text" style={{ marginTop: '17px' }}>
        Product Category
      </div>
      <div className="side-text-description" style={{ textTransform: 'capitalize' }}>
        {category}
      </div>

      {PlatFormIcon && CAMPAIGN_TYPE.VIDEO === type && (
        <>
          <div className="side-sub-header-text" style={{ marginTop: '17px' }}>
            VIDEOS FORMATTED FOR
          </div>
          <div className="side-text-description platform">
            <PlatFormIcon style={{ width: '21px', height: '21px', marginRight: '8px' }} />
            {platform.split(/(?=[A-Z])/).join(' ')}
          </div>
        </>
      )}

      <Gender product={product} />
    </div>
  );
};

export default Details;
