import { trackGtagEvent } from './gtag';

const setUserData = ({ email, ownerName = '' }) => {
  const [firstName, middleName, lastName] = ownerName.split(' ');
  trackGtagEvent({
    event: 'userData',
    userAttributes: {
      email,
      firstName,
      lastName: lastName || middleName,
      country: 'us',
    },
  });
};

export default setUserData;
