export const countRequiredPost = (partnershipUIDs, partnerships) => {
  let requiredPosts = 0;
  partnershipUIDs.forEach(x => {
    requiredPosts += partnerships[x].requiredPosts;
  });
  return requiredPosts;
};
/**
 * Count Creators
 * @param {Record<string, any>} partnerships
 */
export const countCreators = partnerships => {
  return Object.keys(partnerships).filter(x => partnerships[x].status !== 'submitted').length;
};
/**
 * Count Likes
 * @param {string[]} postUIDs
 * @param {Record<string, any>} posts
 * @returns {[number[], number, number]} [approvedPostCount, totalLikes, totalComments]
 */
export const countApprovedPostsStats = (postUIDs, posts) => {
  let totalLikes = 0;
  let totalComments = 0;

  postUIDs.forEach(x => {
    if (posts[x].likes) {
      totalLikes += posts[x].likes;
      totalComments += posts[x].comments;
    }
  });

  return [totalLikes, totalComments];
};
