import Promise from 'bluebird';
import { Button, Row, Col } from 'antd';
import Container from 'components/Common/Container';
import Product from 'components/Products';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Brands from 'components/Brands';
import Loading from 'components/Loading';
import './activeCampaign.less';
import { actions, selectors } from 'stores';
import { firebase } from 'lib/Firebase';

const searchProducts = async (filters, lastCampaignDoc, limit) => {
  let query = firebase.firestore
    .collection('products')
    .where('brandActive', '==', true)
    .where('status', '==', 'active')
    .orderBy('createdAt', 'desc');

  if (filters.category && filters.health) {
    query = query.where(filters.category, '==', filters.health);
  }

  if (filters.brand) {
    query = query.where('brandUID', '==', filters.brand);
  }

  if (filters.search) {
    query = query.where('brandUID', '==', filters.search);
  }

  if (filters.gender) {
    query = query.where('gender', '==', filters.gender);
  }

  if (filters.type) {
    query = query.where('type', '==', filters.type);
  }

  if (lastCampaignDoc) {
    query = query.startAfter(lastCampaignDoc);
  }

  return query.limit(limit).get();
};

/**
 * Admin Active Campaigns Page
 *
 * @type {React.FC}
 */
const ListPage = () => {
  const brands = useSelector(selectors.getBrands());
  const [productUIDs, setProductUIDs] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10, page: 1 });
  const [filters, setFilters] = useState({});
  const [lastProductDoc, setLastProductDoc] = useState();
  const [productModal, setProductModal] = useState({
    productUID: undefined,
    show: false,
  });
  const dispatch = useDispatch();

  async function fetchDataListener(newFilter, lastDoc) {
    if (!lastDoc) {
      setProductUIDs([]);
      setLastProductDoc();
      setPagination(x => ({ ...x, page: 1 }));
    }

    const productDocs = await searchProducts(newFilter, lastDoc, pagination.limit);
    const products = {};
    const sortedProduct = [];
    const addedBrandUIDs = [];
    let newLastDoc = lastDoc;

    productDocs.forEach(doc => {
      const product = doc.data();
      const { brandUID } = product;
      products[doc.id] = product;
      sortedProduct.push(doc.id);
      if (!brands[brandUID]) {
        addedBrandUIDs.push(brandUID);
      }
      newLastDoc = doc;
    });

    const addedBrands = {};

    await Promise.map(
      addedBrandUIDs,
      async brandUID => {
        if (!brandUID) return;

        const brandDoc = await firebase.firestore
          .collection('brands')
          .doc(brandUID)
          .get();

        addedBrands[brandUID] = brandDoc.data();
      },
      { concurrency: 3 }
    );

    dispatch(actions.entities.brands.addBrands(addedBrands));

    dispatch(actions.entities.products.addProducts(products));
    setProductUIDs(x => [...new Set([...x, ...sortedProduct])]);

    setLastProductDoc(newLastDoc);
  } // end

  useEffect(() => {
    fetchDataListener(filters, null);
  }, []);

  function handleFilter(newFilters) {
    if (
      filters.type !== newFilters.type ||
      filters.brand !== newFilters.brand ||
      filters.search !== newFilters.search ||
      filters.gender !== newFilters.gender ||
      (filters.category && filters.health && (!newFilters.category || !newFilters.health)) ||
      (newFilters.category && newFilters.health)
    ) {
      fetchDataListener(newFilters, null);
    }

    setFilters(newFilters);
  }

  function handleSearchChange(val) {
    fetchDataListener({ ...filters, ...val }, null);
    setFilters(x => ({ ...x, ...val }));
  }

  function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });

    fetchDataListener(filters, lastProductDoc);
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;
  const list = productUIDs
    .map(productUID => {
      return (
        <Product.Provider
          isAdmin
          key={productUID}
          productUID={productUID}
          renderLoading={<Loading.AdminProductCard md={8} xs={8} />}
        >
          <Product.AdminCard
            productUID={productUID}
            md={8}
            xs={12}
            onClick={() => setProductModal({ show: true, productUID })}
          />
        </Product.Provider>
      );
    })
    .slice(0, showLimit);

  return (
    <Container width={940} id="admin-brands-active-campaigns">
      <Row type="flex" justify="space-between" className="search-filters">
        <Col md={6} xs={24}>
          <Brands.AdminSearch onChange={handleSearchChange} />
        </Col>
        <Col md={16} xs={24}>
          <Row type="flex" justify="end" gutter={8}>
            <Product.AdminFilters onChange={handleFilter} value={filters} />
          </Row>
        </Col>
      </Row>
      {productUIDs.length > 0 && (
        <Product.Provider
          productUID={productModal.productUID || productUIDs[0]}
          renderLoading={<div />}
        >
          <Product.AdminModal
            visible={productModal.show}
            productUID={productModal.productUID || productUIDs[0]}
            onClose={() => setProductModal(x => ({ ...x, show: false }))}
          />
        </Product.Provider>
      )}

      <Row gutter={16} type="flex" align="top">
        {list}
      </Row>
      <div className="text-center">
        {productUIDs.length > showLimit && productUIDs !== 0 && (
          <Button className="load-more" onClick={loadMore}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ListPage;
