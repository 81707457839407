import { PARTNERSHIP_STATUS } from 'constants/partnerships';
import { firebase } from '../lib/Firebase';
import { docCallbackListenerParser } from './utils';

/**
 * Product Listener
 *
 * @param {string} productUID
 * @param {(product: import('types').Product) => void} callback
 */
export const listenProduct = (productUID, callback) => {
  return firebase.firestore
    .collection('products')
    .doc(productUID)
    .onSnapshot(doc => {
      callback({ uid: productUID, ...doc.data() });
    });
};

/**
 * Product Message Listener
 *
 * @param {*} productUID
 * @param {(action: "modified" | "added", messages: Record<string, any>) => void} callback
 * @returns {(() => any)[]}
 */
export const listenProductMessage = (productUID, callback) => {
  const listeners = [];
  const messageRef = firebase
    .messages()
    .orderByChild('details/partnershipUID')
    .equalTo(productUID);
  listeners.push(
    messageRef.on('child_changed', snap => {
      callback('modified', { [snap.key]: snap.val() });
    })
  );

  listeners.push(
    messageRef.on('child_added', snap => {
      callback('added', { [snap.key]: snap.val() });
    })
  );

  return listeners;
};

/**
 * Product Post Listener
 * @param {string} productUID
 * @param {(dataChanges: {
 *  added?: Record<string, import('types').Post>,
 *  modified?: Record<string, import('types').Post>,
 *  removed?: Record<string, import('types').Post>
 * }) => void} callback
 */
export const listenProductPosts = (productUID, callback) => {
  return firebase.firestore
    .collection('posts')
    .where('productUID', '==', productUID)
    .onSnapshot(docCallbackListenerParser(callback));
};

/**
 * Product Submitted Partnership Listener
 * @param {string} productUID
 * @param {(dataChanges: {
 *  added?: Record<string, import('types').Partnership>,
 *  modified?: Record<string, import('types').Partnership>,
 *  removed?: Record<string, import('types').Partnership>
 * }) => void} callback
 */
export const listenProductSubmittedPartnerships = (productUID, callback) => {
  return firebase.firestore
    .collection('influencersPartnerships')
    .where('productUID', '==', productUID)
    .where('status', '==', PARTNERSHIP_STATUS.SUBMITTED)
    .onSnapshot(docCallbackListenerParser(callback));
};

/**
 * Product Approved Partnership Listener
 *
 * @param {string} productUID
 * @param {(dataChanges: {
 *  added?: Record<string, import('types').Partnership>,
 *  modified?: Record<string, import('types').Partnership>,
 *  removed?: Record<string, import('types').Partnership>
 * }) => void} callback
 */
export const listenProductApprovePartnerships = (productUID, callback) => {
  return firebase.firestore
    .collection('influencersPartnerships')
    .where('productUID', '==', productUID)
    .where('status', 'not-in', [
      PARTNERSHIP_STATUS.SUBMITTED,
      PARTNERSHIP_STATUS.REJECTED,
      PARTNERSHIP_STATUS.INVITED,
    ])
    .onSnapshot(docCallbackListenerParser(callback));
};

/**
 * Product Invited Partnerships Listener
 *
 * @param {string} productUID
 * @param {(dataChanges: {
 *  added: Record<string, import('types').Partnership>,
 *  modified: Record<string, import('types').Partnership>,
 *  removed: Record<string, import('types').Partnership>,
 * }) => void} callback
 */
export const listenProductInvitedPartnerships = (productUID, callback) => {
  return firebase.firestore
    .collection('influencersPartnerships')
    .where('productUID', '==', productUID)
    .where('status', '==', PARTNERSHIP_STATUS.INVITED)
    .onSnapshot(docCallbackListenerParser(callback));
};

/**
 * Product Pending Creator Content Listener
 *
 * @param {string} productUID
 * @param {(dataChanges: {
 *  added?: Record<string, import('types').CreatorContent>,
 *  modified?: Record<string, import('types').CreatorContent>,
 *  removed?: Record<string, import('types').CreatorContent>
 * }) => void} callback
 * @returns
 */
export const listenProductCreatorContent = (productUID, callback) => {
  return firebase.firestore
    .collection('creatorContents')
    .where('productUID', '==', productUID)
    .onSnapshot(docCallbackListenerParser(callback));
};
