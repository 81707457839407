import moment from 'moment';
import { firebase } from 'lib/Firebase';
import React, { useState } from 'react';
import { Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { adminLoginAsBrand } from 'lib/Firebase/callables/admin';
import { useCookies } from 'react-cookie';

const BrandLoginButton = ({ brandId, children, type }) => {
  const [, setCookies] = useCookies('returnToken');
  const history = useHistory();
  const [logging, setLoggingState] = useState(false);

  async function handleLogin() {
    setLoggingState(true);

    try {
      const { token, returnToken } = await adminLoginAsBrand(brandId);
      setCookies('returnToken', returnToken, {
        path: '/',
        expires: new Date(
          moment()
            .add(1, 'hour')
            .unix() * 1000
        ),
      });

      await firebase.auth.signInWithCustomToken(token);
      history.push('/');
    } catch (e) {
      message.error(e.message);
    }
  }

  return (
    <Button loading={logging} onClick={handleLogin} type={type}>
      {children || 'Login'}
    </Button>
  );
};

export default BrandLoginButton;
