/* eslint-disable no-console */
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'styles/base/antd-custom.less';
import { Provider } from 'react-redux';
import TrendRouters from 'routers/TrendRouters';
import stores from 'stores';
import ErrorBoundary from 'lib/Error/ErrorBoundary';
import { FirebaseProvider } from 'lib/Firebase';
import { CookiesProvider } from 'react-cookie';
import Session from 'components/Session';
import Bugsnag from 'lib/bugsnag';
import * as serviceWorker from './serviceWorker';

const BugSnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const App = (
  <CookiesProvider>
    <Provider store={stores}>
      <FirebaseProvider>
        <BugSnagErrorBoundary>
          <ErrorBoundary>
            <Session.Provider>
              <TrendRouters />
            </Session.Provider>
          </ErrorBoundary>
        </BugSnagErrorBoundary>
      </FirebaseProvider>
    </Provider>
  </CookiesProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
