import React, { useState } from 'react';
import { Modal, Row, Col, Button, Alert, Divider, Radio, Input } from 'antd';
import { REJECT_REASONS } from 'constants/influencers';

const { Group: RadioGroup, Button: RadioButton } = Radio;
const { TextArea } = Input;

/**
 * @typedef {{
 *  show,
 *  onHide: () => void,
 *  onSubmit: (reason: string, block: boolean) => void,
 * }} RejectApplicationModal
 *
 * @type {React.FC<RejectApplicationModal>}
 */
const RejectApplicationModal = ({ show, onHide, onSubmit }) => {
  const [reason, setReason] = useState(REJECT_REASONS[0][1]);
  const [customReason, setCustomReason] = useState();
  const [block, setBlock] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const Reasons = REJECT_REASONS.map(([reasonShow, reasonMessage]) => (
    <Radio
      key={reasonShow}
      style={{ display: 'block', height: '30px', lineHeight: '30px' }}
      value={reasonMessage}
    >
      {reasonShow}
    </Radio>
  ));

  async function handleSubmit() {
    setSubmitting(true);
    if (reason || customReason) {
      await onSubmit(reason || customReason, block);
      setSubmitting(false);
      onHide();
    } else {
      setSubmitting(false);
      setHasSubmitted(true);
    }
  }

  return (
    <Modal
      visible={show}
      onOk={handleSubmit}
      closable={false}
      className="trend-modal"
      footer={[
        <Row gutter={20} key="reject-modal">
          <Col span={12}>
            <Button
              key="cancel"
              disabled={submitting}
              className="trend-btn medium-btn secondary"
              onClick={onHide}
              block
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              className="trend-btn medium-btn reject"
              loading={submitting}
              onClick={handleSubmit}
              block
            >
              Confirm Application Pass
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div>
        <Row>
          <Col span={18}>
            <h2 className="text-black mb-2 type-ebold leading-24px">
              Are you sure you want to&nbsp;
              <span style={{ color: '#af4c4c', fontStyle: 'italic' }}>Pass</span>
              &nbsp;on this creator?
            </h2>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <span className="bsize-4 text-black leading-18px">
              Reject this creator from all of
              <br />
              your current campaigns?
            </span>
          </Col>
          <Col span={6}>
            <RadioGroup
              onChange={val => {
                val.preventDefault();
                setBlock(val.target.value);
              }}
              defaultValue="no"
              buttonStyle="solid"
              className="plain-radio-group"
              value={block}
            >
              <RadioButton value={false}>NO</RadioButton>
              <RadioButton value>YES</RadioButton>
            </RadioGroup>
          </Col>
        </Row>
      </div>
      <Divider />
      <RadioGroup
        onChange={val => {
          val.preventDefault();
          setReason(val.target.value);
        }}
        value={reason}
      >
        {Reasons}
      </RadioGroup>
      {!reason && (
        <TextArea
          rows={4}
          onChange={e => {
            setCustomReason(e.target.value);
          }}
          name="rejectCustomReason"
          value={customReason}
          placeholder="Explain why this creator is being rejected..."
        />
      )}
      {!reason && !customReason && hasSubmitted && (
        <Alert
          message="Please enter a reason to explain to the creator why they are being rejected."
          type="warning"
          style={{ marginTop: '5px' }}
        />
      )}
    </Modal>
  );
};
export default RejectApplicationModal;
