import { Button as AntdBtn } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';

export const Variant = {
  Primary: 'primary',
  Secondary: 'secondary'
};

const useStyles = createUseStyles({
  btn: ({ variant }) => ({
    fontFamily: 'SFProDisplay',
    fontWeight: 800,
    fontStyle: 'italic',
    fontSize: '20px',
    lineHeight: '18px',
    textAlign: 'center',
    minWidth: '236px',
    minHeight: '56px',
    color: '#000',
    backgroundColor: variant === Variant.Primary ? '#FFB917' : '#fff',
    border: '3px solid #FFB917',
    borderColor: variant === Variant.Primary ? '#FFB917' : '#000',
    borderRadius: '32px',
    '&:hover': {
      backgroundColor: variant === Variant.Primary ? '#FFB917' : '#fff',
      borderColor: variant === Variant.Primary ? '#FFB917' : '#000',
      color: '#000'
    }
  })
});

const Button = ({ variant = Variant.Primary, type, ...props }) => {
  const classes = useStyles({ variant });
  return <AntdBtn {...props} htmlType={type} className={classes.btn} />;
};

export default Button;
