import {
  listenBrand,
  listenBrandActiveProducts,
  listenBrandCompletedProducts,
} from 'listeners/brands';
import { listenNotification } from 'listeners/notifications';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'stores';

/**
 * Brand listener Hook
 *
 * @param {string | void | false} brandUID
 * @returns {void}
 */
export const useBrandListener = brandUID => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (brandUID) {
      return listenBrand(brandUID, brand => {
        dispatch(actions.session.updateBrandSession(brand));
      });
    }

    return undefined;
  }, [brandUID]);
};

/**
 * Brand Active Campaign Listener Hook
 *
 * @param {string | void | false} brandUID
 */
export const useBrandActiveCampaignListener = brandUID => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (brandUID) {
      return listenBrandActiveProducts(brandUID, changes => {
        if (changes.added) {
          dispatch(actions.session.addActiveProductsSession(changes.added));
        }

        if (changes.removed) {
          dispatch(actions.session.removeActiveProductsSesssion(changes.removed));
        }
      });
    }

    return undefined;
  }, [brandUID]);
};

/**
 * Brand Completed Campaign Listener Hook
 *
 * @param {string | void | false} brandUID
 */
export const useBrandCompletedCampaignListener = brandUID => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (brandUID) {
      return listenBrandCompletedProducts(brandUID, changes => {
        if (changes.added) {
          dispatch(actions.session.addCompletedProductsSession(changes.added));
        }

        if (changes.modified) {
          dispatch(actions.entities.products.updateProducts(changes.modified));
        }

        if (changes.removed) {
          dispatch(actions.session.removeCompletedProductSession(changes.removed));
        }
      });
    }

    return undefined;
  }, [brandUID]);
};

/**
 * Brand Notification Listener Hook
 *
 * @param {string | void | false} brandUID
 */
export const useBrandNotificationListener = brandUID => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (brandUID) {
      return listenNotification(brandUID, notification => {
        dispatch(actions.session.updateNotificationSession(notification));
      });
    }

    return undefined;
  }, [brandUID]);
};
