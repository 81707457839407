import { addPartnerships } from './partnerships';

/**
 * @typedef {AddInfluencerPartnershipsAction} InfluencersPartnershipsActions
 */
export const ADD_INFLUENCERS_PARTNERSHIPS = 'ADD_INFLUENCERS_PARTNERSHIPS';

/**
 * Add Influencer Partnerships Action
 *
 * @param {string} influencerUID
 * @param {import("types").Partnership} partnerships
 * @typedef {{
 * type: ADD_INFLUENCERS_PARTNERSHIPS,
 * payload: {
 * 	influencerUID: string,
 * 	partnershipUIDs: string[]
 * }
 * }} AddInfluencerPartnershipsAction
 */
export const addInfluencerPartnerships = (influencerUID, partnerships) =>
  /**
   * @param {import("redux").Dispatch} dispatch
   */
  dispatch => {
    dispatch(addPartnerships(partnerships));
    dispatch({
      type: ADD_INFLUENCERS_PARTNERSHIPS,
      payload: {
        influencerUID,
        partnershipUIDs: Object.keys(partnerships),
      },
    });
  };
