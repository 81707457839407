import React, { useState } from 'react';
import { notification } from 'antd';
import Product, { useProductData } from 'components/Products';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Container from 'components/Common/Container';
import CreatorProfileDrawer, {
  PrefetchCreatorProfilePages,
} from 'components/Influencers/Drawer/CreatorProfileDrawer';
import useRehire from 'components/RehireInfluencer/useRehire';
import { brandRemovePartnership } from 'api/partnerships';

/**
 * @typedef {{}} CreatorListProps
 * @type {React.FC<CreatorListProps>}
 */
const CreatorList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const rehire = useRehire();
  const [influencerDrawer, setInfluencerDrawer] = useState({
    influencerUID: null,
    show: false,
  });

  const [rejectModal, setRejectModal] = useState({
    partnershipUID: null,
    show: false,
  });

  const { approvedPartnershipUIDs, messageUIDs, invitedInfluencerUIDs } = useProductData();
  const partnerships = useSelector(selectors.getPartnerships());
  const messages = useSelector(selectors.getMessages());

  const { productUID } = useProductData();

  function handleInfluencerRemove(partnershipUID) {
    setRejectModal(state => ({ ...state, show: true, partnershipUID }));
  }

  async function handleConfirmReject(partnershipUID, rejectMessage) {
    try {
      await brandRemovePartnership(productUID, partnershipUID, rejectMessage);
      dispatch(
        actions.productContexts.removeProductApprovedPartnerships(productUID, [partnershipUID])
      );

      notification.success({
        message: 'Rejected',
        description: 'Creator successfully rejected!',
        duration: 2,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: String(e.message) || 'Something went wrong on accepting influencer',
        duration: 4,
      });
    }
  }

  function handleViewMessage(partnershipUID) {
    const { influencerUID } = partnerships[partnershipUID];
    const [messageUID] = messageUIDs.filter(x => messages[x].users[influencerUID]);
    history.push(`/campaigns/${params.campaignId}/messages/${messageUID}`);
  }

  function handleShowInvitation(influencerUID) {
    if (!invitedInfluencerUIDs.includes(influencerUID)) {
      rehire.showInvitationModal(influencerUID);
    }
  }

  const handleCloseProfileDrawer = () => setInfluencerDrawer(state => ({ ...state, show: false }));
  const rehireRequested = invitedInfluencerUIDs.includes(influencerDrawer.influencerUID);

  return (
    <Container width={742}>
      <Product.Creators
        partnershipUIDs={approvedPartnershipUIDs}
        onInfluencerRemove={handleInfluencerRemove}
        onViewMessage={handleViewMessage}
        onViewProfile={influencerUID =>
          setInfluencerDrawer(s => ({ ...s, show: true, influencerUID }))
        }
      />
      <Product.RejectCreatorModal
        {...rejectModal}
        onClose={() => setRejectModal(s => ({ ...s, show: false }))}
        onConfirm={handleConfirmReject}
      />
      <PrefetchCreatorProfilePages influencerUIDs={invitedInfluencerUIDs} />
      {influencerDrawer.show && (
        <CreatorProfileDrawer
          {...influencerDrawer}
          drawerType={rehireRequested ? 'message' : 'rehire'}
          handleRehire={handleShowInvitation}
          handleMessageCreator={handleViewMessage}
          handleClose={handleCloseProfileDrawer}
        />
      )}
    </Container>
  );
};

export default CreatorList;
