import { Avatar as AntAvatar } from 'antd';
import Image from 'components/Common/Image';
import { CREATOR_CONTENT_STATUS } from 'constants/creatorContents';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

/**
 * Creator Content Avatar Component
 *
 * @typedef {{creatorContentUID: string }} AvatarProps
 * @type {React.FC<AvatarProps>}
 */
const Avatar = ({ creatorContentUID }) => {
  const creatorContent = useSelector(selectors.getCreatorContent(creatorContentUID));
  const [img, setImg] = useState('');
  const { contents = {}, status, type } = creatorContent;

  useEffect(() => {
    if (status === CREATOR_CONTENT_STATUS.APPROVED) {
      const contentUID = Object.keys(contents)[0];

      setImg(contents[contentUID].standardResolutionImage);
    }
  }, [contents]);

  function handleError() {
    const contentUID = Object.keys(contents)[0];
    return contents[contentUID].highResolutionImage;
  }

  return (
    <div className={`creator-content-avatar ${status} ${creatorContentUID}`}>
      <AntAvatar size={50} shape="square">
        {status === CREATOR_CONTENT_STATUS.APPROVED && <Image src={img} onError={handleError} />}
      </AntAvatar>
      <div className={`creator-content-type type-${type}`}>{type}</div>
    </div>
  );
};

export default Avatar;
