import { Popover, Icon, Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as CancelIcon } from 'images/complete-campaign-icon-dark.svg';
import GroupIcon from 'images/shoutout.png';
import PencilIcon from 'images/pencil.png';

const DynamicVariablesContent = (
  <>
    <ul>
      <li>[name]</li>
      <li>[username]</li>
    </ul>
    <h4>How to use:</h4>
    <p>
      Hi [name], <br />
      thank you for applying.
      <br />
      ======================== <br />
      Hi James, <br />
      thank you for applying. <br />
    </p>
  </>
);

/**
 * @typedef {{ messageUIDs: string, onHide: () => any, selecting: boolean, toggleSelection: () => any }} GroupMessageProps
 * @type {React.FC<GroupMessageProps & import('antd/lib/form').FormProps>}
 */
const GroupMessage = ({ messageUIDs, form, onSubmit, selecting, toggleSelection }) => {
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator, validateFieldsAndScroll, resetFields } = form;

  function closeModal() {
    setShowModal(false);
    resetFields(['text']);
  }

  async function handleSubmit() {
    setSubmitting(true);
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        onSubmit(values.text);
        closeModal();
      }
      setSubmitting(false);
    });
  }

  return (
    <>
      <div className="actions">
        <Button
          className={`message ${selecting ? 'hide' : ''}`}
          type="default"
          onClick={toggleSelection}
        >
          <img src={GroupIcon} alt="icon" />
          Group Message
        </Button>
        <Button className={`cancel ${!selecting ? 'hide' : ''}`} onClick={toggleSelection}>
          <Icon component={CancelIcon} />
          Cancel
        </Button>
        <Button
          className={`send ${!selecting ? 'hide' : ''}`}
          onClick={() => setShowModal(true)}
          disabled={messageUIDs.length < 1}
        >
          <img src={PencilIcon} alt="icon" />
          Write Message
        </Button>
        <div className={`selected-count ${!selecting ? 'hide' : ''}`}>
          {messageUIDs.length} CREATORS SELECTED
        </div>
      </div>
      <Modal
        visible={showModal}
        title="Send Messages"
        okText="Send"
        onOk={handleSubmit}
        onCancel={closeModal}
        confirmLoading={submitting}
      >
        <Form form={form}>
          <Form.Item
            label="Message"
            help={
              <Popover content={DynamicVariablesContent} title="Variables">
                <Button size="small">
                  Variables
                  <Icon type="question-circle" />
                </Button>
              </Popover>
            }
          >
            {getFieldDecorator('text', { rules: [{ required: true, message: 'Required' }] })(
              <Input.TextArea style={{ height: 300 }} />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Form.create({ name: 'group-message' })(GroupMessage);
