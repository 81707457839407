import React from 'react';
import { Menu, Avatar, Row } from 'antd';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import { PLACEHOLDER_IMG_URL } from 'constants/common';

const CampaignMenu = props => {
  const { campaignId, image, title, ...others } = props;
  const imageSrc =
    image && image !== PLACEHOLDER_IMG_URL ? image : '/images/campaign-placeholder.png';

  return (
    <Menu.Item {...others}>
      <Link to={`/campaigns/${campaignId}/summary`} className="campaign-link">
        <Row type="flex" align="middle">
          <Avatar src={imageSrc} shape="square" size={36} className="campaign-avatar" />
          <div className="campaign-item hidden-mobile">
            <TextTruncate text={title} line={3} />
          </div>
        </Row>
      </Link>
    </Menu.Item>
  );
};

export default CampaignMenu;
