import axios from 'axios';
import mixpanel, { mixpanelTrack } from 'lib/Analytics/Mixpanel';
import { getAccessToken } from 'utils/cookies';

export const brandApproveInfluencer = async (campaignId, partnershipId) => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/approve-partnership/${campaignId}/${partnershipId}`;
  const { data: approvalResponse } = await axios.patch(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );
  const { creatorId, creatorLevel, creditsSpent, campaignName, campaignType } = approvalResponse;
  try {
    if (mixpanel) {
      mixpanelTrack('Brand Approves Partnership', {
        partnershipId,
        campaignName,
        campaignType,
        creatorId,
        creatorLevel,
        // isCreatorVIP,
        creditsSpent,
      });
      mixpanel.people.increment('totalCreatorsApproved');
      mixpanel.people.increment('totalCreditsSpent', creditsSpent);
    }
  } catch (e) {
    console.error(e);
  }
  approvalResponse.influencerUID = creatorId;
  return approvalResponse;
};

/**
 * Brand Invites Creator
 *
 * @param {{
 *  influencerUID: string,
 *  productUID: string,
 *  rehireContentNeeds: string,
 *  rehireWillBrandSendProduct: boolean,
 *  rehireProductDescription: string
 * }} params
 */
export const brandInvitesCreator = async params => {
  const { influencerUID: creatorId, productUID: campaignId, ...rehireBody } = params;
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/invite-creator/${campaignId}/${creatorId}`;
  const { data: inviteResponse } = await axios.patch(url, rehireBody, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
  // Tracking
  try {
    if (mixpanel) {
      mixpanelTrack('Brand Sends Rehire Request', inviteResponse);
      mixpanel.people.increment('totalCreatorsApproved');
      mixpanel.people.increment('totalCreditsSpent', -1 * inviteResponse.creditsSpent);
    }
  } catch (e) {
    console.error(e);
  }
  return inviteResponse;
};

export const brandRemovePartnership = async (campaignId, partnershipId, removalReason) => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/remove-creator/${campaignId}/${partnershipId}`;
  const { data: rejectionResponse } = await axios.patch(
    url,
    { removalReason },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );
  if (mixpanel) {
    const { creatorId, creditsReturned } = rejectionResponse;
    mixpanelTrack('Brand Removes Partnership', {
      partnershipId,
      campaignId,
      creatorId,
      creditsReturned,
      cancelled: true,
      removalReason,
    });
  }
  return rejectionResponse;
};

export async function rejectPartnership(partnershipId, payload) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/partnership/reject-partnership/${partnershipId}`;

  const { data } = await axios.patch(url, payload, {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
  });

  return data;
}
