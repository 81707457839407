import axios from 'axios';
import { getAccessToken } from 'utils/cookies';

export const patchBrandLoggedInAfterApproval = async () => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/auth/brand/update/logged-in-after-approval`;

  const { data } = await axios.patch(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );

  return data;
};
