import { getAccessToken } from './cookies';

/** New Content service functions */
export const isNewContentServiceUrl = url => url.includes('cloudfront') || url.includes('trend.io');

export const getContentUrlFromRecord = (
  { type, standardResolutionImage, highResolutionImage, highResolutionVideo, approvedAt },
  thumbnail = false
) => {
  const contentUrl =
    (type === 'video' ? highResolutionVideo : highResolutionImage) || standardResolutionImage;
  if (isNewContentServiceUrl(contentUrl)) {
    return setContentUrlParams(contentUrl, { thumbnail, approvedAt });
  }
  return contentUrl;
};

export const setContentUrlParams = (contentUrl, options = {}) => {
  const { thumbnail = false, approvedAt } = options;
  const newUrl = new URL(contentUrl);
  const authToken = getAccessToken();
  if (approvedAt) {
    // Add approvedAt to the url to make sure we get the non-watermarked version of the content
    newUrl.searchParams.set('approved-at', approvedAt);
  }
  if (authToken) {
    newUrl.searchParams.set('auth', authToken);
  }
  if (thumbnail) {
    newUrl.searchParams.set('thumbnail', 'true');
  }
  return newUrl.toString();
};

const isEmptyObj = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

export const calculatePartnershipCreditCost = influencer => {
  if (isEmptyObj(influencer)) {
    return 20;
  }
  let creatorLevel = 1;
  const { payOutTiers, payoutTiers } = influencer;
  if (payOutTiers && payOutTiers.content) {
    creatorLevel = payOutTiers.content;
  } else if (payoutTiers && payoutTiers.content) {
    creatorLevel = payoutTiers.content;
  } else {
    // Patch Missing PayOutTiers
    creatorLevel = getLevelFromLegacyId(influencer);
  }
  return creatorLevel * 20;
};

const getLevelFromLegacyId = creator => {
  const { level, levelUID, payoutAmount } = creator;
  if (payoutAmount) {
    if (payoutAmount >= 185) {
      return 3;
    }
    if (payoutAmount >= 120) {
      return 2;
    }
    return 1;
  }
  const levelId = level || levelUID;
  // NOTE: Only works for prod. Need to run script to make sure payOutTiers are set
  switch (levelId) {
    case '7GRz8hAGFVNLmZXrhVE9':
    case 'fgyveUIY1ZiGT7BXjrNR':
      return 3;
    case 'pwAmdnhaSqkAbT1WiPlb':
    case 'LgTsGeGEmsEpEF664g63':
      return 2;
    case 'bmF4iL0PgkQ32mEyCqqL':
    case 'Go2zgrWPI5KwCqifhB9s':
    default:
      return 1;
  }
};
