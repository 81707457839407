import ImageKitImage from 'components/Common/ImageKitImage';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

/**
 * Creator Content Recent Card Component
 *
 * @typedef {{ creatorContentUID: string }} RecentCardProps
 * @type {React.FC<RecentCardProps>}
 */
const RecentCard = ({ creatorContentUID }) => {
  const creatorContent = useSelector(selectors.getCreatorContent(creatorContentUID));
  const influencer = useSelector(selectors.getInfluencer(creatorContent.influencerUID));
  const contents = creatorContent.contents || {};

  if (Object.keys(contents).length === 0) {
    return null;
  }

  const content = contents[Object.keys(contents)[0]];
  const isApproved = content.status === 'approved';
  const contentUrl = isApproved
    ? content.standardResolutionImage
    : content.watermarkUrl || content.standardResolutionImage;

  const influencerUsername = influencer ? influencer.username : 'InfluencerUsername';

  return (
    <div className="creator-content-recent-card">
      <ImageKitImage path={contentUrl} />
      <div className="username">{influencerUsername}</div>
    </div>
  );
};

export default RecentCard;
