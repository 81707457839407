import { Table as AntTable, Button, Row, Col } from 'antd';
import Influencer from 'components/Influencers';
import RehireInfluencer from 'components/RehireInfluencer';
import { CREATOR_CONTENT_STATUS } from 'constants/creatorContents';
import { ReactComponent as IconMessages } from 'images/icon-messages.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import ContentAvatar from './ContentAvatar';

const TableActions = ({ viewMessage, influencerUID }) => {
  return (
    <div>
      <Row>
        <Col span={12}>
          <RehireInfluencer.Button
            influencerUID={influencerUID}
            awaitingLabel={
              <span>
                Awaiting
                <br /> Rehire Response
              </span>
            }
          >
            Rehire Creator
          </RehireInfluencer.Button>
        </Col>

        <Col span={12}>
          <Button
            onClick={viewMessage}
            shape="circle"
            style={{
              background: '#ffb917',
              border: '0',
              width: '35px',
              height: '35px',
            }}
          >
            <IconMessages
              style={{
                position: 'relative',
                top: '2px',
                width: '15px',
                height: '14px',
              }}
            />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const columns = [
  {
    title: 'CREATOR INFO',
    dataIndex: 'influencerUID',
    key: 'influencerUID',
    className: 'influencer-width',
    render: influencerUID => <Influencer.Avatar influencerUID={influencerUID} />,
  },
  {
    title: 'CONTENT APPROVED',
    dataIndex: 'contents',
    key: 'contents',
    className: 'post-width pl-0 post',
    render: contents => {
      return contents.map(({ creatorContentUID, contentUID }) => (
        <ContentAvatar
          key={contentUID}
          contentUID={contentUID}
          creatorContentUID={creatorContentUID}
        />
      ));
    },
  },
  {
    dataIndex: 'action',
    key: 'action',
    align: 'right',
    width: 195,
    className: 'pl-0 actions',
    render: ({ viewMessage }, { influencerUID }) => {
      return <TableActions viewMessage={viewMessage} influencerUID={influencerUID} />;
    },
  },
];
/**
 * Creator Content Table
 *
 * @typedef {{
 * source: { influencerUID: string, creatorContents: string[] }[]
 * isSocial: boolean,
 * onViewProfile(influencerUID: string) => any,
 * onViewMessage: (partnershipUID: string) => any
 * }} TableProps
 * @type {React.FC<TableProps>}
 */
const Table = ({ source, onViewProfile, onViewMessage, isSocial }) => {
  const creatorContents = useSelector(selectors.getCreatorContents());

  function handleViewProfile(influencerUID) {
    return e => {
      const parentElement = e.target.closest('td');
      if (!parentElement) return;

      const parentClass = parentElement.className;
      const tagname = e.target.tagName.toLowerCase();

      if (
        (tagname !== 'td' && parentElement.className.includes('actions')) ||
        parentClass.includes('post')
      ) {
        return;
      }
      onViewProfile(influencerUID);
    };
  }

  const list = source.map(({ influencerUID, creatorContents: creatorContentUIDs }) => {
    const contents = creatorContentUIDs.reduce((res, uid) => {
      const creatorContent = creatorContents[uid];
      const newContents = [];

      Object.keys(creatorContent.contents || {}).forEach(contentUID => {
        if (
          creatorContent.contents[contentUID].status === 'approved' &&
          creatorContent.requiredContentCount > newContents.length
        ) {
          newContents.push({
            creatorContentUID: uid,
            contentUID,
          });
        }
      });

      if (isSocial && creatorContent.status === CREATOR_CONTENT_STATUS.APPROVED) {
        newContents.push({
          creatorContentUID: uid,
          contentUID: Object.keys(creatorContent.contents)[0],
        });
      }

      const limit = isSocial ? 1 : creatorContent.requiredContentCount;
      while (newContents.length < limit) {
        newContents.push({ creatorContentUID: uid });
      }

      return [...res, ...newContents];
    }, []);

    return {
      key: influencerUID,
      influencerUID,
      contents,
      action: {
        viewMessage: () => onViewMessage(influencerUID),
      },
    };
  });

  return (
    <AntTable
      className="trend-table table-approved creator-content-table"
      columns={columns}
      dataSource={list}
      pagination={false}
      onRow={record => ({
        onClick: handleViewProfile(record.influencerUID),
      })}
    />
  );
};

export default Table;
