import mixpanel from 'mixpanel-browser';

const MIXPANEL_OPTIONS = {};

if (
  ['staging', 'development'].includes(
    process.env.VERCEL_ENV || process.env.REACT_APP_VERCEL_ENV || 'development'
  )
) {
  MIXPANEL_OPTIONS.debug = true;
}

mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, MIXPANEL_OPTIONS);

export default mixpanel;

export const mixpanelTrack = (eventName, eventData) => {
  try {
    if (mixpanel) {
      mixpanel.track(eventName, eventData);
    }
  } catch (error) {
    console.error('MixPanel Error');
    console.error(error);
  }
};

export const mixpanelRegister = registrationData => {
  try {
    if (mixpanel) {
      mixpanel.register(registrationData);
    }
  } catch (error) {
    console.error('MixPanel Error');
    console.error(error);
  }
};

export const mixpanelPeopleSet = peopleData => {
  try {
    if (mixpanel) {
      mixpanel.people.set(peopleData);
    }
  } catch (error) {
    console.error('MixPanel Error');
    console.error(error);
  }
};
