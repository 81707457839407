/* eslint-disable no-console */
import axios from 'axios';
import { UserToken } from 'lib/Firebase';
import ApiUrls from '../constants/apiUrls';

export const validateStripeCustomerId = async stripeCustomerId => {
  const { headers } = await UserToken();
  const body = { stripeCustomerId };

  return axios
    .post(ApiUrls.validateStripeCustomerId, body, { headers })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      throw new Error(err.response.data.message);
    });
};
