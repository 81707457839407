import { Row, Col, Card } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { Link } from 'react-router-dom';
import * as routes from '../../constants/routes';

/**
 * @typedef {{ productUIDs: string[] }} ExampleListProps
 * @type {React.FC<ExampleListProps>}
 */
const ExampleList = ({ productUIDs }) => {
  const products = useSelector(selectors.getProducts());
  const brands = useSelector(selectors.getBrands());

  const renderProducts = productUIDs.map(productUID => {
    const { image, brandUID, name: productName, value } = products[productUID];
    const { name: brandName } = brands[brandUID];

    return (
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 12 }}
        lg={{ span: 12 }}
        xl={{ span: 12 }}
        className="item-campaign"
        key={productUID}
      >
        <Link to={routes.toCampaignExample(productUID)}>
          <Card
            hoverable
            style={{
              borderColor: '#f4f4f4',
              borderTop: '0',
              margin: '0 6.5px 13px 6.5px',
            }}
            cover={
              <div
                style={{
                  height: '198px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <img
                  alt="example"
                  src={image}
                  style={{
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                    margin: 'auto',
                    width: '100%',
                  }}
                />
              </div>
            }
          >
            <div style={{ zIndex: -1 }}>
              <h3 className="campaign-user">{brandName}</h3>
              <h5 className="campaign-name" alt={productName}>
                {productName.length > 110 ? `${productName.substring(0, 110)}...` : productName}
              </h5>
              <div className="campaign-value" style={{ zIndex: 1 }}>{`$ ${value} Value`}</div>
            </div>
          </Card>
        </Link>
      </Col>
    );
  });

  return <Row className="mt-8">{renderProducts}</Row>;
};

export default ExampleList;
