import { useContext } from 'react';
import RehireContext from './Context';

const useRehire = () => {
  const rehireState = useContext(RehireContext);

  if (!rehireState) {
    throw new Error('Rehire context not found');
  }

  return rehireState;
};

export default useRehire;
