import React, { useEffect, useState } from 'react';
import Promise from 'bluebird';
import { Icon, Modal as AntModal, Button, Row, Col, Avatar } from 'antd';
import { ReactComponent as CloseIcon } from 'images/icons/close.svg';
import { firebase } from 'lib/Firebase';
import { selectors } from 'stores';
import { useSelector } from 'react-redux';
import { toCampaignCreatorsListPath, toCampaignMessagesPath } from 'constants/routes';

/**
 * Fetch Message
 *
 * @param {string} productUID
 * @param {string[]} influencerUIDs
 * @returns
 */
const fetchMessages = async (productUID, influencerUID) => {
  const messageSnap = await firebase
    .messages()
    .orderByChild('details/partnershipUID')
    .equalTo(productUID)
    .once('value');

  /**
   * @type {Record<string, import('types').Message>}
   */
  const messages = messageSnap.val() || {};

  let approvedMessageUID;

  Object.keys(messages).forEach(messageUID => {
    const message = messages[messageUID];

    const [userUID] = Object.keys(message.users).filter(
      x => message.users[x].type === 'influencer'
    );

    if (!approvedMessageUID && influencerUID === userUID) {
      approvedMessageUID = messageUID;
    }
  });

  return approvedMessageUID;
};

const PartnershipList = ({ partnershipUIDs = [] }) => {
  const partnerships = useSelector(selectors.getPartnerships());
  const influencers = useSelector(selectors.getInfluencers());
  const products = useSelector(selectors.getProducts());
  const [messageLoaded, setMessageLoaded] = useState(false);
  const [partnershipData, setPartnershipData] = useState({});

  async function fetchData() {
    const newPartnershipData = {};
    await Promise.map(partnershipUIDs, async partnershipUID => {
      const { productUID, influencerUID } = partnerships[partnershipUID];
      newPartnershipData[partnershipUID] = await fetchMessages(productUID, influencerUID);
    });
    setPartnershipData(newPartnershipData);
    setMessageLoaded(true);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <h1>{partnershipUIDs.length} Rehire Offers Accepted!</h1>
      <Row type="flex" justify="center">
        <p>
          Creators are excited to work with you again! Reignite the partnership with a quick message
          to iron out any necessary details.
        </p>
      </Row>
      <div className="rehire-list">
        {messageLoaded &&
          partnershipUIDs.map(partnershipUID => {
            const { influencerUID, productUID } = partnerships[partnershipUID];
            const { image, username } = influencers[influencerUID];
            const { name } = products[productUID];
            // Will use this once we've decided if we want to create another message
            const messageUID = partnershipData[partnershipUID];

            return (
              <Row className="rehire-list-item" type="flex" align="middle">
                <Col span={12}>
                  <Avatar src={image} size={50} />
                  <span className="rehire-details">
                    <div className="rehire-details-username">@{username}</div>
                    <div className="rehire-details-campaign">{name}</div>
                  </span>
                </Col>
                <Col span={12} className={`rehire-list-item-actions ${messageUID}`}>
                  <Button href={toCampaignCreatorsListPath(productUID)}>Go To Campaign</Button>
                  <Button type="primary" href={toCampaignMessagesPath(productUID)}>
                    Send Message
                  </Button>
                </Col>
              </Row>
            );
          })}
      </div>
    </div>
  );
};

/**
 * Rehire Influencer AcceptedModal
 *
 * @typedef {{ show: boolean, onClose: () => any, partnershipUIDs: string[] }} ModalProps
 * @type {React.FC<ModalProps>}
 */
const AcceptedModal = ({ partnershipUIDs = [], show, onClose }) => {
  return (
    <AntModal
      closable={false}
      className="rehire-modal accepted"
      visible={show}
      onCancel={onClose}
      footer={false}
      width={742}
    >
      <Button className="close" onClick={onClose}>
        <Icon component={CloseIcon} />
      </Button>
      <PartnershipList partnershipUIDs={partnershipUIDs} />
    </AntModal>
  );
};

export default AcceptedModal;
