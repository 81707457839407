/**
 * An enum-like constant for the value of `source` field in `brands.details` for RDB,
 * and `brands` & `businesses` collection for Firestore.
 */
export const SignupSource = {
  App: 'app',
  FreeTier: 'free_tier',
  PaidTier: 'paid_tier',
};

export const SIGNUP_SOURCES = [SignupSource.App, SignupSource.FreeTier, SignupSource.PaidTier];
