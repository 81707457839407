import React from 'react';
import { Row } from 'antd';
import moment from 'moment';

const stripePaymentUrl = new URL('00g14V7wG9lM7726pU', 'https://buy.stripe.com/');

const PromoBanner = ({ promoDeadline, extendedDeadline }) => {
  const today = moment();
  const promoDeadlineTime = moment(promoDeadline);
  const extendedDeadlineTime = moment(extendedDeadline);

  const promoContent = () => {
    if (today <= promoDeadlineTime) {
      return `Get BFCM-READY! Save $200 on the Growth package with code 200OFF. Ends ${promoDeadlineTime.format(
        'M/D/YY'
      )}.`;
    }
    return `BFCM Sale Extended! Save $200 on the Growth package with code 200OFF. Ends ${extendedDeadlineTime
      .subtract(1, 'days')
      .format('M/D/YY')}.`;
  };

  const isBannerVisible = () => {
    return today <= extendedDeadlineTime;
  };

  if (!isBannerVisible()) return <></>;

  return (
    <Row>
      <a
        href={stripePaymentUrl}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'block',
          position: 'sticky',
          top: '0',
          textAlign: 'center',
          backgroundColor: '#FFB917',
          color: '#000',
          padding: '10px 0',
        }}
      >
        {promoContent()}
      </a>
    </Row>
  );
};

export default PromoBanner;
