import React from 'react';
import * as ROUTES from 'constants/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import Container from 'components/Common/Container';
import ShowPage from './ShowPage';
import Tabs from './Tabs';

/**
 * Api Queues Page
 *
 * @type {React.FC}
 */
const ApiQueues = () => {
  return (
    <Container width={920}>
      <Tabs />
      <Switch>
        <Route path={ROUTES.ADMINV2_API_SHOW_QUEUE} component={ShowPage} />
        <Redirect to={ROUTES.toAdminV2ApiShowQueuePath('socialData')} />
      </Switch>
    </Container>
  );
};

export default ApiQueues;
