import { firebase } from '../lib/Firebase';
import { docCallbackListenerParser } from './utils';

/**
 * Firebase firestore listener for Brand Active Products
 *
 * @param {string} brandUID
 * @param {(dataChanges: {
 *  added?: Record<string, import('types').Product>,
 *  modified?: Record<string, import('types').Product>,
 *  removed?: Record<string, import('types').Product>
 * }) => void} callback
 */
export const listenBrandActiveProducts = (brandUID, callback) => {
  return firebase.firestore
    .collection('products')
    .where('brandUID', '==', brandUID)
    .where('status', '==', 'active')
    .onSnapshot(docCallbackListenerParser(callback));
};

/**
 * Firebase firestore listener for Brand completed Products
 *
 * @param {string} brandUID
 * @param {(dataChanges: {
 *  added?: Record<string, import('types').Product>,
 *  modified?: Record<string, import('types').Product>,
 *  removed?: Record<string, import('types').Product>
 * }) => void} callback
 */
export const listenBrandCompletedProducts = (brandUID, callback) => {
  return firebase.firestore
    .collection('products')
    .where('brandUID', '==', brandUID)
    .where('status', '==', 'completed')
    .onSnapshot(docCallbackListenerParser(callback));
};

/**
 * Returns a listener & runs callback whenever a brand is updated
 * listens to any changes from the products.
 *
 * @param {string} brandUID
 * @param {(brand: import('types').Brand) => void} callback
 * @returns {() => void}
 */
export const listenBrand = (brandUID, callback) => {
  return firebase.firestore
    .collection('brands')
    .doc(brandUID)
    .onSnapshot(doc => {
      callback({ uid: brandUID, ...doc.data() });
    });
};

export const listenBrandPosts = (brandUID, callback) => {
  return firebase.firestore
    .collection('posts')
    .where('brandUID', '==', brandUID)
    .onSnapshot(snap => {
      let posts = {};

      snap.docs.forEach(doc => {
        posts = { ...posts, [doc.id]: doc.data() };
      });

      callback(posts);
    });
};

/**
 *
 * @param {string} brandUID
 * @param {(uid, data: any, action: "added" | "modified" | "removed") => void} callback
 * @returns
 */
export const listenPendingReviews = (brandUID, callback) => {
  return firebase
    .reviews()
    .where('brandUID', '==', brandUID)
    .where('status', '==', 'pending')
    .onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        callback(change.doc.id, change.doc.data(), change.type);
      });
    });
};

export const listenTrendPendingReviews = (brandUID, callback) => {
  return firebase
    .trendReviews()
    .where('brandUID', '==', brandUID)
    .onSnapshot(snapshot => {
      let status = true;
      snapshot.docChanges().forEach(change => {
        const statuses = ['pending', 'approved'];
        if (statuses.includes(change.doc.data().status)) {
          status = false;
        }
      });
      callback(status);
    });
};
