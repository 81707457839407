import React, { useState } from 'react';
import hasAccess from 'components/Helpers/brandHasAccess';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectors } from 'stores';
import { Menu } from 'antd';
import { useSession } from 'components/Session';
import CampaignMenu from './CampaignMenuItem';
import TypeSectionTitle from './TypeSectionTitle';

/**
 *
 * @typedef {{type: string}} TypeSectionProps
 * @type {React.FC<TypeSectionProps>}
 */

const TypeSection = ({ type }) => {
  const [isOpen, setIsOpen] = useState(true);
  const projects = useSelector(selectors.getProducts());
  const session = useSelector(selectors.getSession());
  const history = useHistory();
  const show = hasAccess();
  const { completedProductUIDs: completedProducts = [] } = useSession();

  const projectsByType = { social: [], image: [], video: [] };
  if (projects.length !== 0) {
    Object.entries(projects).forEach(project => {
      const projectObj = project[1];
      const projectUID = project[0];
      const isActive = !completedProducts.includes(projectUID);
      if (projectObj && projectObj.brandUID === session.brand.uid && isActive) {
        projectsByType[projectObj.type].push({ uid: projectUID, ...projectObj });
        projectsByType[projectObj.type].sort((a, b) => a.createdAt - b.createdAt);
      }
    });
  }

  function campaignByTypeMenu(type) {
    const arr = projectsByType[type];

    const hideMenu = type === 'social' && arr.length === 0;

    return (
      !hideMenu &&
      arr.map(project => (
        <CampaignMenu
          key={`campaigns/${project.uid}`}
          image={project.image}
          title={project.name}
          campaignId={project.uid}
        />
      ))
    );
  }

  function onTitleClick() {
    setIsOpen(!isOpen);
  }

  function hasTypeOfProjects(type) {
    return projectsByType[type].length !== 0;
  }

  // eslint-disable-next-line consistent-return
  function hideSocial(typeCampaign) {
    const arr = projectsByType[typeCampaign];
    return type === 'social' && arr.length === 0;
  }

  const path = history.location.pathname.split('/');
  const id = `${path[1]}/${path[2] || ''}`;

  const text = show ? 'No projects, get started' : 'Add credits to start new project';

  return (
    <div className="menu-section">
      <TypeSectionTitle
        option={type}
        titleClick={onTitleClick}
        isOpen={isOpen}
        hideSocial={hideSocial(type)}
      />
      {!hasTypeOfProjects(type) && isOpen && (
        <div
          style={{
            color: '#B0B0B0',
            paddingTop: '8px',
            paddingLeft: '15px',
            paddingBottom: '18px',
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '14px',
          }}
        >
          {type !== 'social' && text}
        </div>
      )}
      {hasTypeOfProjects(type) && isOpen && (
        <div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[id]}
            defaultOpenKeys={['campaigns']}
            className={['trend-menu-inline campaign-menu']}
            style={{ paddingBottom: '10px' }}
          >
            <Menu.ItemGroup>{campaignByTypeMenu(type)}</Menu.ItemGroup>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default TypeSection;
