/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useRef, useState } from 'react';
import './CreatorProfileDrawer.less';
import { Spin } from 'antd';

/*
interface CreatorProfileDrawerProps {
  show: boolean;
  influencerUID: string;
  drawerType:  'application' | 'rehire' | 'message' | 'preview';
  jobType?:
  creditCost?: number;
  rehireSent?: boolean;

  handleClose: () => void;
  handleApprove?: () => void;
  handleReject?: () => void;
  handleRehire?: (id: string) => void;
  handleMessageCreator?: (id: string) => void;
}
*/
const buildProfileLink = uid => `${process.env.REACT_APP_CREATOR_WEB_URL}/iframe/profile/${uid}`;

export const PrefetchCreatorProfilePages = ({ influencerUIDs }) => {
  const prefetchLinks = influencerUIDs.map(uid => (
    <link key={uid} rel="preconnect" href={buildProfileLink(uid)} />
  ));
  return <>{prefetchLinks}</>;
};

const CreatorProfileDrawer = ({
  show,
  influencerUID,
  drawerType,
  campaignType,
  handleClose,
  handleApprove,
  handleReject,
  handleRehire,
  handleMessageCreator,
}) => {
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const creatorUrl = buildProfileLink(influencerUID);

  useEffect(() => {
    if (drawerType) {
      const profileActions = { drawerType, jobType: campaignType };
      iframeRef.current.contentWindow.postMessage(
        profileActions,
        process.env.REACT_APP_CREATOR_WEB_URL
      );
    }
  }, [drawerType]);

  useEffect(() => {
    if (window) {
      window.onmessage = e => {
        if (e.origin === process.env.REACT_APP_CREATOR_WEB_URL) {
          const { data } = e;
          if (typeof data !== 'string' && data.action === 'copy') {
            return navigator.clipboard.writeText(data.text);
          }
          switch (data) {
            case 'close':
              return handleClose();
            case 'approve':
              return handleApprove();
            case 'pass':
              return handleReject();
            case 'rehire':
              return handleRehire(influencerUID);
            case 'message':
              return handleMessageCreator(influencerUID);
            default:
          }
        }
        return null;
      };
    }
  }, [handleClose]);

  const onIframeLoad = () => {
    setIsLoading(false);
    const profileActions = { drawerType, jobType: campaignType };
    iframeRef.current.contentWindow.postMessage(
      profileActions,
      process.env.REACT_APP_CREATOR_WEB_URL
    );
  };

  return (
    <div className={`creator-profile-drawer ${show ? 'show' : ''}`}>
      {isLoading && <Spin />}
      <iframe
        onLoad={onIframeLoad}
        ref={iframeRef}
        title="Creator Profile"
        src={creatorUrl}
        allow="clipboard-read; clipboard-write"
      />
    </div>
  );
};

export default CreatorProfileDrawer;
