import { useContext } from 'react';
import Context from './Context';

const useCheckSession = () => {
  const session = useContext(Context);

  return !!session;
};

export default useCheckSession;
