import React from 'react';
import { isMobile } from 'react-device-detect';
import { ReactComponent as CloseIcon } from 'images/ContentShowcase/Close_x.svg';
import ModalDetails from 'components/ContentShowcase/ContentModalDetails';

/**
 * @typedef {{
 *  content: sting,
 *  closeModal: () => any
 * }} ContentModalProps
 *
 * @type {React.FC<ContentModalProps>}
 */

const ContentModal = ({ modalData, closeModal }) => {
  const { content, creator, type, avatar } = modalData;

  const classname = isMobile || window.innerWidth <= 768 ? 'content-modal mobile' : 'content-modal';

  return (
    <div className={classname}>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div role="button" id="modal-header" onClick={() => closeModal()}>
        <CloseIcon id="close-icon" onClick={() => closeModal()} />
      </div>
      <div id="modal-body">
        {!isMobile && avatar && creator && <ModalDetails avatar={avatar} creator={creator} />}
        <div className="content-container">
          {type === 'image' ? (
            <img
              alt={!creator ? 'Trend Creator Content' : creator}
              className="content"
              src={content}
            />
          ) : (
            <video className="content" controls autoPlay>
              <source src={content} type="video/mp4" />
              <source src={content} type="video/webm" />
              <source src={content} type="video/ogg" />
              <source src={content} type="video/avi" />
              Sorry, your browser doesn&#39;t support embedded videos.
            </video>
          )}
        </div>
        {isMobile && avatar && creator && <ModalDetails avatar={avatar} creator={creator} />}
      </div>
    </div>
  );
};

export default ContentModal;
