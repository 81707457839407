import React, { useState, useEffect } from 'react';
import Container from 'components/Common/Container';
import { Modal, Button, Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

export const NotesModal = ({ userId, contentValue, showModal, closeModal }) => {
  const influencers = useSelector(selectors.getInfluencers());
  const [userInfo, setUserInfo] = useState('');

  useEffect(() => {
    setUserInfo(influencers[userId]);
  }, [userId, influencers]);

  return (
    <Modal
      visible={showModal}
      width={742}
      maskCloseable
      closable={false}
      footer={null}
      className="trend-modal reject-creator-content"
    >
      <Container width={616}>
        <div className="center-horizontally" style={{ marginBottom: '10px' }}>
          <Avatar src={userInfo.image} size={90} />
        </div>
        <div
          className="type-sfpro-heavy text-black size-18px lh-18px"
          style={{
            marginTop: '11px',
            marginBottom: '19px',
            fontWeight: 'bold',
            textAlign: 'center',
            fontFamily: 'SFProDisplay !important',
          }}
        >
          {userInfo.username === '' ? (
            <div style={{ textTransform: 'capitalize' }}>{userInfo.name}</div>
          ) : (
            <div>@{userInfo.username}</div>
          )}
        </div>

        <h2 className="text-center type-sfpro-heavy" style={{ marginBottom: '10px' }}>
          Application Note
        </h2>
      </Container>
      <Container width={448}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            backgroundColor: '#F1F5FD',
            fontFamily: 'SFPRoDisplay',
            width: '100%',
            height: '245px',
            padding: '25px',
            borderRadius: '6px',
            overscrollBehaviorY: 'contain',
            overflowY: 'auto',
          }}
        >
          <p>{contentValue}</p>
        </div>
        <div className="text-center" style={{ marginTop: '2rem' }}>
          <Button
            className="close trend-btn medium-btn secondary"
            onClick={() => closeModal()}
            style={{ width: '40%', height: '50px' }}
          >
            Close
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
