import { SET_INITIAL_BRAND_CONTEXT } from '../actions/brandContexts';

/**
 * @typedef {Record<string, import('components/Brands/Context').BrandContext>} BrandContexts
 * @type {BrandContexts}
 */
const initialState = {};

/**
 * Product Reducer
 *
 * @param {BrandContexts} state
 * @param {import('../actions/brandContexts').BrandContextsActions} action
 * @returns {BrandContexts}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_BRAND_CONTEXT: {
      return {
        ...state,
        [action.payload.brandUID]: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
