import { Row, Col, Modal, Button } from 'antd';
import React, { useState } from 'react';

/**
 * @typedef {{ onClick: () => Promise<void>}} StopAcceptingProps
 * @type {React.FC<StopAcceptingProps>}
 */
const StopAccepting = ({ onClick }) => {
  const [modal, setModal] = useState({
    show: false,
  });
  const [submitting, setSubmitting] = useState(false);

  async function handleConfirm() {
    setSubmitting(true);
    await onClick();
    setSubmitting(false);
  }

  return (
    <>
      <Button
        key="remove-listing"
        onClick={() => setModal(x => ({ ...x, show: true }))}
        className="trend-btn secondary mr-2 type-firme-italic bg-white"
        style={{ borderColor: '#a9b0b9' }}
      >
        Stop&nbsp;
        <span className="hidden-xmobile">Accepting Applications</span>
      </Button>
      <Modal
        visible={modal.show}
        onCancel={() => setModal(x => ({ ...x, show: false }))}
        className="trend-modal"
        closable={false}
        footer={null}
        width={350}
      >
        <div className="label">
          Are you sure you want to
          <div className="highlighted">stop accepting applications?</div>
        </div>

        <Row style={{ marginTop: '20px' }} gutter={20} key="reject-modal">
          <Col span={12}>
            <Button
              key="cancel"
              disabled={submitting}
              onClick={() => setModal(x => ({ ...x, show: false }))}
              className="trend-btn medium-btn secondary"
              block
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              loading={submitting}
              onClick={handleConfirm}
              className="trend-btn medium-btn"
              block
            >
              Yes
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default StopAccepting;
