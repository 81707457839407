import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import InstagramImg from 'images/components/Influencers/instagram-button-icon.png';

/**
 * Influencer Instagram Button Component
 *
 * @typedef {{ influencerUID: string }} InstagramButtonProps
 * @type {React.FC<InstagramButtonProps & import('antd/lib/button').ButtonProps>}
 */
const InstagramButton = ({ influencerUID, children, ...rest }) => {
  const { username } = useSelector(selectors.getInfluencer(influencerUID));

  return (
    <Button
      className="influencer-instagram-button"
      type="link"
      href={`https://instagram.com/${username}`}
      target="__blank"
      {...rest}
    >
      <img src={InstagramImg} alt="instagram" />
      <span>View Instagram Account</span>
    </Button>
  );
};

export default InstagramButton;
