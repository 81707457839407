// eslint-disable-next-line no-unused-vars
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IconNotificationBell } from 'images/notification-bell.svg';
import { useSession } from 'components/Session';
import { firebase } from '../../lib/Firebase';

const Notifications = () => {
  const { brand, notification: brandNotifications } = useSession();
  const { uid: brandUID } = brand;
  const [requestPermission, setRequestPermission] = useState(false);
  const [tokensList, setTokensList] = useState([]);

  const notificationData = brandNotifications || {};
  const { newApplication, newPost, newMessage, tokens = [] } = notificationData;

  useEffect(() => {
    if (tokensList.length > 0 && brandUID) {
      const saveTokens = async () => {
        await firebase
          .notifications()
          .doc(brandUID)
          .set({ tokens: tokensList }, { merge: true });
      };

      saveTokens();
      setTokensList([]);
    }
  }, [setTokensList, tokensList, brandUID]);

  const notifWarning = () => {
    notification.warning({
      message: 'Notification',
      description: 'This browser does not support notifications.',
      duration: 5,
    });
  };

  const grantedPermission = async () => {
    if (requestPermission) {
      return;
    }

    setRequestPermission(true);

    let token;

    // bug:
    // https://www.gitmemory.com/issue/firebase/firebase-js-sdk/2364/554778269
    try {
      try {
        token = await firebase.messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY });
      } catch {
        token = await firebase.messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY });
      }

      if (!localStorage.getItem('notifToken') && (newApplication || newPost || newMessage)) {
        localStorage.setItem('notifToken', token);

        notification.success({
          message: 'Notifications are on!',
          description: "We'll alert you at key points during your campaign",
          duration: 3,
          className: 'ant-notification trend-notifcation notif-on',
          icon: <IconNotificationBell />,
        });
      }

      setTokensList([...new Set([...tokens, token])]);
    } catch (err) {
      console.error(err);
      notifWarning();
    }
  };

  const handlPermission = async permission => {
    if (!('permission' in Notification)) {
      Notification.permission = permission;
    }

    if (permission === 'granted') {
      await grantedPermission();
    }

    if (permission !== 'granted') {
      localStorage.removeItem('notifToken');
    }
  };

  const checkPermission = () => {
    if ('Notification' in window) {
      try {
        Notification.requestPermission().then(handlPermission);
      } catch {
        Notification.requestPermission(handlPermission);
      }
    } else {
      notifWarning();
    }
  };

  useEffect(() => {
    if (newApplication || newMessage || newPost) {
      checkPermission();
    }
  }, [newApplication, newMessage, newPost]);

  return null;
};

export default Notifications;
