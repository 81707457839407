/* eslint import/prefer-default-export: 0 */
import { firebase } from '../lib/Firebase';

class BusinessForUpdateListener {
  constructor() {
    this.subscriptions = [];
  }

  addListener(subs) {
    this.subscriptions.push(subs);
  }
}

export const businessForUpdateListener = async (lastBrandDoc = null, limit = 250, callback) => {
  let brands = [];
  let tempLastBrandDoc = null;

  let query = firebase.firestore
    .collection('brands')
    .where('status', 'in', ['pending', 'approved', 'rejected'])
    .orderBy('createdAt', 'desc');

  if (limit) {
    query = query.limit(limit);
  }

  if (lastBrandDoc) {
    query = query.startAfter(lastBrandDoc);
  }

  const brandsSnap = await query.get();

  brandsSnap.forEach(doc => {
    brands = [...brands, { uid: doc.id, ...doc.data() }];
    tempLastBrandDoc = doc;
  });

  callback(brands, tempLastBrandDoc);
};

export const businessForUpdateSubs = new BusinessForUpdateListener();
