import React from 'react';
import CreatorAction from 'images/components/Products/Form/StyleCategoryOptions/Action.jpg';
import CreatorReview from 'images/components/Products/Form/StyleCategoryOptions/Review.jpg';
import CreatorUnboxing from 'images/components/Products/Form/StyleCategoryOptions/Unboxing.jpg';
import ProductPhotography from 'images/components/Products/Form/PhotosNeeded/ProductPhotography.png';
import ProductAction from 'images/components/Products/Form/PhotosNeeded/ProductAction.png';
import StylePhotography from 'images/components/Products/Form/PhotosNeeded/StylePhotography.png';
import { POST_TYPES } from './posts';

const CAMPAIGN_STATS = {
  Applications: 'applicantHealth',
  Influencers: 'influencerHealth',
  Shipments: 'shippedHealth',
  'Post Overdue': 'postOverdueHealth',
  Downloads: 'downloadHealth',
  'Last Login': 'lastLoginHealth',
};

const CAMPAIGN_TYPE = {
  SOCIAL: 'social',
  IMAGE: 'image',
  VIDEO: 'video',
};

const CAMPAIGN_STYLE_CATEGORIES = {
  PRODUCT_PHOTOGRAPHY: 'productPhotography',
  LIFE_STYLE_OR_ACTION_PHOTOGRAPHY: 'lifestyleOrActionPhotography',
  PORTRAIT_STYLE_PHOTOGRAPHY: 'portraitStylePhotography',
  ACTION_OR_PRODUCT_IN_USE_VIDEO: 'actionOrProductInUseVideo',
  UNBOXING_EXPLANATION_VIDEO: 'unboxingExplanationVideo',
  PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO: 'productReviewOrTestimonialVideo',
};

const CAMPAIGN_STYLE_CATEGORY_LABEL = {
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_PHOTOGRAPHY]: 'Product Photography',
  [CAMPAIGN_STYLE_CATEGORIES.LIFE_STYLE_OR_ACTION_PHOTOGRAPHY]: 'Lifestyle/Product in Action',
  [CAMPAIGN_STYLE_CATEGORIES.PORTRAIT_STYLE_PHOTOGRAPHY]: 'Portrait Style Photography',
  [CAMPAIGN_STYLE_CATEGORIES.ACTION_OR_PRODUCT_IN_USE_VIDEO]: 'Action/Product in Use Video',
  [CAMPAIGN_STYLE_CATEGORIES.UNBOXING_EXPLANATION_VIDEO]: 'Unboxing Explanation Video',
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO]:
    'Product Review/Testimonial Video',
};

const STYLE_CATEGORIES = {
  [POST_TYPES.IMAGE]: [
    CAMPAIGN_STYLE_CATEGORIES.PRODUCT_PHOTOGRAPHY,
    CAMPAIGN_STYLE_CATEGORIES.LIFE_STYLE_OR_ACTION_PHOTOGRAPHY,
    CAMPAIGN_STYLE_CATEGORIES.PORTRAIT_STYLE_PHOTOGRAPHY,
  ],
  [POST_TYPES.VIDEO]: [
    CAMPAIGN_STYLE_CATEGORIES.ACTION_OR_PRODUCT_IN_USE_VIDEO,
    CAMPAIGN_STYLE_CATEGORIES.UNBOXING_EXPLANATION_VIDEO,
    CAMPAIGN_STYLE_CATEGORIES.PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO,
  ],
};

export const CAMPAIGN_LAYOUT = {
  Square: 'square',
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};

export const VIDEO_LENGTH = {
  FIFTEEN: '15seconds',
  THIRTY: '15-30seconds',
  SIXTY: '60seconds',
};

export const CAMPAIGN_PLATFORM = {
  INSTAGRAM: 'instagram',
  TIKTOK: 'tiktok',
  FACEBOOK: 'facebook',
  YOUTUBE: 'youtube',
  AMAZON: 'amazon',
  GENERAL_USE: 'generalUse',
};

const STYLE_GUIDE_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
};

const CATEGORY_TITLE = {
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_PHOTOGRAPHY]: {
    image: ProductPhotography,
    label: (
      <>
        Product
        <br />
        Photography
      </>
    ),
    moreExamples: 'https://trend.io/image/1',
  },
  [CAMPAIGN_STYLE_CATEGORIES.PORTRAIT_STYLE_PHOTOGRAPHY]: {
    image: StylePhotography,
    label: (
      <>
        Portrait Style
        <br />
        Photography
      </>
    ),
    moreExamples: 'https://trend.io/image/2',
  },
  [CAMPAIGN_STYLE_CATEGORIES.LIFE_STYLE_OR_ACTION_PHOTOGRAPHY]: {
    image: ProductAction,
    label: (
      <>
        Lifestyle / Product
        <br />
        In Action
      </>
    ),
    moreExamples: 'https://trend.io/image/3',
  },
  [CAMPAIGN_STYLE_CATEGORIES.ACTION_OR_PRODUCT_IN_USE_VIDEO]: {
    image: CreatorAction,
    label: (
      <>
        Action / Product
        <br />
        in Use Video
      </>
    ),
    moreExamples: 'https://trend.io/video/1',
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/verbv2.appspot.com/o/productVideos%2Faction.mov?alt=media&token=268f5741-127f-444c-9a58-2aec38d7beaa',
  },
  [CAMPAIGN_STYLE_CATEGORIES.UNBOXING_EXPLANATION_VIDEO]: {
    image: CreatorUnboxing,
    label: (
      <>
        Unboxing
        <br />
        Explanation Video
      </>
    ),
    moreExamples: 'https://trend.io/video/2',
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/verbv2.appspot.com/o/productVideos%2Funboxing.mov?alt=media&token=d7d74d4f-8059-4282-ad2b-be36e1f78497',
  },
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO]: {
    image: CreatorReview,
    label: (
      <>
        Product Review /<br />
        Testimonial Video
      </>
    ),
    moreExamples: 'https://trend.io/video/3',
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/verbv2.appspot.com/o/productVideos%2Freview.mov?alt=media&token=f4ff698f-41d0-430c-8ce4-63a8f33bdc17',
  },
};

const CAMPAIGN_TYPE_SIDE_NAV = {
  [CAMPAIGN_TYPE.SOCIAL]: (
    <>
      CREATIVE
      <br />
      Brief
    </>
  ),
  [CAMPAIGN_TYPE.IMAGE]: (
    <>
      PHOTOS
      <br />
      NEEDED!
    </>
  ),
  [CAMPAIGN_TYPE.VIDEO]: (
    <>
      VIDEOS
      <br />
      NEEDED!
    </>
  ),
};

export {
  CAMPAIGN_STATS,
  CAMPAIGN_TYPE,
  STYLE_CATEGORIES,
  CAMPAIGN_STYLE_CATEGORIES,
  CAMPAIGN_STYLE_CATEGORY_LABEL,
  STYLE_GUIDE_TYPE,
  CATEGORY_TITLE,
  CAMPAIGN_TYPE_SIDE_NAV,
};
