import React from 'react';
import moment from 'moment';
import { Row, Col, Avatar, Icon, Spin } from 'antd';
import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import urlify from 'helpers/urlify';
import { createUseStyles } from 'react-jss';
import { ReactComponent as IconReject } from 'images/message-alert.svg';
import { ReactComponent as PlayButtonSVG } from '../../images/play-btn.svg';

const useStyles = createUseStyles({
  messageRow: { backgroundColor: '#ffffff', marginTop: 40, marginBottom: 40 },
  messageContainer: (props = {}) => ({
    padding: 22,
    borderRadius: 10,
    backgroundColor: props.own ? '#0A5BFE' : '#F1F5FD',
  }),
  rejectionHeading: () => ({
    alignItems: 'center',
    color: '#ffffff',
    display: 'flex',
  }),
  rejectionMessageContainer: () => ({
    padding: 22,
    borderRadius: 10,
    backgroundColor: '#ffa615',
  }),
  message: (props = {}) => ({
    marginBottom: '0',
    fontSize: '12px',
    color: props.own ? '#fff' : '#000',
    lineHeight: '2.08',
    whiteSpace: 'pre-wrap',
  }),
  messageTime: {
    marginTop: '15px',
    fontFamily: 'TruenoRegular',
    fontSize: '10px',
    fontStyle: 'italic',
    color: '#FFFFFF',
    textAlign: 'start',
    letterSpacing: 0,
    lineHeight: '17px',
  },
  video: (props = {}) => ({
    maxHeight: 400,
    marginLeft: props.own ? 'auto' : 60,
    marginRight: props.own ? 30 : 'auto',
  }),
});

const Message = ({ message, isOwn }) => {
  const classes = useStyles({ own: isOwn });
  return (
    <p
      className={`${classes.message} text-control`}
      // TODO: Maybe use react-linkify instead? https://www.npmjs.com/package/react-linkify
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: urlify(message) }}
    />
  );
};

const ImageDisplay = ({ item }) => {
  const classes = useStyles({ own: item.own });

  return (
    <Row className={classes.messageRow} type="flex" justify="space-around">
      <img
        style={{
          maxHeight: 300,
          maxWidth: 400,
          marginLeft: item.own ? 'auto' : 60,
          marginRight: item.own ? 30 : 'auto',
          borderRadius: 10,
        }}
        alt="trend"
        src={item.url}
      />
    </Row>
  );
};

const VideoDisplay = ({ item }) => {
  const classes = useStyles({ own: item.own });
  const [isPlaying, setPlaying] = React.useState(false);
  const [isPlayed, setIsPlayed] = React.useState(false);
  const [loading, setLoading] = React.useState();
  const vidRef = React.createRef();

  const togglePlay = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!isPlaying) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
    setPlaying(!isPlaying);

    if (!isPlayed) {
      setIsPlayed(true);
    }
  };

  const handleLoading = () => {
    setLoading(false);
  };

  return (
    <Row className={classes.messageRow} type="flex" justify="space-around">
      <div
        style={{
          position: 'relative',
          marginLeft: item.own ? 'auto' : 60,
          marginRight: item.own ? 30 : 'auto',
        }}
        onClick={togglePlay}
        role="presentation"
      >
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            zIndex: 1,
          }}
          className="message-video-button-container"
        >
          {!isPlaying && (
            <Icon component={PlayButtonSVG} style={{ width: '100%', fontSize: '5rem' }} />
          )}
        </div>
        {!isPlayed && (
          <img
            style={{
              maxHeight: 300,
              borderRadius: 10,
            }}
            alt="trend"
            src={item.thumbnail}
          />
        )}
        <Spin spinning={loading}>
          <video
            ref={vidRef}
            src={`${item.url}#t=0.01`}
            preload="auto"
            type={item.type}
            style={{
              objectFit: 'cover',
              maxHeight: 300,
              maxWidth: '100%',
              display: isPlaying || isPlayed ? 'block' : 'none',
            }}
            onLoadedData={handleLoading}
          />
        </Spin>
      </div>
    </Row>
  );
};

const Own = ({ item, rejection }) => {
  const messageStart = 'Your content was rejected for ';
  const messageEnd = ' Please review feedback and make any necessary updates to your content.';
  const messageRejected =
    rejection === true ||
    (item.text.indexOf(messageStart) === 0 &&
      item.text.indexOf(messageEnd) === item.text.length - messageEnd.length &&
      item.text.indexOf(messageEnd) > item.text.indexOf(messageStart) + messageStart.length); // eslint-disable no-mixed-operators

  const classes = useStyles({ own: item.own, rejection: messageRejected || false });

  if (item.type && item.type.includes('image')) {
    return <ImageDisplay item={item} />;
  }

  if (item.type && item.type.includes('video')) {
    return <VideoDisplay item={item} />;
  }

  return (
    <Row className={classes.messageRow} type="flex" justify="space-around">
      <Col align="center" span={4} />
      <Col
        span={14}
        offset={3}
        className={messageRejected ? classes.rejectionMessageContainer : classes.messageContainer}
      >
        {messageRejected && (
          <div className={classes.rejectionHeading}>
            <IconReject />
            &nbsp;&nbsp;Content Rejection:
          </div>
        )}
        <Message message={item.text} isOwn />
        <div className={classes.messageTime}>
          {moment(item.timestamp, DATE_TIMEZONE_FORMAT).fromNow()}
        </div>
      </Col>
    </Row>
  );
};

const SomeoneElse = ({ item }) => {
  const classes = useStyles({ own: item.own });

  if (item.type && item.type.includes('image')) {
    return <ImageDisplay item={item} />;
  }

  if (item.type && item.type.includes('video')) {
    return <VideoDisplay item={item} />;
  }

  return (
    <Row className={classes.messageRow} type="flex" justify="space-around">
      <Col
        span={14}
        className={classes.messageContainer}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <div style={{ marginRight: 15 }}>
          <Avatar src={item.avatar} size={50}>
            {item.sender.name}
          </Avatar>
        </div>
        <div>
          <Message message={item.text} isOwn={false} />
          <div className={classes.messageTime} style={{ color: '#000000' }}>
            {moment(item.timestamp, DATE_TIMEZONE_FORMAT).fromNow()}
          </div>
        </div>
      </Col>
      <Col align="center" span={4} />
    </Row>
  );
};

/**
 * Chat Item Component
 *
 * @typedef {{item: any}} ChatItemProps
 * @type {React.FC<ChatItemProps>}
 */
const ChatItem = ({ item, rejection }) => {
  return item.own ? <Own item={item} rejection={rejection} /> : <SomeoneElse item={item} />;
};

export default ChatItem;
