import useSWR from 'swr';
import axios from 'axios';
import { getAccessToken } from 'utils/cookies';

/** *
 *	{
 *		"submissions": [
 *			{
 *				"creatorId": "string",
 *				"previewImg": "string",
 *				"submittedAt": number,
 *				"numSubmissions": number
 *			}
 *		],
 *		"approvals": [
 *			{
 *				"creatorId": "string",
 *				"totalContentApproved": number,
 *				"requiredContent": {
 *					"STYLE_CATEGORY": {}
 *					"productPhotography": {
 *						"submissionPreviews": ["string"],
 *						"numContentRequired": number
 *					}
 *				}
 *			}
 *		]
 *	}
 */
const trendAPIFetcher = endpoint =>
  axios
    .get(`${process.env.REACT_APP_TREND_API_URL}${endpoint}`, {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    })
    .then(res => res.data);

export const useCampaignContents = campaignId => {
  const swrResponse = useSWR(`/campaign/submissions/${campaignId}`, trendAPIFetcher);
  return swrResponse;
};
