import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Container from '../../../components/Common/Container';
import DeniedTab from './DeniedTab';
import ProcessingTab from './ProcessingTab';
import Headers from './Headers';
import PaidTab from './PaidTab';

/**
 * Admin V2 Payouts Tab
 *
 * @type {React.FC}
 */
const PayoutsPage = () => {
  return (
    <div id="payouts-page">
      <Container width={940}>
        <Headers />
        <Switch>
          <Route exact path="/adminV2/payouts/denied" component={DeniedTab} />
          <Route exact path="/adminV2/payouts/paid" component={PaidTab} />
          <Route exact path="/adminV2/payouts/processing" component={ProcessingTab} />
          <Redirect to="/adminV2/payouts/processing" />
        </Switch>
      </Container>
    </div>
  );
};

export default PayoutsPage;
