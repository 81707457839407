import React, { useState } from 'react';
import moment from 'moment';
import { Tag, Button, message, Popconfirm, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { adminResendPayout } from 'lib/Firebase/callables/admin';
import { PARTNERSHIP_PAYOUT_STATUS } from '../../../constants/partnerships';

const statusColor = {
  processing: 'orange',
  paid: 'green',
  denied: 'red',
};

const PayoutStatus = ({ status }) => {
  if (!status) return <div />;

  return (
    <Tag color={statusColor[status]} style={{ textTransform: 'uppercase' }}>
      {status}
    </Tag>
  );
};

const CompletedCampaign = ({ partnershipUID }) => {
  const dispatch = useDispatch();
  const partnership = useSelector(selectors.getPartnership(partnershipUID));
  const { productUID } = partnership;
  const product = useSelector(selectors.getProduct(productUID));
  const { brandUID, name: productName } = product;
  const brand = useSelector(selectors.getBrand(brandUID));
  const { name: brandName } = brand;

  const [loading, setLoading] = useState(false);
  const { payoutAmount, payoutStatus, payoutStatusMessage } = partnership;

  let { dueDate, completedAt } = partnership;
  dueDate = dueDate && moment(dueDate * 1000);
  completedAt = completedAt && moment(completedAt * 1000);
  let diff;
  if (dueDate && completedAt) {
    diff = completedAt.diff(dueDate, 'days');
  }

  async function handleResend() {
    setLoading(true);
    try {
      await adminResendPayout(partnershipUID);
      dispatch(
        actions.entities.partnerships.updatePartnerships({
          [partnershipUID]: { ...partnership, payoutStatus: 'processing' },
        })
      );

      message.success('Processing a new batch payment');
      setLoading(false);
    } catch (e) {
      message.error('Unauthorized access, please switch to an admin brand');
    }
  }

  return (
    <div className="campaign-details">
      <div className="campaign-details-brand-name">{brandName}</div>
      <div className="campaign-details-name">{productName}</div>
      <div className="campaigns-details-status">
        <div className="details-status">
          <div className="status">
            <div className="status-value">{completedAt ? completedAt.format('M/D/YY') : '--'}</div>
            <div className="status-label">Date Completed</div>
          </div>
        </div>
        <div className="details-status">
          <div className="status">
            <div className="status-value">{diff ? `${diff} days` : `--`}</div>
            <div className="status-label">Days Completed after due date</div>
          </div>
        </div>
        <div className="details-status">
          <div className="status">
            <div className="status-value">
              <PayoutStatus status={payoutStatus} /> {payoutAmount || `--`}
            </div>
            {payoutStatus === PARTNERSHIP_PAYOUT_STATUS.DENIED && (
              <>
                <div className="status-value" />
                <div className="status-value">
                  <Alert
                    type="error"
                    description={
                      <>
                        <p>{payoutStatusMessage}</p>
                        <Popconfirm
                          title="Are you sure you want to resend a new payment?"
                          onConfirm={handleResend}
                        >
                          <Button loading={loading} type="primary">
                            Resend Payout
                          </Button>
                        </Popconfirm>
                      </>
                    }
                  />
                </div>
              </>
            )}
            <div className="status-label">payment</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedCampaign;
