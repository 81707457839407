import Promise from 'bluebird';
import { useFirebase } from 'lib/Firebase';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import Context from './Context';

/**
 * Brand Provider
 *
 * @typedef {{ brandUID: string }} ProviderProps
 * @type {React.FC<ProviderProps>}
 */
const Provider = ({ brandUID, children }) => {
  const firebase = useFirebase();
  const brand = useSelector(selectors.getBrandContext(brandUID));
  const dispatch = useDispatch();

  async function fetchData() {
    const brandDoc = await firebase.firestore
      .collection('brands')
      .doc(brandUID)
      .get();

    if (brandDoc.exists) {
      const approvedPartnershipSnap = await firebase.firestore
        .collection('influencersPartnerships')
        .where('brandUID', '==', brandUID)
        .where('status', 'not-in', ['rejected', 'submitted'])
        .get();

      /**
       * @type {Record<string, import('types').Partnership>}
       */
      const approvedPartnership = {};
      approvedPartnershipSnap.forEach(doc => {
        approvedPartnership[doc.id] = doc.data();
      });

      /**
       * @type {Record<string,import('types').Influencer>}
       */
      const influencers = {};
      await Promise.map(
        Object.keys(approvedPartnership),
        async partnershipUID => {
          const { influencerUID } = approvedPartnership[partnershipUID];

          const influencerDoc = await firebase.firestore
            .collection('influencers')
            .doc(influencerUID)
            .get();

          influencers[influencerUID] = influencerDoc.data();
        },
        { concurrency: 5 }
      );

      dispatch(actions.entities.influencers.addInfluencers(influencers));

      const purchasedHistoriesSnap = await firebase.firestore
        .collection('purchasedHistory')
        .where('brandUID', '==', brandUID)
        .get();
      const purchasedHistories = {};

      purchasedHistoriesSnap.forEach(doc => {
        purchasedHistories[doc.id] = doc.data();
      });

      dispatch(
        actions.brandContexts.setInitialBrandContext(
          brandUID,
          brandDoc.data(),
          approvedPartnership,
          purchasedHistories
        )
      );
    }
  }

  useEffect(() => {
    if (!brand) {
      fetchData();
    }
  }, []);

  if (!brand) {
    return <div>LOADING</div>;
  }

  return <Context.Provider value={brand}>{children}</Context.Provider>;
};

export default Provider;
