import { firebaseService } from './context';

const firebase = firebaseService;

export default async () =>
  firebase.auth.currentUser.getIdToken(true).then(tokenId => ({
    tokenId,
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  }));
