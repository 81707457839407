import axios from 'axios';
import { getAccessToken } from 'utils/cookies';

export const patchMakeUserContentViewer = async email => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/auth/update/permissions`;

  const { data } = await axios.patch(
    url,
    {
      email,
      permissions: ['content_viewer'],
    },
    {
      headers: { 'trend-api-key': process.env.REACT_APP_TREND_API_KEY },
    }
  );

  return data;
};

export const patchAdminApproveBrand = async approvalPayload => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/admin/brand/approve`;

  const { data } = await axios.patch(
    url,
    { ...approvalPayload },
    {
      headers: { 'trend-api-key': process.env.REACT_APP_TREND_API_KEY },
    }
  );

  return data;
};

export const patchAdminRejectBrand = async brandId => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/admin/brand/${brandId}/reject`;

  const { data } = await axios.patch(
    url,
    {},
    {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    }
  );

  return data;
};

export const patchAdminUpdateBrand = async updatePayload => {
  const brandId = updatePayload.brandUID;
  const url = `${process.env.REACT_APP_TREND_API_URL}/admin/brand/${brandId}/update`;

  const { data } = await axios.patch(
    url,
    { ...updatePayload },
    {
      headers: {
        // NOTE: Want to move towards using the access token instead of the API key
        // - allows more granular permissions
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );

  return data;
};

export const postAdminContentUrl = async ({ contentId, collectionId }) => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/content/admin/pre-signed-url`;

  const { data } = await axios.post(
    url,
    { collectionId, recordId: contentId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );

  return data;
};

export const patchAdminApproveCreator = async ({ uid: firebaseId, payOutTiers }) => {
  const level = payOutTiers.content;
  const url = `${process.env.REACT_APP_TREND_API_URL}/creator/admin/approve`;
  const { data } = await axios.patch(
    url,
    { firebaseId, level },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );
  return data;
};

export const patchAdminRejectCreator = async firebaseId => {
  const url = `${process.env.REACT_APP_TREND_API_URL}/creator/admin/reject`;
  const { data } = await axios.patch(
    url,
    { firebaseId },
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );
  return data;
};

export async function patchAdminBumpCampaign(campaignId) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/admin/${campaignId}/bump`;

  const response = await axios.patch(url, null, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return response.data;
}
