import { ADD_BRANDS } from 'stores/actions/entities/brands';

/**
 * Brands Reducer
 *
 * @typedef {Record<string, import("types").Brand>} BrandsReducer
 * @param {BrandsReducer} state
 * @param {import("stores/actions/entities/brands").BrandActions} action
 * @returns {BrandsReducer}
 */
const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_BRANDS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
