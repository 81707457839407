import React from 'react';
import { Row, Select } from 'antd';
import STATES from 'constants/states';
import { CREATOR_CATEGORIES } from 'constants/creator';
import FavoriteFilterButton from './FavoriteCreators/FavoriteFilterButton';

const { Option } = Select;
/**
 * Application Filter Component
 *
 * @typedef {{ onChange: () => void }} ApplicationFilterProps
 * @type {React.FC<ApplicationFilterProps>}
 */
const ApplicationFilter = ({ onChange }) => {
  const categories = Object.entries(CREATOR_CATEGORIES);

  /**
   * @param {"category" | "location" | "influencerType" } type
   */
  function handleFilterChange(type) {
    return val => {
      onChange({ [type]: val });
    };
  }

  const locations = Object.keys(STATES).map(stateCode => (
    <Option value={STATES[stateCode]} key={stateCode}>
      {STATES[stateCode]}
    </Option>
  ));

  const renderCategories = categories.map(([category, label]) => (
    <Option value={category} key={category}>
      {label}
    </Option>
  ));

  return (
    <Row>
      <FavoriteFilterButton onChange={onChange} />
      <Select
        onChange={handleFilterChange('category')}
        allowClear
        placeholder="Categories"
        style={{
          width: 98,
          marginRight: '12px',
          marginLeft: '23px',
        }}
        className="trend-select"
        showArrow={false}
      >
        {renderCategories}
      </Select>
      <Select
        onChange={handleFilterChange('location')}
        allowClear
        placeholder="Location"
        style={{
          width: 98,
          marginRight: '12px',
        }}
        className="trend-select"
        showArrow={false}
      >
        {locations}
      </Select>
    </Row>
  );
};

export default ApplicationFilter;
