import { useContext } from 'react';
import MediaViewerContext from './Context';

const useMediaViewer = () => {
  const mediaViewer = useContext(MediaViewerContext);

  if (!mediaViewer) {
    throw new Error("Can't find any Provider");
  }
  return mediaViewer;
};
export default useMediaViewer;
