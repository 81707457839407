import React from 'react';
import { Row, Icon, Col, Skeleton } from 'antd';

/**
 * Admin Product Card Loading
 *
 * @type {React.FC}
 */
const AdminProductCard = ({ children, ...rest }) => {
  return (
    <Col {...rest}>
      <div className="campaign-card">
        <div className="campaign-card-upper">
          <div className="campaign-card-name">
            <Skeleton paragraph={false} />
          </div>
          <Row>
            <Col span={10}>
              <div className="campaign-card-credits">
                Credits: <Icon type="loading" />
              </div>
            </Col>
            <Col span={14}>
              <div className="brand-details-health">
                <span className="health-icon" />
                Health:{' '}
                <span className="health-label type-bold">
                  <Icon type="loading" />
                </span>
              </div>
            </Col>
          </Row>
          <Row className="brand-details">
            <Col span={24}>
              <div className="brand-details-purchase">
                Last Purchase Date:{' '}
                <span className="type-bold">
                  <Icon type="loading" />
                </span>
              </div>
              <div className="brand-details-purchase">
                How Many Purchased:{' '}
                <span className="type-bold">
                  <Icon type="loading" />
                </span>
              </div>
              <div>
                Last credit used:{' '}
                <span className="type-bold">
                  <Icon type="loading" />
                </span>
              </div>
              <div className="brand-details-purchase">
                Lifetime credits purchased:{' '}
                <span className="type-bold">
                  <Icon type="loading" />
                </span>
              </div>
              <div className="brand-details-name">
                <Skeleton paragraph={false} />
              </div>
              <div className="brand-details-email">
                <Icon type="loading" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="campaign-card-lower">
          <div className="campaign-startdate">
            Campaign Start Date: <Icon type="loading" />
          </div>
          <Row className="campaign-stats">
            <Skeleton paragraph={3} />
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default AdminProductCard;
