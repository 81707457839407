// eslint-disable-next-line import/no-cycle
import {
  ADD_PRODUCT_APPROVED_PARTNERSHIPS,
  ADD_PRODUCT_SUBMITTED_PARTNERSHIPS,
  ADD_PRODUCT_INVITED_PARTNERSHIPS,
  REMOVE_PRODUCT_APPROVED_PARTNERSHIPS,
  REMOVE_PRODUCT_SUBMITTED_PARTNERSHIPS,
  REMOVE_PRODUCT_CREATOR_CONTENTS,
  SET_INITIAL_PRODUCT,
  SET_PARTNERSHIPS_LISTENER,
  SET_PRODUCT_POSTS_LISTENER,
  ADD_PRODUCT_MESSAGE,
  SET_PRODUCT_MESSAGE_LISTENER,
  ADD_PRODUCT_CREATOR_CONTENTS,
  SET_PRODUCT_CREATOR_CONTENTS_LISTENER,
  REMOVE_PRODUCT_POSTS,
  ADD_PRODUCT_POSTS,
  REMOVE_PRODUCT_INVITED_PARTNERSHIPS,
} from '../actions/productContexts';

const addMessages = (state, productUID, messageUIDs) => {
  const newMessage = [...new Set([...state[productUID].messageUIDs, ...messageUIDs])];
  return {
    ...state,
    [productUID]: {
      ...state[productUID],
      messageUIDs: newMessage,
    },
  };
};

/**
 * @param {ProductContextReducer} state
 * @param {string} productUID
 * @param {"approved" | "submitted" | "pending"} type
 * @param {string[]} postUIDs
 */
const addPosts = (state, productUID, postUIDs) => {
  return {
    ...state,
    [productUID]: {
      ...state[productUID],
      postUIDs: [...new Set([...(state[productUID].postUIDs || []), ...postUIDs])],
    },
  };
};

/**
 *
 * @param {ProductContextReducer} state
 * @param {string} productUID
 * @param {string[]} postUIDs
 */
const removePosts = (state, productUID, postUIDs) => {
  return {
    ...state,
    [productUID]: {
      ...state[productUID],
      postUIDs: state[productUID].postUIDs.filter(x => !postUIDs.includes(x)),
    },
  };
};

/**
 * @param {ProductContextReducer} state
 * @param {string} productUID
 * @param {"submitted" | "approved" | "invited"} type
 * @param {string[]} partnershipUIDs
 * @returns {ProductContextReducer}
 */
const addPartnerships = (state, productUID, type, partnershipUIDs) => {
  const newPartnerships = state[productUID][`${type}PartnershipUIDs`];

  return {
    ...state,
    [productUID]: {
      ...state[productUID],
      [`${type}PartnershipUIDs`]: [...new Set([...newPartnerships, ...partnershipUIDs])],
    },
  };
};

/**
 * @param {ProductContextReducer} state
 * @param {string} productUID
 * @param {"submitted" | "approved" | "invited"} type
 * @param {string[]} partnershipUIDs
 * @returns {ProductContextReducer}
 */
const removePartnerships = (state, productUID, type, partnershipUIDs) => {
  /**
   * @type {string[]}
   */
  let newPartnerships = state[productUID][`${type}PartnershipUIDs`];
  newPartnerships = newPartnerships.filter(x => !partnershipUIDs.includes(x));

  return {
    ...state,
    [productUID]: {
      ...state[productUID],
      [`${type}PartnershipUIDs`]: newPartnerships,
    },
  };
};

/**
 * @param {ProductContextReducer} state
 * @param {string} productUID
 * @param {string[]} creatorContentUIDs
 * @returns
 */
const addCreatorContents = (state, productUID, creatorContentUIDs) => {
  return {
    ...state,
    [productUID]: {
      ...state[productUID],
      creatorContentUIDs: [
        ...new Set([...(state[productUID].creatorContentUIDs || []), ...creatorContentUIDs]),
      ],
    },
  };
};

/**
 * @param {ProductContextReducer} state
 * @param {string} productUID
 * @param {string[]} creatorContentUIDs
 */
const removeCreatorContents = (state, productUID, creatorContentUIDs) => {
  return {
    ...state,
    [productUID]: {
      ...state[productUID],
      creatorContentUIDs: state[productUID].creatorContentUIDs.filter(
        x => !creatorContentUIDs.includes(x)
      ),
    },
  };
};

/**
 * @typedef {Record<string, {
 *  productUID: string,
 *  approvedPartnershipUIDs: string[],
 *  submittedPartnershipUIDs: string[],
 *  invitedInfluencerUIDs: string[],
 *  postUIDs: string[],
 *  creatorContentUIDs: string[],
 *  messageUIDs: string[],
 *  listenPosts?: boolean,
 *  listenPartnerships?: boolean,
 *  listenMessage?: boolean,
 *  listenCreatorContents?: boolean
 * }>} ProductContextReducer
 * @type {ProductContextReducer}
 */
const initialState = {};

/**
 * Product Reducer
 *
 * @param {ProductContextReducer} state
 * @param {import('../actions/productContexts').ProductActions} action
 * @returns {ProductContextReducer}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_PRODUCT: {
      return {
        ...state,
        [action.payload.productUID]: action.payload,
      };
    }

    case SET_PARTNERSHIPS_LISTENER: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          listenPartnerships: true,
        },
      };
    }

    case SET_PRODUCT_POSTS_LISTENER: {
      return { ...state, [action.payload]: { ...state[action.payload], listenPosts: true } };
    }

    case SET_PRODUCT_MESSAGE_LISTENER: {
      return { ...state, [action.payload]: { ...state[action.payload], listenMessage: true } };
    }

    case ADD_PRODUCT_APPROVED_PARTNERSHIPS: {
      const { productUID, partnershipUIDs } = action.payload;
      return addPartnerships(state, productUID, 'approved', partnershipUIDs);
    }

    case REMOVE_PRODUCT_APPROVED_PARTNERSHIPS: {
      const { productUID, partnershipUIDs } = action.payload;
      return removePartnerships(state, productUID, 'approved', partnershipUIDs);
    }

    case ADD_PRODUCT_INVITED_PARTNERSHIPS: {
      const { productUID, invitedInfluencerUIDs } = action.payload;

      return {
        ...state,
        [productUID]: {
          ...state[productUID],
          invitedInfluencerUIDs: [
            ...new Set([
              ...(state[productUID].invitedInfluencerUIDs || []),
              ...invitedInfluencerUIDs,
            ]),
          ],
        },
      };
    }

    case REMOVE_PRODUCT_INVITED_PARTNERSHIPS: {
      const { productUID, invitedInfluencerUIDs } = action.payload;
      const newInvitedInfluecerUIDs = state[productUID].invitedInfluencerUIDs.filter(
        influencerUID => !invitedInfluencerUIDs.includes(influencerUID)
      );
      return {
        ...state,
        [productUID]: {
          ...state[productUID],
          invitedInfluencerUIDs: newInvitedInfluecerUIDs,
        },
      };
    }

    case ADD_PRODUCT_SUBMITTED_PARTNERSHIPS: {
      const { productUID, partnershipUIDs } = action.payload;
      return addPartnerships(state, productUID, 'submitted', partnershipUIDs);
    }

    case REMOVE_PRODUCT_SUBMITTED_PARTNERSHIPS: {
      const { productUID, partnershipUIDs } = action.payload;
      return removePartnerships(state, productUID, 'submitted', partnershipUIDs);
    }

    case ADD_PRODUCT_POSTS: {
      const { productUID, postUIDs } = action.payload;
      return addPosts(state, productUID, postUIDs);
    }

    case REMOVE_PRODUCT_POSTS: {
      const { productUID, postUIDs } = action.payload;
      return removePosts(state, productUID, postUIDs);
    }

    case ADD_PRODUCT_MESSAGE: {
      const { productUID, messageUIDs } = action.payload;
      return addMessages(state, productUID, messageUIDs);
    }

    case ADD_PRODUCT_CREATOR_CONTENTS: {
      const { productUID, creatorContentUIDs } = action.payload;
      return addCreatorContents(state, productUID, creatorContentUIDs);
    }

    case REMOVE_PRODUCT_CREATOR_CONTENTS: {
      const { productUID, creatorContentUIDs } = action.payload;
      return removeCreatorContents(state, productUID, creatorContentUIDs);
    }

    case SET_PRODUCT_CREATOR_CONTENTS_LISTENER: {
      const productUID = action.payload;
      return {
        ...state,
        [productUID]: {
          ...state[productUID],
          listenCreatorContents: true,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
