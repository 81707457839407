import { Form, message, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Button, { Variant } from 'components/Common/Button';
import { imageDimensionValidator } from 'helpers/customValidators';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import Upload from './Upload';

const useStyles = createUseStyles({
  modal: {
    fontFamily: 'SFProDisplay',
    borderRadius: '10px',
    minWidth: '735px',
    minHeight: '549px',
  },
  title: {
    fontFamily: 'SFProDisplay',
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: '54px',
    textAlign: 'center',
    marginBottom: '36px',
  },
  content: {
    display: 'grid',
    gap: '31px',
    justifyContent: 'center',
  },
  btnContainer: {
    display: 'grid',
    gap: '16px',
    gridAutoFlow: 'column',
  },
});

const ApprovalModal = ({ show, onApprove, onCancel, form }) => {
  const classes = useStyles();
  const { validateFieldsAndScroll, getFieldDecorator, resetFields } = form;
  const [submitting, setSubmitting] = useState(false);

  // Resets form values everytime modal is opened/closed.
  useEffect(() => {
    resetFields();
  }, [resetFields, show]);

  const handleSubmit = async e => {
    e.preventDefault();
    setSubmitting(true);
    validateFieldsAndScroll(async (errors, values) => {
      if (errors) {
        const messages = Object.keys(errors).map(key => `${key}: ${errors[key].errors[0].message}`);
        messages.forEach(error => {
          message.error(error);
        });
      } else {
        await onApprove(values);
      }
      setSubmitting(false);
    });
  };

  const handleCancel = () => {
    if (submitting) return;
    onCancel();
  };

  return (
    <Modal
      visible={show}
      closable={false}
      footer={false}
      className={classes.modal}
      onCancel={handleCancel}
    >
      <h3 className={classes.title}>Add Images and Approve</h3>

      <Form onSubmit={handleSubmit}>
        <div className={classes.content}>
          <div
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: '27px',
              justifyContent: 'center',
            }}
          >
            {getFieldDecorator('productImage', {
              rules: [{ validator: imageDimensionValidator({ minWidth: 640, minHeight: 360 }) }],
            })(<Upload title="Add a product image" subTitle="Minimum 640x360" color="#49C8FE" />)}

            {getFieldDecorator('brandImage', {
              rules: [{ validator: imageDimensionValidator({ minWidth: 640, minHeight: 360 }) }],
            })(<Upload title="Add a Brand Image" subTitle="Minimum 640x360" color="#49FE71" />)}
          </div>

          <div>
            {getFieldDecorator('description')(
              <TextArea rows={3} placeholder="2-3 sentences that decribe the company" />
            )}
          </div>

          <div className={classes.btnContainer}>
            <Button variant={Variant.Secondary} onClick={handleCancel}>
              Cancel
            </Button>

            <Button type="submit" disabled={submitting}>
              Yes, Approve
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: 'business-approval' })(ApprovalModal);
