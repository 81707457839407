import { useContext } from 'react';
import Context from './Context';

const useBrand = () => {
  const brand = useContext(Context);

  if (!brand) {
    throw new Error('Not inside a brand context');
  }

  return brand;
};

export default useBrand;
