import React from 'react';
import ImageKitImage from 'components/Common/ImageKitImage';

/**
 * Product Image Component
 *
 * @typedef {{ image: string }} ImageProps
 * @type {React.FC<ImageProps>}
 */
const Image = ({ image }) => (
  <div className="campaign-image">
    <ImageKitImage path={image} alt="Campaign" transformation={[{ width: '480' }]} />
  </div>
);

export default Image;
