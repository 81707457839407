// TODO: Figure this one out
import React, { useState, useEffect } from 'react';
import { Modal, Rate, Input, Button, Icon, Form, Row } from 'antd';
import { useSession } from 'components/Session';
import { brandReviewInfluencer } from 'lib/Firebase/callables/brands';
import { ReactComponent as CloseIcon } from 'images/Modal/icon-close.svg';
import { ReactComponent as StarIcon } from '../../images/star-grey.svg';
import { listenPendingReviews } from '../../listeners/brands';

const PendingModal = ({ form: { getFieldDecorator, validateFieldsAndScroll, resetFields } }) => {
  const {
    brand: { uid: brandUID },
  } = useSession();
  const [reviews, setReviews] = useState([]);
  const [serve, setServe] = useState(false);
  const [submitting, setSubmittingState] = useState(false);
  const review = reviews[0];

  useEffect(() => {
    if (review) {
      setTimeout(() => {
        setServe(true);
      }, 2000);
    }
  }, [review]);

  useEffect(() => {
    return listenPendingReviews(brandUID, (uid, review, action) => {
      if (action === 'added') {
        setReviews(state => [...state, { uid, ...review }]);
      }
    });
  }, []);

  function handleSubmit() {
    validateFieldsAndScroll(async (err, fields) => {
      setSubmittingState(true);
      if (!err) {
        const { attitudeProfessionalism, contentQuality, comments } = fields;
        let params = {
          attitudeProfessionalism,
          contentQuality,
        };

        if (comments) {
          params = {
            ...params,
            comments,
          };
        }

        brandReviewInfluencer(review.uid, params);
        resetFields();
        setServe(false);
        setSubmittingState(false);
        setReviews(state => {
          const newState = [...state];
          newState.shift();
          return newState;
        });
      } else {
        setSubmittingState(false);
      }
    });
  }

  async function handleSkip() {
    resetFields();
    setServe(false);
    setReviews(state => {
      const newState = [...state];
      newState.shift();
      return newState;
    });
    await brandReviewInfluencer(review.uid, { skip: true });
  }

  return (
    <Modal
      width={742}
      wrapClassName="pending-review-modal"
      footer={null}
      visible={serve}
      closable={false}
    >
      {review && (
        <>
          <div className="close">
            <Button onClick={handleSkip}>
              <Icon component={CloseIcon} />
            </Button>
          </div>
          <Row type="flex" justify="center">
            <div className="review-photo">
              <img src={review.image} alt="nice" />
              <span className="instagram-logo" />
              <span className="emoji-heart" />
              {review.code && (
                <Button
                  className="link"
                  type="default"
                  href={`https://instagram.com/p/${review.code}`}
                  target="_blank"
                >
                  View Post
                </Button>
              )}
            </div>
          </Row>

          <div className="review-message">
            <p>How was your experience working with @{review.username}?</p>
          </div>

          <div className="review-form">
            <Form.Item className="review-input">
              <label className="review-input-label">Content Quality</label>
              <div className="review-input-control">
                {getFieldDecorator('contentQuality', {
                  initialValue: 0,
                  rules: [
                    {
                      required: true,
                      message: 'Required',
                    },
                    {
                      type: 'number',
                      min: 1,
                      message: 'Required',
                    },
                  ],
                })(<Rate character={<Icon component={StarIcon} />} />)}
              </div>
            </Form.Item>
            <Form.Item className="review-input">
              <label className="review-input-label">Attitude & Professionalism</label>
              <div className="review-input-control">
                {getFieldDecorator('attitudeProfessionalism', {
                  initialValue: 0,
                  rules: [
                    { required: true, message: 'Required' },
                    {
                      type: 'number',
                      min: 1,
                      message: 'Required',
                    },
                  ],
                })(<Rate character={<Icon component={StarIcon} />} />)}
              </div>
            </Form.Item>
          </div>

          <div className="review-additional-comments">
            <Form.Item>
              {getFieldDecorator('comments')(
                <Input.TextArea
                  autosize={false}
                  placeholder="Any additional comments? (optional) This review will be made public."
                />
              )}
            </Form.Item>
          </div>
          <div className="review-footer">
            <Button className="skip-button" onClick={handleSkip}>
              Skip Review
            </Button>
            <Button
              className="submit-button"
              loading={submitting}
              onClick={handleSubmit}
              type="primary"
            >
              Submit Review
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default Form.create({ name: 'review-modal' })(PendingModal);
