import React from 'react';
import STATES from 'constants/states';

/**
 * @typedef {{ product: import('components/Session/Context').Product }} StateProps
 * @type {React.FC<StateProps>}
 */
const State = ({ product }) => {
  const { states } = product;
  return (
    <div>
      <h3 className="size-13px text-black">Target States</h3>
      <div className="size-12px post-requirements-states" style={{ marginTop: '21px' }}>
        {!Object.values(states || {}).length && <span className="label-value"> All </span>}
        {Object.values(states || {}).map((state, index) => (
          <div className="post-requirements-states-state" key={state}>
            <span className="label-value">
              <span className="circle">{index + 1}</span>
              <span className="mr-1">{STATES[state] || state}</span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default State;
