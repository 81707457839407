import { Row, Col, Button } from 'antd';
import { useProductData } from 'components/Products';
import React, { useState } from 'react';
import EmptyPosts from 'components/Common/EmptyPosts';
import Container from 'components/Common/Container';
import { CAMPAIGN_TYPE } from 'constants/campaigns';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { ReactComponent as BackArrow } from 'images/pages/Campaigns/ShowPage/Approvals/MediaCompleted/BackArrow.svg';
import CustomEmpty from 'components/Common/CustomEmpty';
import CreatorContent from 'components/CreatorContent';
import { retrieveAndCompressFiles, retrieveFile } from 'lib/Download/resourceDownloader';
import { CREATOR_CONTENT_CONTENTS_STATUS } from 'constants/creatorContents';
import { brandIncrementProductDownloadCount } from 'lib/Firebase/callables/brands';

/**
 * MediaDisplay
 *
 * @typedef {{ creatorContentUIDs: string[], type: string }} MediaDisplayProps
 * @type {React.FC<MediaDisplayProps>}
 */
const MediaDisplay = ({ creatorContentUIDs }) => {
  const creatorContents = useSelector(selectors.getCreatorContents());
  const influencers = useSelector(selectors.getInfluencers());
  const filteredContent = [];

  async function handleDownload(creatorContentUID, contentUID) {
    const { contents, influencerUID, productUID, partnershipUID } = creatorContents[
      creatorContentUID
    ];
    const { username } = influencers[influencerUID];
    const { highResolutionImage, highResolutionVideo, type } = contents[contentUID];

    await retrieveFile(highResolutionVideo || highResolutionImage, `${username}-${contentUID}`);
    await brandIncrementProductDownloadCount({
      campaignId: productUID,
      creatorId: influencerUID,
      partnershipId: partnershipUID,
      count: 1,
      type,
    });
  }

  creatorContentUIDs.forEach(uid => {
    const creatorContent = creatorContents[uid];
    Object.keys(creatorContent.contents || {}).forEach(contentUID => {
      if (creatorContent.contents[contentUID].status === CREATOR_CONTENT_CONTENTS_STATUS.APPROVED) {
        filteredContent.push(
          <Col md={8} sm={12} xs={24} key={contentUID} style={{ marginBottom: '10px' }}>
            <CreatorContent.Card
              creatorContentUID={uid}
              contentUID={contentUID}
              onDownload={handleDownload}
            />
          </Col>
        );
      }
    });
  });

  if (filteredContent.length === 0) {
    return (
      <CustomEmpty
        style={{ marginTop: '10px', textAlign: 'center' }}
        description="No content yet."
      />
    );
  }

  return <Row gutter={10}>{filteredContent}</Row>;
};

/**
 * Campaign Show Approval Completed Page
 *
 * @typedef {{}} PostsAnalyticsProps
 * @type {React.FC<PostsAnalyticsProps>}
 */
const MediaCompleted = () => {
  const creatorContents = useSelector(selectors.getCreatorContents());
  const {
    productUID,
    creatorContentUIDs: { approved, submitted, pending, rejected },
    approvedPartnershipUIDs,
  } = useProductData();

  const { name } = useSelector(selectors.getProduct(productUID));
  const [filters, setFilters] = useState({
    influencerUID: null,
  });
  const [downloading, setDownloading] = useState(false);

  const { type: campaignType = CAMPAIGN_TYPE.SOCIAL } = useSelector(
    selectors.getProduct(productUID)
  );

  let finalUIDs = [...approved, ...submitted, ...pending, ...rejected];
  if (filters.influencerUID) {
    finalUIDs = finalUIDs.filter(x => creatorContents[x].influencerUID === filters.influencerUID);
  }

  let hasApprovedContent = approved.length > 0;
  if (!hasApprovedContent) {
    finalUIDs.forEach(uid => {
      if (!hasApprovedContent && creatorContents[uid].approvedContentCount > 0) {
        hasApprovedContent = true;
      }
    });
  }

  if (!hasApprovedContent) {
    return (
      <div style={{ background: '#ffffff' }}>
        <EmptyPosts />
      </div>
    );
  }

  function handleFilterChange(newFilters) {
    setFilters(x => ({ ...x, ...newFilters }));
  }

  async function handleDownloadAll() {
    // TODO: Fix CORS issue
    const resources = [];
    finalUIDs.forEach(uid => {
      const { contents } = creatorContents[uid];
      Object.keys(contents || {}).forEach(contentUID => {
        const { highResolutionVideo, highResolutionImage, status: contentStatus } = contents[
          contentUID
        ];
        const url = highResolutionVideo || highResolutionImage;
        if (contentStatus === CREATOR_CONTENT_CONTENTS_STATUS.APPROVED) {
          resources.push({
            url,
            filename: contentUID,
          });
        }
      });
    });

    setDownloading(true);
    await retrieveAndCompressFiles(resources, name);
    await brandIncrementProductDownloadCount({
      campaignId: productUID,
      creatorId: 'all',
      partnershipId: 'all',
      count: resources.length,
      type: 'all',
    });
    setDownloading(false);
  }

  return (
    <div id="campaignMediaCompleted">
      <Container width={742}>
        <Row style={{ marginTop: '24px', marginBottom: '27px' }}>
          <Col span={14}>
            <CreatorContent.Filters
              partnershipUIDs={approvedPartnershipUIDs}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={10} className="download-container">
            <Button
              loading={downloading}
              id="downloadAllBtn"
              className="trend-btn"
              onClick={handleDownloadAll}
            >
              Download All
              <BackArrow
                style={{
                  width: '7px',
                  height: '7px',
                  marginLeft: '6.51px',
                }}
              />
            </Button>
          </Col>
        </Row>
      </Container>

      <Container width={742}>
        <MediaDisplay type={campaignType} creatorContentUIDs={finalUIDs} />
      </Container>
    </div>
  );
};

export default MediaCompleted;
