import React from 'react';
import ShowcaseVideo from './ShowcaseVideo';

/**
 *
 * @typedef {{
 *  showModal: () => any,
 *  item: any
 * }} ContentCardProps
 * @type {React.FC<ContentCardProps>}
 */

export const ContentCard = React.memo(({ showModal, item }) => {
  const { mediaUrl, creatorHandle, type, profilePictureUrl, mediaThumbnail, styleType } = item;

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      className="content-card"
      role="button"
      onClick={() => showModal(mediaUrl, creatorHandle, type, profilePictureUrl, styleType)}
    >
      {type === 'image' ? (
        <img className="content image" alt={creatorHandle} src={mediaThumbnail} />
      ) : (
        <ShowcaseVideo noPlay={false} src={item.previewUrl} extra={mediaThumbnail} />
      )}
      <span className="content-creator">
        <img className="creator-avatar" alt={creatorHandle} src={profilePictureUrl} />
        <p className="creator-name">{creatorHandle}</p>
      </span>
    </div>
  );
});
