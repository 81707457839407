import React from 'react';
import { Modal } from 'antd';
import Video from 'components/Common/Video';

const VideoPreviewModal = ({ setVisible, visible, videoLink, autoPlay }) => {
  return (
    <Modal
      footer={null}
      closable={false}
      wrapClassName="videos-preview"
      visible={visible}
      width={5}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <Video src={visible ? videoLink : undefined} autoPlay={autoPlay} />
    </Modal>
  );
};

export default VideoPreviewModal;
