import React from 'react';
import { Icon } from 'antd';
import { ReactComponent as CreateNewProject } from 'images/icons/NewProjectIcons/AddFirstProject.svg';
import mixpanel from 'lib/Analytics/Mixpanel';
import { toNewCampaign } from 'constants/routes';

/**
 *
 * @typedef {{}} InitalSideMenuProps
 * @type {React.FC<InitalSideMenuProps>}
 */

const InitalSideMenu = () => {
  function trackRouting(e) {
    e.preventDefault();
    if (mixpanel) {
      mixpanel.track('Brand Starts Campaign', { type: 'first' });
    }
    toNewCampaign();
  }

  return (
    <div style={{ color: 'white' }}>
      <div id="project-tab">My Projects</div>
      <div style={{ padding: '0 35px 0 10px' }}>
        <button
          type="button"
          onClick={trackRouting}
          style={{ border: 'none', background: 'transparent' }}
        >
          <span id="icon-container">
            <div className="sonar-wrapper">
              <div className="sonar-emitter">
                <Icon component={CreateNewProject} style={{ fontSize: '29.33px' }} />
                <div className="sonar-wave sonar-wave1" />
                <div className="sonar-wave sonar-wave2" />
                <div className="sonar-wave sonar-wave3" />
              </div>
            </div>
            <div id="icon-text">Start New Project</div>
          </span>
        </button>
      </div>
    </div>
  );
};

export default InitalSideMenu;
