import { DATE_TIMEZONE_FORMAT } from 'constants/common';
import moment from 'moment';

export const WRITE_MESSAGES = 'WRITE_MESSAGES';

/**
 * Write Message Action
 *
 * @param {Record<string, import("types").Message>} messages
 * @returns {{ type: WRITE_MESSAGES, payload: messages }}
 */
export const writeMessages = messages => {
  const messageUIDs = Object.keys(messages);
  messageUIDs.forEach(messageUID => {
    const message = messages[messageUID];
    const [brandUID] = Object.keys(message.users).filter(x => message.users[x].type === 'brand');
    const [influencerUID] = Object.keys(message.users).filter(
      x => message.users[x].type === 'influencer'
    );
    let lastBrandRead;
    let lastMessage;
    let lastMessageDate;
    // TODO: Double check this patch. There's a small delay between message thread creation and the first message being sent.
    Object.keys(message.messages || {}).forEach(chatUID => {
      const chat = message.messages[chatUID];
      if (
        !lastBrandRead ||
        (moment(chat.timestamp, DATE_TIMEZONE_FORMAT) > lastBrandRead && chat.sender === brandUID)
      ) {
        lastBrandRead = chat.timestamp;
      }

      if (!lastMessageDate || moment(chat.timestamp, DATE_TIMEZONE_FORMAT) > lastMessageDate) {
        lastMessageDate = moment(chat.timestamp, DATE_TIMEZONE_FORMAT).unix();
      }

      lastMessage = chat.text;
    });

    message.lastMessageDate = lastMessageDate;
    message.brandUID = brandUID;
    message.influencerUID = influencerUID;
    message.lastBrandRead = moment(lastBrandRead, DATE_TIMEZONE_FORMAT).unix();
    message.lastMessage = lastMessage;
  });

  return {
    type: WRITE_MESSAGES,
    payload: messages,
  };
};

/**
 * @typedef {ReturnType<writeMessages>} MessageActions
 */
