import { Menu } from 'antd';
import { toAdminV2ApiShowQueuePath } from 'constants/routes';
import React from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Api Queue Tabs
 * @type {React.FC}
 */
const Tabs = () => {
  const history = useHistory();
  const { pathname } = history.location;
  let [, selected] = pathname.split('/apiQueues');
  [, selected] = selected.split('/');

  async function handleChange({ key }) {
    history.push(toAdminV2ApiShowQueuePath(key));
  }

  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={[selected]}
      selectedKeys={[selected]}
      onClick={handleChange}
    >
      <Menu.Item key="socialData">Social Data</Menu.Item>
      <Menu.Item key="activeCampaign">Active Campaign</Menu.Item>
    </Menu>
  );
};

export default Tabs;
