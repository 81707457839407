import React from 'react';
import { Menu } from 'antd';
import Container from 'components/Common/Container';
import { useSession } from 'components/Session';
import { useHistory } from 'react-router-dom';

/**
 * Admin Layout Nav
 *
 * @type {React.FC}
 */
const AdminNav = () => {
  const history = useHistory();
  let path = history.location.pathname;
  path = path.substring(path.lastIndexOf('/') + 1);
  const {
    brand: { isAdmin },
  } = useSession();

  function goTo(p) {
    history.push(`/adminv2/${p}`);
  }

  return (
    <div className="page-header">
      <Container width={940}>
        <h1>Trend Brands</h1>
        {isAdmin && (
          <Menu mode="horizontal" selectedKeys={[path]}>
            <Menu.Item key="influencers" onClick={() => goTo('influencers')}>
              Database
            </Menu.Item>
            <Menu.Item key="activeCampaigns" onClick={() => goTo('activeCampaigns')}>
              Active Campaigns
            </Menu.Item>
            <Menu.Item key="reviews" onClick={() => goTo('reviews')}>
              Reviews
            </Menu.Item>
            <Menu.Item key="referrals" onClick={() => goTo('referrals')}>
              Referrals
            </Menu.Item>
            {/* <Menu.Item key="businesses" onClick={() => goTo('businesses')}>
            Businesses
          </Menu.Item> */}
            {/* <Menu.Item key="yotpoMetric" onClick={() => goTo('yotpoMetric')}>
            YotPo Metric
          </Menu.Item> */}
            <Menu.Item key="payouts" onClick={() => goTo('payouts')}>
              Payouts
            </Menu.Item>
            <Menu.Item key="onboardingGuides" onClick={() => goTo('onboardingGuides')}>
              Onboarding Guides
            </Menu.Item>
            <Menu.Item key="businessApproval" onClick={() => goTo('businessApproval')}>
              Businesses for approval
            </Menu.Item>
            <Menu.Item key="expiredCredits" onClick={() => goTo('expiredCredits')}>
              Expired Credits
            </Menu.Item>
            <Menu.Item key="pendingInfluencers" onClick={() => goTo('pendingInfluencers')}>
              Pending Influencers
            </Menu.Item>
            <Menu.Item key="subscriptions" onClick={() => goTo('subscriptions')}>
              Subscriptions
            </Menu.Item>
            <Menu.Item key="apiQueues" onClick={() => goTo('apiQueues')}>
              API Queues
            </Menu.Item>
          </Menu>
        )}
      </Container>
    </div>
  );
};

export default AdminNav;
