// TODO: Figure this one out
import React from 'react';
import { Row, Col } from 'antd';
import chunk from 'lodash/chunk';

const instagramLink = code => `https://instagram.com/p/${code}`;

const RecentPost = ({ images = {} }) => {
  if (Object.keys(images).length === 0) {
    return <div />;
  }

  const imageList = Object.keys(images)
    .splice(0, 12)
    .map(id => ({ id, path: images[id] }));

  const imageDisplay = chunk(imageList, 2).map((imageGroup, index) => {
    const imageCol = imageGroup.map(image => (
      <Col key={image.id} span={12}>
        <a href={instagramLink(image.id)} target="_blank" rel="noopener noreferrer">
          <img src={image.path} alt="recent-post" style={{ objectFit: 'cover' }} />
        </a>
      </Col>
    ));

    return (
      // eslint-disable-next-line react/no-array-index-key
      <Row key={index} gutter={4}>
        {imageCol}
      </Row>
    );
  });

  return (
    <div className="influencer-posts">
      <label className="influencer-info-label">RECENT POSTS</label>
      <div className="influencer-posts-images">{imageDisplay}</div>
    </div>
  );
};

export default RecentPost;
