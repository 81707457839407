// overall status for influencer
// posts
// > influencers/../partnerships/products/../status
export const POST_STATUS = {
  needsShipping: 'needsShipping',
  shipped: 'shipped',
  rejected: 'rejected',
  active: 'active',
  completed: 'completed',
};

// influencer per post status
// or detail status
// > influencers/../partnerships/products/../posts/../details/status
export const POST_DETAIL_STATUS = {
  submitted: 'submitted',
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
};

/**
 * @enum {{VIDEO: 'video', IMAGE: 'image', STORY: 'story'}} PostTypes
 */

/**
 * @type {POST_TYPES}
 */
export const POST_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image',
  STORY: 'story',
};

export const IMPRESSIONS = 13;
