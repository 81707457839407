import React from 'react';
import { Row, Col } from 'antd';
import { ReactComponent as IconBack } from 'images/icon-back-arrow.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

const styles = {
  campaignTitleWrapper: {
    width: 'calc(100% - 60px)',
  },
  campaignTitle: {
    fontSize: '26',
    lineHeight: '30px',
    paddingLeft: '20px',
  },
  back: {
    width: '60px',
    color: '#000',
    borderRight: '1px solid #666',
  },
  userAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  userInfo: {
    marginLeft: '20px',
  },
  notifIcon: {
    marginLeft: '40px',
  },
};

/**
 * Example Header Component
 *
 * @typedef {{ productUID: string }} ExampleHeaderProps
 * @type {React.FC<ExampleHeaderProps>}
 */
const ExampleHeader = ({ productUID }) => {
  const { name: productName } = useSelector(selectors.getProduct(productUID));

  return (
    <Row styles={styles.bannerWrapper} type="flex" justify="space-between">
      <Col md={18} xs={20} style={{ paddingBottom: '30px' }}>
        <Row type="flex" align="middle">
          <Col style={styles.back}>
            <Link
              className="type-sfpro-regular text-center"
              to="/exampleCampaigns"
              style={{ color: '#000', fontStyle: 'italic', fontWeight: 'bold' }}
            >
              <IconBack />
              <br />
              Back
            </Link>
          </Col>
          <Col style={styles.campaignTitleWrapper}>
            <h1 style={styles.campaignTitle} className="mb-0">
              {productName}
            </h1>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ExampleHeader;
