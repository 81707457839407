/* eslint-disable consistent-return */
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectDatabaseEmulator } from 'firebase/database';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/messaging';
import app from 'firebase/compat/app';
import 'firebase/compat/performance';
import mime from 'mime-types';
import { clearAuthCookies } from 'utils/cookies';

const appConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(appConfig);

    this.auth = app.auth();
    this.db = app.database();
    this.googleAuth = getAuth();
    this.storage = app.storage();
    this.firestore = app.firestore();
    this.functions = app.functions();
    if (
      process.env.REACT_APP_VERCEL_ENV === 'development' &&
      process.env.REACT_APP_USE_CUSTOM_EMULATORS === 'true'
    ) {
      // If you want to use the emulator database
      this.functions.useEmulator('localhost', 5002);

      // authentication emulator
      connectAuthEmulator(this.googleAuth, 'http://localhost:9099');

      // database emulator
      connectDatabaseEmulator(this.db, 'localhost', 9000);

      // firestore emulator
      connectFirestoreEmulator(this.firestore, 'localhost', 8080);
    }
    this.app = app;

    try {
      this.messaging = app.messaging();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("This browser doesn't support the API's required to use the firebase SDK. ", e);
    }
  }

  doSignOut = (event, callback = null) => {
    event.preventDefault();
    clearAuthCookies();
    if (callback) {
      callback();
    }
    this.auth.signOut();
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  brand = uid => this.db.ref(`brands/${uid}`);

  brands = () => this.db.ref('brands');

  brandInfo = uid => this.db.ref(`brands/${uid}/details`);

  posts = () => this.db.ref('posts');

  influencer = uid => this.db.ref(`influencers/${uid}`);

  influencers = () => this.db.ref('influencers');

  influencerProduct = (influencerUID, campaignUID) =>
    this.db.ref(`influencers/${influencerUID}/partnerships/products/${campaignUID}`);

  messages = () => this.db.ref('messages');

  influencers = () => this.db.ref('influencers');

  activeProducts = () => this.db.ref('products/active');

  activeProduct = uid => this.db.ref(`products/active/${uid}`);

  completedProduct = campaignUID => this.db.ref(`products/completed/${campaignUID}`);

  completedProducts = () => this.db.ref('products/completed');

  campaignPosts = uid =>
    this.db.ref('influencers').orderByChild(`partnerships/products/${uid}/posts`);

  paypalRef = () => this.db.ref('admin/payPal');

  payoutsRef = () => this.db.ref('admin/payouts');

  postStatistics = brandUID => this.db.ref('postStatistics').child(brandUID);

  posts = () => this.db.ref('posts');

  additionalMedia = campaignUID => this.db.ref('additionalMedia').child(campaignUID);

  reviews = () => this.firestore.collection('reviews');

  trendReviews = () => this.firestore.collection('trendReviews');

  review = reviewUID => this.firestore.collection('reviews').doc(reviewUID);

  purchasedHistory = () => this.firestore.collection('purchasedHistory');

  brandSearch = () => this.firestore.collection('brandSearch');

  influencerSearch = () => this.firestore.collection('influencers');

  notifications = () => this.firestore.collection('notifications');

  notifAlerts = brandUID =>
    this.firestore
      .collection('notifications')
      .doc(brandUID)
      .collection('notifAlerts');

  trackDownloadPost = async postUID => {
    const postSnap = await this.db
      .ref('posts')
      .child(postUID)
      .once('value');

    if (postSnap.exists()) {
      const { productUID, influencerUID } = postSnap.val();

      await this.firestore
        .collection('products')
        .doc(productUID)
        .update({
          [`appliedUID.${influencerUID}.posts.${postUID}.downloaded`]: true,
        });
    }
  };

  yotpoMetric = () => this.firestore.collection('yotpoMetric');

  callable = name => {
    return this.functions.httpsCallable(`callable-${name}`);
  };

  callService = ({ serviceName, data, type }) => {
    const func = this.callable('requestService');
    return func({ serviceName, data, type });
  };

  branchUserJobPostStatsUpdate = async data => {
    const func = this.callable('branchUserJobPostStatsUpdate');

    return func(data);
  };

  updateProductActiveByDeliveryDate = ({ influencerUID, productUID, deliveryDate }) => {
    const func = this.callable('updateProductActiveByDeliveryDate');
    return func({ influencerUID, productUID, deliveryDate });
  };

  fixInfluencersData = ({ mainInfluencerUID }) => {
    const func = this.callable('fixInfluencersData');
    return func({ mainInfluencerUID });
  };

  uploadFile = async ({ file, filename }) => {
    const ext = mime.extension(file.type);
    const snapshot = await this.storage.ref([filename, ext].join('.')).put(file);
    const url = await snapshot.ref.getDownloadURL();
    return url;
  };

  updateInfluencersInstagramData = async ({ influencerUID, instagramId }) => {
    const func = this.callable('updateInfluencersInstagramData');
    return func({ influencerUID, instagramId });
  };
}

export default Firebase;
