import Container from 'components/Common/Container';
import Product from 'components/Products';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { Row, Col } from 'antd';

/**
 * Example Campaigns Page
 *
 * @typedef {{}} ExampleCampaignProps
 * @type {React.FC<ExampleCampaignProps>}
 */
const ListPage = () => {
  const productUIDs = useSelector(selectors.getExampleCampaigns());

  return (
    <div id="recent-campaign-page">
      <Container width={733}>
        <h1>Example Campaigns</h1>
        <Row>
          <Col span={24}>
            <Product.ExampleList productUIDs={productUIDs} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ListPage;
