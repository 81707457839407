import React, { useState } from 'react';
import { useCheckSession } from 'components/Session';
import RehireContext from './Context';
import InvitationModal from './InvitationModal';

/**
 * Influencer Rehire Provider Component
 *
 * @typedef {{ onSubmit: () => Promise<any> }} RehireProviderProps
 * @type {React.FC<RehireProviderProps>}
 */
const RehireProvider = ({ children, onSubmit }) => {
  const hasSession = useCheckSession();
  const [invitationModal, setInvitationModal] = useState({
    show: false,
    influencerUID: null,
  });

  function handleShowInvitationModal(influencerUID) {
    setInvitationModal({ influencerUID, show: true });
  }

  // edgecase for no session
  if (!hasSession) {
    return (
      <RehireContext.Provider
        value={{ ...invitationModal, showInvitationModal: handleShowInvitationModal }}
      >
        {children}
      </RehireContext.Provider>
    );
  }

  return (
    <RehireContext.Provider
      value={{ ...invitationModal, showInvitationModal: handleShowInvitationModal }}
    >
      {children}
      <InvitationModal
        show={invitationModal.show}
        influencerUID={invitationModal.influencerUID}
        onClose={() => setInvitationModal({ influencerUID: null, show: false })}
        onSubmit={onSubmit}
      />
    </RehireContext.Provider>
  );
};

export default RehireProvider;
