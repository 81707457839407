import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from 'images/ContentShowcase/DrawerButton.svg';

/**
 *
 * @typedef {{
 *  question: string,
 *  answer: string,
 * }} FAQCardProps
 * @type {React.FC<FAQCardProps>}
 */

const FAQCard = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  function drawerOpen() {
    setIsOpen(!isOpen);
  }

  const drawerClass = isOpen ? 'answer-drawer open' : 'answer-drawer';
  const iconClass = isOpen ? 'plus-icon open' : 'plus-icon';
  return (
    <div className="faq-card">
      <ul>
        <li>
          <span>
            <div className="question-text">{question}</div>
            <PlusIcon
              className={iconClass}
              onClick={() => drawerOpen()}
              style={{ height: '24px', width: '24px' }}
            />
          </span>
        </li>
        <li className={drawerClass}>{answer}</li>
      </ul>
    </div>
  );
};

export default FAQCard;
