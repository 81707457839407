/**
 * @typedef {ReturnType<addPostsStatistics>} EntityPostsStatisticsActions
 */
export const ADD_POSTS_STATISTICS = 'ADD_POSTS_STATISTICS';

/**
 * @param {string} posts
 * @param {Record<string, import("types").PostStatistic>} postsStatistics
 * @returns {{ type: ADD_POSTS_STATISTICS, payload: { productUID: string, postsStatistics: postsStatistics}> }}
 */
export const addPostsStatistics = (productUID, postsStatistics) => ({
  type: ADD_POSTS_STATISTICS,
  payload: {
    productUID,
    postsStatistics,
  },
});
