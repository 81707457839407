import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Form, Input, Button, Modal, PageHeader, Checkbox, Divider, message } from 'antd';
import { selectors } from 'stores';
import { validateStripeCustomerId } from 'api/subscriptions';
import { patchAdminUpdateBrand } from 'api/admin';
import BrandCollectionFields from './BrandCollectionFields';

const { confirm } = Modal;

/**
 * Admin Brand Edit Page
 * @typedef {{ form: import('antd/lib/form/Form').WrappedFormUtils }} EditProps
 * @type {React.FC<EditProps>}
 */
const Edit = ({ form }) => {
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { validateFieldsAndScroll, getFieldDecorator, setFieldsValue, isFieldTouched } = form;
  const { id } = useParams();
  const brand = useSelector(selectors.getBrand(id));
  const { isAdmin } = brand;
  useEffect(() => {
    const { stripeCustomerId, isContentAdmin } = brand;
    setFieldsValue({
      stripeCustomerId,
      isContentAdmin,
    });
  }, []);

  async function validateStripe(rule, value, callback) {
    if (value && isFieldTouched('stripeCustomerId')) {
      try {
        setSearching(true);
        await validateStripeCustomerId(value);
        setSearching(false);
        callback();
      } catch (err) {
        callback(false);
      }
    }
  }

  function handleSubmit() {
    confirm({
      title: 'Are you sure you want to update this Brand?',
      onOk: async () => {
        validateFieldsAndScroll(async (err, values) => {
          if (err) return;
          setSubmitting(true);
          try {
            Object.keys(values).forEach(x => {
              if (!values[x]) {
                delete values[x];
              }
            });
            await patchAdminUpdateBrand({ brandUID: id, ...values });
            message.success('Brand successfully updated');
          } catch (e) {
            message.error(e.message);
          }
          setSubmitting(false);
        });
      },
    });
  }

  return (
    <>
      <PageHeader title={brand.name} onBack={() => history.push('/admin/brands')} />
      <Row>
        <Divider orientation="left">Stripe & Admin Access</Divider>
        <Form form={form}>
          <Form.Item label="Stripe Customer Id" style={{ width: '200px' }}>
            {getFieldDecorator('stripeCustomerId', {
              rules: [
                { required: true, message: 'Required' },
                {
                  validator: validateStripe,
                  message: 'Stripe customer does not exists',
                },
              ],
            })(<Input.Search loading={searching} />)}
          </Form.Item>
          {getFieldDecorator('isContentAdmin')(
            // TODO: Finish update functionality for isContentAdmin handling
            <Checkbox checked={brand.isContentAdmin}>Allow access to Admin Content page</Checkbox>
          )}

          <Form.Item>
            {isAdmin && (
              <small style={{ color: 'red' }}>
                Account already has full admin privileges.
                <br />
              </small>
            )}
            <Button loading={submitting} onClick={handleSubmit} type="primary">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Row>
      <BrandCollectionFields brand={brand} />
    </>
  );
};

export default Form.create({ name: 'admin-brand-edit' })(Edit);
