import React from 'react';
import { ReactComponent as IconProfile } from 'images/icon-profile.svg';
import { ReactComponent as IconApprove } from 'images/icon-approve.svg';
import { ReactComponent as IconReject } from 'images/icon-reject.svg';

/**
 * @typedef {{
 * partnershipUID: stirng,
 * approveCallback: (partnershipUID: string) => void,
 * rejectCallback: (partnershipUID: string) => void,
 * viewProfileCallback: (partnershipUID: string) => void
 * }} ApplicationActionsProps
 * @type {React.FC<ApplicationActionsProps>}
 */
const ApplicationActions = ({ approveCallback, rejectCallback, viewProfileCallback }) => {
  return (
    <div className="cell-action">
      <div
        role="presentation"
        style={{ cursor: 'pointer' }}
        className="link"
        onClick={viewProfileCallback}
      >
        <IconProfile
          width="14"
          height="15"
          style={{ position: 'relative', top: '3px', marginRight: '5px' }}
        />
        <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>PROFILE</span>
      </div>
      <div>
        <div
          role="presentation"
          style={{ cursor: 'pointer' }}
          className="link"
          onClick={approveCallback}
        >
          <IconApprove
            width="14"
            height="15"
            style={{
              position: 'relative',
              top: '5px',
              marginRight: '5px',
            }}
          />
          <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>APPROVE</span>
        </div>

        <div
          role="presentation"
          style={{ cursor: 'pointer' }}
          className="link"
          onClick={rejectCallback}
        >
          <IconReject
            width="14"
            height="15"
            style={{
              position: 'relative',
              top: '5px',
              marginRight: '5px',
            }}
          />
          <span style={{ color: '#5b6572', letterSpacing: '0.5px', fontSize: '9px' }}>PASS</span>
        </div>
      </div>
    </div>
  );
};

export default ApplicationActions;
