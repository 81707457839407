import React from 'react';
import { ReactComponent as IconSignout } from 'images/icon-sign-out.svg';
import { compose } from 'recompose';
import { withFirebase } from 'lib/Firebase';
import { withCookies } from 'react-cookie';

const onSignOut = (event, firebase) => {
  firebase.doSignOut(event);
};

const SignOutButton = ({ firebase, cookies }) => (
  <a
    href="/logout"
    onClick={event => {
      onSignOut(event, firebase, cookies);
    }}
  >
    <IconSignout />
    <span className="white d-block ht-5 bsize-4 d-block type-sbold ">Sign Out</span>
  </a>
);

export default compose(
  withFirebase,
  withCookies
)(SignOutButton);
