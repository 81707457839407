import React, { useState, useEffect } from 'react';
import { brandFavoriteInfluencer, brandUnfavoriteInfluencer } from 'lib/Firebase/callables/brands';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { ReactComponent as FilledHeart } from 'images/FavoritingIcons/Heart_Filled.svg';
import { ReactComponent as EmptyHeart } from 'images/FavoritingIcons/Heart_Empty.svg';
import { useSession } from 'components/Session';

const FavoriteCreatorButton = ({ partnershipUID }) => {
  const partnership = useSelector(selectors.getPartnership(partnershipUID));
  const creatorId = partnership.influencerUID;
  const [isFavorite, setIsFavorite] = useState();
  const [isLoading, setIsLoading] = useState();
  const { brand } = useSession();

  useEffect(() => {
    //if partnership has been favorited then setIsFavorite(true) else it remains in default state of false
    const favoriteStatus = async () => {
      if (brand && brand.favorites) {
        setIsFavorite(brand.favorites.includes(partnership.influencerUID));
      } else {
        setIsFavorite(partnership.isFavorite ? partnership.isFavorite : false);
      }
    };
    favoriteStatus();
  }, []);

  async function handleFavoriteCreator() {
    if (!isLoading) {
      setIsFavorite(true);
      setIsLoading(true);
      try {
        const changedDB = await brandFavoriteInfluencer(brand.uid, {
          creatorId,
          partnershipId: partnershipUID,
        });
        if (changedDB) {
          setIsFavorite(true);
          setIsLoading(false);
          notification.success({
            message: 'Added to Favorites',
            description: 'Creator added to your favorites list',
            duration: 2,
          });
        }
      } catch (e) {
        setIsFavorite(false);
        setIsLoading(false);
        notification.error({
          message: 'error',
          description:
            String(e.message) || 'Something went wrong on adding influencer to favorites',
          duration: 4,
        });
      }
    }
  }

  async function handleUnFavoriteCreator() {
    if (!isLoading) {
      setIsFavorite(false);
      setIsLoading(true);
      try {
        // include check for response before setting the state
        const changedDB = await brandUnfavoriteInfluencer(brand.uid, {
          creatorId,
          partnershipId: partnershipUID,
        });
        if (changedDB) {
          setIsFavorite(false);
          setIsLoading(false);
          notification.success({
            message: 'Removed from Favorites',
            description: 'Creator removed from your favorites list',
            duration: 2,
          });
        }
      } catch (e) {
        setIsFavorite(true);
        setIsLoading(false);
        notification.error({
          message: 'error',
          description:
            String(e.message) || 'Something went wrong on removing influencer to favorites',
          duration: 4,
        });
      }
    }
  }
  return (
    <div className="favorite-button-container">
      <button
        type="button"
        className="favorite-button"
        onClick={() => (isFavorite ? handleUnFavoriteCreator() : handleFavoriteCreator())}
      >
        <div className="favorite-icon">{isFavorite ? <FilledHeart /> : <EmptyHeart />}</div>
      </button>
    </div>
  );
};

export default FavoriteCreatorButton;
