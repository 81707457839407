/**
 * @typedef {ReturnType<addProduct> | ReturnType<addProducts> | ReturnType<updateProducts>} EntityProductActions
 */

export const ADD_PRODUCT = 'ADD_PRODUCT';

/**
 * @param {string} productUID
 * @param {import("components/Session/Context").Product} product
 * @returns {{ type: ADD_PRODUCT, payload: { productUID: product, product: import("components/Session/Context").Product}}}
 */
export const addProduct = (productUID, product) => ({
  type: ADD_PRODUCT,
  payload: {
    productUID,
    product,
  },
});

export const ADD_PRODUCTS = 'ADD_PRODUCTS';
/**
 *
 * @param {Record<string, import("types").Product>} products
 * @returns {{ type: ADD_PRODUCTS, payload: Record<string, import("types").Product>}}
 */
export const addProducts = products => ({
  type: ADD_PRODUCTS,
  payload: products,
});

export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';

/**
 * @param {Record<string, import("types").Product>} products
 * @returns {{ type: UPDATE_PRODUCTS, payload: Record<string, import("types").Product>}}
 */
export const updateProducts = products => ({
  type: UPDATE_PRODUCTS,
  payload: products,
});

export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export const clearProducts = () => ({
  type: CLEAR_PRODUCTS,
  payload: [],
});
