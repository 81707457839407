import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSession } from 'components/Session';

const RootPage = () => {
  const { activeProductUIDs } = useSession();
  const recentCampaignUID = activeProductUIDs[0];

  let redirectUrl = '/campaigns/showcase';

  if (recentCampaignUID) {
    redirectUrl = `/campaigns/${recentCampaignUID}/summary`;
  }

  return <Redirect to={redirectUrl} />;
};

export default RootPage;
