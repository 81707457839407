import moment from 'moment';
import { Button, Descriptions, Radio, Table } from 'antd';
import { useFirebase } from 'lib/Firebase';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/**
 * @type {import('antd/lib/table').ColumnProps[]}
 */
const columns = [
  {
    key: 'completedAt',
    dataIndex: 'completedAt',
    title: 'Requested At',
    render: val => {
      return moment.unix(val).format('MMM DD, YYYY HH:mm:SS');
    },
  },
  {
    key: 'actionName',
    dataIndex: 'actionName',
    title: 'Action Name',
  },
  {
    key: 'status',
    dataIndex: 'status',
    title: 'Status',
  },
];

/**
 * Api Queues Show Page
 * @type {React.FC}
 */
const ShowPage = () => {
  const LIMIT = 20;
  const firebase = useFirebase();
  const { api } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    status: undefined,
    lastDoc: null,
    page: 1,
  });

  async function fetchData(newFilters = filters, loadmore) {
    setLoading(true);
    let query = firebase.firestore
      .collection('queues')
      .where('apiName', '==', api)
      .orderBy('completedAt', 'desc');

    if (newFilters.status) {
      query = query.where('status', '==', newFilters.status);
    }

    if (loadmore) {
      query = query.startAt(filters.lastDoc);
    }

    const queueDocs = await query.limit(LIMIT + 1).get();

    const newData = [];
    let lastDoc;
    queueDocs.forEach(doc => {
      newData.push({ key: doc.id, ...doc.data() });

      lastDoc = doc;
    });

    if (loadmore) {
      setData(x => [...x.slice(0, LIMIT * filters.page), ...newData]);
      setFilters({ ...newFilters, lastDoc, page: filters.page + 1 });
    } else {
      setData(newData);
      setFilters({ ...newFilters, lastDoc, page: 1 });
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [api]);

  function expandedRow({ result, data, createdAt, status, errorMessage }) {
    return (
      <div className="details">
        <Descriptions bordered title="Request Details">
          <Descriptions.Item label="Status">{status}</Descriptions.Item>
          <Descriptions.Item label="Created At" span={2}>
            {moment.unix(createdAt).format('MMM DD, YYYY HH:mm:SS')}
          </Descriptions.Item>
          <Descriptions.Item label="Parameters" span={3}>
            {Object.keys(data).map(field => (
              <div key={field}>
                {field}: {JSON.stringify(data[field])}
              </div>
            ))}
          </Descriptions.Item>
          {status === 'success' && (
            <Descriptions.Item label="Response" span={3}>
              {Object.keys(result).map(field => (
                <div key={field}>
                  {field}: {JSON.stringify(result[field])}
                </div>
              ))}
            </Descriptions.Item>
          )}
          {status === 'failed' && (
            <Descriptions.Item label="Error Message" span={3}>
              {errorMessage}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  }

  const limitData = data.slice(0, LIMIT * filters.page);
  return (
    <div id="api-queues-page">
      <div className="filters">
        <Radio.Group
          onChange={e => fetchData({ ...filters, status: e.target.value })}
          value={filters.status}
        >
          <Radio.Button value={undefined}>All</Radio.Button>
          <Radio.Button value="success">Success</Radio.Button>
          <Radio.Button value="pending">Pending</Radio.Button>
          <Radio.Button value="failed">Failed</Radio.Button>
        </Radio.Group>
      </div>

      <Table
        columns={columns}
        dataSource={limitData}
        expandedRowRender={expandedRow}
        pagination={false}
        loading={loading}
      />
      <div style={{ textAlign: 'center', padding: 10 }}>
        {data.length === LIMIT * filters.page + 1 && (
          <Button onClick={() => fetchData(filters, true)}>Load More</Button>
        )}
      </div>
    </div>
  );
};

export default ShowPage;
