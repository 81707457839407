import React from 'react';
import { Empty } from 'antd';
import NoInfluencersImg from 'images/DefaultEmpty/no-influencers.png';

const EmptyInfluencers = () => (
  <Empty
    className="empty-no-applications"
    style={{
      marginTop: '66px',
      marginBottom: '109px',
    }}
    image={NoInfluencersImg}
    description={
      <span className="empty-desc-header type-sfpro-bold mb-2 mt-6">
        No Creator Applications Approved
      </span>
    }
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        Once you approve creators, you’ll see their profile & shipping information here. This
        <br />
        will be your HUB for managing your project creators.
      </div>
    </div>
  </Empty>
);

export default EmptyInfluencers;
