import { mixpanelRegister, mixpanelTrack } from 'lib/Analytics/Mixpanel';
import { trackGtagEvent } from 'lib/Analytics/gtag';

export const trackAddPaymentInfo = brand => {
  const brandId = brand.uid;
  const firstName = brand.firstName
    ? brand.firstName
    : brand.fullName
        .split(' ')
        .slice(0, -1)
        .join(' ');
  const lastName = brand.lastName
    ? brand.lastName
    : brand.fullName
        .split(' ')
        .slice(-1)
        .join(' ');
  const brandPayload = {
    firstName,
    lastName,
    email: brand.email || brand.authEmail || null,
    country: brand.country || null,
    state: brand.state || null,
    city: brand.city || null,
    ipAddress: brand.ipAddress || null,
  };
  const cleanBrandPayload = Object.fromEntries(
    Object.entries(brandPayload).filter(([, v]) => v != null)
  );
  const AddPaymentInfoPayload = {
    event: 'AddPaymentInfo',
    ...cleanBrandPayload,
    external_id: brandId,
  };
  trackGtagEvent(AddPaymentInfoPayload);
  mixpanelRegister({ brandId });
  mixpanelTrack('Brand Adds Credit Card');
};
