import moment from 'moment';
import { patchBrandLoggedInAfterApproval } from 'api/analytics';

export const trackGtagEvent = data => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const cleanObject = obj =>
  Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));

/** Handle Login After Approval */
export const handleLoginAfterApproval = async (brand, authMetadata) => {
  // Check if 1st session since approval
  const { creationTime } = authMetadata;
  const createdAt = moment(creationTime).unix();
  const startClientSideTracking = moment('2023-08-01').unix();
  if (createdAt > startClientSideTracking) {
    // Fire GTM Event
    const cleanFullName = brand.fullName
      ? brand.fullName.trim().replace(/^[0-9\s]*|[+*\r\n]/g, '')
      : '';
    const firstName = brand.firstName
      ? brand.firstName
      : cleanFullName
          .split(' ')
          .slice(0, -1)
          .join(' ');

    const lastName = brand.lastName
      ? brand.lastName
      : cleanFullName
          .split(' ')
          .slice(-1)
          .join(' ');
    const gtagData = {
      external_id: brand.uid,
      firstName,
      lastName,
      registration_date: createdAt,
      email: brand.email || brand.authEmail,
    };
    trackGtagEvent({
      event: 'BrandLogsInAfterApproval',
      ...gtagData,
    });
  }
  patchBrandLoggedInAfterApproval();
};

// export enum GTagEvent {
//   PURCHASE = 'Purchase',
//   LAUNCH_CAMPAIGN = 'LaunchCampaign',
//   FB_IMAGE_CAMPAIGN = 'PhotographyCampaign',
//   FB_VIDEO_CAMPAIGN = 'VideoCampaign',
//   INITIATE_CHECKOUT = 'InitiateCheckout',
//   COMPLETE_REGISTRATION = 'complete_registration',
// }
// interface GTagTrackingEvent {
//   event: GTagEvent;
//   [key: string]: string | string[] | number | boolean | undefined;
// }
