// NOTE: This page can likely be removed
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { firebase } from 'lib/Firebase';
import Container from 'components/Common/Container';
import { Button, Table, Tag } from 'antd';

const columns = [
  {
    key: 'Brand Name',
    title: 'name',
    dataIndex: 'name',
  },
  {
    key: 'Email',
    title: 'email',
    dataIndex: 'email',
  },
  {
    key: 'type',
    title: 'Request Type',
    dataIndex: 'type',
    render: val => {
      if (val === 'pro') {
        return <Tag color="gold">PRO</Tag>;
      }
      return <Tag color="blue">Business</Tag>;
    },
  },
  {
    key: 'requestedAt',
    title: 'Requested At',
    dataIndex: 'requestedAt',
    render: val => moment(val * 1000).format('MMM DD, YYYY'),
  },
];

const LIMIT_INCREMENT = 10;
/**
 * Subscriptions Page
 *
 * @type {React.FC}
 */
const SubscriptionsPage = () => {
  const [lastDoc, setLastDoc] = useState();
  const [limit, setLimit] = useState(LIMIT_INCREMENT);
  const [data, setData] = useState([]);

  async function fetchData() {
    let query = await firebase.firestore
      .collection('subscriptionRequests')
      .orderBy('requestedAt')
      .limit(limit + 1);

    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }

    const subRequestDocs = await query.get();
    const addedData = [];
    let newLastDoc;
    subRequestDocs.forEach(doc => {
      addedData.push(doc.data());
      newLastDoc = doc;
    });

    setData(x => [...x, ...addedData]);
    setLastDoc(newLastDoc);
  }

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {}, [limit]);

  async function handleLoadMore() {
    setLimit(limit + LIMIT_INCREMENT);
  }

  const source = data.slice(0, limit);

  return (
    <Container width={1300}>
      <Table columns={columns} dataSource={source} pagination={false} />
      {data.length > limit && (
        <>
          <Button onClick={handleLoadMore}>Load more</Button>
        </>
      )}
    </Container>
  );
};

export default SubscriptionsPage;
