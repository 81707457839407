/* eslint-disable import/prefer-default-export */
export const PARTNERSHIP_STATUS = {
  INVITED: 'invited', // Influencer is invited by the brand
  SUBMITTED: 'submitted', // Influencer Submitted and application to the campaign
  NEEDS_SHIPPING: 'needsShipping', // Influencer Approved in the Campaign
  SHIPPED: 'shipped', // Influencer products is shipped by the campaign
  ACTIVE: 'active', // Influencer Receives the product from the brand
  POST_SUBMITTED: 'postSubmitted', // Influencer Posted all of the required posts from a campagin
  REJECTED: 'rejected', // Influencer is rejected from the campaign
  COMPLETED: 'completed', // Influencer completed his partnership with the campaign
  PENDING_APPROVAL: 'pendingApproval', // Minimum required content for all collection is submitted and waiting for approval
  REVISION_REQUESTED: 'revisionRequested', // Brand has a revision request for any content collection
};

export const APPROVE_PARTNERSHIP_STATUSES = [
  PARTNERSHIP_STATUS.ACTIVE,
  PARTNERSHIP_STATUS.NEEDS_SHIPPING,
  PARTNERSHIP_STATUS.SHIPPED,
  PARTNERSHIP_STATUS.PENDING_APPROVAL,
  PARTNERSHIP_STATUS.REVISION_REQUESTED,
  PARTNERSHIP_STATUS.COMPLETED,
  PARTNERSHIP_STATUS.POST_SUBMITTED,
];

export const PARTNERSHIP_PAYOUT_STATUS = {
  PAID: 'paid',
  PROCESSING: 'processing',
  DENIED: 'denied',
};
