import { Row, Col, Modal, Button } from 'antd';
import React, { useState } from 'react';
import VideoPlayer from 'react-player';
import Image from '../Image';
import MediaViewerContext from './Context';

/**
 * Media Viewer Provider
 *
 * @typedef {{ type: 'image' | 'video', src: string }} ProviderProps
 * @type {React.FC<ProviderProps>}
 */
const MediaProvider = ({ children }) => {
  const [playing, setPlaying] = useState(false);

  const [modal, setModal] = useState({
    show: false,
    url: null,
    type: null,
  });

  function handleClose() {
    setPlaying(false);
    setModal(prevModal => ({ ...prevModal, show: false }));
  }

  return (
    <MediaViewerContext.Provider value={{ setModal }}>
      {children}
      <Modal
        className="media-viewer-modal"
        centered
        width="auto"
        closable={false}
        visible={modal.show}
        onCancel={handleClose}
        footer={false}
        zIndex={9999}
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.9)' }}
      >
        <Row justify="center" type="flex">
          <Col sm={24}>
            {modal.type === 'image' || modal.type === 'story' ? (
              <Image src={modal.src} />
            ) : (
              <VideoPlayer
                url={modal.src}
                playing={playing}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                width="100%"
                height="80vh"
                controls
                config={{ file: { attributes: { controlsList: 'nodownload nofullscreen' } } }}
              />
            )}
            <div className="footer">
              <Button className="close" type="link" onClick={handleClose}>
                Close Content
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </MediaViewerContext.Provider>
  );
};

export default MediaProvider;
