import axios from 'axios';

export const getPendingInfluencers = async (filters = {}, pagination = {}) => {
  try {
    const params = filters.email
      ? {
          email: filters.email,
          applicationStatus: 'submitted',
        }
      : {
          applicationStatus: 'submitted',
          perPage: pagination.pageSize,
          page: pagination.page,
          paginationFlags: true,
          ...filters,
        };
    const url = filters.email
      ? `${process.env.REACT_APP_TREND_API_URL}/creator/findByUserEmail`
      : `${process.env.REACT_APP_TREND_API_URL}/creator`;

    const { data } = await axios.get(url, {
      params,
      headers: { 'trend-api-key': process.env.REACT_APP_TREND_API_KEY },
    });

    return {
      total: data.total || 0,
      totalPages: data.totalPages || 1,
      documents: data.documents || (data && [data]) || [],
    };
  } catch (error) {
    console.error(`Could not find pending influencers due to: ${JSON.stringify(error, null, 2)}`);
    return {
      totalPages: 1,
      total: 0,
      documents: [],
    };
  }
};

export const setCreatorBypassIsOverdueFlag = async (influencerUID, bypassIsOverdueFlag) => {
  try {
    const url = `${process.env.REACT_APP_TREND_API_URL}/creator/influencer/${influencerUID}`;

    const { data } = await axios.patch(
      url,
      {
        bypassIsOverdueFlag,
      },
      {
        headers: { 'trend-api-key': process.env.REACT_APP_TREND_API_KEY },
      }
    );

    return data;
  } catch (error) {
    console.error(`Could bypass overdue status due to: ${JSON.stringify(error, null, 2)}`);
    return false;
  }
};
