import { Switch, Route, Redirect } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import React from 'react';
import ShowPage from './ShowPage';
import ListPage from './ListPage';

/**
 * Admin V2 Brands
 *
 * @type {React.FC}
 */
const Brands = () => {
  return (
    <div>
      <Switch>
        <Route path={ROUTES.ADMINV2_BRAND_PATH} component={ShowPage} />
        <Route path={ROUTES.ADMINV2_BRANDS_PATH} component={ListPage} />
        <Redirect to={ROUTES.ADMINV2_BRANDS_PATH} />
      </Switch>
    </div>
  );
};

export default Brands;
