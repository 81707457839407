import {
  CREATOR_CONTENT_TYPES,
  ACTIVE_SUBMISSION_STATUSES,
  isSubmittedContent,
  isDownloadableContent,
  CREATOR_CONTENT_STATUS,
} from 'constants/creatorContents';

export const getProductContexts = () =>
  /**
   * @param {import('stores/reducers').MainReducer} state
   */
  state => {
    return state.productContexts;
  };
/**
 * Selector for Product page
 *
 * @param {string} productUID
 */
export const getProductPage = productUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {import('stores/reducers/productContexts').ProductContextReducer}
   */
  state => {
    return state.productContexts[productUID];
  };

/**
 *
 * @param {string} productUID
 * @returns {import("types").Partnership[]}
 */
export const getProductApplications = productUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {import('stores/reducers/productContexts').ProductContextReducer}
   */
  state => {
    const { partnerships, influencers } = state.entities;
    const applications = state.productContexts[productUID].submittedPartnershipUIDs
      .filter(uid => {
        const { influencerUID } = partnerships[uid];
        const isSuspendedInfluencer = Boolean(influencers[influencerUID].isSuspended);
        return !isSuspendedInfluencer;
      })
      .sort((a, b) => partnerships[a].createdAt - partnerships[b].createdAt);

    return applications.map(uid => partnerships[uid]);
  };

export const getProductMessageByInfluencer = (productUID, influencerUID) =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    const { messages } = state.entities;
    const { messageUIDs } = state.productContexts[productUID];

    const [message] = messageUIDs.filter(
      x => messages[x].users[influencerUID].type === 'influencer'
    );
    return message;
  };

/**
 *
 * @param {string} productUID
 */
export const getProductMessages = productUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {{read: string[], unRead: string[]}}
   */
  state => {
    const {
      brand: { uid },
    } = state.session;
    const { messages } = state.entities;
    const { messageUIDs } = state.productContexts[productUID];

    const unRead = [];
    const read = [];

    messageUIDs.forEach(x => {
      if (messages[x].users[uid].hasUnread) unRead.push(x);
      else read.push(x);
    });
    const sorter = (a, b) => messages[b].lastMessageDate - messages[a].lastMessageDate;
    unRead.sort(sorter);
    read.sort(sorter);

    return {
      read,
      unRead,
    };
  };

/**
 * Product Creator Contents Selector
 *
 * @param {string} productUID
 */
export const getProductCreatorContents = productUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    const { entities, productContexts } = state;

    const { creatorContentUIDs = [] } = productContexts[productUID] || {};

    /**
     * @type {{
     *  pending: string[],
     *  submitted: string[],
     *  rejected: string[],
     *  approved: string[]
     * }}
     */
    const creatorContents = {
      pending: [],
      submitted: [],
      rejected: [],
      approved: [],
    };

    creatorContentUIDs.forEach(uid => {
      let { status } = entities.creatorContents[uid];
      if (status === 'partialApproved' || status === 'revisionRequested') {
        status = 'submitted';
      }
      creatorContents[status].push(uid);
    });

    return creatorContents;
  };

export const getProductInfluencersCreatorContents = productUID =>
  /**
   * @param {import('stores/reducers').MainReducer} state
   */
  state => {
    const { entities, productContexts } = state;
    const { creatorContentUIDs } = productContexts[productUID];
    const { creatorContents } = entities;

    /**
     * @type {Record<string, import('types').CreatorContent[]>}
     */
    const influencerContents = {};
    creatorContentUIDs.forEach(uid => {
      const creatorContent = creatorContents[uid];
      const { influencerUID, type } = creatorContent;

      if (type !== CREATOR_CONTENT_TYPES.STORY) {
        influencerContents[influencerUID] = [...(influencerContents[influencerUID] || []), uid];
      }
    });

    return influencerContents;
  };

export const getProductPosts = productUID =>
  /**
   * @param {import('stores/reducers').MainReducer} state
   */
  state => {
    const { entities, productContexts } = state;
    const { postUIDs = [] } = productContexts[productUID] || {};

    /**
     * @type {{
     *  pending: string[],
     *  submitted: string[],
     *  rejected: string[],
     *  approved: string[]
     * }}
     */
    const posts = {
      pending: [],
      submitted: [],
      rejected: [],
      approved: [],
    };

    postUIDs.forEach(uid => {
      const { status } = entities.posts[uid];
      posts[status].push(uid);
    });

    return posts;
  };

export const getNumPendingSubmissions = productUID =>
  /**
   * @param {import('stores/reducers').MainReducer} state
   */
  state => {
    const { entities, productContexts } = state;
    const { creatorContentUIDs = [] } = productContexts[productUID] || {};

    const creatorsWithSubmissions = [];

    creatorContentUIDs.forEach(uid => {
      const contentCollection = entities.creatorContents[uid];
      const {
        influencerUID,
        contents = {},
        status: collectionStatus,
        category,
      } = contentCollection;
      if (
        collectionStatus === CREATOR_CONTENT_STATUS.REJECTED ||
        creatorsWithSubmissions.includes(influencerUID)
      ) {
        return;
      }
      const isActiveCollection = ACTIVE_SUBMISSION_STATUSES.includes(collectionStatus);
      const isRequiredCategory = category !== 'other';
      const hasPendingSubmission = Object.values(contents).some(
        ({ isNew, status }) =>
          (isNew && !isDownloadableContent(status)) ||
          (isRequiredCategory && isActiveCollection && isSubmittedContent(status))
      );
      if (hasPendingSubmission) {
        creatorsWithSubmissions.push(influencerUID);
      }
    });
    return creatorsWithSubmissions.length;
  };
