/* eslint import/prefer-default-export: 0 */

import { firebase } from '../lib/Firebase';

export const expiredCreditsListener = (filters = {}, lastDoc = null, limit = 10, callback) => {
  let query = firebase.firestore
    .collection('expiredCredits')
    .where('returned', '==', false)
    .orderBy('expiredAt', 'desc');

  if (filters.search) {
    query = query.where('brandUID', '==', filters.search);
  }

  if (lastDoc) {
    query = query.startAfter(lastDoc);
  }

  return query.limit(limit).onSnapshot(snap => {
    if (!snap.empty) {
      callback(snap.docs);
    }
  });
};
