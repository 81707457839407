import { isNewContentServiceUrl } from 'utils/content';
import { postAdminContentUrl } from 'api/admin';
import { forceDownload, retrieveFile } from 'lib/Download/resourceDownloader';

export const adminDownloadContent = async ({ contentId, collectionId }, contentCollection) => {
  const { contents } = contentCollection;
  const { highResolutionVideo, highResolutionImage } = contents[contentId];
  const contentUrl = highResolutionVideo || highResolutionImage;
  if (isNewContentServiceUrl(contentUrl)) {
    const { signedUrl, filename } = await postAdminContentUrl({ contentId, collectionId });
    return forceDownload(signedUrl, filename, true);
  }
  return retrieveFile(contentUrl, `${collectionId}-${contentId}`);
};
