import { ADD_REVIEWS } from 'stores/actions/entities/reviews';

/**
 *
 * @param {ReviewsReducer} state
 * @param {Record<string, import('types').Review>} products
 */
const addReviews = (state, products) => {
  const newState = { ...state };

  Object.keys(products).forEach(productUID => {
    newState[productUID] = products[productUID];
  });

  return newState;
};

/**
 * @typedef {Record<string, import('types').Review>} ReviewsReducer
 */
const initialState = {};

/**
 *
 * @param {ReviewsReducer} state
 * @param {import('stores/actions/entities/reviews').EntityReviewActions} action
 * @returns {ReviewsReducer}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REVIEWS: {
      return addReviews(state, action.payload);
    }

    default:
      return state;
  }
};

export default reducer;
