import React from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { useSession } from 'components/Session';

/**
 * Influencer Avatar Component
 *
 * @typedef {{
 * influencerUID: string,
 * showWorkBefore: boolean,
 * showName: boolean,
 * onClick: () => void
 * }} InfluencerAvatarProps
 * @type {React.FC<InfluencerAvatarProps>}
 */
const InfluencerAvatar = ({
  influencerUID,
  showWorkBefore,
  showName,
  onClick,
  isSocialCampaign,
}) => {
  const influencer = useSelector(selectors.getInfluencer(influencerUID));
  const { completedPartnershipInfluencerUIDs } = useSession();

  const influencerName = influencer ? influencer.name : 'unknown';
  return (
    <div className="influencer-avatar" role="presentation" onClick={onClick}>
      <div className="influencer-avatar-image">
        <Avatar src={influencer ? influencer.image : 'https://via.placeholder.com/150'} size={50} />
      </div>
      <div className="text-left influencer-info">
        <div className="influencer-info-username" style={{ paddingTop: showName ? '0px' : '8px' }}>
          {isSocialCampaign ? `@${influencer.username}` : influencerName}
        </div>
        {showName && <div className="influencer-info-name">{influencer.name}</div>}
        <div className="influencer-info-state">{influencer ? influencer.state : 'unknown'}</div>
      </div>
      {showWorkBefore && completedPartnershipInfluencerUIDs.includes(influencerUID) && (
        <div className="status-tag type-firme-iblack spacing-1px" style={{ color: '#000000' }}>
          <span>WORKED TOGETHER BEFORE</span>
        </div>
      )}
    </div>
  );
};

export default InfluencerAvatar;
