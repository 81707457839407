import React, { useEffect, useState } from 'react';
import { Table, Button, Spin } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Container from 'components/Common/Container';
import { firebase } from 'lib/Firebase';
import { adminApproveReferral } from 'lib/Firebase/callables/admin';
import { selectors } from 'stores';

const columns = [
  {
    key: 'companyName',
    dataIndex: 'companyName',
    title: 'Company Name',
  },
  {
    key: 'contactName',
    title: 'Contact Name',
    render: ({ contactName, contactEmail }) => contactName || contactEmail,
  },
  {
    key: 'submitedDate',
    title: 'Submitted Date',
    render: ({ submittedAt }) => moment(submittedAt * 1000).format('MMM DD, YYYY'),
  },
  {
    key: 'brand',
    title: 'Referred By',
    render: ({ brand }) => {
      if (!brand) return <Spin />;
      return brand.name || brand.lowercaseName || brand.companyName;
    },
  },
  {
    key: 'actions',
    name: 'actions',
    title: 'Action',
    render: ({ uid, brandUID, onAccept, onReject, loading, status }) => {
      if (status !== 'pending') {
        return status.toUpperCase();
      }
      return (
        <div style={{ minWidth: '193px' }}>
          <Spin spinning={loading}>
            <Button type="primary" onClick={() => onAccept(uid, brandUID)}>
              Accept
            </Button>

            <Button
              type="danger"
              onClick={() => onReject(uid, brandUID)}
              style={{ marginLeft: '10px' }}
            >
              Reject
            </Button>
          </Spin>
        </div>
      );
    },
  },
];

/**
 * Admin V2 Referrals Tab
 *
 * @type {React.FC}
 */
const ReferralsPage = () => {
  const initialPagination = { page: 1, limit: 20 };
  const [pagination, setPagination] = useState(initialPagination);
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState([]);
  const brands = useSelector(selectors.getBrands());
  const [lastDoc, setLastDoc] = useState({});

  const fetch = async (lastDocParam = null) => {
    let referralsSnap = firebase.firestore
      .collection('referrals')
      .orderBy('submittedAt', 'desc')
      .limit(20);

    if (lastDocParam) {
      referralsSnap = referralsSnap.startAfter(lastDocParam);
    }

    referralsSnap = await referralsSnap.get();

    if (!referralsSnap.empty) {
      const list = [];
      let data = null;
      referralsSnap.docs.forEach(reviewDoc => {
        const docData = reviewDoc.data();
        list.push({ uid: reviewDoc.id, ...docData });
        data = reviewDoc;
      });

      setReferrals(prevState => {
        return [...prevState, ...list];
      });
      setLastDoc(data);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  async function handleAccept(referralUID, brandUID) {
    setLoading(state => [...state, referralUID]);
    await adminApproveReferral(brandUID, referralUID);

    const [selectedReferral] = referrals.filter(x => x.uid === referralUID);
    const selectedIndex = referrals.indexOf(selectedReferral);
    referrals[selectedIndex].status = 'approved';
    setReferrals([...referrals]);

    setLoading(state => [...state.filter(x => x !== referralUID)]);
  }

  async function handleReject(referralUID) {
    setLoading(state => [...state, referralUID]);
    await firebase.firestore
      .collection('referrals')
      .doc(referralUID)
      .update({
        status: 'rejected',
        rejectedAt: moment().unix(),
      });

    const [selectedReferral] = referrals.filter(x => x.uid === referralUID);
    const selectedIndex = referrals.indexOf(selectedReferral);
    referrals[selectedIndex].status = 'rejected';
    setReferrals([...referrals]);
    setLoading(state => [...state.filter(x => x !== referralUID)]);
  }

  const dataSource = referrals.map(review => {
    return {
      key: review.uid,
      ...review,
      brand: brands[review.brandUID],
      loading: loading.includes(review.uid),
      onAccept: handleAccept,
      onReject: handleReject,
    };
  });

  function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });

    fetch(lastDoc);
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;
  return (
    <Container width={940} id="admin-business-approval">
      <Table dataSource={dataSource} columns={columns} pagination={false} showHeader />
      <div className="text-center loadmore">
        {dataSource.length > showLimit && (
          <Button className="load-more" onClick={loadMore}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ReferralsPage;
