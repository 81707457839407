import React from 'react';
import { Col, Row, Icon, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { selectors } from 'stores';

const statHealth = health => {
  if (health === 3) return 'great';
  if (health === 2) return 'in range';
  return 'poor';
};

const cardDateFormat = dateUnix => {
  if (dateUnix) return moment(dateUnix * 1000).format('MM/DD/YY');
  return 'N/A';
};

const productTypeLabels = {
  social: 'Social Campaign',
  video: 'Video Campaign',
  image: 'Photo Campaign',
};

const productTypeIcons = {
  social: 'instagram',
  video: 'video-camera',
  image: 'camera',
};

/**
 * Products Admin Card Component
 *
 * @typedef {{ productUID: string }} CardFullDetailsProps
 * @type {React.FC<CardFullDetailsProps & import('antd/lib/card').CardProps>}
 */
const AdminCard = ({ productUID, ...rest }) => {
  const product = useSelector(selectors.getProduct(productUID));
  const { brandUID } = product;
  const brand = useSelector(selectors.getBrand(brandUID));

  if (_.isEmpty(product)) {
    return null;
  }

  if (_.isEmpty(brand)) {
    return null;
  }

  const {
    applicantCount = 0,
    applicantHealth = 1,
    influencerCount = 0,
    influencerHealth = 1,
    postCount = 0,
    completedPostCount = 0,
    postOverdueCount = 0,
    postOverdueHealth = 1,
    shippedCount = 0,
    shippedHealth = 1,
    downloadCount = 0,
    downloadHealth = 1,
    lastLoginHealth = 1,
    health: campaignHealth,
    lastCreditUsed,
  } = product;

  const {
    credits = 0,
    standardCredits = 0,
    addedVipCredits = 0,
    addedStandardCredits = 0,
    lastPurchasedCredits = 0,
    lifetimePurchasedCredits = 0,
    name: brandName,
    authEmail: email,
    lastPurchaseDate,
    lastLoginDate,
    lastLoginHealth: brandLastLoginHealth,
  } = brand;
  const totalCredits = credits + standardCredits + addedStandardCredits + addedVipCredits;
  const startDate = moment.unix(product.createdAt).format('MM/DD/YY');

  return (
    <Col {...rest}>
      <div className={`campaign-card card-${productUID}`}>
        <div className="campaign-card-upper">
          <div className="campaign-card-name" title={product.name}>
            {product.name}
          </div>
          <div className="campaign-card-type">
            <Tooltip title={productTypeLabels[product.type]} placement="bottom">
              <Icon type={productTypeIcons[product.type]} />
            </Tooltip>
          </div>
          <Row>
            <Col span={10}>
              <div className="campaign-card-credits">Credits: {totalCredits}</div>
            </Col>
            <Col span={14}>
              <div className="brand-details-health">
                <span className={`health-icon ${campaignHealth}`} />
                Health: <span className="health-label type-bold">{campaignHealth}</span>
              </div>
            </Col>
          </Row>
          <Row className="brand-details">
            <Col span={24}>
              <div className="brand-details-purchase">
                Last Purchase Date:{' '}
                <span className="type-bold">{cardDateFormat(lastPurchaseDate)}</span>
              </div>
              <div className="brand-details-purchase">
                How Many Purchased: <span className="type-bold">{lastPurchasedCredits}</span>
              </div>
              <div>
                Last credit used:{' '}
                <span className="type-bold">{cardDateFormat(lastCreditUsed)}</span>
              </div>
              <div className="brand-details-purchase">
                Lifetime credits purchased:{' '}
                <span className="type-bold">{lifetimePurchasedCredits}</span>
              </div>
              <div className="brand-details-name" title={brandName}>
                {brandName}
              </div>
              <div className="brand-details-email">{email}</div>
            </Col>
          </Row>
        </div>
        <div className="campaign-card-lower">
          <div className="campaign-startdate">Campaign Start Date: {startDate}</div>
          <Row className="campaign-stats">
            <Col span={12} className="stat">
              Last Login: {cardDateFormat(lastLoginDate)}
              <div className={`stat-health ${statHealth(brandLastLoginHealth || lastLoginHealth)}`}>
                <span className="stat-icon" />
                {statHealth(brandLastLoginHealth || lastLoginHealth)}
              </div>
            </Col>
            <Col span={12} className="stat">
              Posts: {completedPostCount}/{postCount}
              <div className="stat-health">
                <span className="stat-icon" />
              </div>
            </Col>
            <Col span={12} className="stat">
              Applications: {applicantCount}
              <div className={`stat-health ${statHealth(applicantHealth)}`}>
                <span className="stat-icon" />
                {statHealth(applicantHealth)}
              </div>
            </Col>
            <Col span={12} className="stat">
              Post Overdue: {postOverdueCount}/{postCount}
              <div className={`stat-health ${statHealth(postOverdueHealth)}`}>
                <span className="stat-icon" />
                {statHealth(postOverdueHealth)}
              </div>
            </Col>
            <Col span={12} className="stat">
              Creators: {influencerCount}
              <div className={`stat-health ${statHealth(influencerHealth)}`}>
                <span className="stat-icon" />
                {statHealth(influencerHealth)}
              </div>
            </Col>
            <Col span={12} className="stat">
              Downloaded: {downloadCount}
              <div className={`stat-health ${statHealth(downloadHealth)}`}>
                <span className="stat-icon" />
                {statHealth(downloadHealth)}
              </div>
            </Col>
            <Col span={12} className="stat">
              Shipments: {shippedCount}/{influencerCount}
              <div className={`stat-health ${statHealth(shippedHealth)}`}>
                <span className="stat-icon" />
                {statHealth(shippedHealth)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Col>
  );
};

export default AdminCard;
