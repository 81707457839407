import { Row, Spin } from 'antd';
import MinLayout from 'components/Common/MinLayout';
import React from 'react';

/**
 * @type {React.FC}
 */
const ExampleList = () => {
  return ((
    <Row
      type="flex"
      justify="space-around"
      align="middle"
      style={{ height: '80vh', textAlign: 'center' }}
    >
      <MinLayout>
        <Spin />
      </MinLayout>
    </Row>
  ));
};
export default ExampleList;
