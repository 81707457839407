import React from 'react';
import mixpanel from 'lib/Analytics/Mixpanel';
import { useCheckSession } from 'components/Session';
import hasAccess from 'components/Helpers/brandHasAccess';
import { toNewCampaign } from 'constants/routes';

const ModalDetails = ({ avatar, creator }) => {
  const hasSession = useCheckSession();
  const show = hasAccess();

  function trackTo(e) {
    e.preventDefault();
    if (mixpanel) {
      mixpanel.track('Onboarding from Content Modal', { first: hasSession });
    }
    toNewCampaign();
  }

  return (
    <div id="creator-details">
      <span className="content-creator">
        <img className="creator-avatar" src={avatar} alt={creator} />
        <div>
          <p className="creator-name">{creator}</p>
          <p id="creator-text">
            Start a content project to collect applications from creators like {creator}
          </p>
        </div>
      </span>
      {show && (
        <button
          type="button"
          onClick={trackTo}
          style={{
            border: 'none',
            backgroundColor: '#FFB917',
            paddingLeft: '1.2rem',
            paddingRight: '1.2rem',
            fontWeight: '700',
            borderRadius: '4px',
          }}
        >
          Start Content Project
        </button>
      )}
    </div>
  );
};

export default ModalDetails;
