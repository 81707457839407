export const CREATOR_CATEGORIES = {
  beauty: 'Beauty',
  comedy: 'Comedy',
  designArt: 'Design / Art',
  diy: 'DIY',
  family: 'Family',
  fashion: 'Fashion',
  cooking: 'Food / Cooking',
  gaming: 'Gaming',
  healthFitness: 'Health & Fitness',
  learningEducation: 'Learning / Education',
  lifestyle: 'Lifestyle',
  musicDance: 'Music and Dance',
  photography: 'Photography',
  sports: 'Sports',
  tech: 'Tech',
  travelAdventure: 'Travel and Adventure',
  vlogging: 'Vlogging',
  wellness: 'Wellness',
};
