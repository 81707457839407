import Promise from 'bluebird';
import { PARTNERSHIP_STATUS } from 'constants/partnerships';
import { POST_DETAIL_STATUS } from 'constants/posts';
import { firebase } from 'lib/Firebase';
// eslint-disable-next-line import/no-cycle
import { actions } from 'stores';
import { writeCreatorContents } from './entities/creatorContents';
import { addInfluencers } from './entities/influencers';
import { writeMessages } from './entities/message';
import { addPartnerships } from './entities/partnerships';
import { addPosts } from './entities/posts';
import { addProduct } from './entities/products';

/**
 * @param {Record<string, import('types').Partnership>} partnerships
 * @param {import('redux').Dispatch} dispatch
 */
const addPartnershipsAndInfluencers = partnerships =>
  /**
   *
   * @param {import('redux').Dispatch} dispatch
   * @param {() => import('stores/reducers').MainReducer} getState
   */
  async (dispatch, getState) => {
    const {
      entities: { influencers },
    } = getState();
    const partnershipUIDs = Object.keys(partnerships);

    const newInfluencers = {};
    await Promise.map(
      partnershipUIDs,
      async partnershipUID => {
        const partnership = partnerships[partnershipUID];
        const { influencerUID } = partnership;
        if (!influencers[influencerUID]) {
          const influencerDoc = await firebase.firestore
            .collection('influencers')
            .doc(influencerUID)
            .get();

          newInfluencers[influencerUID] = influencerDoc.data();
        }
      },
      { concurrency: 3 }
    );

    dispatch(addInfluencers(newInfluencers));
    dispatch(addPartnerships(partnerships));
  };

export const SET_INITIAL_PRODUCT = 'SET_INITIAL_PRODUCT';
/**
 * Set Intial Product Context
 *
 * @typedef {{ type: SET_INITIAL_PRODUCT, payload: import("components/Products/Context").ProductContext}} SetInitialProductAction
 *
 * @param {import("components/Products/Context").ProductContext} product
 * @param {{
 *  product: import('types').Product,
 *  messages: Record<string, import('types').Message>,
 *  approvedPartnerships: Record<string, import('types').Partnership>,
 *  submittedPartnerships: Record<string, import('types').Partnership>,
 *  invitedPartnerships: Record<string, import('types').Partnership>,
 *  posts: Record<string, import('types').Post>,
 *  influencers: Record<string, import('types').Influencer>,
 *  creatorContents: Record<string, import('types').CreatorContent>
 * }} data
 */
export const setInitialProduct = (productUID, data) =>
  /**
   * @param {Dispatch} dispatch
   */

  dispatch => {
    const {
      product,
      messages,
      approvedPartnerships,
      submittedPartnerships,
      invitedPartnerships,
      posts,
      influencers,
      creatorContents,
    } = data;
    dispatch(addProduct(product));
    dispatch(addInfluencers(influencers));
    dispatch(
      addPartnerships({ ...approvedPartnerships, ...submittedPartnerships, ...invitedPartnerships })
    );
    dispatch(writeCreatorContents(creatorContents));
    dispatch(addPosts(posts));
    dispatch(writeMessages(messages));

    const invitedInfluencerUIDs = Object.keys(invitedPartnerships).map(
      partnershipUID => invitedPartnerships[partnershipUID].influencerUID
    );

    dispatch({
      type: SET_INITIAL_PRODUCT,
      payload: {
        productUID,
        approvedPartnershipUIDs: Object.keys(approvedPartnerships),
        submittedPartnershipUIDs: Object.keys(submittedPartnerships),
        invitedInfluencerUIDs,
        messageUIDs: Object.keys(messages),
        postUIDs: Object.keys(posts),
        creatorContentUIDs: Object.keys(creatorContents),
      },
    });
  };

export const SET_PARTNERSHIPS_LISTENER = 'SET_PARTNERSHIPS_LISTENER';

/**
 * Set Partnership Listeners
 *
 * @typedef {{ type: SET_PARTNERSHIPS_LISTENER, payload: string }} SetPartnershipListenerAction
 * @param {string} productUID
 */
export const setPartnershipListener = productUID => ({
  type: SET_PARTNERSHIPS_LISTENER,
  payload: productUID,
});

export const SET_PRODUCT_POSTS_LISTENER = 'SET_PRODUCT_POSTS_LISTENER';
/**
 * Set Product Posts Listener
 *
 * @param {string} productUID
 * @typedef {{ type: SET_PRODUCT_POSTS_LISTENER, payload: string }} SetProductPostsListenerAction
 */
export const setProductPostsListener = productUID => ({
  type: SET_PRODUCT_POSTS_LISTENER,
  payload: productUID,
});

export const SET_PRODUCT_MESSAGE_LISTENER = 'SET_PRODUCT_MESSAGE_LISTENER';

/**
 * Set Product Message Listener Action
 *
 * @typedef {{ type: SET_PRODUCT_MESSAGE_LISTENER, payload: string }} SetProductMessageListenerAction
 * @param {string} productUID
 */
export const setProductMessageListener = productUID => ({
  type: SET_PRODUCT_MESSAGE_LISTENER,
  payload: productUID,
});

export const ADD_PRODUCT_APPROVED_PARTNERSHIPS = 'ADD_PRODUCT_APPROVED_PARTNERSHIPS';

/**
 * Add Product Approved Partnerships Action
 *
 * @typedef {{ type: ADD_PRODUCT_APPROVED_PARTNERSHIPS, payload: { productUID: string, partnershipUIDs: string[] }}} AddProductApprovedPartnershipsAction
 * @param {string} productUID
 * @param {Record<string, import('types').Partnership>} partnerships
 */
export const addProductApprovedPartnerships = (productUID, partnerships) =>
  /**
   * @param {import('redux').Dispatch} dispatch
   * @param {() => import('stores/reducers').MainReducer} getState
   */
  async dispatch => {
    dispatch(addPartnershipsAndInfluencers(partnerships, dispatch));

    // TODO: Missing influencer details for recently approved partnerships
    // Keep track of influencers
    const influencerUIDs = new Set();

    // Filter out duplicate partnerships
    const filteredPartnerships = Object.keys(partnerships).filter(partnershipID => {
      const partnership = partnerships[partnershipID];
      const creatorId = partnership.creatorId || partnership.influencerUID;
      const influencerMatch = influencerUIDs.has(creatorId);

      if (!influencerMatch) {
        influencerUIDs.add(partnerships[partnershipID].influencerUID);
        return true;
      }
      return false;
    });
    return dispatch({
      type: ADD_PRODUCT_APPROVED_PARTNERSHIPS,
      payload: {
        productUID,
        partnershipUIDs: filteredPartnerships,
      },
    });
  };

export const ADD_PRODUCT_INVITED_PARTNERSHIPS = 'ADD_PRODUCT_INVITED_PARTNERSHIPS';

/**
 * Add Product Invited Partnership Action
 * @typedef {{ type: ADD_PRODUCT_INVITED_PARTNERSHIPS, payload: { productUID: string, invitedInfluencerUIDs: string[] }}} AddProductInvitedPartnershipAction
 * @param {string} productUID
 * @param {Record<string, import('types').Partnership>} partnerships
 */
export const addProductInvitedPartnerships = (productUID, partnerships) =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  async dispatch => {
    await dispatch(addPartnershipsAndInfluencers(partnerships));
    const invitedInfluencerUIDs = Object.keys(partnerships).map(
      partnershipUID => partnerships[partnershipUID].influencerUID
    );

    dispatch({
      type: ADD_PRODUCT_INVITED_PARTNERSHIPS,
      payload: {
        productUID,
        invitedInfluencerUIDs,
      },
    });
  };

export const REMOVE_PRODUCT_INVITED_PARTNERSHIPS = 'REMOVE_PRODUCT_INVITED_PARTNERSHIPS';
/**
 * Remove Product Invited Partnerships Action
 *
 * @param {string} productUID,
 * @param {Record<string, import('types').Partnership>} partnerships
 * @typedef {{ type: REMOVE_PRODUCT_INVITED_PARTNERSHIPS, payload: { productUID: string, partnershipUIDs: string[]}}} RemoveProductInvitedPartnershipsAction
 */

export const removeProductInvittedPartnerships = (productUID, partnerships) => ({
  type: REMOVE_PRODUCT_INVITED_PARTNERSHIPS,
  payload: {
    productUID,
    invitedInfluencerUIDs: Object.keys(partnerships).map(
      partnershipUID => partnerships[partnershipUID].influencerUID
    ),
  },
});

export const REMOVE_PRODUCT_APPROVED_PARTNERSHIPS = 'REMOVE_PRODUCT_APPROVED_PARTNERSHIPS';

/**
 * Remove Product Approved Partnerships Action
 *
 * @typedef {{type: REMOVE_PRODUCT_APPROVED_PARTNERSHIPS, payload: { productUID: string, partnershipUIDs: string[] }}} RemoveProductApprovedPartnershipsAction
 * @param {string} productUID
 * @param {string[]} partnershipUIDs
 */
export const removeProductApprovedPartnerships = (productUID, partnershipUIDs) => ({
  type: REMOVE_PRODUCT_APPROVED_PARTNERSHIPS,
  payload: {
    productUID,
    partnershipUIDs,
  },
});

export const ADD_PRODUCT_SUBMITTED_PARTNERSHIPS = 'ADD_PRODUCT_SUBMITTED_PARTNERSHIPS';

/**
 * Add Product Submitted Partnerships Action
 *
 * @typedef {{ type: ADD_PRODUCT_SUBMITTED_PARTNERSHIPS, payload: { productUID: string, partnershipUIDs: string[] }}} AddProductSubmittedPartnershipsAction
 * @param {string} productUID
 * @param {Record<string, import('types').Partnership>} partnerships
 */
export const addProductSubmittedPartnerships = (productUID, partnerships) =>
  /**
   * @param {import('redux').Dispatch}
   */
  async dispatch => {
    await dispatch(addPartnershipsAndInfluencers(partnerships, dispatch));

    dispatch({
      type: ADD_PRODUCT_SUBMITTED_PARTNERSHIPS,
      payload: {
        productUID,
        partnershipUIDs: Object.keys(partnerships),
      },
    });
  };

export const REMOVE_PRODUCT_SUBMITTED_PARTNERSHIPS = 'REMOVE_PRODUCT_SUBMITTED_PARTNERSHIPS';

/**
 * Remove Product Submitted Partnerships Action
 *
 * @param {string} productUID
 * @param {string[]} partnershipUIDs
 * @typedef {{ type: REMOVE_PRODUCT_SUBMITTED_PARTNERSHIPS, payload: { productUID: string, partnershipUIDs: string[] }}} RemoveProductSubmittedPartnershipsAction
 */
export const removeProductSubmittedPartnerships = (productUID, partnershipUIDs) => ({
  type: REMOVE_PRODUCT_SUBMITTED_PARTNERSHIPS,
  payload: {
    productUID,
    partnershipUIDs,
  },
});

export const REMOVE_PRODUCT_PARTNERSHIPS = 'REMOVE_PRODUCT_PARTNERSHIPS';
/**
 * Remove Product Partnerships Action
 *
 * @typedef {{ type: REMOVE_PRODUCT_PARTNERSHIPS, payload: { productUID: string, partnershipUIDs: string[] }}} RemoveProductPartnershipsAction
 * @param {string} productUID
 * @param {string[]} partnershipUIDs
 */
export const removeProductPartnerships = (productUID, partnershipUIDs) => ({
  type: REMOVE_PRODUCT_PARTNERSHIPS,
  payload: {
    productUID,
    partnershipUIDs,
  },
});

export const ADD_PRODUCT_PENDING_POSTS = 'ADD_PRODUCT_PENDING_POSTS';
/**
 * Add Product Post Action
 * @typedef {{
 * type: ADD_PRODUCT_PENDING_POSTS,
 * payload: {
 *  productUID: string,
 *  postUIDs: string[],
 * }}} AddProductPendingPostsAction
 * @param {string} productUID
 * @param {Record<string, import('types').Post>} posts

 */
export const addProductPendingPosts = (productUID, posts) =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  dispatch => {
    const postUIDs = Object.keys(posts);
    postUIDs.forEach(postUID => {
      const post = posts[postUID];

      if (post.status === POST_DETAIL_STATUS.rejected) {
        posts[postUID] = {
          ...post,
          standardResolutionImage: '',
          fullResImage: '',
          thumbnail: '',
          comments: 0,
          likes: 0,
        };
      }
    });

    dispatch(addPosts(posts));
    return dispatch({
      type: ADD_PRODUCT_PENDING_POSTS,
      payload: {
        productUID,
        postUIDs,
      },
    });
  };

export const ADD_PRODUCT_POSTS = 'ADD_PRODUCT_POSTS';
/**
 * Add Product Post Action
 *
 * @param {string} productUID
 * @param {Record<string, import('types').Post>} posts
 * @typedef {{
 * type: ADD_PRODUCT_POSTS,
 * payload: {
 *  productUID: string,
 *  postUIDs: string[],
 * }}} AddProductPostsAction
 */
export const addProductPosts = (productUID, posts) =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  dispatch => {
    const postUIDs = Object.keys(posts);

    dispatch(addPosts(posts));
    return dispatch({
      type: ADD_PRODUCT_POSTS,
      payload: {
        productUID,
        postUIDs,
      },
    });
  };

export const REMOVE_PRODUCT_POSTS = 'REMOVE_PRODUCT_POSTS';
/**
 * Remove Product Post Action
 *
 * @typedef {{
 * type: REMOVE_PRODUCT_POSTS,
 * payload: {
 * productUID: string,
 * postUIDS: string[]
 * }}} RemoveProductPostsAction
 * @param {string} productUID
 * @param {Record<string, import('types').Post>} posts
 */
export const removeProductPosts = (productUID, posts) => ({
  type: REMOVE_PRODUCT_POSTS,
  payload: {
    productUID,
    postUIDs: Object.keys(posts),
  },
});

export const REMOVE_PRODUCT_PENDING_POSTS = 'REMOVE_PRODUCT_PENDING_POSTS';
/**
 * Remove Product Post Action
 *
 * @param {string} productUID
 * @param {string[]} postUIDs
 * @typedef {{
 * type: REMOVE_PRODUCT_PENDING_POSTS,
 * payload: {
 *  productUID: string,
 *  postUIDs: string[],
 * }}} RemoveProductPendingPostsAction
 */
export const removeProductPendingPosts = (productUID, postUIDs) => ({
  type: REMOVE_PRODUCT_PENDING_POSTS,
  payload: {
    productUID,
    postUIDs,
  },
});

export const ADD_PRODUCT_APPROVED_POSTS = 'ADD_PRODUCT_APPROVED_POSTS';
/**
 * Add Product Approved Post Action
 *
 * @param {string} productUID
 * @param {Record<string, import('types').Post>} posts
 * @typedef {{
 * type: ADD_PRODUCT_APPROVED_POSTS,
 * payload: {
 *  productUID: string,
 *  postUIDs: string[],
 * }}} AddProductApprovedPostsAction
 */
export const addProductApprovedPosts = (productUID, posts) =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  dispatch => {
    dispatch(addPosts(posts));
    const postUIDs = Object.keys(posts);

    return dispatch({
      type: ADD_PRODUCT_APPROVED_POSTS,
      payload: {
        productUID,
        postUIDs,
      },
    });
  };

export const REMOVE_PRODUCT_APPROVED_POSTS = 'REMOVE_PRODUCT_APPROVED_POSTS';
/**
 * Add Product Approved Post Action
 *
 * @param {string} productUID
 * @param {string[]} postUIDs
 * @typedef {{
 * type: REMOVE_PRODUCT_APPROVED_POSTS,
 * payload: {
 *  productUID: string,
 *  postUIDs: string[],
 * }}} RemoveProductApprovedPostsAction
 */
export const removeProductApprovedPosts = (productUID, postUIDs) => ({
  type: REMOVE_PRODUCT_APPROVED_POSTS,
  payload: {
    productUID,
    postUIDs,
  },
});

export const ADD_PRODUCT_SUBMITTED_POSTS = 'ADD_PRODUCT_SUBMITTED_POSTS';
/**
 * Add Product Submitted Post Action
 *
 * @param {string} productUID
 * @param {Record<string, import('types').Post>} posts
 * @typedef {{
 * type: ADD_PRODUCT_SUBMITTED_POSTS,
 * payload: {
 *  productUID: string,
 *  postUIDs: string[],
 * }}} AddProductSubmittedPostsAction
 */
export const addProductSubmittedPosts = (productUID, posts) =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  dispatch => {
    dispatch(addPosts(posts));
    const postUIDs = Object.keys(posts);

    return dispatch({
      type: ADD_PRODUCT_SUBMITTED_POSTS,
      payload: {
        productUID,
        postUIDs,
      },
    });
  };

export const REMOVE_PRODUCT_SUBMITTED_POSTS = 'REMOVE_PRODUCT_SUBMITTED_POSTS';
/**
 * Add Product Submitted Post Action
 *
 * @typedef {{
 * type: REMOVE_PRODUCT_SUBMITTED_POSTS,
 * payload: {
 *  productUID: string,
 *  postUIDs: string[],
 * }}} RemoveProductSubmittedPostsAction
 * @param {string} productUID
 * @param {string[]} postUIDs
 */
export const removeProductSubmittedPosts = (productUID, postUIDs) => ({
  type: REMOVE_PRODUCT_SUBMITTED_POSTS,
  payload: {
    productUID,
    postUIDs,
  },
});

export const ADD_PRODUCT_MESSAGE = 'ADD_PRODUCT_MESSAGE';

/**
 * Update Prouct Message Action
 *
 * @param {string} productUID
 * @param {Record<string, import('types').Message>} messages
 * @typedef {{type: ADD_PRODUCT_MESSAGE, payload: { productUID: string, messageUIDs: string[] }}} AddProductMessageAction
 */
export const addProductMessage = (productUID, messages) =>
  /**
   * @param {import('react').Dispatch} dispatch
   * @param {() => import('stores/reducers').MainReducer} getState
   * @returns
   */
  async (dispatch, getState) => {
    const state = getState();
    const { partnerships } = state.entities;
    const { approvedPartnershipUIDs } = state.productContexts[productUID];
    const approvedInfluencerUIDs = approvedPartnershipUIDs.map(x => partnerships[x].influencerUID);

    const messageUIDs = Object.keys(messages);

    // Check if message is
    const approvedMessageUIDs = [];
    await Promise.map(messageUIDs, async messageUID => {
      const message = messages[messageUID];
      const [influencerUID] = Object.keys(message.users).filter(
        x => message.users[x].type === 'influencer'
      );

      if (!approvedInfluencerUIDs.includes(influencerUID)) {
        const partnershipDoc = await firebase.firestore
          .collection('influencersPartnerships')
          .where('influencerUID', '==', influencerUID)
          .where('productUID', '==', productUID)
          .where('status', 'not-in', [PARTNERSHIP_STATUS.REJECTED, PARTNERSHIP_STATUS.SUBMITTED])
          .get();

        // Partnership exists
        if (!partnershipDoc.empty) {
          const influencerDoc = await firebase.firestore
            .collection('influencers')
            .doc(influencerUID)
            .get();

          dispatch(
            actions.entities.influencers.addInfluencers({
              [influencerUID]: { uid: influencerUID, ...influencerDoc.data() },
            })
          );
          approvedMessageUIDs.push(messageUID);
        }
      } else {
        approvedMessageUIDs.push(messageUID);
      }
    });

    dispatch(actions.entities.messages.writeMessages(messages));

    dispatch({
      type: ADD_PRODUCT_MESSAGE,
      payload: {
        productUID,
        messageUIDs: approvedMessageUIDs,
      },
    });
  };

export const ADD_PRODUCT_CREATOR_CONTENTS = 'ADD_PRODUCT_CREATOR_CONTENT';

/**
 * Add Product Creator Content
 *
 * @typedef {{ type: ADD_PRODUCT_CREATOR_CONTENTS, payload: { productUID: string, creatorContentUIDs: string[] }}} AddProductCreatorContentsAction
 * @param {string} productUID
 * @param {Record<string, import('types').CreatorContent>} creatorContents
 */
export const addProductCreatorContents = (productUID, creatorContents) =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  dispatch => {
    dispatch(writeCreatorContents(creatorContents));

    dispatch({
      type: ADD_PRODUCT_CREATOR_CONTENTS,
      payload: {
        productUID,
        creatorContentUIDs: Object.keys(creatorContents),
      },
    });
  };

export const REMOVE_PRODUCT_CREATOR_CONTENTS = 'REMOVE_PRODUCT_CREATOR_CONTENTS';
/**
 * Remove Product Creator Contents
 *
 * @typedef {{ type: REMOVE_PRODUCT_CREATOR_CONTENTS, payload: { productUID: string, creatorContentUIDs: string[] }}} RemoveProductCreatorContentsAction
 * @param {string} productUID
 * @param {string[]} creatorContentUIDs
 */
export const removeProductCreatorContents = (productUID, creatorContentUIDs) => ({
  type: REMOVE_PRODUCT_CREATOR_CONTENTS,
  payload: {
    productUID,
    creatorContentUIDs,
  },
});

export const SET_PRODUCT_CREATOR_CONTENTS_LISTENER = 'SET_CREATOR_CONTENTS_LISTENERS';

/**
 * Set Product Creator content listeners
 *
 * @typedef {{ type: SET_PRODUCT_CREATOR_CONTENTS_LISTENER, payload: string }} SetProductCreatorContentsListener
 * @param {string} productUID
 */

export const setProductCreatorContentsListener = productUID => ({
  type: SET_PRODUCT_CREATOR_CONTENTS_LISTENER,
  payload: productUID,
});

/**
 * @typedef
 * {SetInitialProductAction |
 * SetPartnershipListenerAction |
 * RemoveProductPartnershipsAction |
 * AddProductApprovedPartnershipsAction |
 * RemoveProductApprovedPartnershipsAction |
 * RemoveProductSubmittedPartnershipsAction |
 * SetProductPostsListenerAction |
 * AddProductPendingPostsAction |
 * RemoveProductPendingPostsAction |
 * AddProductApprovedPostsAction |
 * RemoveProductApprovedPostsAction |
 * AddProductSubmittedPostsAction |
 * RemoveProductSubmittedPostsAction |
 * AddProductMessageAction |
 * AddProductSubmittedPartnershipsAction |
 * SetProductMessageListenerAction |
 * AddProductCreatorContentsAction |
 * SetProductCreatorContentsListener |
 * AddProductPostsAction |
 * RemoveProductPostsAction |
 * AddProductInvitedPartnershipAction |
 * RemoveProductInvitedPartnershipsAction |
 * RemoveProductCreatorContentsAction}
 * ProductActions
 */
