import React, { useEffect, useState } from 'react';
import { Row, Spin } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MinLayout from 'components/Common/MinLayout';
import { actions, selectors } from 'stores';
import * as ROUTES from '../../../constants/routes';
import ListPage from './list';
import EditPage from './edit';
import { firebase } from '../../../lib/Firebase';

const main = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const brandUIDs = useSelector(selectors.getAdminBrands());

  const fetchBrands = async () => {
    setLoading(true);

    const brandDocs = await firebase.firestore
      .collection('brands')
      .where('status', '==', 'approved')
      .get();
    const brands = {};

    brandDocs.forEach(doc => {
      const brand = doc.data();
      if (brand.name) {
        brands[doc.id] = doc.data();
      }
    });

    dispatch(actions.adminPages.setAdminBrands(brands));
    setLoading(false);
  };

  useEffect(() => {
    if (!brandUIDs) {
      fetchBrands();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <Row type="flex" justify="center">
      <MinLayout className="content">
        <Switch>
          <Route exact path={ROUTES.ADMIN_BRANDS_PATH} component={ListPage} />
          <Route exact path={ROUTES.ADMIN_BRAND_PATH} component={EditPage} />
        </Switch>
      </MinLayout>
    </Row>
  );
};

export default main;
