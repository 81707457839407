import { WRITE_CREATOR_CONTENTS } from 'stores/actions/entities/creatorContents';

/**
 * CreatorContents Reducer
 *
 * @typedef {Record<string, import("types").CreatorContent>} CreatorContentsReducer
 * @param {CreatorContentsReducer} state
 * @param {import("stores/actions/entities/creatorContents").CreatorContentActions} action
 * @returns {CreatorContentsReducer}
 */
const reducer = (state = {}, action) => {
  switch (action.type) {
    case WRITE_CREATOR_CONTENTS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
