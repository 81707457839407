import React from 'react';
import { Empty } from 'antd';
import NoPostsImg from 'images/DefaultEmpty/No_Posts.png';

const EmptyPosts = () => (
  <Empty
    className="empty-no-applications"
    style={{
      marginTop: '66px',
      marginBottom: '109px',
    }}
    image={NoPostsImg}
    description={
      <span className="empty-desc-header type-sfpro-bold mb-2 mt-6">
        This is your project content gallery
      </span>
    }
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        Once your project is underway you’ll be able able to view and download your
        <br />
        approved content, to use with and share with your teams. You have 100% usage
        <br />
        rights to repurpose content on your own websites, ads, and social accounts.
      </div>
    </div>
  </Empty>
);

export default EmptyPosts;
