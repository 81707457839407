/**
 * Selector for Product
 *
 * @param {string} productUID
 */
export const getProduct = productUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    return state.entities.products[productUID];
  };

/**
 *  Get Products Selector
 */
export const getProducts = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    return state.entities.products;
  };

/**
 * Selector for Influencers
 */
export const getInfluencers = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {Record<string, import("types").Influencer>}
   */
  state => state.entities.influencers;

/**
 * Selector for Influencer
 *
 * @param {string} influencerUID
 */
export const getInfluencer = influencerUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {import("types").Influencer}
   */
  state => {
    return state.entities.influencers[influencerUID];
  };

/**
 * Selector for Partnership
 *
 */
export const getPartnership = partnershipUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {import("types").Partnership}
   */
  state => {
    return state.entities.partnerships[partnershipUID];
  };

/**
 * Selector for Partnerships
 *
 */
export const getPartnerships = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {Record<string, import("types").Partnership>}
   */
  state => {
    return state.entities.partnerships;
  };

/**
 * Selector for Posts
 */
export const getPosts = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {Record<string, import("types").Post>}
   */
  state => {
    return state.entities.posts;
  };

/**
 * Selector for Post
 *
 * @param {string} postUID
 */
export const getPost = postUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {import("types").Post}
   */
  state => {
    return state.entities.posts[postUID];
  };

/**
 * Seletor for Influencer Reviews
 *
 * @param {string} influencerUID
 */
export const getInfluencerReviews = influencerUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    return state.entities.influencerReviews[influencerUID];
  };

/**
 * Reviews Selector
 */
export const getReviews = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {Record<string, import("types").Review>}
   */
  state => {
    return state.entities.reviews;
  };

/**
 * Messages Selector
 */
export const getMessages = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    return state.entities.messages;
  };

/**
 * Message Selector
 */
export const getMessage = messageUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    return state.entities.messages[messageUID];
  };

/**
 * Product Posts Statistics Selector
 */
export const getProductPostsStatistics = productUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    return state.entities.postsStatistics[productUID] || {};
  };

/**
 * Brands Selector
 *
 */
export const getBrands = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => state.entities.brands;

/**
 * Brand Selector
 *
 */
export const getBrand = brandUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => state.entities.brands[brandUID];

export const getInfluencerPartnerships = influencerUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => state.entities.influencersPartnerships[influencerUID];

/**
 * Purchase Histories Selector
 */
export const getPurchasedHistories = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => state.entities.purchasedHistories;

/**
 * Creator Contents Selector
 */
export const getCreatorContents = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => state.entities.creatorContents;

/**
 * Creator Content Selector
 * @param {string} uid
 */
export const getCreatorContent = uid =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => state.entities.creatorContents[uid];
