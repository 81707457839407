import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Input, Icon, Avatar } from 'antd';
import moment from 'moment';
import './AdminCampaignModal.less';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { PARTNERSHIP_STATUS } from 'constants/partnerships';

const statusLabel = {
  'in-range': 'In Time Range',
  overdue: 'OVERDUE',
  completed: 'COMPLETED',
};

const columns = [
  {
    title: 'Creator',
    key: 'username',
    className: 'influencer-details',
    dataIndex: 'username',
    render: (username, { image, status, key }) => {
      return (
        <Row align="middle" className={`influencer-data ${key}`}>
          <Avatar src={image} />
          <div className="wrapper">
            <div className="influencer-username">{username}</div>
            <div className={`post-state ${status}`}>{statusLabel[status]}</div>
          </div>
        </Row>
      );
    },
  },
  {
    title: 'Product Delivered',
    className: 'product-delivered',
    dataIndex: 'activeAt',
    key: 'activeAt',
    render: val => (val ? `${moment().diff(moment(val * 1000), 'days')} days ago` : '--'),
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    className: 'due-date',
    key: 'dueDate',
    render: val => (val ? moment(val).format('MM/DD/YY') : '--'),
  },
  {
    dataIndex: 'actions',
    className: 'actions',
    render: ({ onClick }, val) => {
      return (
        <Button type={val.selected ? 'primary' : 'default'} onClick={onClick}>
          {val.selected ? 'Added' : 'Removed'}
        </Button>
      );
    },
  },
];

/**
 *
 * @param {string} search
 * @param {Record<string, import('types').Influencer>} influencers
 * @returns
 */
const searchByUserame = (search = '') =>
  /**
   * @param {import('types').Partnership} data
   * @returns
   */
  data => {
    if (data.username && search) {
      return data.username.toLowerCase().includes(search.toLowerCase());
    }
    return false;
  };

const byActive = (a, b) => {
  if (a.activeAt === b.activeAt) {
    return 0;
  }

  if (a.activeAt === undefined) {
    return 1;
  }

  if (b.activeAt === undefined) {
    return -1;
  }

  return a.activeAt > b.activeAt ? 1 : -1;
};

/**
 * Product Admin Modal Selectable Influencer List Component
 *
 * @typedef {{ partnershipUIDs: string[], onSelectChange: () => any, value: string[] }} SelectableInfluencerListProps
 * @type {React.FC<SelectableInfluencerListProps>}
 */
const SelectableInfluencerList = ({ partnershipUIDs = [], onSelectChange, value = [] }) => {
  const partnerships = useSelector(selectors.getPartnerships());
  const influencers = useSelector(selectors.getInfluencers());
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  function handleClick(uid) {
    setSelected(prevState => {
      let newState = [...prevState];
      if (newState.includes(uid)) {
        newState = newState.filter(x => x !== uid);
      } else {
        newState = [...newState, uid];
      }
      onSelectChange(newState);
      return newState;
    });
  }

  function handleSearch(e) {
    setSearch(e.target.value);
  }

  const overdue = [];
  const completed = [];
  const pendings = [];
  partnershipUIDs.forEach(partnershipUID => {
    const partnership = partnerships[partnershipUID];
    const influencer = influencers[partnership.influencerUID];
    const data = {
      ...partnership,
      key: partnershipUID,
      username: influencer.username,
      selected: selected.includes(partnershipUID),
      actions: {
        onClick: () => handleClick(partnershipUID),
      },
    };
    if (partnership.status === PARTNERSHIP_STATUS.COMPLETED) {
      data.status = 'completed';
      completed.push(data);
    } else if (partnership.dueDate < partnership.postSubmittedAt) {
      data.status = 'overdue';
      overdue.push(data);
    } else {
      data.status = 'in-range';
      pendings.push(data);
    }
  });

  overdue.sort(byActive);
  completed.sort(byActive);
  pendings.sort(byActive);

  const s = [...overdue, ...pendings, ...completed].filter(searchByUserame(search));

  return (
    <>
      <div className="search">
        <Icon type="search" />
        <Input onChange={handleSearch} placeholder="Search" />
      </div>
      <Table className="influencer-table" dataSource={s} columns={columns} pagination={false} />
    </>
  );
};

export default SelectableInfluencerList;
