import moment from 'moment';
import { Button, Row, Col, Icon, Form, Modal, Checkbox, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { firebase } from 'lib/Firebase';
import { requestShipmentDetails } from 'api/shipment';
import ShippingHistory from 'components/CampaignDashboard/Influencers/ShippingHistory';
import { NonShippableConfirmationModal } from './Shipment/NonShippableConfirmationModal';

const { Item } = Form;
const { Option } = Select;

const carrierCodes = {
  usps: 'USPS',
  stamps_com: 'Stamps.com',
  ups: 'UPS',
  fedex: 'FedEx',
  dhl_express: 'DHL',
  // TODO: Not supported, not sure why it was offered. May want to support in the future.
  // canada_post: 'Canada Post',
};

const carrierOptions = () =>
  Object.entries(carrierCodes).map(([id, value]) => (
    <Option key={id} value={id}>
      {value}
    </Option>
  ));

/**
 * Product Shipping Form Component
 *
 * @typedef {{
 * show: boolean,
 * partnershipUID: string | null,
 * onClose: () => void,
 * onSubmit: (partnershipUID: string, params: { productShipmentUID: string, nonShippable: boolean, carrierCode: string, trackingNumber: string }) => Promise<void>
 * }} ShippingFormProps
 * @type {React.FC<ShippingFormProps & import('antd/lib/form').FormProps>}
 */
const ShippingForm = ({ show, partnershipUID, onClose, form, onSubmit }) => {
  const partnership = useSelector(selectors.getPartnership(partnershipUID));
  const influencers = useSelector(selectors.getInfluencers());
  const [showForm, setShowForm] = useState(true);
  const [shipment, setShipment] = useState({
    trackingNumber: null,
    carrierCode: null,
    nonShippable: false,
    productShipmentUID: null,
    details: {},
    fetching: true,
    submitting: false,
  });
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);

  const { getFieldDecorator, setFieldsValue } = form;

  async function handleSubmit() {
    const { validateFieldsAndScroll } = form;
    setShipment(s => ({ ...s, submitting: true }));
    if (shipment.nonShippable) {
      await onSubmit(partnershipUID, { nonShippable: true });
      setShipment(s => ({ ...s, submitting: false }));
    } else {
      await validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          await onSubmit(partnershipUID, {
            ...values,
            productShipmentUID: shipment.productShipmentUID,
            nonShippable: false,
          });
        }
        setShipment(s => ({ ...s, submitting: false }));
      });
    }
  }

  function handleNotShipping() {
    if (!shipment.nonShippable) {
      setDisplayConfirmationModal(true);
    }

    setShipment(state => ({
      ...state,
      nonShippable: !state.nonShippable,
    }));
  }

  function handleNonShippableConfirmation() {
    setDisplayConfirmationModal(false);
  }

  function handleNonShippableCancel() {
    setDisplayConfirmationModal(false);
    setShipment(state => ({ ...state, nonShippable: false }));
  }

  async function fetchData() {
    setShipment(s => ({ ...s, fetching: true }));

    const shipmentDocs = await firebase.firestore
      .collection('productShipments')
      .where('influencerUID', '==', partnership.influencerUID)
      .where('productUID', '==', partnership.productUID)
      .get();

    const newShipment = {
      carrierCode: null,
      trackingNumber: null,
      details: {},
      nonShippable: partnership.status !== 'needsShipping',
    };

    if (!shipmentDocs.empty) {
      const [shipmentDoc] = shipmentDocs.docs;
      const { carrierCode, trackingNumber } = shipmentDoc.data();
      newShipment.carrierCode = carrierCode;
      newShipment.trackingNumber = trackingNumber;
      newShipment.nonShippable = false;
      newShipment.productShipmentUID = shipmentDoc.id;
      newShipment.details = await requestShipmentDetails(carrierCode, trackingNumber);
      setFieldsValue({ carrierCode, trackingNumber });
    } else {
      setFieldsValue({ carrierCode: null, trackingNumber: null });
    }

    setShipment({
      ...newShipment,
      fetching: false,
    });
  }

  useEffect(() => {
    if (partnershipUID) {
      fetchData();
    }
  }, [partnershipUID]);

  const { nonShippable, fetching, submitting } = shipment;

  const renderDetails = () => {
    const { details } = shipment;
    const { address, secondAddress, city, state, zip } =
      influencers[partnership && partnership.influencerUID] || {};

    if (details.actualDeliveryDate) {
      firebase.updateProductActiveByDeliveryDate({
        influencerUID: partnership.influencerUID,
        productUID: partnership.productUID,
        deliveryDate: details.actualDeliveryDate,
      });
    }

    const shipDate = details.shipDate || 'N/A';
    const deliveryDate = details.actualDeliveryDate || details.estimatedDeliveryDate || 'N/A';
    return (
      <div style={{ display: !showForm && !fetching ? 'block' : 'none' }}>
        <div style={{ textAlign: 'center' }}>
          {`${address} ${secondAddress}, ${city}`}
          <br />
          {`${state}, ${zip}`}
        </div>

        <div>
          <Row style={{ marginTop: '20px' }}>
            <Col span={12} style={{ textAlign: 'center' }}>
              <strong>Date Shipped</strong>
              <br />
              <span>
                {shipDate !== 'N/A' ? moment(shipDate).format('ddd, MMM D YYYY') : shipDate}
              </span>
            </Col>
            <Col span={12} style={{ textAlign: 'center' }}>
              <strong>{details.actualDeliveryDate ? 'Delivered On' : 'Expected Delivery'}</strong>
              <br />
              <span>
                {deliveryDate !== 'N/A'
                  ? moment(deliveryDate).format('ddd, MMM D YYYY')
                  : deliveryDate}
              </span>
            </Col>
          </Row>
          <ShippingHistory events={details.events} />
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Form style={{ display: showForm && !fetching ? 'block' : 'none' }}>
        <Item
          style={{
            background: '#ddd',
            paddingLeft: '20px',
            borderRadius: '3px',
          }}
        >
          {getFieldDecorator('nonShippable')(
            <Checkbox checked={nonShippable} onChange={handleNotShipping}>
              Product does not need shipping (promo/coupon code).
            </Checkbox>
          )}
        </Item>
        <p>
          If you are shipping this product, add the tracking information below for best results.
          This will let creators know when to expect their package and will help them plan their
          post date.
        </p>
        <div style={{ display: nonShippable ? 'none' : 'block' }}>
          <Item label="Carrier Code">
            {getFieldDecorator('carrierCode', {
              rules: [
                {
                  required: !nonShippable,
                  message: 'Please input Shipping Carrier',
                },
              ],
            })(
              <Select disabled={nonShippable} placeholder="Shipping Carrier">
                {carrierOptions()}
              </Select>
            )}
          </Item>
          <Item label="Tracking Number">
            {getFieldDecorator('trackingNumber', {
              rules: [
                {
                  required: !nonShippable,
                  message: 'Please input Tracking Number',
                },
              ],
            })(<Input disabled={nonShippable} placeholder="0000000" />)}
          </Item>
        </div>
      </Form>
    );
  };

  return (
    <>
      <Modal
        title="Shipping Details"
        visible={show && !displayConfirmationModal}
        onCancel={onClose}
        footer={[
          showForm ? (
            <Button key="submit" type="primary" loading={submitting} onClick={handleSubmit}>
              Update
            </Button>
          ) : (
            <Button key="close" onClick={onClose}>
              Close
            </Button>
          ),
        ]}
      >
        <div style={{ textAlign: 'center' }}>
          <Button style={{ marginBottom: '20px' }} onClick={() => setShowForm(s => !s)}>
            <Icon type={showForm ? 'solution' : 'setting'} />
            {showForm ? 'Show Tracking Details' : 'Edit Tracking Number'}
          </Button>
        </div>
        {fetching && (
          <div style={{ textAlign: 'center', fontSize: '16pt' }}>
            <Icon type="loading" />
          </div>
        )}
        {renderDetails()}
        {renderForm()}
      </Modal>

      <NonShippableConfirmationModal
        visible={shipment.nonShippable && displayConfirmationModal}
        handleNonShippableConfirmation={handleNonShippableConfirmation}
        handleNonShippableCancel={handleNonShippableCancel}
      />
    </>
  );
};

export default Form.create({ name: 'shipping-form' })(ShippingForm);
