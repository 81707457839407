import {
  ADD_PRODUCT,
  ADD_PRODUCTS,
  UPDATE_PRODUCTS,
  CLEAR_PRODUCTS,
} from 'stores/actions/entities/products';

/**
 * @typedef {Record<string, import('types').Product>} ProductsReducer
 */
const initialState = {};

/**
 *
 * @param {ProductsReducer} state
 * @param {import('stores/actions/entities/products').EntityProductActions} action
 * @returns
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT: {
      return {
        ...state,
        [action.payload.productUID]: action.payload.product,
      };
    }

    case UPDATE_PRODUCTS:
    case ADD_PRODUCTS: {
      return { ...state, ...action.payload };
    }

    case CLEAR_PRODUCTS: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
