import React, { useEffect, useState } from 'react';
import { Button, Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import './ApprovalCard.less';
import ContentPreviewModal from 'components/Common/ContentPreviewModal';
import { getCountdown } from 'utils/dateUtils';

/**
 * Creator Content Approval Card Component
 *
 * @typedef {{
 * 	creatorId: string,
 *  previewContent: string,
 *  numNewContent: number,
 *  onClick: () => any,
 * 	approvalAt: number,
 *  status: 'pending' | 'completed'
 * }} ApprovalCardProps
 * @type {React.FC<ApprovalCardProps>}
 */
const ApprovalCard = ({
  creatorId,
  previewContent,
  numNewContent,
  onClick,
  approvalAt = 0,
  status,
}) => {
  const { name, image: profilePicture, fullName, firstName, lastName } = useSelector(
    selectors.getInfluencer(creatorId)
  );

  const [approvalCountdown, setApprovalCountdown] = useState('');
  useEffect(() => {
    const interval = setInterval(() => {
      if (approvalAt > 0 && status !== 'completed') {
        setApprovalCountdown(getCountdown(approvalAt));
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [approvalAt]);

  const { standardResolutionImage: thumbnailUrl, type: contentType } = previewContent;
  const contentUrl =
    contentType === 'image'
      ? previewContent.highResolutionImage
      : previewContent.highResolutionVideo;

  return (
    <div className="creator-content-card">
      <div role="button" tabIndex={-1} className="video-image-container">
        {/* Is this correct for Video campaigns */}
        <ContentPreviewModal
          contentType={contentType}
          url={contentUrl}
          thumbnailUrl={thumbnailUrl}
          className="video-image"
          visible={false}
          setVisible={onClick}
        />
        {numNewContent > 0 && (
          <div className="info-container">
            <span className="info-title">{numNewContent} NEW</span>
          </div>
        )}
        <Button onClick={onClick} className="btn-group">
          <span className="btn-title">Review</span>
          <img src="/images/backarrow.svg" alt="arrow" className="icon-style" />
        </Button>
      </div>
      <div className="card-other-images" role="button" onClick={onClick} tabIndex={-1} />
      <div className="card-details">
        <Avatar src={profilePicture} size={35} />
        <div className="card-username">
          <span>{name || fullName || `${firstName} ${lastName}`}</span>
          {approvalCountdown !== '' && (
            <span className="approvals-countdown">{approvalCountdown}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovalCard;
