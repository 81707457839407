export const CREATOR_CONTENT_STATUS = {
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  PARTIAL_APPROVED: 'partialApproved',
  REJECTED: 'rejected',
  REVISION_REQUESTED: 'revisionRequested',
};

export const ACTIVE_SUBMISSION_STATUSES = [
  CREATOR_CONTENT_STATUS.SUBMITTED,
  CREATOR_CONTENT_STATUS.PARTIAL_APPROVED,
  CREATOR_CONTENT_STATUS.REVISION_REQUESTED,
];

export const CREATOR_CONTENT_CONTENTS_STATUS = {
  SUBMITED: 'submited', // Catches typo bug
  SUBMITTED: 'submitted',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  FREE: 'free',
};

export const isSubmittedContent = contentStatus =>
  contentStatus === CREATOR_CONTENT_CONTENTS_STATUS.SUBMITTED ||
  contentStatus === CREATOR_CONTENT_CONTENTS_STATUS.SUBMITED;

export const isDownloadableContent = contentStatus =>
  contentStatus === CREATOR_CONTENT_CONTENTS_STATUS.FREE ||
  contentStatus === CREATOR_CONTENT_CONTENTS_STATUS.APPROVED;

export const CREATOR_CONTENT_CONTENTS_TYPE = {
  VIDEO: 'video',
  IMAGE: 'image',
};

export const CREATOR_CONTENT_TYPES = {
  VIDEO: 'video',
  IMAGE: 'image',
  STORY: 'story',
};
