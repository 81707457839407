import { WRITE_MESSAGES } from 'stores/actions/entities/message';

/**
 * @typedef {Record<string, import("types").Message>} MessageReducer
 * @type {MessageReducer}
 */
const initialState = {};
/**
 *
 * @param {MessageReducer} state
 * @param {import("stores/actions/entities/message").MessageActions} action
 * @returns
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WRITE_MESSAGES: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
