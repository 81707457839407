import React, { useState, useEffect } from 'react';
import Promise from 'bluebird';
import { firebase, useFirebase } from 'lib/Firebase';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import * as influencerActions from 'lib/Firebase/callables/influencers';
import setUserData from 'lib/Analytics/userData';
import AcceptedModal from 'components/RehireInfluencer/AcceptedModal';
import { APPROVE_PARTNERSHIP_STATUSES, PARTNERSHIP_STATUS } from 'constants/partnerships';
import { brandViewApproveInvites } from 'lib/Firebase/callables/brands';
import {
  setAccessToken,
  setRefreshToken,
  getCustomToken,
  clearAuthCookies,
  setAdminCookies,
} from 'utils/cookies';
import { toAuth } from 'constants/routes';
import { handleLoginAfterApproval } from 'lib/Analytics/gtag';
import {
  useBrandNotificationListener,
  useBrandActiveCampaignListener,
  useBrandCompletedCampaignListener,
  useBrandListener,
} from './listeners';
import Context from './Context';

/** Get Campaigns */
const getCampaigns = async (firebase, brandId) => {
  // NOTE: Move this function to API
  const productDocs = await firebase.firestore
    .collection('products')
    .where('brandUID', '==', brandId)
    .orderBy('createdAt', 'desc')
    .get();

  const activeProducts = {};
  const completedProducts = {};
  productDocs.forEach(doc => {
    /**
     * @type {import('types').Product}
     */
    const product = doc.data();
    if (product.status === 'active') activeProducts[doc.id] = product;
    if (product.status === 'completed') completedProducts[doc.id] = product;
  });
  return { activeProducts, completedProducts };
};

/**
 * Get Approved Partnerships
 *
 * @param {import('redux').Dispatch} dispatch
 * @param {string} brandUID
 */
const getApprovedPartnerships = async (dispatch, brandUID) => {
  // NOTE: Move this function to API
  const partnershipDocs = await firebase.firestore
    .collection('influencersPartnerships')
    .where('brandUID', '==', brandUID)
    .where('status', 'in', APPROVE_PARTNERSHIP_STATUSES)
    .get();

  const influencers = {};
  const completedPartnerships = {};
  const acceptedOfferPartnerships = {};
  await Promise.map(partnershipDocs.docs, async doc => {
    /**
     * @type {import('types').Partnership}
     */
    const partnership = doc.data();

    // Completed partnerships
    if (partnership.status === PARTNERSHIP_STATUS.COMPLETED) {
      completedPartnerships[doc.id] = partnership;
    }

    // Recently Accepted an invitation offer
    if (
      [PARTNERSHIP_STATUS.NEEDS_SHIPPING, PARTNERSHIP_STATUS.ACTIVE].includes(partnership.status) &&
      partnership.usedRehireCreatorModal
    ) {
      acceptedOfferPartnerships[doc.id] = doc.data();

      // Fetch influencer that haven't been fetch
      if (!influencers[partnership.influencerUID]) {
        influencers[partnership.influencerUID] = (await firebase.firestore
          .collection('influencers')
          .doc(partnership.influencerUID)
          .get()).data();
      }
    }
  });

  dispatch(actions.entities.influencers.addInfluencers(influencers));
  dispatch(
    actions.entities.partnerships.addPartnerships({
      ...completedPartnerships,
      ...acceptedOfferPartnerships,
    })
  );

  return { completedPartnerships, acceptedOfferPartnerships };
};

const Provider = ({ children }) => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const session = useSelector(selectors.getSession());
  const [acceptedInviteModal, setAcceptedInviteModal] = useState({
    partnershipUIDs: [],
    show: false,
  });
  const [loaded, setLoaded] = useState(false);
  const [intervalId, setIntervalId] = useState();
  // Careful, since this value is updated in the useEffect below, it may accidentally be used before it is updated.
  const brandUID = session && session.brand.uid;
  useBrandListener(brandUID);
  useBrandActiveCampaignListener(brandUID);
  useBrandCompletedCampaignListener(brandUID);
  useBrandNotificationListener(brandUID);

  const refreshAccessToken = async () => {
    firebase.auth.currentUser.getIdToken(true).then(token => {
      setAccessToken(token);
      setRefreshToken(firebase.auth.currentUser.refreshToken);
    });
  };

  useEffect(() => {
    const customToken = getCustomToken();
    if (!firebase.auth.currentUser && typeof customToken === 'string') {
      firebase.auth.signInWithCustomToken(customToken);
    }
    firebase.auth.onAuthStateChanged(async auth => {
      if (process.env.REACT_APP_ENV !== 'production') {
        window.infActions = influencerActions;
      }
      if (auth) {
        const { uid: brandId } = auth;
        if (!intervalId) {
          refreshAccessToken();
          const refreshIntervalId = setInterval(refreshAccessToken, 1000 * 60 * 4);
          setIntervalId(refreshIntervalId);
        }
        // Move this logic to the API
        const brandDoc = await firebase.firestore
          .collection('brands')
          .doc(brandId)
          .get();
        const brand = brandDoc.data();

        if (brand.status === 'approved' && !brand.hasLoggedInAfterApproval) {
          handleLoginAfterApproval(brand, auth.metadata);
        }

        const { activeProducts, completedProducts } = await getCampaigns(firebase, brandId);

        const notificationDoc = await firebase.firestore
          .collection('notifications')
          .doc(brandId)
          .get();

        const notification = notificationDoc.data();
        const { completedPartnerships, acceptedOfferPartnerships } = await getApprovedPartnerships(
          dispatch,
          brandId
        );

        const acceptedInviteUIDs = Object.keys(acceptedOfferPartnerships);

        if (acceptedInviteUIDs.length > 0) {
          setAcceptedInviteModal({
            partnershipUIDs: acceptedInviteUIDs,
            show: true,
          });
          try {
            await brandViewApproveInvites();
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }

        dispatch(
          actions.session.initializeSession({
            brand: { uid: brandId, ...brand },
            activeProducts,
            completedProducts,
            notification,
            completedPartnerships,
          })
        );

        if (brand) {
          setAdminCookies(brand);
        }

        if (process.env.REACT_APP_ENV === 'production' && window.dataLayer) {
          setUserData({
            email: auth.email,
            ownerName: typeof brand === 'object' ? brand.fullName : '',
          });
        }

        setLoaded(true);
      } else if (!getCustomToken()) {
        if (intervalId) {
          clearInterval(intervalId);
        }
        dispatch(actions.session.removeSession());
        dispatch(actions.entities.products.clearProducts());
        clearAuthCookies();
        toAuth();
      }
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    });
  }, []);

  async function handleModalClose() {
    setAcceptedInviteModal({ partnershipUIDs: [], show: false });
  }

  if (!loaded) {
    return <div>LOADING</div>;
  }
  if (loaded && !firebase.auth.currentUser) {
    return <></>;
  }

  return (
    <Context.Provider value={session}>
      {children}
      <AcceptedModal
        partnershipUIDs={acceptedInviteModal.partnershipUIDs}
        show={acceptedInviteModal.show}
        onClose={handleModalClose}
      />
    </Context.Provider>
  );
};

export default Provider;
