import { notification, Row, Col, Icon } from 'antd';
import { useProductData } from 'components/Products';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { ReactComponent as IconAlert } from 'images/alert.svg';
import { brandCreateShipment, brandUpdateShipment } from 'lib/Firebase/callables/brands';
import { CSVLink } from 'react-csv';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import Container from 'components/Common/Container';
import useRehire from 'components/RehireInfluencer/useRehire';
import CreatorProfileDrawer, {
  PrefetchCreatorProfilePages,
} from 'components/Influencers/Drawer/CreatorProfileDrawer';
import { toCampaignMessagePath } from 'constants/routes';
import ShipmentTable from 'components/Products/ShipmentTable';
import ShippingForm from 'components/Products/ShippingForm';

/**
 * @typedef {{}} ShipmentsProps
 * @type {React.FC<ShipmentsProps>}
 */
const Shipments = () => {
  const history = useHistory();
  const [influencerDrawer, setInfluencerDrawer] = useState({
    influencerUID: null,
    show: false,
  });
  const [shippingForm, setShippingForm] = useState({
    partnershipUID: null,
    show: false,
  });

  const {
    approvedPartnershipUIDs,
    invitedInfluencerUIDs,
    messageUIDs,
    productUID,
  } = useProductData();
  const messages = useSelector(selectors.getMessages());
  const partnerships = useSelector(selectors.getPartnerships());
  const influencers = useSelector(selectors.getInfluencers());
  const rehire = useRehire();

  function handleShowShippingDetails(partnershipUID) {
    setShippingForm({
      partnershipUID,
      show: true,
    });
  }

  function handleViewMessage(influencerUID) {
    const [messageUID] = messageUIDs.filter(x => messages[x].users[influencerUID]);

    history.push(toCampaignMessagePath(productUID, messageUID));
  }

  /**
   * @param {string} partnershipUID
   * @param {{
   * productShipmentUID?: string,
   * nonShippable: boolean,
   * carrierCode: string,
   * trackingNumber: string
   * }} params
   */
  async function handleShipmentSubmit(partnershipUID, params) {
    try {
      if (!params.productShipmentUID) {
        await brandCreateShipment(partnershipUID, params);
        notification.success({
          message: 'Shipment',
          description: 'Shipment successfully created!',
          duration: 2,
        });
      } else {
        await brandUpdateShipment(partnershipUID, params);
        notification.success({
          message: 'Shipment',
          description: 'Shipment successfully updated!',
          duration: 2,
        });
      }

      setShippingForm(s => ({ ...s, show: false }));
    } catch (e) {
      notification.error({
        message: 'Error',
        description: String(e.message) || 'Something went wrong on accepting influencer',
        duration: 4,
      });
    }
  }

  const shipped = [];
  const needsShipping = [];

  let csv = [['Full Name', 'Address']];
  approvedPartnershipUIDs.forEach(partnershipUID => {
    if (partnerships[partnershipUID].status === 'needsShipping') {
      needsShipping.push(partnershipUID);

      const { name, address, secondAddress, city, state, zip } = influencers[
        partnerships[partnershipUID].influencerUID
      ];
      csv = [...csv, [name, `${address} ${secondAddress}, ${city}, ${state} ${zip}`]];
    } else {
      shipped.push(partnershipUID);
    }
  });

  const hasNoPartnership = approvedPartnershipUIDs.length === 0;

  function handleShowInvitation(influencerUID) {
    if (!invitedInfluencerUIDs.includes(influencerUID)) {
      rehire.showInvitationModal(influencerUID);
    }
  }

  const handleCloseProfileDrawer = () => setInfluencerDrawer(state => ({ ...state, show: false }));
  const rehireRequested = invitedInfluencerUIDs.includes(influencerDrawer.influencerUID);

  return (
    <div id="shipments-content">
      {hasNoPartnership && <div>hey empty</div>}
      {needsShipping.length > 0 && (
        <div className="shipment-list needsShipping">
          <div className="shipment-list-header">
            <Container width={742}>
              <Row type="flex" align="top">
                <Col span={18}>
                  <div className="shipment-list-title">
                    <IconAlert style={{ marginRight: '9px' }} />
                    {needsShipping.length}/{approvedPartnershipUIDs.length} Creators need products
                    shipped to create content
                  </div>
                  <p className="shipment-list-description">
                    Adding tracking codes allow us to assign due dates to the creators because they
                    tell us when the packages get delivered. If you don’t have a physical product,
                    select “Product does not need shipping” and click the “Mark as Shipped” button.
                  </p>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <CSVLink
                    data={csv}
                    filename="NeedShipping.csv"
                    className="ant-btn trend-btn secondary shipment-list-download"
                  >
                    <Icon component={IconDownload} />
                    <span>
                      Download
                      <br />
                      Shipping CSV
                    </span>
                  </CSVLink>
                </Col>
              </Row>
            </Container>
          </div>

          <Container width={742}>
            <ShipmentTable
              partnershipUIDs={needsShipping}
              onClickShippingDetails={handleShowShippingDetails}
              onClickViewProfile={influencerUID =>
                setInfluencerDrawer(s => ({ ...s, influencerUID, show: true }))
              }
            />
          </Container>
        </div>
      )}
      {shipped.length > 0 && (
        <div className="shipment-list shipped">
          <div className="shipment-list-header">
            <Container width={742}>
              <Row type="flex" align="top">
                <Col span={18}>
                  <div className="shipment-list-title">Shipped Products</div>
                  <p className="shipment-list-description">
                    These creators have yet to post. If you are looking for a status on when they
                    will post, feel free to message them at any point. You can expect content
                    between 7-14 days after the product has been marked, “Delivered”.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <Container width={742}>
            <ShipmentTable
              partnershipUIDs={shipped}
              onClickShippingDetails={handleShowShippingDetails}
              onClickViewProfile={influencerUID =>
                setInfluencerDrawer(s => ({ ...s, influencerUID, show: true }))
              }
            />
          </Container>
        </div>
      )}
      <ShippingForm
        {...shippingForm}
        onSubmit={handleShipmentSubmit}
        onClose={() => setShippingForm(state => ({ ...state, show: false }))}
      />
      <PrefetchCreatorProfilePages influencerUIDs={invitedInfluencerUIDs} />
      {influencerDrawer.show && (
        <CreatorProfileDrawer
          {...influencerDrawer}
          drawerType={rehireRequested ? 'message' : 'rehire'}
          handleRehire={handleShowInvitation}
          handleMessageCreator={handleViewMessage}
          handleClose={handleCloseProfileDrawer}
        />
      )}
    </div>
  );
};

export default Shipments;
