import Promise from 'bluebird';
import { message as messageNotif } from 'antd';
import Message from 'components/Messages';
import { useProductData } from 'components/Products';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { brandSendMessage } from 'lib/Firebase/callables/brands';
import { useHistory, useRouteMatch } from 'react-router-dom';
import EmptyMessages from 'components/Common/EmptyMessages';
import Container from 'components/Common/Container';

/**
 * @typedef {{}} MessagesProps
 * @type {React.FC<MessagesProps>}
 */
const Messages = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { productUID } = useProductData();
  const influencers = useSelector(selectors.getInfluencers());
  const messages = useSelector(selectors.getMessages());
  const { read, unRead } = useSelector(selectors.getProductMessages(productUID));
  const [groupMessage, setGroupMessage] = useState({
    messageUIDs: [],
    selecting: false,
  });

  function handleViewMessage(messageUID) {
    history.push(`${url}/${messageUID}`);
  }

  function handleSelect(val) {
    setGroupMessage(s => ({ ...s, messageUIDs: val }));
  }

  async function handleGroupMessageSubmit(text) {
    const { messageUIDs: conversationUIDs } = groupMessage;

    try {
      await Promise.map(
        conversationUIDs,
        async conversationUID => {
          const { influencerUID } = messages[conversationUID];
          const influencer = influencers[influencerUID];

          const newText = text
            .replace('[username]', `@${influencer.username}`)
            .replace('[name]', influencer.name);

          await brandSendMessage({
            conversationUID,
            creatorId: influencerUID,
            text: newText,
            type: 'text',
          });
          setGroupMessage(s => ({ ...s, selecting: false }));
        },
        { concurrency: 3 }
      );
      messageNotif.success('Messages successfully sent!', 3);
    } catch (e) {
      messageNotif.error(e.message, 3);
    }
  }

  const allMessage = [...unRead, ...read];
  if (allMessage.length === 0) {
    return (
      <div className="message-container">
        <EmptyMessages />
      </div>
    );
  }

  return (
    <div className="message-container">
      <div className="trend-table-actions">
        <Container width={742}>
          <Message.GroupMessage
            {...groupMessage}
            toggleSelection={() => setGroupMessage(s => ({ ...s, selecting: !s.selecting }))}
            onSubmit={handleGroupMessageSubmit}
          />
        </Container>
      </div>

      <Container width={742}>
        <Message.List
          messageUIDs={allMessage}
          onViewMessage={handleViewMessage}
          showCheckbox={groupMessage.selecting}
          onRowSelect={handleSelect}
        />
      </Container>
    </div>
  );
};

export default Messages;
