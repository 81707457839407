import { ADMINV2_ACTIVE_CAMPAIGNS_PATH, ADMINV2_ACTIVE_CAMPAIGN_PATH } from 'constants/routes';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ListPage from './ListPage';
import ShowPage from './ShowPage';

/**
 * Active Campaigns
 *
 * @type {React.FC}
 */
const ActiveCampaigns = () => {
  return (
    <Switch>
      <Route path={ADMINV2_ACTIVE_CAMPAIGN_PATH} component={ShowPage} />
      <Route path={ADMINV2_ACTIVE_CAMPAIGNS_PATH} component={ListPage} />
      <Redirect to={ADMINV2_ACTIVE_CAMPAIGNS_PATH} />
    </Switch>
  );
};

export default ActiveCampaigns;
