import React, { useState } from 'react';
import { Modal, Button, Row, Col, Icon, message as m } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './AdminCampaignModal.less';
import { selectors } from 'stores';
import { ReactComponent as CloseIcon } from '../../../images/close-x.svg';
import EmailModal from './EmailModal';
import SelectableInfluencerList from './SelectableInfluencerList';
import Actions from './Actions';
import useProductData from '../useProductData';

const initialEmailModal = {
  show: false,
  submitting: false,
};

/**
 * Product Admin Modal Component
 *
 * Requires Product Provider
 *
 * @typedef {{}} AdminModalProps
 * @type {React.FC<AdminModalProps>}
 */
const AdminModal = ({ productUID, visible, onClose }) => {
  const productData = useProductData();
  const product = useSelector(selectors.getProduct(productUID));
  const { brandUID, postOverdueCount, postCount } = product;
  const brand = useSelector(selectors.getBrand(brandUID));
  const [emailModal, setEmailModal] = useState(initialEmailModal);
  const [selectedPartnershipUIDs, setSelectedPartnershipUIDs] = useState([]);
  const { approvedPartnershipUIDs } = productData;

  function handleSelectChange(influencerUIDs) {
    setSelectedPartnershipUIDs(influencerUIDs);
  }

  function handleEmailClick() {
    setEmailModal(s => ({ ...s, show: true }));
  }

  function handleMessageSent() {
    setSelectedPartnershipUIDs([]);
    setEmailModal(s => ({ ...s, show: false }));
    m.success('Message successfully sent!');
  }

  function handleEmailBack() {
    setEmailModal(s => ({ ...s, show: false }));
  }

  function handleClose() {
    setSelectedPartnershipUIDs([]);
    onClose();
  }

  function handleEmailClose() {
    setEmailModal(s => ({ ...s, show: false }));
    setSelectedPartnershipUIDs([]);
    onClose();
  }

  const formatedLastPurchaseDate = moment(brand.lastPurchaseDate * 1000).format('MM/DD/YY');
  const formatedLastLoginDate = moment(brand.lastLoginDate * 1000).format('MM/DD/YY');

  const { credits = 0, standardCredits = 0, addedStandardCredits = 0, addedVipCredits = 0 } = brand;
  const totalCredits = credits + standardCredits + addedVipCredits + addedStandardCredits;

  return (
    <>
      <Modal
        className="admin-campaign-email-modal"
        visible={!emailModal.show && visible}
        closable={false}
        footer={false}
      >
        <div className="modal-header">
          <h1>{product.name} </h1>
          <div className="close">
            <Button onClick={handleClose} type="link">
              <Icon component={CloseIcon} />
            </Button>
          </div>
          <Row className="campaign-details">
            <Col span={18}>
              <Row>
                <Col span={6} className="brand-name">
                  {brand.name}
                </Col>
                <Col span={6} className="brand-credits">
                  Credits: {totalCredits}
                </Col>
                <Col span={12} className="brand-lastpurchasedate">
                  Last Purchase Date: {formatedLastPurchaseDate}
                </Col>
              </Row>
              <Row>
                <Col span={10} className="brand-email">
                  {brand.authEmail}
                </Col>
                <Col span={14} className="brand-lastlogindate">
                  Last Login Date: {formatedLastLoginDate}
                </Col>
              </Row>
            </Col>
            <Col span={6} className="action text-right">
              <Actions
                productUID={productUID}
                brandUID={product.brandUID}
                productName={product.name}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-body">
          <div className="influencer-list">
            <Row className="title">
              <Col className="name" span={12}>
                List of Creators
              </Col>
              <Col span={12} className="overdue-count">
                Overdue Posts: {postOverdueCount} / {postCount}
              </Col>
            </Row>
            <SelectableInfluencerList
              partnershipUIDs={approvedPartnershipUIDs}
              onSelectChange={handleSelectChange}
              value={selectedPartnershipUIDs}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button type="primary" block onClick={handleEmailClick}>
            Compose Email
            <br />
            <span>Email List: {selectedPartnershipUIDs.length}</span>
          </Button>
        </div>
      </Modal>
      <EmailModal
        visible={emailModal.show}
        partnershipUIDs={selectedPartnershipUIDs}
        onSuccess={handleMessageSent}
        onBack={handleEmailBack}
        onClose={handleEmailClose}
      />
    </>
  );
};

export default AdminModal;
