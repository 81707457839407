import React, { useState } from 'react';
import { ReactComponent as NewProject } from 'images/icons/NewProjectIcons/CreateNewProject.svg';
import { ReactComponent as Cheveron } from 'images/ContentShowcase/Cheveron.svg';
import mixpanel from 'lib/Analytics/Mixpanel';
import hasAccess from 'components/Helpers/brandHasAccess';
import { useSession } from 'components/Session';
import AddCreditsModal from 'components/Products/AddCreditsModal/AddCreditsModal';
import { toNewCampaign } from 'constants/routes';

const TypeSectionTitle = ({ option, titleClick, isOpen, hideSocial }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const show = hasAccess();
  const { brand } = useSession(false);

  function trackRouting() {
    if (option === 'social') {
      return;
    }
    const credits = brand ? brand.credits || brand.standardCredits : 0;
    if (brand && brand.firstCampaign && credits === 0) {
      setModalOpen(true);
      return;
    }
    if (mixpanel) {
      mixpanel.track('Brand Starts Campaign', { type: option });
    }
    toNewCampaign(option);
  }
  const closeModal = () => setModalOpen(false);
  const iconClass = isOpen ? 'cheveron-icon open' : 'cheveron-icon';

  const hide = option === 'social' ? hideSocial : false;

  return (
    <>
      <AddCreditsModal show={modalOpen} onClose={closeModal} />
      <div style={{ padding: '0 8px 10px 15px' }}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className="menu-title"
            role="presentation"
            style={{
              color: 'white',
              textTransform: 'capitalize',
              display: hide ? 'none' : 'auto',
            }}
          >
            {option} Projects
          </div>
          <div
            className="icons"
            style={{
              marginLeft: 'auto',
              display: hide ? 'none' : 'flex',
              alignItems: 'center',
            }}
          >
            {show && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className="new-project"
                onClick={trackRouting}
                style={{ display: option === 'social' ? 'none' : 'auto' }}
              >
                <NewProject className="new-project-icon" />
              </div>
            )}
            <Cheveron className={iconClass} onClick={() => titleClick()} />
          </div>
        </span>
      </div>
    </>
  );
};

export default TypeSectionTitle;
