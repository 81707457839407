import { SET_EXAMPLE_CAMPAIGNS } from 'stores/actions/exampleCampaigns';

/**
 * @type {ExampleCampaignReducer}
 */
const initialState = [];
/**
 * Example Campaigns Reducers
 *
 * @typedef {string[]} ExampleCampaignReducer
 *
 * @param {ExampleCampaignReducer} state
 * @param {import("stores/actions/exampleCampaigns").ExampleCampaignActions} action
 * @returns {ExampleCampaignReducer}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXAMPLE_CAMPAIGNS: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default reducer;
