import { firebase } from '../lib/Firebase';

/**
 *
 * @param {string} brandUID
 * @param {(notification: any) => void} callback
 * @returns
 */
const listenNotification = (brandUID, callback) => {
  const unsubscribe = firebase
    .notifications()
    .doc(brandUID)
    .onSnapshot(snapshot => {
      if (snapshot.exists) {
        callback(snapshot.data());
      }
    });

  return unsubscribe;
};

export { listenNotification as default, listenNotification };
