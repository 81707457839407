import React, { useContext } from 'react';
import { clearAuthCookies } from 'utils/cookies';
import Firebase from './firebase';

export const firebaseService = new Firebase();
window.signout = () => {
  clearAuthCookies();
  firebaseService.auth.signOut();
};

/**
 * @type {React.Context<import("./").firebase>}
 */
const FirebaseContext = React.createContext();

export const FirebaseProvider = ({ children, service = firebaseService }) => (
  <FirebaseContext.Provider value={service}>{children}</FirebaseContext.Provider>
);

export const useFirebase = () => {
  const firebase = useContext(FirebaseContext);
  if (!firebase) throw new Error('useFirebase hook must be used within the <FirebaseProvider>');
  return firebase;
};

export const withFirebase = Component => props => {
  const firebase = useFirebase();
  return <Component {...props} firebase={firebase} />;
};

export default FirebaseContext;
