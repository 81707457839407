import { ADD_POSTS_STATISTICS } from 'stores/actions/entities/postsStatistics';

/**
 * @typedef {string} ProductUID
 * @typedef {Record<ProductUID, { [date: string]: import("types").PostStatistic[] }>} PostsStatisticsReducer
 */
const initialState = {};

/**
 * @param {PostsStatisticsReducer} state
 * @param {import("stores/actions/entities/postsStatistics").EntityPostsStatisticsActions} action
 * @returns
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POSTS_STATISTICS: {
      const { productUID, postsStatistics } = action.payload;
      return { ...state, [productUID]: postsStatistics };
    }
    default:
      return state;
  }
};

export default reducer;
