import React, { useState } from 'react';
import mixpanel from 'lib/Analytics/Mixpanel';
import ContentModal from './ContentModal';
import { ContentCard } from './ContentCard';

/**
 *
 * @typedef {{
 *  contentType: string,
 *  isMobile: boolean,
 * }} ShowcaseSectionProps
 * @type {React.FC<ShowcaseSectionProps>}
 */

const ShowcaseSection = ({ isMobile, contentItems }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    content: '',
    creator: '',
    avatar: '',
    type: '',
    styleType: '',
  });

  const classname = isMobile ? 'content-showcase-section mobile' : 'content-showcase-section';

  function onContentClick(content, creator, type, profilePictureUrl, styleType) {
    setModalContent({
      ...modalContent,
      content,
      creator,
      type,
      avatar: profilePictureUrl,
      styleType,
    });
    if (mixpanel) {
      mixpanel.track('User Looks at Content in Modal', {
        contentCreator: creator,
        contentCategory: styleType,
        contentType: type,
      });
    }
    showModal();
  }

  function showModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
    setModalContent();
  }

  return (
    <>
      <div className={classname}>
        {contentItems &&
          contentItems.map(item => (
            <ContentCard key={item.creatorHandle} showModal={onContentClick} item={item} />
          ))}
      </div>
      {modalOpen && modalContent && (
        <ContentModal modalData={modalContent} closeModal={closeModal} />
      )}
    </>
  );
};

export default ShowcaseSection;
