import { Spin, Row, Col, Icon } from 'antd';
import React, { createRef, useEffect, useState } from 'react';
import { ReactComponent as PlayButtonSVG } from 'images/ContentShowcase/playIcon.svg';
import { isMobile } from 'react-device-detect';

/**
 * Video Component
 *
 * @typedef {{
 *    src: string,
 *    extra?: any,
 *    autoPlay?: boolean
 *    noPlay?: boolean
 * }} VideoProps
 * @type {React.FC<VideoProps>}
 */
const ShowcaseVideo = ({ src, autoPlay, extra }) => {
  const vidRef = createRef();

  const isMobileScreen = isMobile || window.innerWidth < 700;

  const [loaded, setLoaded] = useState({ [src]: false });

  useEffect(() => {
    if (loaded && vidRef && !isMobileScreen) {
      vidRef.current.addEventListener('ended', event => {
        // eslint-disable-next-line no-param-reassign
        event.target.currentTime = 0;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vidRef]);

  if (isMobileScreen) {
    return (
      <div className="video-thumbnail">
        <div className="icon-container">
          <Icon component={PlayButtonSVG} style={{ width: '100%' }} />
        </div>
        <img className="content image" alt="video thumbnail" src={extra} />
      </div>
    );
  }

  return (
    <div
      className="video-loader"
      role="button"
      tabIndex={-1}
      onMouseEnter={() => vidRef.current.play()}
      onMouseLeave={() => vidRef.current.pause()}
    >
      {loaded[src] && (
        <Row justify="center" align="middle" type="flex" className="video-player-actions">
          <Col>
            <Icon component={PlayButtonSVG} style={{ width: '100%' }} />
          </Col>
        </Row>
      )}
      <video
        key={src}
        ref={vidRef}
        preload="auto"
        onLoadedData={() => {
          setLoaded({ [src]: true });
        }}
        autoPlay={autoPlay}
      >
        <source src={src} type="video/mp4" />
        <source src={src} type="video/webm" />
        <source src={src} type="video/ogg" />
        <source src={src} type="video/avi" />
        Sorry, your browser doesn&#39;t support embedded videos.
      </video>
      {!loaded[src] && <Spin />}
    </div>
  );
};

export default ShowcaseVideo;
