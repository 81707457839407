/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

let composeEnhancer = compose;
if (process.env.NODE_ENV !== 'production') {
  composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export default () => {
  const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)));

  return store;
};
