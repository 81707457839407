import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Table, message, Icon, AutoComplete } from 'antd';
import moment from 'moment';
import Promise from 'bluebird';
import Container from 'components/Common/Container';
import { expiredCreditsListener } from 'listeners/expiredCredits';
import { firebase } from 'lib/Firebase';
import { adminReturnExpiredCredits } from 'lib/Firebase/callables/admin';
import { selectors } from 'stores';

const columns = [
  {
    title: 'Brand',
    className: 'brand',
    render: data => {
      return (
        <div style={{ marginTop: '10px' }}>
          <h4>{data.brandInfo.name}</h4>
          <span>{data.brandInfo.email}</span>
        </div>
      );
    },
  },
  {
    title: 'Last login',
    className: 'last-login-date',
    render: data => {
      const date = moment.unix(data.brandInfo.lastLoginDate);
      return <div>{date.format('M/D/YY')}</div>;
    },
  },
  {
    title: 'Last Purchase Date',
    className: 'last-purchase-date',
    render: data => {
      const date = moment.unix(data.brandInfo.lastPurchaseDate);
      return <div>{date.format('M/D/YY')}</div>;
    },
  },
  {
    title: 'Credits Purchased',
    render: data => {
      const { addedStandardCredits } = data;
      return <div>{addedStandardCredits}</div>;
    },
  },
  {
    title: 'Last Purchase Date',
    dataIndex: ['brandInfo', 'lastPurchaseDate'],
    sorter: (a, b) => a.brandInfo.lastPurchaseDate - b.brandInfo.lastPurchaseDate,
    render: data => {
      const date = moment.unix(data);
      return <div>{date.format('M/D/YY')}</div>;
    },
  },
  {
    title: 'Credits Expiration Date',
    dataIndex: 'expiredAt',
    sorter: (a, b) => a.expiredAt - b.expiredAt,
    render: data => {
      const date = moment.unix(data);
      return <div>{date.format('M/D/YY')}</div>;
    },
  },
  {
    title: '# of Credits Removed',
    dataIndex: 'totalCredits',
    sorter: (a, b) => a.totalCredits - b.totalCredits,
    render: data => {
      return <div>{data || 0}</div>;
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    render: ({ addCreditsBack }) => {
      return (
        <div style={{ width: '162px' }}>
          <Button
            className="size-10px type-firme-iblack spacing-1px "
            type="primary"
            onClick={addCreditsBack}
          >
            <span className="text-black">ADD CREDITS BACK</span>
          </Button>
        </div>
      );
    },
  },
];

const initialPagination = { page: 1, limit: 30 };

const { Option } = AutoComplete;

const modalInitial = { show: false, uid: null };
/**
 * Admin V2 Expired Credits Page
 *
 * @type {React.FC}
 */
const ExpiredCreditsPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const [pagination, setPagination] = useState(initialPagination);
  const [expiredCreditsList, setExpiredCreditsList] = useState([]);
  const [showModal, setShowModal] = useState(modalInitial);
  const [lastDocument, setLastDocument] = useState(null);
  const [currentSearch, setCurrentSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [outstandingCredits, setOutstandingCredits] = useState(null);
  const brandUIDs = useSelector(selectors.getAdminBrands());
  const brands = useSelector(selectors.getBrands());

  const retrieveExpiredCreditsList = async (lastDoc = null, filters = {}) => {
    await expiredCreditsListener(filters, lastDoc, pagination.limit, result => {
      const data = [];
      let doc = null;
      Promise.map(result, async res => {
        const snap = res.data();
        const { brandUID, totalCredits } = snap;
        const brandInfo = await firebase.firestore
          .collection('brands')
          .doc(brandUID)
          .get();
        data.push({
          uid: res.id,
          ...res.data(),
          brandInfo: { ...brandInfo.data() },
          actions: {
            addCreditsBack: () =>
              setShowModal({ show: true, uid: res.id, brandUID, credits: totalCredits }),
          },
        });
        doc = res;
      }).then(() => {
        if (Object.keys(filters).length) {
          setExpiredCreditsList(data);
        } else {
          setExpiredCreditsList([...expiredCreditsList, ...data]);
          setLastDocument(doc);
        }
      });
    });
  };

  const retrieveTotalNumberOfOutStandingCredits = async () => {
    const brands = await firebase.firestore.collection('brands').get();

    let totalOutStanding = 0;
    Promise.map(brands.docs, brand => {
      const { standardCredits = 0 } = brand.data();
      if (standardCredits) {
        totalOutStanding += standardCredits;
      }
    }).then(() => {
      setOutstandingCredits(totalOutStanding);
    });
  };

  useEffect(() => {
    retrieveExpiredCreditsList();

    retrieveTotalNumberOfOutStandingCredits();
  }, []);

  async function handleApprove() {
    try {
      setSubmitting(true);
      await adminReturnExpiredCredits(showModal.uid);
      message.success('Successfully returned credits!');
    } catch (e) {
      message.error('Something went wrong', e.message);
    }
    setSubmitting(true);
    setShowModal({ ...showModal, show: false });
  }

  const loadMore = () => {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });

    retrieveExpiredCreditsList(lastDocument);
  };

  const showLimit = pagination.limit * pagination.page;

  function onChange(val = {}) {
    const newFilters = { ...val };
    retrieveExpiredCreditsList(null, newFilters);
  }

  function handleChange(value) {
    if (onChange) {
      onChange({ search: value });
      setCurrentSearch(value);
    }
  }

  function handleSearch(value = '') {
    const data = brandUIDs.filter(x => {
      const { name = '' } = brands[x] || {};
      return name.toLowerCase().includes(value.toLowerCase());
    });

    setOptions(data);
  }

  const list = options.map(x => (
    <Option key={x} value={x}>
      {brands[x].name}
    </Option>
  ));

  return (
    <div id="admin-expired-credits-page">
      <Container width={940}>
        <Row className="search" type="flex" align="middle">
          <Col span={1}>
            <Icon type="search" />
          </Col>
          <Col span={15}>
            <AutoComplete
              allowClear
              onSearch={handleSearch}
              onSelect={handleChange}
              onChange={val => !val && currentSearch && handleChange(null)}
              placeholder="Search"
            >
              {list}
            </AutoComplete>
          </Col>
          <Col
            span={8}
            style={{
              fontFamily: 'TruenoBold',
              fontSize: '12px',
              textAlign: 'right',
              paddingRight: '30px',
            }}
          >
            Total Outstanding Credits: {outstandingCredits}
          </Col>
        </Row>
        <Table
          dataSource={expiredCreditsList}
          columns={columns}
          pagination={false}
          showHeader
          id="expired-credits-table"
        />
        <div className="text-center loadmore">
          {expiredCreditsList.length > showLimit - 1 && (
            <Button className="load-more" onClick={() => loadMore()}>
              LOAD MORE
            </Button>
          )}
        </div>
      </Container>

      <Modal
        visible={showModal.show}
        closable={false}
        footer={false}
        className="admin-approve-modal"
      >
        <p className="text-center">Are you sure you want to return these credits?</p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            <Button loading={submitting} type="primary" block onClick={() => handleApprove()}>
              Confirm
            </Button>
            <Button block onClick={() => setShowModal({ ...showModal, show: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ExpiredCreditsPage;
