/**
 * @typedef {ReturnType<addInfluencers>} EntityInfluencersActions
 */
export const ADD_INFLUENCERS = 'ADD_INFLUENCERS';

/**
 * Entity Add Influencer Action
 * @param {Record<string, import("types").Influencer>} influencers
 */
export const addInfluencers = influencers => ({
  type: ADD_INFLUENCERS,
  payload: influencers,
});
