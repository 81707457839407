import React, { useState } from 'react';
import { Layout } from 'antd';
import { isMobile } from 'react-device-detect';
import { useSession, useCheckSession } from 'components/Session';
import Logo from 'components/Common/Logo';
import ContentShowcaseTab from './SideNav/ContentShowcaseTab';
import ContentShowcase from '../../pages/Campaigns/ContentShowcase';
import InitalSideMenu from './SideNav/SideMenus/InitalSideMenu';
import ContentCredit from './SideNav/ContentCredit';

const { Sider, Content } = Layout;

/**
 *
 * @typedef {{}} ContentShowcaseLayoutProps
 * @type {React.FC<ContentShowcaseLayoutProps>}
 */

const ContentShowcaseLayout = () => {
  const [layoutConfig, setLayoutConfig] = useState({
    squishedNav: false,
  });
  const session = useSession(false);
  const { squishedNav } = layoutConfig;
  const isMobileScreen = isMobile;
  // eslint-disable-next-line no-nested-ternary
  const finalCredits = !useCheckSession()
    ? 0
    : session.brand.standardCredits
    ? session.brand.standardCredits
    : 0;

  return (
    <div>
      {!isMobileScreen && (
        <Layout>
          <Sider
            width={210}
            style={{
              overflow: 'hidden',
              height: '100vh',
              position: 'fixed',
              zIndex: '99',
              left: '0',
              minHeight: '100vh',
            }}
            breakpoint="lg"
            onBreakpoint={broken => {
              setLayoutConfig(state => ({ ...state, squishedNav: broken }));
            }}
          >
            {!squishedNav && <Logo />}
            <ContentCredit standardCredits={finalCredits} />
            <ContentShowcaseTab isActive />
            <div>
              <InitalSideMenu />
            </div>
          </Sider>

          <Layout id="trend-content-layout" style={{ marginLeft: squishedNav ? '80px' : '210px' }}>
            <Content>
              <ContentShowcase />
            </Content>
          </Layout>
        </Layout>
      )}
      {isMobileScreen && (
        <Layout>
          <Layout id="trend-content-layout">
            <Content>
              <ContentShowcase />
            </Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
};

export default ContentShowcaseLayout;
