import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import trendLogo from 'images/trend@2x.png';
import { notification, Layout, Row, Col, Form, Input, Modal, Button } from 'antd';
import { useSession } from 'components/Session';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { firebase } from 'lib/Firebase';
import { brandUpdateAccount } from 'lib/Firebase/callables/brands';

const { Content } = Layout;

/**
 * Update Email Page
 *
 * @typedef {{ form: import('antd/lib/form/Form').WrappedFormUtils }} UpdateEmailProps
 * @type {React.FC<UpdateEmailProps>}
 */
const UpdateEmail = ({ form }) => {
  const [successModal, setSuccessModal] = useState(false);
  const {
    brand: { authEmail },
  } = useSession();
  const [submitting, setSubmitting] = useState(false);
  const { getFieldDecorator, validateFieldsAndScroll } = form;
  const history = useHistory();

  useEffect(() => {}, []);

  function handleSubmit() {
    setSubmitting(true);
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          const { email, password } = values;
          // Retest auth before updating email
          const creds = firebase.app.auth.EmailAuthProvider.credential(authEmail, password);
          await firebase.auth.currentUser.reauthenticateWithCredential(creds);
          // Update email
          await brandUpdateAccount({ authEmail: email });
          setSuccessModal(true);
        } catch (e) {
          notification.error({
            message: 'Error',
            description: e.message,
            duration: 3,
          });
        }
        setSubmitting(false);
      }
    });
  }

  return (
    <div className="bg-black">
      <div className="action-link clickable">
        <div className="back">
          <div onClick={() => history.goBack()} role="presentation" style={{ color: '#ffb917' }}>
            <IconBack />
            <span className="white d-block type-sbold bsize-4">Back</span>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', padding: '4rem 0' }}>
        <img
          alt="Trend"
          src={trendLogo}
          width="162"
          height="31"
          style={{ display: 'inline-block' }}
        />
      </div>
      <h1 style={{ textAlign: 'center', color: '#fff' }}>Change Email</h1>
      <h3 style={{ textAlign: 'center', color: '#fff' }}>{authEmail}</h3>
      <div>
        <Layout>
          <Content style={{ padding: 24, background: '#000' }}>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={6}>
                <Form form={form} layout="vertical">
                  <Form.Item>
                    <label className="trend-label">New Email</label>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: 'Email is required!',
                        },
                        {
                          type: 'email',
                          message: 'Invalid Email!',
                        },
                      ],
                    })(<Input type="text" size="large" name="email" className="trend-input" />)}
                  </Form.Item>
                  <Form.Item>
                    <label className="trend-label">Input Password</label>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Input Password is required!',
                        },
                      ],
                    })(<Input type="password" size="large" className="trend-input" />)}
                  </Form.Item>
                  <Button
                    block
                    loading={submitting}
                    size="large"
                    type="submit"
                    className="trend-btn"
                    htmlType="submit"
                    onClick={handleSubmit}
                  >
                    Change Email
                  </Button>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
        <Modal
          visible={successModal}
          closable={false}
          className="trend-modal"
          width={300}
          footer={[
            <Button
              key="okay"
              onClick={() => {
                history.push('/account/store/edit');
              }}
              className="trend-btn huge-btn"
              block
            >
              Okay
            </Button>,
          ]}
        >
          <span className="bsize-4 text-black leading-18px">
            Email address was successfully updated!
          </span>
        </Modal>
      </div>
    </div>
  );
};

export default Form.create({ name: 'email-form' })(UpdateEmail);
