import React from 'react';
import { Empty } from 'antd';
import NoApplicationsImg from 'images/DefaultEmpty/no-applications.png';

const EmptyApplications = () => (
  <Empty
    className="empty-no-applications"
    style={{
      marginTop: '66px',
      marginBottom: '109px',
    }}
    image={NoApplicationsImg}
    description={
      <span className="empty-desc-header type-sfpro-bold mb-2 mt-6">Awaiting Applications</span>
    }
  >
    <div className="mt-1">
      <div className="empty-desc-body type-sfpro-regular">
        Hang tight! After your project is approved by our team, content creators will
        <br className="hidden-mobile" />
        apply to work with you! If you have any questions about your listing or how you
        <br className="hidden-mobile" />
        can attract more creators to your campaign, contact support@trend.io.
      </div>
    </div>
  </Empty>
);

export default EmptyApplications;
