import React, { useEffect, useState } from 'react';
import { Table, Descriptions } from 'antd';
import Promise from 'bluebird';
import moment from 'moment';
import { firebase } from '../../../lib/Firebase';

const PaidTab = () => {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const columns = [
    {
      key: 'influencerUID',
      dataIndex: 'username',
      title: 'Username',
    },
    {
      key: 'productUID',
      dataIndex: 'productName',
      title: 'Product Name',
    },
  ];

  const expandedRow = ({ productName, username, completedAt }) => (
    <div className="partnership-details">
      <Descriptions title="Partnership" bordered>
        <Descriptions.Item label="Product">{productName}</Descriptions.Item>
        <Descriptions.Item label="Influencer">{username}</Descriptions.Item>
        <Descriptions.Item label="Completed At">
          {completedAt && moment.unix(completedAt).format('MMM DD, YYYY')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );

  async function fetchData() {
    setFetching(true);
    const partnershipsSnap = await firebase.firestore
      .collection('influencersPartnerships')
      .where('payoutStatus', '==', 'paid')
      .limit(50)
      .get();

    const newState = await Promise.map(partnershipsSnap.docs, async doc => {
      const { influencerUID, productUID } = doc.data();
      const { username } = (await firebase.firestore
        .collection('influencers')
        .doc(influencerUID)
        .get()).data();
      const { name } = (await firebase.firestore
        .collection('products')
        .doc(productUID)
        .get()).data();

      return {
        uid: doc.id,
        username,
        productName: name,
        ...doc.data(),
      };
    });

    setData([...data, ...newState]);
    setFetching(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="denied-tab">
      <Table
        dataSource={data}
        columns={columns}
        expandedRowRender={expandedRow}
        pagination={false}
        loading={fetching}
      />
    </div>
  );
};

export default PaidTab;
