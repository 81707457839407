import { Button as AntButton, Icon } from 'antd';
import ThumbUp from 'images/components/RehireInfluencer/thumbup.png';
import React from 'react';
import { ReactComponent as RehireIcon } from 'images/components/RehireInfluencer/rehire-icon.svg';
import { useProductData } from 'components/Products';
import useRehire from './useRehire';

/**
 * Rehire Influencer Button Component
 *
 * @typedef {{
 *  influencerUID: string,
 *  awaitingLabel: string,
 * }} ButtonProps
 * @type {React.FC<ButtonProps & ButtonProps>}
 */
const Button = ({ children, influencerUID, awaitingLabel, ...rest }) => {
  const rehire = useRehire();

  const { invitedInfluencerUIDs } = useProductData();
  let status;
  let buttonIcon = <Icon component={RehireIcon} />;

  if (invitedInfluencerUIDs.includes(influencerUID)) {
    status = 'awaiting';
    buttonIcon = <img src={ThumbUp} alt="Rehire" />;
  }

  function handleShowInvitation() {
    if (status !== 'awaiting') {
      rehire.showInvitationModal(influencerUID);
    }
  }

  return (
    <AntButton className={`rehire-button ${status}`} onClick={handleShowInvitation} {...rest}>
      <span className="image-wrapper">{buttonIcon}</span>
      <span>{status === 'awaiting' ? awaitingLabel : children}</span>
    </AntButton>
  );
};

export default Button;
