import uuid from 'uuid';
import { firebaseService } from '../context';

const firebase = firebaseService;

export const influencerSendMessage = async (messageUID, text, type, url, thumbnail) => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'sendMessage', data: { messageUID, text, type, url, thumbnail } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerSubmitApplication = async productUID => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'submitApplication', data: { productUID } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerUpdateAccount = async params => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'updateAccount', data: params });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerReadMessage = async messageUID => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'readMessage', data: { messageUID } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerReadApproveApplication = async productUID => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'readApproveApplication', data: { productUID } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerUpdateProfilePicture = async image => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'updateProfilePicture', data: { image } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerUpdatePushToken = async token => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'updatePushToken', data: { token } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerEnablePushToken = async isPushNotificationsEnabled => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'enablePushToken', data: { isPushNotificationsEnabled } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerUpdateLevel = async levelUID => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'updateLevel', data: { levelUID } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const influencerSubmitCreatorContent = async (creatorContentUID, type) => {
  try {
    const func = firebase.callable('influencers');

    const params = {
      standardResolutionImage:
        'https://storage.googleapis.com/verbv2.appspot.com/posts%2F-L-lbghZLtoda0ctK8To%2F0%2FstandardResolutionImage?GoogleAccessId=firebase-adminsdk-xv6rh%40verbv2.iam.gserviceaccount.com&Expires=10418889600&Signature=wZVvIi1EP6OlVEmd7hfTWMx601OcjPDegdvrpKuZ%2FgP10kO6ZbD8YSxZVTD4HvumnExKRXKcZFnOuQwVflecPBphL1GPlIJpMJOLQhCNyxfbQ3tBKiHlEl%2BdbfCkMg9ivXV6QwoDogUKXYXau%2FcfeK861r%2BKna4nM0UqOhWxS2N2hWDdB8HlArJq4BzlGAekTBVtVZ8U1JiyYFG8rVGBDkOtwe2QD3652FqcnhEtRu4FkzgVk%2Fcfj2zxb6nBp8UagJ6uQBeg4Hh%2B6UOZMMyWoO5hV5qyIILBOKa5mOpl4SaMQhHcAfrz8%2FiwHqIrLJyZ91%2BKPSsERKvbKVOKp9Caug%3D%3D',
    };

    if (type === 'video') {
      params.highResolutionVideo =
        'https://firebasestorage.googleapis.com:443/v0/b/development-verbv2.appspot.com/o/influencers%2FZTj22JUt1rek6nVTyW1HLUfOtot1%2Fpartnerships%2Fproducts%2F-M72RwRVBqAVejisOl2m%2Fvideos%2F-MhHkesBZCbabiQF7DhY_9344AC1D-1F69-433D-A1EB-3CBF23300C6B.mov?alt=media&token=baf2a05b-042f-4321-8314-54a01f4eb970';
      params.type = 'video';
    } else {
      params.highResolutionImage =
        'https://storage.googleapis.com/verbv2.appspot.com/posts%2F-L-lbghZLtoda0ctK8To%2F0%2FstandardResolutionImage?GoogleAccessId=firebase-adminsdk-xv6rh%40verbv2.iam.gserviceaccount.com&Expires=10418889600&Signature=wZVvIi1EP6OlVEmd7hfTWMx601OcjPDegdvrpKuZ%2FgP10kO6ZbD8YSxZVTD4HvumnExKRXKcZFnOuQwVflecPBphL1GPlIJpMJOLQhCNyxfbQ3tBKiHlEl%2BdbfCkMg9ivXV6QwoDogUKXYXau%2FcfeK861r%2BKna4nM0UqOhWxS2N2hWDdB8HlArJq4BzlGAekTBVtVZ8U1JiyYFG8rVGBDkOtwe2QD3652FqcnhEtRu4FkzgVk%2Fcfj2zxb6nBp8UagJ6uQBeg4Hh%2B6UOZMMyWoO5hV5qyIILBOKa5mOpl4SaMQhHcAfrz8%2FiwHqIrLJyZ91%2BKPSsERKvbKVOKp9Caug%3D%3D';
      params.type = 'image';
    }

    const contents = {};
    [0, 1, 2, 3].forEach(position => {
      contents[uuid.v4()] = {
        ...params,
        position,
      };
    });

    if (type === 'story') {
      contents[uuid.v4()] = {
        standardResolutionImage:
          'https://storage.googleapis.com/verbv2.appspot.com/posts%2F-L-lbghZLtoda0ctK8To%2F0%2FstandardResolutionImage?GoogleAccessId=firebase-adminsdk-xv6rh%40verbv2.iam.gserviceaccount.com&Expires=10418889600&Signature=wZVvIi1EP6OlVEmd7hfTWMx601OcjPDegdvrpKuZ%2FgP10kO6ZbD8YSxZVTD4HvumnExKRXKcZFnOuQwVflecPBphL1GPlIJpMJOLQhCNyxfbQ3tBKiHlEl%2BdbfCkMg9ivXV6QwoDogUKXYXau%2FcfeK861r%2BKna4nM0UqOhWxS2N2hWDdB8HlArJq4BzlGAekTBVtVZ8U1JiyYFG8rVGBDkOtwe2QD3652FqcnhEtRu4FkzgVk%2Fcfj2zxb6nBp8UagJ6uQBeg4Hh%2B6UOZMMyWoO5hV5qyIILBOKa5mOpl4SaMQhHcAfrz8%2FiwHqIrLJyZ91%2BKPSsERKvbKVOKp9Caug%3D%3D',
        highResolutionVideo:
          'https://firebasestorage.googleapis.com:443/v0/b/development-verbv2.appspot.com/o/influencers%2FZTj22JUt1rek6nVTyW1HLUfOtot1%2Fpartnerships%2Fproducts%2F-M72RwRVBqAVejisOl2m%2Fvideos%2F-MhHkesBZCbabiQF7DhY_9344AC1D-1F69-433D-A1EB-3CBF23300C6B.mov?alt=media&token=baf2a05b-042f-4321-8314-54a01f4eb970',
        type: 'video',
      };
    }

    const response = await func({
      action: 'submitContent',
      data: {
        creatorContentUID,
        contents,
      },
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const addAdditionalContent = async (creatorContentUID, type) => {
  try {
    const func = firebase.callable('influencers');

    const params = {
      standardResolutionImage:
        'https://storage.googleapis.com/verbv2.appspot.com/posts%2F-L-lbghZLtoda0ctK8To%2F0%2FstandardResolutionImage?GoogleAccessId=firebase-adminsdk-xv6rh%40verbv2.iam.gserviceaccount.com&Expires=10418889600&Signature=wZVvIi1EP6OlVEmd7hfTWMx601OcjPDegdvrpKuZ%2FgP10kO6ZbD8YSxZVTD4HvumnExKRXKcZFnOuQwVflecPBphL1GPlIJpMJOLQhCNyxfbQ3tBKiHlEl%2BdbfCkMg9ivXV6QwoDogUKXYXau%2FcfeK861r%2BKna4nM0UqOhWxS2N2hWDdB8HlArJq4BzlGAekTBVtVZ8U1JiyYFG8rVGBDkOtwe2QD3652FqcnhEtRu4FkzgVk%2Fcfj2zxb6nBp8UagJ6uQBeg4Hh%2B6UOZMMyWoO5hV5qyIILBOKa5mOpl4SaMQhHcAfrz8%2FiwHqIrLJyZ91%2BKPSsERKvbKVOKp9Caug%3D%3D',
    };

    if (type === 'video') {
      params.highResolutionVideo =
        'https://firebasestorage.googleapis.com:443/v0/b/development-verbv2.appspot.com/o/influencers%2FZTj22JUt1rek6nVTyW1HLUfOtot1%2Fpartnerships%2Fproducts%2F-M72RwRVBqAVejisOl2m%2Fvideos%2F-MhHkesBZCbabiQF7DhY_9344AC1D-1F69-433D-A1EB-3CBF23300C6B.mov?alt=media&token=baf2a05b-042f-4321-8314-54a01f4eb970';
      params.type = 'video';
    } else {
      params.highResolutionImage =
        'https://storage.googleapis.com/verbv2.appspot.com/posts%2F-L-lbghZLtoda0ctK8To%2F0%2FstandardResolutionImage?GoogleAccessId=firebase-adminsdk-xv6rh%40verbv2.iam.gserviceaccount.com&Expires=10418889600&Signature=wZVvIi1EP6OlVEmd7hfTWMx601OcjPDegdvrpKuZ%2FgP10kO6ZbD8YSxZVTD4HvumnExKRXKcZFnOuQwVflecPBphL1GPlIJpMJOLQhCNyxfbQ3tBKiHlEl%2BdbfCkMg9ivXV6QwoDogUKXYXau%2FcfeK861r%2BKna4nM0UqOhWxS2N2hWDdB8HlArJq4BzlGAekTBVtVZ8U1JiyYFG8rVGBDkOtwe2QD3652FqcnhEtRu4FkzgVk%2Fcfj2zxb6nBp8UagJ6uQBeg4Hh%2B6UOZMMyWoO5hV5qyIILBOKa5mOpl4SaMQhHcAfrz8%2FiwHqIrLJyZ91%2BKPSsERKvbKVOKp9Caug%3D%3D';
      params.type = 'image';
    }

    const contents = {};
    [0, 1, 2, 3].forEach(position => {
      contents[uuid.v4()] = {
        ...params,
        position,
      };
    });

    if (type === 'story') {
      contents[uuid.v4()] = {
        standardResolutionImage:
          'https://storage.googleapis.com/verbv2.appspot.com/posts%2F-L-lbghZLtoda0ctK8To%2F0%2FstandardResolutionImage?GoogleAccessId=firebase-adminsdk-xv6rh%40verbv2.iam.gserviceaccount.com&Expires=10418889600&Signature=wZVvIi1EP6OlVEmd7hfTWMx601OcjPDegdvrpKuZ%2FgP10kO6ZbD8YSxZVTD4HvumnExKRXKcZFnOuQwVflecPBphL1GPlIJpMJOLQhCNyxfbQ3tBKiHlEl%2BdbfCkMg9ivXV6QwoDogUKXYXau%2FcfeK861r%2BKna4nM0UqOhWxS2N2hWDdB8HlArJq4BzlGAekTBVtVZ8U1JiyYFG8rVGBDkOtwe2QD3652FqcnhEtRu4FkzgVk%2Fcfj2zxb6nBp8UagJ6uQBeg4Hh%2B6UOZMMyWoO5hV5qyIILBOKa5mOpl4SaMQhHcAfrz8%2FiwHqIrLJyZ91%2BKPSsERKvbKVOKp9Caug%3D%3D',
        highResolutionVideo:
          'https://firebasestorage.googleapis.com:443/v0/b/development-verbv2.appspot.com/o/influencers%2FZTj22JUt1rek6nVTyW1HLUfOtot1%2Fpartnerships%2Fproducts%2F-M72RwRVBqAVejisOl2m%2Fvideos%2F-MhHkesBZCbabiQF7DhY_9344AC1D-1F69-433D-A1EB-3CBF23300C6B.mov?alt=media&token=baf2a05b-042f-4321-8314-54a01f4eb970',
        type: 'video',
      };
    }

    const response = await func({
      action: 'addAdditionalContent',
      data: {
        creatorContentUID,
        contents,
      },
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const readPartnershipNotification = async (partnershipUID, type) => {
  try {
    const func = firebase.callable('influencers');

    const response = await func({
      action: 'readPartnershipNotification',
      data: {
        partnershipUID,
        type,
      },
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const afterSignIn = async () => {
  try {
    const func = firebase.callable('influencers');
    await func({ action: 'afterSignIn', data: {} });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};
