import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { Modal, Button, message } from 'antd';
import { adminCompletePartnership } from 'lib/Firebase/callables/admin';

const { confirm } = Modal;
/**
 * Influencer Drawer Active Campaign Component
 *
 * @typedef {{ partnershipUID }} ActiveCampaignProps
 * @type {React.FC<ActiveCampaignProps>}
 */
const ActiveCampaign = ({ partnershipUID }) => {
  const dispatch = useDispatch();
  const partnership = useSelector(selectors.getPartnership(partnershipUID));
  const { productUID, approvedPostCount = 0, requiredPosts = 0 } = partnership;
  const product = useSelector(selectors.getProduct(productUID));
  const { brandUID, name: productName } = product;
  const brand = useSelector(selectors.getBrand(brandUID));
  const { name: brandName } = brand;
  const [showModal, setShowModal] = useState(false);

  function handleComplete() {
    confirm({
      title: 'Are you sure you want to complete this partnership?',
      content: (
        <p>
          Completing this will send a <strong>PAYOUT</strong> to influencer
        </p>
      ),
      onOk: async () => {
        try {
          await adminCompletePartnership(partnershipUID);

          dispatch(
            actions.entities.partnerships.updatePartnerships({
              [partnershipUID]: { ...partnership, status: 'completed' },
            })
          );
        } catch (e) {
          message.error(e.message);
        }
      },
    });
  }

  function handleCompleteOnly() {
    confirm({
      title: 'Are you sure you want to complete this partnership?',
      content: (
        <p>
          This process will <strong>NOT</strong> send any payouts to the influencers
        </p>
      ),
      onOk: async () => {
        try {
          await adminCompletePartnership(partnershipUID, false);

          dispatch(
            actions.entities.partnerships.updatePartnerships({
              [partnershipUID]: { ...partnership, status: 'completed' },
            })
          );
        } catch (e) {
          message.error(e.message);
        }
      },
    });
  }

  let { dueDate, activeAt, shippedAt } = partnership;
  dueDate = dueDate && moment(dueDate);
  activeAt = activeAt && moment(activeAt);
  shippedAt = shippedAt && moment(shippedAt);
  const currentDate = moment();
  const twoDaysAgo = moment().add(2, 'days');

  let shipmentStatus = 'danger';
  if (activeAt) {
    shipmentStatus = 'success';
  } else if (shippedAt) {
    shipmentStatus = 'warning';
  }

  let postStatus = 'success';
  if (dueDate && dueDate < currentDate) {
    postStatus = 'danger';
  } else if (dueDate && dueDate > currentDate && dueDate < twoDaysAgo) {
    postStatus = 'warning';
  }

  return (
    <div className="campaign-details">
      <div className="campaign-details-brand-name">{brandName}</div>
      <div className="campaign-details-name">{productName}</div>
      <div className="campaigns-details-status">
        <div className="details-status">
          <span className={`icon ${shipmentStatus}`} />
          <div className="status">
            <div className="status-value">
              Delivered - {activeAt && moment(activeAt * 1000).format('M/D/YY')}
            </div>
            <div className="status-label">Shipment</div>
          </div>
        </div>
        <div className="details-status">
          <span className={`icon ${postStatus}`} />
          <div className="status">
            <div className="status-value">
              {approvedPostCount}/{requiredPosts} - Due{' '}
              {dueDate && moment(dueDate * 1000).format('M/D/YY')}
            </div>
            {approvedPostCount === requiredPosts && (
              <div style={{ marginBottom: 4 }}>
                <Button type="danger" onClick={() => setShowModal(true)}>
                  Complete Partnership
                </Button>
                <Modal visible={showModal} onCancel={() => setShowModal(false)} footer={false}>
                  <h1>Complete Partnership</h1>
                  <p>
                    <strong>Complete Only</strong> - Complete without sending any payouts to the
                    influencer
                    <br />
                    <strong>Complete with Payout</strong> - Complete and send a payout to the
                    influencer
                  </p>
                  <Button type="primary" block onClick={handleCompleteOnly}>
                    Complete Only
                  </Button>
                  <br />
                  <br />
                  <Button type="primary" block onClick={handleComplete}>
                    Complete with Payout
                  </Button>
                </Modal>
              </div>
            )}
            <div className="status-label">Posts</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveCampaign;
