import React from 'react';
import { Empty } from 'antd';
import NoMessagesImg from 'images/DefaultEmpty/No_Messages.png';

const EmptyMessages = () => (
  <Empty
    className="empty-no-messages"
    style={{
      marginTop: '66px',
      marginBottom: '109px',
    }}
    image={<img src={NoMessagesImg} alt="no messages" style={{ width: '504px', height: 'auto' }} />}
    description={<span className="empty-desc-header type-sfpro-bold mb-2 mt-6">No Messages</span>}
  >
    <div className="mt-1">
      <div className="empty-desc-body">
        After you approve your creators, you’ll be able to chat with them right here in the
        <br />
        platform instead of through DM’s or lengthy email threads.
      </div>
    </div>
  </Empty>
);

export default EmptyMessages;
