import React from 'react';
import { Row, Col } from 'antd';
import { IMPRESSIONS } from 'constants/posts';
import CurrencyFormat from 'react-currency-format';
import UpperCaseLabel from 'components/Common/UpperCaseLabel';

/**
 * @typedef {{
 *  creators: number,
 *  activePosts: number,
 *  requiredPosts: number,
 *  likes: number,
 *  comments: number,
 * }} StatsProps
 * @type {React.FC<StatsProps>}
 */
const Stats = ({ creators, activePosts, requiredPosts, likes = 0, comments = 0 }) => {
  const impressions = likes * IMPRESSIONS;

  return (
    <div id="campaignStats">
      <Row gutter={8}>
        <Col md={5} xs={8} sm={5}>
          <h2 className="stats-heading">
            <CurrencyFormat value={creators} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Creators</UpperCaseLabel>
        </Col>
        <Col md={5} xs={8} sm={5}>
          <h2 className="stats-heading">
            <CurrencyFormat value={activePosts} displayType="text" thousandSeparator />
            /
            <CurrencyFormat value={requiredPosts} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Live Posts</UpperCaseLabel>
        </Col>
        <Col md={5} xs={8} sm={5}>
          <h2 className="stats-heading">
            <CurrencyFormat value={likes} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Likes</UpperCaseLabel>
        </Col>
        <Col md={5} xs={8} sm={5}>
          <h2 className="stats-heading">
            <CurrencyFormat value={comments} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Comments</UpperCaseLabel>
        </Col>
        <Col md={4} xs={8} sm={4}>
          <h2 className="stats-heading">
            <CurrencyFormat value={impressions} displayType="text" thousandSeparator />
          </h2>
          <UpperCaseLabel>Impression</UpperCaseLabel>
        </Col>
      </Row>
    </div>
  );
};

export default Stats;
