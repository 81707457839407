import { addBrands } from './entities/brands';

export const SET_ADMIN_BRANDS = 'SET_ADMIN_BRANDS';

/**
 * Set Admin Action
 *
 * @typedef {{ type: SET_ADMIN_BRANDS, payload: string[] }} SetAdminBrandsAction
 *
 * @param {Record<string, import("types").Brand>} brands
 */
export const setAdminBrands = brands =>
  /**
   * @param {Dispatch} dispatch
   */
  dispatch => {
    dispatch(addBrands(brands));
    dispatch({
      type: SET_ADMIN_BRANDS,
      payload: Object.keys(brands),
    });
  };

/**
 * @typedef {SetAdminBrandsAction} AdminPagesActions
 */
