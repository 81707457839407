import Promise from 'bluebird';
import React, { useState } from 'react';
import { Modal, Button, Row, Col, Icon, Input } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { adminSendInfluencerEmail } from 'lib/Firebase/callables/admin';
import './AdminCampaignModal.less';
import { ReactComponent as BackIcon } from '../../../images/back-arrow-white.svg';
import { ReactComponent as CloseIcon } from '../../../images/close-x.svg';

const { TextArea } = Input;

/**
 * Product Admin Email Modal
 * @typedef {{visible: boolean, partnershipUIDs: string[], onSuccess: () => void, onBack: () => void, onClose: () => void}} EmailModalProps
 * @type {React.FC<EmailModalProps>}
 */
const EmailModal = ({ visible, partnershipUIDs = [], onSuccess, onBack, onClose }) => {
  const partnerships = useSelector(selectors.getPartnerships());
  const influencerUIDs = partnershipUIDs.map(x => partnerships[x].influencerUID);

  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  function handleChange(e) {
    setMessage(e.target.value);
  }

  async function handleClick() {
    setSubmitting(true);
    try {
      await Promise.map(influencerUIDs, async influencerUID => {
        await adminSendInfluencerEmail(influencerUID, message);
      });
      setMessage('');
      setSubmitting(false);
      onSuccess();
    } catch (e) {
      // do nothing
    }
  }

  function handleBack() {
    onBack();
  }

  function handleClose() {
    onClose();
  }

  return (
    <Modal className="admin-compose-email" visible={visible} closable={false} footer={false}>
      <div className="modal-header">
        <Button className="back" type="link" onClick={handleBack}>
          <Icon component={BackIcon} />
        </Button>
        <Button className="close" type="link" onClick={handleClose}>
          <Icon component={CloseIcon} />
        </Button>
        Sending to {influencerUIDs.length} Creators
      </div>
      <div className="modal-body">
        <p>
          Enter your email below. This email will automatically add the creators first name. Make
          sure to add a signature at the end.
        </p>
        <h1>Hello &quot;X&quot;,</h1>
        <TextArea placeholder="Enter your message here." onChange={handleChange} value={message} />
      </div>
      <div className="modal-footer">
        <Row justify="space-between">
          <Col span={12} className="text-left" onClick={handleBack}>
            <Button>Cancel</Button>
          </Col>
          <Col span={12} className="text-right">
            <Button type="primary" onClick={handleClick} loading={submitting}>
              Confirm Approval
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EmailModal;
