import React from 'react';
import { Icon } from 'antd';
import { ReactComponent as Instagram } from '../../../images/socials/Instagram.svg';
import { ReactComponent as TikTok } from '../../../images/socials/TikTok.svg';
import { ReactComponent as Youtube } from '../../../images/socials/Youtube.svg';

const Socials = ({ socials }) => {
  function createSocialButtons() {
    const socialBtns = [];
    if (socials && Object.keys(socials).length > 0) {
      if (socials.instagram) {
        socialBtns.push(
          <a
            href={`https://instagram.com/${socials.instagram}`}
            className="influencer-socials-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon component={Instagram} style={{ fontSize: '18px' }} />
          </a>
        );
      }
      if (socials.tikTok) {
        socialBtns.push(
          <a
            href={`https://tiktok.com/@${socials.tikTok}`}
            className="influencer-socials-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon component={TikTok} style={{ fontSize: '20px' }} />
          </a>
        );
      }
      if (socials.youtube) {
        socialBtns.push(
          <a
            href={`https://youtube.com/channel/${socials.youtube}`}
            className="influencer-socials-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon component={Youtube} style={{ fontSize: '25px' }} />
          </a>
        );
      }
    }

    return socialBtns;
  }

  return (
    <div style={{ paddingTop: '6px', paddingBottom: '6px' }}>
      <ul
        style={{
          listStyle: 'none',
          padding: '0',
          margin: '0',
          justifyContent: 'center',
          display: 'flex',
          textAlign: 'center',
        }}
      >
        {createSocialButtons().map((button, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li className="influencer-socials" key={index}>
              {button}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Socials;
