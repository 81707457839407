import { useFirebase } from 'lib/Firebase';
import { Row, AutoComplete, Icon } from 'antd';
import debounce from 'components/Helpers/useDebounce';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';

/**
 * Influencer Admin Search Component
 *
 * @typedef {{ onChange: (influencerUID: string) => any }} AdminSearchProp
 * @type {React.FC<AdminSearchProp>}
 */
const AdminSearch = ({ onChange }) => {
  const dispatch = useDispatch();
  const influencers = useSelector(selectors.getInfluencers());
  const [currentSearch, setCurrentSearch] = useState('');
  const [options, setOptions] = useState([]);
  const firebase = useFirebase();
  const [loading, setLoading] = useState(false);

  async function handleSearch(value) {
    setLoading(true);
    const influencerDocs = await firebase.firestore
      .collection('influencers')
      .where('username', '>=', value)
      .limit(5)
      .get();

    if (!influencerDocs.empty) {
      const influencerUIDs = [];
      const newInfluencers = {};
      influencerDocs.forEach(doc => {
        const data = doc.data();
        if (data.username.includes(value)) {
          influencerUIDs.push(doc.id);
          newInfluencers[doc.id] = doc.data();
        }
      });

      dispatch(actions.entities.influencers.addInfluencers(newInfluencers));
      setOptions(influencerUIDs);
    }
    setCurrentSearch(value);

    setLoading(false);
  }

  function handleChange(value) {
    onChange(value);
  }

  const list = options.map(x => {
    return (
      <AutoComplete.Option key={x} value={x}>
        {influencers[x].username}
      </AutoComplete.Option>
    );
  });

  return (
    <Row className="search" type="flex" align="middle">
      <Icon type={loading ? 'loading' : 'search'} style={{ 'margin-right': '5px' }} />
      <AutoComplete
        allowClear
        onSearch={debounce(handleSearch, 500)}
        onSelect={handleChange}
        onChange={val => !val && currentSearch && handleChange(null)}
        placeholder="Search creators"
      >
        {list}
      </AutoComplete>
    </Row>
  );
};

export default AdminSearch;
