const API_URL = process.env.REACT_APP_FUNCTION_API_URL;
const baseUrl = path => `${API_URL}/api/${path}`;

const Routes = {
  shipengine: baseUrl('shipengine'),
  postReportCsv: baseUrl('download/postReportCsv'), // Can probably remove
  sendAnalyticsEmail: baseUrl('sendAnalyticsEmail'), // Can probably remove
  sendFreeTrialInvitation: baseUrl('freeTrialInvitation'), // Can probably remove
  cardList: baseUrl('cardList'),
};

export default Routes;
