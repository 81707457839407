/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Table, Button, message, Spin, Radio, Icon, AutoComplete } from 'antd';
import Container from 'components/Common/Container';
import { adminApproveInfluencer, adminRejectInfluencer } from 'lib/Firebase/callables/admin';
import debounce from 'components/Helpers/useDebounce';
import { getPendingInfluencers } from 'api/creators';
import CreatorContentStyle from '../../components/Products/CreatorContentStyle';
import mixpanel from '../../lib/Analytics/Mixpanel';

const creatorColumns = [
  {
    title: 'CREATOR CONTACT',
    render: creator => {
      return (
        <div>
          <strong>
            <p>@{creator.instagramUsername}</p>
          </strong>
          <p>{creator.authEmail}</p>
          <p>{moment(creator.createdAtDisplay).format('MM/DD/YYYY h:mm:ss a')}</p>
        </div>
      );
    },
  },
  {
    title: 'SOCIAL ACCOUNTS',
    key: 'socials',
    dataIndex: 'socials',
    render: socials => {
      const socialLinks = {
        website: 'https://',
        instagram: 'https://www.instagram.com/',
        tikTok: 'https://www.tiktok.com/@',
      };
      return Object.entries(socials).map(socialEntry => {
        const [channel, handle] = socialEntry;
        if (Object.keys(socialLinks).includes(channel) && handle && handle !== '') {
          return (
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${socialLinks[channel]}${handle}`}
              >
                {channel}
              </a>
            </p>
          );
        }
        return <div />;
      });
    },
  },
  {
    title: 'COUNTRY',
    dataIndex: 'country',
    key: 'country',
    render: country => {
      return <div>{country}</div>;
    },
  },
  {
    title: 'APPLIED FOR',
    dataIndex: 'jobTypes',
    key: 'jobTypes',
    render: jobTypes => {
      const acceptableValues = ['social', 'content'];
      const jobPreferences = Object.entries(jobTypes)
        .filter(jobEntry => {
          const [jobPreference, value] = jobEntry;
          return acceptableValues.includes(jobPreference) && value;
        })
        .map(jobEntry => {
          const [jobPreference] = jobEntry;
          return jobPreference.charAt(0).toUpperCase() + jobPreference.slice(1);
        });
      if (jobPreferences.length >= 2) {
        return jobPreferences.join(' & ');
      }
      if (jobPreferences.length === 1) {
        return `${jobPreferences[0]} Only`;
      }
      return 'Joined via IOS';
    },
  },
  {
    title: 'CONTENT STYLE',
    className: 'content-styles',
    rowClassName: 'content-style',
    render: creator => {
      if (creator.portfolio) {
        if (Array.isArray(creator.portfolio)) {
          return <CreatorContentStyle portfolio={creator.portfolio.slice(0, 6)} />;
        }
        const acceptablePortfolioKeys = ['videos', 'photos'];
        const cleanPortfolio = Object.fromEntries(
          Object.entries({ ...creator.portfolio }).filter(([key]) => {
            if (acceptablePortfolioKeys.includes(key)) {
              return true;
            }
            return false;
          })
        );
        const videos = cleanPortfolio.videos
          ? cleanPortfolio.videos.map(url => ({ url, type: 'video' }))
          : [];
        const photos = cleanPortfolio.photos
          ? cleanPortfolio.photos.map(url => ({ url, type: 'image' }))
          : [];
        const portfolio = [...videos, ...photos];
        return <CreatorContentStyle portfolio={portfolio.slice(0, 6)} />;
      }
      return null;
    },
  },
  {
    title: 'ACTIONS',
    dataIndex: 'actions',
    align: 'right',
    key: 'actions',
    className: 'actions',
    render: ({ onApprove, onReject }) => {
      const displayRecord = () => {
        return (
          <div>
            <Button type="primary" onClick={onApprove} block>
              Approve
            </Button>
            <Button onClick={onReject} block>
              Reject
            </Button>
          </div>
        );
      };
      return <>{displayRecord()}</>;
    },
  },
];

const modalInitial = {
  show: false,
  influencerUID: null,
  index: null,
  email: null,
  instagramId: null,
};

/**
 * Admin V2 Pending Influencer Page
 *
 * @type {React.FC}
 */
const PendingInfluencersPage = () => {
  const [loading, setLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(modalInitial);
  const [influencerList, setInfluencerList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    total: 0,
    totalPages: 1,
    loading: true,
  });

  const [filters, setFilters] = useState({});
  const [genderFilter, setGenderFilter] = useState('all');

  const [creatorLevels, setCreatorLevels] = useState({
    social: 0,
    content: 0,
  });

  function onButtonChange(e) {
    const propertyName = e.target.name;
    const selectionValue = e.target.value;
    setCreatorLevels({ ...creatorLevels, [propertyName]: selectionValue });
  }

  async function retrievePendingInfluencers() {
    const data = await getPendingInfluencers(
      {
        ...filters,
        gender: genderFilter === 'all' ? undefined : genderFilter,
      },
      pagination
    );

    setPagination(state => {
      return {
        ...state,
        total: data.total,
        totalPages: data.totalPages,
        loading: false,
      };
    });

    // remove creators without a user populated
    const creatorRenderData = (data.documents || [])
      .filter(creator => !!creator.user && creator.applicationStatus === 'submitted')
      .map(creator => {
        const acceptableSocialKeys = ['website', 'instagram', 'tikTok', 'youtube'];
        const acceptableJobTypeKeys = ['social', 'content'];

        return {
          mongoUserId: creator.userId,
          creatorId: creator._id,
          dbSource: 'mongodb',
          socialProvider: creator.user.socialProvider || '',
          createdAt: creator.createdAt,
          createdAtDisplay: creator.createdAt,
          instagramUsername: creator.socials.instagram || '',
          username: creator.socials.instagram || '',
          authEmail: creator.user.email || '',
          phoneNumber: creator.user.phoneNumber || '',
          gender: creator.gender,
          image: creator.user.profilePicture || '',
          address: creator.address.line1,
          secondAddress: creator.address.line2,
          city: creator.address.city,
          state: creator.address.state,
          zip: creator.address.postalCode,
          country: creator.address.country,
          ageRange: creator.ageRange || '18-24',
          paypalEmail: creator.payPalEmail,
          firstName: creator.user.firstName,
          lastName: creator.user.lastName,
          name: `${creator.user.firstName} ${creator.user.lastName}`,
          tikTokUsername: creator.socials.tikTok || '',
          status: creator.applicationStatus,
          uid: creator.user.firebaseUserId,
          user: creator.user,
          isSuspended: false,
          lastAppliedDate: 0,
          device: 'ios',
          categories: { deprecated: 'deprecated' },
          socials:
            Object.fromEntries(
              Object.entries({ ...creator.socials }).filter(([key, value]) => {
                if (value !== '' && acceptableSocialKeys.includes(key)) {
                  return true;
                }
                return false;
              })
            ) || {},
          jobTypes:
            Object.fromEntries(
              Object.entries({ ...creator.jobTypes }).filter(([key]) => {
                if (acceptableJobTypeKeys.includes(key)) {
                  return true;
                }
                return false;
              })
            ) || {},
          portfolio: creator.portfolio,
        };
      });

    setInfluencerList(creatorRenderData);
  }

  useEffect(() => {
    retrievePendingInfluencers();
  }, [pagination.page, pagination.pageSize, filters, genderFilter]);

  const updateState = uid => {
    setInfluencerList(state =>
      state.filter(influencer => !(Array.isArray(uid) ? uid : [uid]).includes(influencer.uid))
    );
  };

  async function handlePageChange(page, pageSize) {
    setPagination(state => {
      return {
        ...state,
        page: page - 1,
        pageSize,
        loading: true,
      };
    });
  }

  async function handlePageSizeChange(currentSize, newSize) {
    setPagination(state => {
      return {
        ...state,
        pageSize: newSize,
        loading: true,
      };
    });
  }

  async function handleApprove({ isBulk = false, ...data }) {
    setLoading(true);

    try {
      const { index } = approveModal;
      const approvePayload = Object.assign(data, { payOutTiers: creatorLevels });

      if (isBulk) {
        message.info(`Approving creator(s) in bulk`, 5);
        await Promise.all(
          selectedRowKeys.map(async influencerUID => {
            const influencer = influencerList.find(i => i.uid === influencerUID);
            if (!influencer) throw Error(`Influencer with UID ${influencerUID} not found in list`);

            return adminApproveInfluencer({
              uid: influencerUID,
              payOutTiers: creatorLevels,
            });
          })
        );
        updateState(selectedRowKeys);
        setSelectedRowKeys([]);
      } else {
        message.info(`Approving creator`);
        await adminApproveInfluencer(approvePayload);
        updateState(influencerList[index].uid);
      }

      if (mixpanel) {
        mixpanel.track('Admin Approves Creator', {
          ...approvePayload,
          levels: creatorLevels,
        });
      }

      setApproveModal(modalInitial);
      message.success('Successfully Approved!');
    } catch (error) {
      message.error(error.message);
    }

    setLoading(false);
  }

  async function handleSearchChange(email) {
    setPagination(state => ({
      ...state,
      loading: true,
    }));

    setFilters(state => ({
      ...state,
      email,
    }));
  }

  async function handleReject({ index, isBulk = false }) {
    setLoading(true);
    try {
      if (isBulk) {
        message.info(`Rejecting creator(s) in bulk...`, 5);
        await Promise.all(
          selectedRowKeys.map(async influencerUID => {
            const influencer = influencerList.find(i => i.uid === influencerUID);
            if (!influencer) throw Error(`Influencer with UID ${influencerUID} not found in list`);

            return adminRejectInfluencer(influencerUID);
          })
        );

        updateState(selectedRowKeys);
        setSelectedRowKeys([]);
      } else {
        message.info(`Rejecting creator...`);
        await adminRejectInfluencer(influencerList[index].uid);
        updateState(influencerList[index].uid);
      }

      if (mixpanel) {
        mixpanel.track('Admin rejects Creator');
      }

      message.success('Successfully Rejected!');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error', e);
      message.error(e);
    }
    setLoading(false);
  }

  const source = influencerList
    .filter(data => Object.keys(data).length > 0)
    .map((data, index) => {
      return {
        ...data,
        key: data.uid,
        actions: {
          onApprove: () => {
            setApproveModal({
              ...data,
              show: true,
              index,
            });
          },
          onReject: () =>
            handleReject({
              ...data,
              isBulk: false,
              index,
            }),
        },
      };
    });

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const bulkApproveCreators = () => {
    setApproveModal({
      show: true,
      isBulk: true,
    });
  };

  const changeGenderFilter = e => {
    setGenderFilter(e.target.value);
  };

  return (
    <Container width={1300} id="admin-pending-influencers-approval">
      <Row className="search" type="flex" align="middle">
        <Icon type="search" />
        <AutoComplete
          allowClear
          onChange={debounce(handleSearchChange, 500)}
          placeholder="Search by email"
          style={{ marginRight: '16px' }}
        />
        <Radio.Group value={genderFilter} onChange={changeGenderFilter}>
          <Radio.Button value="all">All</Radio.Button>
          <Radio.Button value="male">Male</Radio.Button>
          <Radio.Button value="female">Female</Radio.Button>
        </Radio.Group>
      </Row>

      <Row className="bulk-actions">
        <Button
          type="primary"
          ghost
          onClick={() => handleReject({ isBulk: true })}
          disabled={!hasSelected}
        >
          Bulk Reject
        </Button>
        <Button type="primary" onClick={bulkApproveCreators} disabled={!hasSelected}>
          Bulk Approve
        </Button>
      </Row>

      <Table
        dataSource={source}
        columns={creatorColumns}
        pagination={{
          position: 'both',
          total: pagination.total,
          pageSize: pagination.pageSize,
          showTotal: total => `Total ${total}`,
          onChange: handlePageChange,
          onShowSizeChange: handlePageSizeChange,
          hideOnSinglePage: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          showSizeChanger: true,
        }}
        rowSelection={rowSelection}
        loading={pagination.loading}
        bordered
        showHeader
      />
      <Modal
        visible={approveModal.show}
        closable={false}
        footer={false}
        className="admin-approve-modal"
      >
        <h2 className="text-center">Set Creator Approval Levels</h2>
        <p className="text-center">
          Set the creator's qualification level for each campaign category. Select Not Included if
          the creator cannot apply for that type
        </p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={10}>
                    <b>Content</b>
                    <Radio.Group
                      value={creatorLevels.content}
                      name="content"
                      onChange={onButtonChange}
                    >
                      <Radio value={0}>Not Approved</Radio>
                      <Radio value={1}>Level 1 (1 credit)</Radio>
                      <Radio value={2}>Level 2 (2 credit)</Radio>
                      <Radio value={3}>Level 3 (3 credit)</Radio>
                      <Radio value={4}>Level 4 (4 credit)</Radio>
                      <Radio value={5}>Level 5 (5 credit)</Radio>
                    </Radio.Group>
                  </Col>
                  <Col span={10}>
                    <b>Social</b>
                    <Radio.Group
                      value={creatorLevels.social}
                      name="social"
                      onChange={onButtonChange}
                    >
                      <Radio value={0}>Not Approved</Radio>
                      <Radio value={1}>Level 1 (1 credit)</Radio>
                      <Radio value={2}>Level 2 (2 credit)</Radio>
                      <Radio value={3}>Level 3 (3 credit)</Radio>
                      <Radio value={4}>Level 4 (4 credit)</Radio>
                      <Radio value={5}>Level 5 (5 credit)</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <br />

                <Button type="primary" block onClick={() => handleApprove(approveModal)}>
                  Confirm {approveModal.isBulk ? 'Bulk' : 'Standard'} Approval
                </Button>

                <Button block onClick={() => setApproveModal({ ...approveModal, show: false })}>
                  Cancel
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Modal>
    </Container>
  );
};

export default PendingInfluencersPage;
