import { Button, Avatar, Row, Col, Icon } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { ReactComponent as BackArrowWhite } from 'images/pages/Campaigns/ShowPage/Approvals/MediaCompleted/BackArrowWhite.svg';
import { CREATOR_CONTENT_CONTENTS_TYPE } from 'constants/creatorContents';
import RehireInfluencer from 'components/RehireInfluencer';
import ContentPreviewModal from 'components/Common/ContentPreviewModal';
import { getContentUrlFromRecord } from 'utils/content';

/**
 * Creator Content Content Card Component
 *
 * @typedef {{ creatorContentUID, contentUID, onDownload: (creatorContentUID: string) => Promise<any> }} ApprovedCardProps
 * @type {React.FC<ApprovedCardProps>}
 */
const Card = ({ creatorContentUID, contentUID, onDownload }) => {
  const [previewModal, setPreviewModal] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const creatorContent = useSelector(selectors.getCreatorContent(creatorContentUID));
  const influencer = useSelector(selectors.getInfluencer(creatorContent.influencerUID));
  const content = creatorContent.contents[contentUID];
  const contentUrl = getContentUrlFromRecord(content);

  async function handleDownload() {
    setDownloading(true);
    await onDownload(creatorContentUID, contentUID);
    setDownloading(false);
  }

  const isVideo = content.type === CREATOR_CONTENT_CONTENTS_TYPE.VIDEO;

  return (
    <div className="creator-content-media-card">
      <ContentPreviewModal
        contentType={isVideo ? 'video' : 'image'}
        url={contentUrl}
        visible={previewModal}
        setVisible={setPreviewModal}
        imageKitPreview
        ikTransformations={[
          {
            width: '300',
            height: '300',
          },
        ]}
      />
      <div className="media-actions" role="button" tabIndex="0">
        <div
          className="media-actions-overlay"
          role="button"
          tabIndex="0"
          onClick={() => {
            setPreviewModal(true);
          }}
        />
        <div className="influencer-section">
          <Avatar src={influencer.image} size={40} />
          <div className="username">{`@${influencer.username}`}</div>
        </div>
        <div className="action-button">
          <Row gutter={4} type="flex">
            <Col span={12}>
              <RehireInfluencer.Button
                awaitingLabel={
                  <div>
                    Awaiting
                    <br />
                    Rehire
                    <br />
                    Response
                  </div>
                }
                influencerUID={creatorContent.influencerUID}
              >
                Rehire
                <br />
                Creator
              </RehireInfluencer.Button>
            </Col>
            <Col span={12}>
              <Button
                disabled={downloading}
                loading={downloading}
                onClick={e => {
                  e.stopPropagation();
                  handleDownload();
                }}
              >
                <div className="shape download">
                  <Icon component={BackArrowWhite} />
                </div>
                <div className="label">
                  Download
                  <br />
                  Content
                </div>
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Card;
