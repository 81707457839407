import React, { useEffect, useState } from 'react';
import { Icon, Row, Col } from 'antd';
import moment from 'moment-timezone';
import Promise from 'bluebird';
import { POST_STATUS } from 'constants/posts';
import { firebase } from '../../../lib/Firebase';
import { formatCurrency } from '../../Helpers/numeral';

const initalState = {
  today: 0,
  sevenDays: 0,
  thirtyDays: 0,
  overAll: 0,
};

const useStat = (actionName, field) => {
  const [stats, setStats] = useState({ ...initalState });
  const [loading, setLoading] = useState({
    loadedData: false,
    loadedOverall: false,
  });

  function retrieveStats() {
    setLoading({ loadedData: false, loadedOverall: false });
    const mainRef = firebase.firestore.collection('statistics').doc(actionName);

    const currentDay = moment()
      .tz('America/Los_Angeles')
      .startOf('day');

    const previousDate = moment()
      .tz('America/Los_Angeles')
      .subtract(24, 'hours');

    const sevenDaysAgo = moment()
      .tz('America/Los_Angeles')
      .startOf('day')
      .subtract(7, 'days');

    const thirtyDays = moment()
      .tz('America/Los_Angeles')
      .startOf('day')
      .subtract(30, 'days');

    const currentData = mainRef
      .collection('daily')
      .where('date', '>=', thirtyDays.format('YYYY-MM-DD'))
      .orderBy('date', 'desc')
      .onSnapshot(async docs => {
        let params = {
          today: 0,
          sevenDays: 0,
          thirtyDays: 0,
        };

        await Promise.map(docs.docs, async doc => {
          const dateId = moment.tz(doc.id, 'America/Los_Angeles');
          const { count } = doc.data();

          let value = count;

          /**
           * fetch specific data where status === true only
           * for payoutInfluencer since there are some duplicate
           * data saved
           */
          if (actionName === ActionName.payoutInfluencer) {
            const payoutDataSnap = await mainRef
              .collection('daily')
              .doc(doc.id)
              .collection('data')
              .where('status', '==', POST_STATUS.completed)
              .get();

            let payoutAmount = 0;
            payoutDataSnap.docs.forEach(payoutDoc => {
              payoutAmount += payoutDoc.data().payoutAmount;
            });

            value = payoutAmount;
          }

          let last24hrs = 0;
          const currentDayFormat = currentDay.format('YYYY-MM-DD');
          const previousDateFormat = previousDate.format('YYYY-MM-DD');

          /**
           * fetch last 24hrs
           */
          if (doc.id === previousDateFormat && previousDateFormat !== currentDayFormat) {
            const dailySnap = await mainRef
              .collection('daily')
              .doc(doc.id)
              .collection('data')
              .where('createdAt', '>=', previousDate.unix())
              .get();

            if (actionName === ActionName.payoutInfluencer) {
              dailySnap.docs.forEach(dailyDoc => {
                const { status, payoutAmount } = dailyDoc.data();

                if (status === POST_STATUS.completed) {
                  last24hrs += payoutAmount;
                }
              });
            } else {
              last24hrs = dailySnap.docs.length;
            }
          }

          if (last24hrs > 0) {
            params = { ...params, today: params.today + last24hrs };
          }

          if (currentDayFormat === doc.id) {
            params = { ...params, today: params.today + value };
          }

          if (dateId > sevenDaysAgo) {
            params = { ...params, sevenDays: params.sevenDays + value };
          }

          if (dateId >= thirtyDays) {
            params = { ...params, thirtyDays: params.thirtyDays + value };
          }
        });

        setStats(s => ({ ...s, ...params }));
        setLoading(state => ({ ...state, loadedData: true }));
      });

    const overAllListener = mainRef.onSnapshot(doc => {
      if (doc.exists) {
        const { count = 0, ...data } = doc.data();
        let value = count;

        if (field) {
          value = data[field];
        }
        setStats(s => ({ ...s, overAll: value }));
      }
      setLoading(state => ({ ...state, loadedOverall: true }));
    });

    return [overAllListener, currentData];
  }

  useEffect(() => {
    const [listener, listener2] = retrieveStats();

    return () => {
      listener();
      listener2();
    };
  }, []);

  const { loadedData, loadedOverall } = loading;
  return [stats, loadedData && loadedOverall];
};

const DisplayStat = ({ title, stats, loaded }) => {
  const { today = 0, sevenDays = 0, thirtyDays = 0, overAll = 0 } = stats;
  return (
    <Col span={6}>
      <div className="stats">
        <div className="stat-title">
          {title} {!loaded && <Icon type="loading" />}
        </div>
        <Row>
          <Col span={12}>
            <div className="stat-count">{today}</div>
            <div className="stat-label">Last 24 hrs</div>
          </Col>
          <Col span={12}>
            <div className="stat-count">{sevenDays}</div>
            <div className="stat-label">Last 7 days</div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="stat-count">{thirtyDays}</div>
            <div className="stat-label">Last 30 days</div>
          </Col>
          <Col span={12}>
            <div className="stat-count">{overAll}</div>
            <div className="stat-label">All Time</div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

/**
 * @enum {{
 * newInfluencer: 'newInfluencer',
 * completedPartnership: 'completedPartnership',
 * approvedPartnership: 'approvedPartnership',
 * payoutInfluencer: 'payoutInfluencer',
 * creatorApplications: 'creatorApplications',
 * createdProduct: 'createdProduct',
 * rejectedCreatorContents: 'rejectedCreatorContents',
 * }}
 */
const ActionName = {
  newInfluencer: 'newInfluencer',
  completedPartnership: 'completedPartnership',
  approvedPartnership: 'approvedPartnership',
  payoutInfluencer: 'payoutInfluencer',
  creatorApplications: 'creatorApplications',
  createdProducts: 'createdProducts',
  rejectedCreatorContents: 'rejectedCreatorContents',
};

const SignUpStats = () => {
  const [stats, loaded] = useStat(ActionName.newInfluencer);

  return <DisplayStat title="New Creators in Trend" stats={stats} loaded={loaded} />;
};

const CompletedPartnershipStats = () => {
  const [stats, loaded] = useStat(ActionName.completedPartnership);

  return <DisplayStat title="Completed Partnerships" stats={stats} loaded={loaded} />;
};

const ApprovedPartnershipStats = () => {
  const [stats, loaded] = useStat(ActionName.approvedPartnership);

  return <DisplayStat title="New Partnerships" stats={stats} loaded={loaded} />;
};

const PayoutStats = () => {
  const [stats, loaded] = useStat(ActionName.payoutInfluencer, 'payoutAmountTotal');

  let formatedStats = {};

  Object.keys(stats).forEach(stat => {
    const value = formatCurrency(stats[stat], '$');
    formatedStats = { ...formatedStats, [stat]: value };
  });

  return <DisplayStat title="Payouts" stats={formatedStats} loaded={loaded} />;
};

const CreatorApplications = () => {
  const [stats, loaded] = useStat(ActionName.creatorApplications);

  return <DisplayStat title="Campaign Applications" stats={stats} loaded={loaded} />;
};

const CreatorContent = () => {
  const [stats, loaded] = useStat(ActionName.createdProducts);

  return <DisplayStat title="Content Campaigns" stats={stats} loaded={loaded} />;
};

const ContentCreatorRejected = () => {
  const [stats, loaded] = useStat(ActionName.rejectedCreatorContents);

  return <DisplayStat title="Rejected Contents" stats={stats} loaded={loaded} />;
};

const Statistic = () => {
  return (
    <Row type="flex" className="influencers-stats" gutter={[12, 12]}>
      <SignUpStats />
      <ApprovedPartnershipStats />
      <CompletedPartnershipStats />
      <PayoutStats />
      <CreatorApplications />
      <CreatorContent />
      <ContentCreatorRejected />
    </Row>
  );
};

export default Statistic;
