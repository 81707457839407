import Container from 'components/Common/Container';
import React from 'react';

/**
 * Admin V2 Brand List Page
 *
 * @type {React.FC}
 */
const ListPage = () => {
  return (
    <Container width={930}>
      <div>
        <h1>Brands</h1>
      </div>
    </Container>
  );
};

export default ListPage;
