const { createContext } = require('react');

/**
 * @typedef {{
 * brandUID: string,
 * approvedPartnershipUIDs: string[],
 * purchasedHistoryUIDs: string[]
 * }} BrandContext
 * @type {React.Context<BrandContext>}
 */
const Context = createContext();

export default Context;
