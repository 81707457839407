import { addPartnerships } from './entities/partnerships';

/**
 * @typedef {ReturnType<initializeSession> |
 * ReturnType<updateBrandSession> |
 * ReturnType<addActiveProductsSession> |
 * ReturnType<addCompletedProductsSession> |
 * ReturnType<addCompletedPartnershipInfluencers> |
 * ReturnType<removeActiveProductsSesssion> |
 * ReturnType<removeCompletedProductSession> |
 * ReturnType<removeSession> |
 * ReturnType<updateNotificationSession>} SessionActions
 */
const { addProducts } = require('./entities/products');

export const INITIALIZE_SESSION = 'INITIALIZE_SESSION';

/**
 *
 * @param {{
 *  brand: import('types').Brand,
 *  activeProducts: Record<string, import('types').Product>,
 *  completedProducts: Record<string, import('types').Product>,
 *  notification: import('types').Notification,
 *  completedPartnerships: Record<string, import('types').Partnership>,
 *  categories: string[],
 * }} param0
 * @returns {{
 *  type: INITIALIZE_SESSION,
 *  payload: {
 *  	brand: import('types').Brand,
 *    activeProductUIDs: string[],
 *    completedProductUIDs: string[],
 *    notification: import('types').Notification,
 *    completedPartnershipInfluencerUIDs: string[],
 *    categories: string[]
 *  }
 * }}
 */
export const initializeSession = ({
  brand,
  activeProducts,
  completedProducts,
  notification,
  completedPartnerships,
  categories,
}) =>
  /**
   * @param {import("redux").Dispatch} dispatch
   */
  dispatch => {
    dispatch(addProducts({ ...activeProducts, ...completedProducts }));
    dispatch(addPartnerships(completedPartnerships));
    const completedPartnershipInfluencerUIDs = Object.keys(completedPartnerships).map(
      partnershipUID => {
        return completedPartnerships[partnershipUID].influencerUID;
      }
    );

    return dispatch({
      type: INITIALIZE_SESSION,
      payload: {
        brand,
        activeProductUIDs: Object.keys(activeProducts),
        completedProductUIDs: Object.keys(completedProducts),
        notification,
        completedPartnershipInfluencerUIDs,
        categories,
      },
    });
  };

export const UPDATE_BRAND_SESSION = 'UPDATE_BRAND_SESSION';
/**
 * Update Session Brand
 *
 * @param {import('types').Brand} brand
 * @returns {{ type: UPDATE_BRAND_SESSION, payload: import('types').Brand}}
 */
export const updateBrandSession = brand => ({
  type: UPDATE_BRAND_SESSION,
  payload: brand,
});

export const ADD_ACTIVE_PRODUCTS_SESSION = 'ADD_ACTIVE_PRODUCT_SESSION';
/**
 * Add Active Products Sesssion
 *
 * @param {Record<string, import('types').Product>} products
 * @returns {{ type: ADD_ACTIVE_PRODUCTS_SESSION, payload: string[] }}
 */
export const addActiveProductsSession = products =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  dispatch => {
    dispatch(addProducts(products));

    return dispatch({
      type: ADD_ACTIVE_PRODUCTS_SESSION,
      payload: Object.keys(products),
    });
  };

export const ADD_COMPLETED_PRODUCTS_SESSION = 'ADD_COMPLETED_PRODUCTS_SESSION';
/**
 * Add Completed Products Sesssion
 *
 * @param {Record<string, import('types').Product>} products
 * @returns {{ type: ADD_COMPLETED_PRODUCTS_SESSION, payload: string[] }}
 */
export const addCompletedProductsSession = products =>
  /**
   * @param {import('redux').Dispatch} dispatch
   */
  dispatch => {
    dispatch(addProducts(products));

    return dispatch({
      type: ADD_COMPLETED_PRODUCTS_SESSION,
      payload: Object.keys(products),
    });
  };

export const ADD_COMPLETED_PARTNERSHIP_INFLUENCERS = 'ADD_COMPLETED_PARTNERSHIP_INFLUENCERS';

/**
 * Add Completed Partnership Influencers
 *
 * @param {Record<string, import('types').Influencer>} Influencers
 * @returns {{ type: ADD_COMPLETED_PARTNERSHIP_INFLUENCERS, payload: string[] }}
 */
export const addCompletedPartnershipInfluencers = influencers => ({
  type: ADD_COMPLETED_PARTNERSHIP_INFLUENCERS,
  payload: Object.keys(influencers),
});

export const REMOVE_ACTIVE_PRODUCTS_SESSION = 'REMOVE_ACTIVE_PRODUCTS_SESSION';

/**
 * Remove Active Product Session Action
 *
 * @param {Record<string, import('types').Product>} products
 * @returns {{ type: REMOVE_ACTIVE_PRODUCTS_SESSION, payload: string[] }}
 */
export const removeActiveProductsSesssion = products => ({
  type: REMOVE_ACTIVE_PRODUCTS_SESSION,
  payload: Object.keys(products),
});

export const REMOVE_COMPLETED_PRODUCTS_SESSION = 'REMOVE_COMPLETED_PRODUCTS_SESSION';
/**
 * Remove Completed Product Session Action
 *
 * @param {Record<string, import('types').Product>} products
 * @returns {{ type: REMOVE_COMPLETED_PRODUCTS_SESSION, payload: string[] }}
 *
 */
export const removeCompletedProductSession = products => ({
  type: REMOVE_COMPLETED_PRODUCTS_SESSION,
  payload: Object.keys(products),
});

export const UPDATE_NOTIFICATION_SESSION = 'UPDATE_NOTIFICATION_SESSION';
/**
 * Update notification Action
 *
 * @param {import('types').Notification} notification
 * @returns {{ type: UPDATE_NOTIFICATION_SESSION, payload: import('types').Notification}}
 */
export const updateNotificationSession = notification => ({
  type: UPDATE_NOTIFICATION_SESSION,
  payload: notification,
});

export const REMOVE_SESSION = 'REMOVE_SESSION';

/**
 * Remove Session Action
 *
 * @returns {{ type: REMOVE_SESSION }}
 */
export const removeSession = () => ({
  type: REMOVE_SESSION,
});
