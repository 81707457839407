import { useSession, useCheckSession } from 'components/Session';

const hasAccess = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasSession = useCheckSession();

  if (hasSession) {
    const {
      brand: { standardCredits, lastPurchasedCredits },
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useSession();
    const hasBoughtCredits = standardCredits > 0 || lastPurchasedCredits > 0;
    if (hasBoughtCredits) {
      return true;
    }
    return false;
  }

  return true;
};

export default hasAccess;
