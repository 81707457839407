import React from 'react';
import { ReactComponent as ImageIcon } from 'images/components/Products/PostRequirements/ImageType.svg';
import { ReactComponent as VideoIcon } from 'images/components/Products/PostRequirements/VideoType.svg';
import { ReactComponent as InstagramStoryIcon } from 'images/components/Products/PostRequirements/InstagramStory.svg';
import { CAMPAIGN_STYLE_CATEGORIES, STYLE_CATEGORIES } from 'constants/campaigns';

const POST_TYPE_LABEL = {
  [STYLE_CATEGORIES.image]: 'Image',
  [STYLE_CATEGORIES.video]: 'Video',
};

const STYLE_TYPE_LABEL = {
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_PHOTOGRAPHY]: 'Product Photography',
  [CAMPAIGN_STYLE_CATEGORIES.PORTRAIT_STYLE_PHOTOGRAPHY]: 'Portrait Style Photography',
  [CAMPAIGN_STYLE_CATEGORIES.LIFE_STYLE_OR_ACTION_PHOTOGRAPHY]: 'Lifestyle / Product in Action',
  [CAMPAIGN_STYLE_CATEGORIES.ACTION_OR_PRODUCT_IN_USE_VIDEO]: 'Action / Product in Use Video',
  [CAMPAIGN_STYLE_CATEGORIES.UNBOXING_EXPLANATION_VIDEO]: 'Unboxing Explanation Video',
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO]:
    'Product Review / Testimonial Video',
};
/**
 * @param {import('components/Session/Context').Product} product
 * @returns
 */
const requiredPost = product => {
  const { postTypes = [], styleCategory = [], isStoryRequired } = product;

  return (
    <div>
      <div className="side-sub-header-text" style={{ marginTop: '20px' }}>
        Posts / Content
      </div>
      {postTypes.map((val, index) => {
        let postType = 'Image';
        if (val === 'video') {
          postType = 'Video';
        }
        return (
          <div style={{ marginTop: '16px' }}>
            <div className="side-text-description post-content">
              {val === STYLE_CATEGORIES.image ? (
                <ImageIcon style={{ width: '20px', height: '20px', marginRight: '13px' }} />
              ) : (
                <VideoIcon style={{ width: '20px', height: '16.06px', marginRight: '13px' }} />
              )}
              <div>
                1 {POST_TYPE_LABEL[val]} Post
                <div className="caption">
                  {STYLE_TYPE_LABEL[styleCategory[index]] || `${postType} Post`}
                </div>{' '}
              </div>
            </div>
          </div>
        );
      })}

      {isStoryRequired && (
        <div style={{ marginTop: '21px' }}>
          <div
            className="side-text-description post-content"
            style={{ textTransform: 'capitalize' }}
          >
            <InstagramStoryIcon style={{ width: '20px', height: '15.09px', marginRight: '13px' }} />
            1 Instagram Story
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * @typedef {{ product: import('components/Session/Context').Product }} PostRequirementsProps
 * @type {React.FC<PostRequirementsProps>}
 */
const PostRequirements = ({ product }) => {
  const { hashtags = {}, brandtags = {} } = product;

  return (
    <div id="campaignPostRequirements">
      <div className="side-header-text">Post Requirements</div>
      {requiredPost(product)}

      {Object.values(hashtags).length ? (
        <>
          <div className="side-sub-header-text" style={{ marginTop: '35px' }}>
            REQUIRED HASHTAGS
          </div>
          <div className="side-text-description">
            {Object.values(hashtags).map(tag => (
              <span className="side-text-description" key={tag}>
                <span className="mr-1">#{tag}</span>
              </span>
            ))}
          </div>
        </>
      ) : null}

      {Object.values(brandtags).length ? (
        <>
          <div className="side-sub-header-text" style={{ marginTop: '18px' }}>
            REQUIRED BRANDTAGS
          </div>
          <div className="side-text-description">
            {Object.values(brandtags).map(tag => (
              <span className="side-text-description" key={tag}>
                <span className="mr-1">@{tag}</span>
              </span>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PostRequirements;
