import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCheckSession, useSession } from 'components/Session';

const CONTENT_ADMIN_PAGES = ['/admin', '/adminv2', '/adminv2/contents'];

const AdminRoute = ({ component: Component, layout: Layout, authUser, ...rest }) => {
  const hasSession = useCheckSession();
  useEffect(() => {}, [hasSession]);

  if (!hasSession) {
    return <Redirect to="/login" />;
  }

  const {
    brand: { isAdmin, isContentAdmin },
  } = useSession();
  const { pathname } = rest.location;
  const isContentAdminPage = CONTENT_ADMIN_PAGES.includes(pathname);

  if (!isAdmin && !(isContentAdmin && isContentAdminPage)) {
    return <Redirect to="/" />;
  }

  const renderCompoent = props => {
    if (Layout) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={renderCompoent} />;
};

export default AdminRoute;
