import { useContext } from 'react';
import Context from './Context';

/**
 * Hook for Session
 * Raises an error if there is no session found
 *
 * @returns {import('stores/reducers/session').SessionReducer}
 * @throws {Error}
 */
const useSession = (throwError = true) => {
  const session = useContext(Context);

  if (!session && throwError) {
    throw new Error('Session Not found');
  }

  return session;
};

export default useSession;
