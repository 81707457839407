// NOTE: This functionality has been moved to retool
import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Table, Button, message, Icon } from 'antd';
import moment from 'moment';
import { adminApproveBrand, adminRejectBrand } from 'lib/Firebase/callables/admin';
import { SignupSource } from 'constants/signupSources';
import { ReactComponent as DownloadIcon } from 'images/download.svg';
import {
  businessForUpdateSubs,
  businessForUpdateListener,
} from '../../../listeners/businessesForUpdate';
import { firebase } from '../../../lib/Firebase';
import Container from '../../../components/Common/Container';
import ApprovalModal from './ApprovalModal';

const columns = [
  {
    key: 'fullName',
    dataIndex: 'fullName',
    title: 'Name',
  },
  {
    key: 'email',
    title: 'Email',
    render: business => {
      return business && business.email;
    },
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Company Name',
  },
  {
    dataIndex: 'registrationDate',
    key: 'registrationDate',
    title: 'Registration Date',
    render: registrationDate =>
      registrationDate ? moment.unix(registrationDate).format('MMMM Do YYYY, h:mm:ss a') : null,
    sorter: () => {},
  },
  {
    key: 'firstCampaign',
    title: 'First Campaign',
    dataIndex: 'firstCampaign',
    render: firstCampaign => (firstCampaign ? <span>&#10003;</span> : null),
  },
  {
    key: 'approvalDate',
    dataIndex: 'approvalDate',
    title: 'Approval Date',
    render: approvalDate =>
      approvalDate ? moment.unix(approvalDate).format('MMMM Do YYYY, h:mm:ss a') : null,
    sorter: () => {},
  },
  {
    key: 'firstCredit',
    dataIndex: 'firstCredit',
    title: 'First Credit',
    sorter: (a, b) => a.firstCredit - b.firstCredit,
    render: firstCredit => (firstCredit ? <span>&#10003;</span> : <span>&#10007;</span>),
  },
  {
    key: 'source',
    title: 'Plan',
    dataIndex: 'source',
    render: source => {
      if (source === SignupSource.FreeTier) {
        return 'Starter';
      }

      if (source === SignupSource.PaidTier) {
        return 'Pro';
      }

      return null;
    },
  },
  {
    title: 'Action',
    dataIndex: 'actions',
    align: 'center',
    key: 'actions',
    className: 'actions',
    sorter: (a, b) => a.firstCampaign - b.firstCampaign,
    render: ({ firstCampaign, status, onApprove, onReject }) => {
      const displayRecord = () => {
        let display = null;
        if (status === 'approved') {
          display = 'Approved';
        } else if (status === 'rejected') {
          display = 'Rejected';
        } else if (firstCampaign) {
          display = (
            <div style={{ width: '200px', marginTop: '3px', marginBottom: '3px' }}>
              <Button
                size="small"
                type="primary"
                onClick={onApprove}
                style={{ marginRight: '3px' }}
              >
                Approve
              </Button>
              <Button size="small" onClick={onReject}>
                Reject
              </Button>
            </div>
          );
        }
        return display;
      };
      return <>{displayRecord()}</>;
    },
  },
];
const initialPagination = { page: 1, limit: 250 };
const modalInitial = { show: false, businessId: null, index: null, email: null };

/**
 * Admin V2 Business Approval Page
 *
 * @type {React.FC}
 */
const BusinessApprovalPage = () => {
  const [pagination, setPagination] = useState(initialPagination);
  const [approveModal, setApproveModal] = useState(modalInitial);
  const [rejectModal, setRejectModal] = useState(modalInitial);
  const [downloading, setDownloading] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const [brandsList, setBrandsList] = useState([]);
  const [lastBrandDoc, setLastBrandDoc] = useState(null);

  const [source, setSource] = useState([]);

  async function retrieveBusinesses(brandDoc = null) {
    const listener = await businessForUpdateListener(
      brandDoc,
      pagination.limit,
      (brands, newLastBrandDoc) => {
        setBrandsList(prevState => {
          return [...prevState, ...brands];
        });

        setLastBrandDoc(newLastBrandDoc);
      }
    );

    businessForUpdateSubs.addListener(listener);
  }

  useEffect(() => {
    async function requestBusiness() {
      retrieveBusinesses();
    }

    requestBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateState = (status, isApprove) => {
    const updateBusinessList = [...brandsList];

    if (isApprove) {
      const { index } = approveModal;
      updateBusinessList[index].status = status;
      setApproveModal(modalInitial);
    } else {
      const { index } = rejectModal;
      updateBusinessList[index].status = status;
      setRejectModal(modalInitial);
    }
    setBrandsList(updateBusinessList);
  };

  async function updateBrandStatus(status, field) {
    const brandUID = status === 'approved' ? approveModal.businessId : rejectModal.businessId;
    await firebase.firestore
      .collection('brands')
      .doc(brandUID)
      .update({
        status,
        [field]: moment().unix(),
      });

    await firebase
      .brand(brandUID)
      .child('details')
      .update({ status, [field]: moment().unix() });
  }

  async function handleApprove(values) {
    try {
      const productSnapshot = await firebase.firestore
        .collection('products')
        .where('brandUID', '==', approveModal.businessId)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get();

      const firstProduct = productSnapshot.docs[0];

      // TODO: Standardize how brand images get uploaded
      const [brandImageUrl, productImageUrl] = await Promise.all([
        values.brandImage
          ? firebase.uploadFile({ file: values.brandImage, filename: approveModal.businessId })
          : undefined,
        values.productImage
          ? firebase.uploadFile({ file: values.productImage, filename: firstProduct.id })
          : undefined,
      ]);

      await adminApproveBrand(approveModal.businessId, {
        storeImage: brandImageUrl,
        description: values.description,
        productImage: productImageUrl,
      });

      updateState('approved', true);

      message.success('Successfully Approved!');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error', e);
      message.error('Something went wrong');
    }
  }

  async function handleReject() {
    setRejecting(true);
    try {
      await adminRejectBrand(rejectModal.businessId);
      updateBrandStatus('rejected', 'rejectedAt');
      updateState('rejected');

      message.success('Successfully Rejected!');
    } catch (e) {
      message.error(e.message);
    }
    setRejecting(false);
  }

  async function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });

    await retrieveBusinesses(lastBrandDoc);
  }

  const showLimit = pagination.limit * pagination.page;

  useEffect(() => {
    setSource(
      brandsList
        .filter(data => Object.keys(data).length > 0)
        .map((data, index) => {
          return {
            key: data.uid,
            fullName: data.fullName,
            email: data.email,
            name: data.name,
            registrationDate: data.createdAt ? data.createdAt : 0,
            firstCampaign: data.firstCampaign,
            approvalDate: data.approvedAt ? data.approvedAt : 0,
            firstCredit: !!data.lastPurchaseDate,
            source: data.source,
            actions: {
              status: data.status,
              firstCampaign: data.firstCampaign,
              onApprove: () =>
                setApproveModal({ show: true, businessId: data.uid, index, email: data.email }),
              onReject: () =>
                setRejectModal({ show: true, businessId: data.uid, index, email: data.email }),
            },
          };
        })
        .slice(0, showLimit)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandsList]);

  const sortData = (key, order = 'ascend') => {
    return function innerSort(a, b) {
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'descend' ? comparison * -1 : comparison;
    };
  };

  const handleChange = (tablePagination, filters, sorter) => {
    if (sorter.order) {
      setSource(source.sort(sortData(sorter.columnKey, sorter.order)));
    } else {
      setSource(
        brandsList
          .filter(data => Object.keys(data).length > 0)
          .map((data, index) => {
            return {
              key: data.uid,
              fullName: data.fullName,
              email: data.email,
              name: data.name,
              registrationDate: data.createdAt ? data.createdAt : 0,
              firstCampaign: data.firstCampaign,
              approvalDate: data.approvedAt ? data.approvedAt : 0,
              firstCredit: data.firstCredit,
              source: data.source,
              actions: {
                status: data.status,
                firstCampaign: data.firstCampaign,
                onApprove: () =>
                  setApproveModal({ show: true, businessId: data.uid, index, email: data.email }),
                onReject: () =>
                  setRejectModal({ show: true, businessId: data.uid, index, email: data.email }),
              },
            };
          })
          .slice(0, showLimit)
      );
    }
  };

  const downloadCSV = () => {
    setDownloading(true);
    businessForUpdateListener(null, null, brandsList => {
      let csvContent = `Name,Email,Company Name,Registration Date,First Campaign,Approval Date,First Credit,Plan,Action\r\n`;

      brandsList.forEach(doc => {
        const {
          fullName,
          email,
          name,
          createdAt,
          firstCampaign,
          approvedAt,
          lastPurchaseDate,
          source: productPlan,
          status: productStatus,
        } = doc;
        const firstCredit = !!lastPurchaseDate;
        let sourceText = '';
        if (productPlan === SignupSource.FreeTier) {
          sourceText = 'Starter';
        }

        if (productPlan === SignupSource.PaidTier) {
          sourceText = 'Pro';
        }

        const row = [
          `"${fullName || ''}"`,
          `"${email || ''}"`,
          `"${name || ''}"`,
          `"${createdAt ? moment.unix(createdAt).format('YY/MM/DD') : ''}"`,
          `"${firstCampaign ? '✓' : ''}"`,
          `"${approvedAt ? moment.unix(approvedAt).format('YY/MM/DD') : ''}"`,
          `"${firstCredit ? '✓' : ''}"`,
          `"${sourceText}"`,
          `"${productStatus || ''}"`,
        ].join(',');

        csvContent += `${row}\r\n`;
      });

      const csv = new Blob([csvContent], { type: 'text/CSV' });
      const url = URL.createObjectURL(csv);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'businesses.csv');
      link.click();

      setDownloading(false);
    });
  };

  return (
    <Container width={1300} id="admin-business-approval">
      <Row justify="end" style={{ margin: '3px 0' }}>
        <Col style={{ textAlign: 'end' }}>
          <Button block className="download-csv" loading={downloading} onClick={downloadCSV}>
            {downloading ? null : <Icon component={DownloadIcon} />}
            <span>
              Download
              <br /> Business CSV
            </span>
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={source}
        columns={columns}
        pagination={false}
        onChange={handleChange}
        showHeader
      />
      <ApprovalModal
        show={approveModal.show}
        onApprove={handleApprove}
        onCancel={() => setApproveModal({ ...approveModal, show: false })}
      />

      <Modal
        visible={rejectModal.show}
        closable={false}
        footer={false}
        className="admin-approve-modal"
      >
        <p className="text-center">Are you sure you want to reject this Business?</p>
        <Row type="flex" justify="center">
          <Col className="button-options">
            <Button loading={rejecting} type="primary" block onClick={() => handleReject()}>
              Confirm
            </Button>
            <Button block onClick={() => setRejectModal({ ...rejectModal, show: false })}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
      <div className="text-center loadmore">
        {brandsList.length === pagination.limit * pagination.page && (
          <Button className="load-more" onClick={() => loadMore()}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default BusinessApprovalPage;
