import { combineReducers } from 'redux';
import products from './products';
import posts from './posts';
import partnerships from './partnerships';
import influencers from './influencers';
import reviews from './reviews';
import influencerReviews from './influencerReviews';
import messages from './messages';
import postsStatistics from './postsStatistics';
import brands from './brands';
import influencersPartnerships from './influencersPartnerships';
import purchasedHistories from './purchasedHistories';
import creatorContents from './creatorContents';

/**
 * Entities Reducer
 *
 * @typedef {ReturnType<entities>} EntitiesReducer
 */

const entities = combineReducers({
  products,
  posts,
  partnerships,
  influencers,
  reviews,
  influencerReviews,
  messages,
  postsStatistics,
  brands,
  influencersPartnerships,
  purchasedHistories,
  creatorContents,
});

export default entities;
