import React from 'react';
import numeral from 'numeral';
import { Row, Icon, Col } from 'antd';
import { ReactComponent as NegativeIcon } from '../../../images/negative.svg';
import { ReactComponent as PositiveIcon } from '../../../images/positive.svg';
import { ReactComponent as NoChangeIcon } from '../../../images/no-change.svg';
import formatSocialData from '../../Helpers/numeral';

const addIndicator = (val = 0, [a, b, c]) => {
  const num = parseFloat(val * 100).toFixed(2);
  if (num < 0) {
    return b;
  }
  if (num > 0) {
    return c;
  }

  return a;
};

const Stats = ({ followedByCount, avgCommentCount, avgLikeCount, stats = {} }) => {
  const {
    avgCommentCount: lastAvgCommentCount = avgCommentCount,
    avgLikeCount: lastAvgLikeCount = avgLikeCount,
  } = stats;

  const commentPercentDiff = (avgCommentCount - lastAvgCommentCount) / avgCommentCount;
  const likesPercentDiff = (avgLikeCount - lastAvgLikeCount) / avgLikeCount;

  return (
    <div className="influencer-stats">
      <div className="influencer-stats-followers">
        <label className="influencer-stats-label">FOLLOWERS</label>
        <div className="influencer-stats-value">{formatSocialData(followedByCount)}</div>
      </div>
      <Row className="influencer-stats-other-details">
        <Col span={12} className="influencer-stats-likes">
          <label className="influencer-stats-label">LIKES / POST</label>
          <div
            className={`influencer-stats-value ${addIndicator(likesPercentDiff, [
              '',
              'down',
              'up',
            ])}`}
          >
            <Icon
              className="influencer-stats-value-indicator-icon"
              component={addIndicator(likesPercentDiff, [NoChangeIcon, NegativeIcon, PositiveIcon])}
            />
            <span className="influencer-stats-value-count">
              {formatSocialData(avgLikeCount)}
              <span className="influencer-stats-value-indicator-percent">
                ({numeral(likesPercentDiff).format('0.00%')})
              </span>
            </span>
          </div>
        </Col>
        <Col span={12} className="influencer-stats-comments">
          <label className="influencer-stats-label">COMMENTS / POST</label>
          <div
            className={`influencer-stats-value ${addIndicator(commentPercentDiff, [
              '',
              'down',
              'up',
            ])}`}
          >
            <Icon
              className="influencer-stats-value-indicator-icon"
              component={addIndicator(commentPercentDiff, [
                NoChangeIcon,
                NegativeIcon,
                PositiveIcon,
              ])}
            />
            {formatSocialData(avgCommentCount)}
            <span className="influencer-stats-value-indicator-percent">
              ({numeral(commentPercentDiff).format('0.00%')})
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Stats;
