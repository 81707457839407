import React, { useState } from 'react';
import moment from 'moment';
import { Icon, Button, Dropdown, Menu, message, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { adminBumpActiveCampaign, adminLoginAsBrand } from 'lib/Firebase/callables/admin';
import { useCookies } from 'react-cookie';
import { firebase } from '../../../lib/Firebase';

const Actions = ({ brandUID, productUID, productName }) => {
  const [loading, setLoading] = useState(false);
  const [, setCookies] = useCookies('returnToken');
  const history = useHistory();

  const generateDeepLink = async (productId, productName) =>
    fetch(`${process.env.REACT_APP_BRANCH_ENDPOINT}/url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        branch_key: process.env.REACT_APP_BRANCH_KEY,
        campaign: productName,
        data: {
          $desktop_url: `${window.location.protocol}//${window.location.host}/campaigns/${productId}`,
          $ios_url: `verb://campaigns/${productId}`,
          uid: productId,
          type: 'campaign',
        },
      }),
    });

  async function copyCampaignLink(productId, productName) {
    const text = generateDeepLink(productId, productName);

    if (navigator.userAgent.indexOf('Safari') > navigator.userAgent.indexOf('Chrome')) {
      const clipboardText = new window.ClipboardItem({
        'text/plain': text
          .then(res => res.json())
          .then(text => new Blob([text.url], { type: 'text/plain' })),
      });
      navigator.clipboard.write([clipboardText]);
    } else {
      const clipboardText = await text.then(async res => res.json()).then(text => text.url);
      navigator.clipboard.writeText(clipboardText);
    }
  }

  async function handleClick({ key }) {
    setLoading(true);
    if (key === 'login') {
      try {
        const { token, returnToken } = await adminLoginAsBrand(brandUID);
        setCookies('returnToken', returnToken, {
          path: '/',
          expires: new Date(
            moment()
              .add(1, 'hour')
              .unix() * 1000
          ),
        });
        await firebase.auth.signInWithCustomToken(token);
        history.push('/');
      } catch (e) {
        message.error(e.message);
      }
    } else if (key === 'move-listing-top') {
      await adminBumpActiveCampaign(productUID);

      notification.success({
        message: 'Success',
        description: 'Campaign successfully bumped.',
        duration: 3,
      });

      setLoading(false);
    } else if (key === 'copy-campaign-link') {
      await copyCampaignLink(productUID, productName);

      notification.success({
        message: 'Success',
        description: "The campaign's link has been copied to your clipboard",
      });

      setLoading(false);
    }
  }

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="login">Login to campaign</Menu.Item>
      <Menu.Item key="scrape">Scrape posts</Menu.Item>
      <Menu.Item key="move-listing-top">Move listing to the top</Menu.Item>
      <Menu.Item key="copy-campaign-link">Copy campaign link</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Button type="primary" loading={loading}>
        Actions <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

export default Actions;
