import { Button, Icon } from 'antd';
import React from 'react';
import { ReactComponent as ApprovalCheck } from 'images/campaign/ApprovalCheck.svg';
import * as MediaViewer from 'components/Common/MediaViewer';

/**
 * Creator Content Approval Content Card Component
 *
 * @typedef {{ type: string, url: string, isApproved: boolean,  onClick: () => void }} ApprovalContentCardProps
 * @type {React.FC<ApprovalContentCardProps>}
 */
const ApprovalContentCard = ({ type, url, isApproved, onClick }) => {
  return (
    <div className={`creator-content-content-card ${isApproved && 'approved'}`}>
      <MediaViewer.Content type={type} src={url} />
      <div className="card-action">
        <Button onClick={onClick}>
          <Icon component={ApprovalCheck} />
          {isApproved ? 'Approved' : 'Select'}
        </Button>
      </div>
    </div>
  );
};

export default ApprovalContentCard;
