import { addBrands } from './entities/brands';
import { addPartnerships } from './entities/partnerships';
import { writePurchasedHistories } from './entities/purchasedHistories';

export const SET_INITIAL_BRAND_CONTEXT = 'SET_INITIAL_BRAND_CONTEXT';

/**
 * Set Initial Brand Context Action
 *
 * @typedef {{ type: SET_INITIAL_BRAND_CONTEXT, payload: { brandUID: string, approvedPartnershipUIDs: string[], purchaseHistoryUIDs: string[] }}} SetInitialBrandContextAction
 * @param {Record<string, import("types").Brand>} brands
 * @param {Record<string, import("types").Partnership>} approvedPartnership
 * @param {Record<string, import("types").PurchasedHistory} purchaseHistories
 */
export const setInitialBrandContext = (brandUID, brand, approvedPartnership, purchasedHistories) =>
  /**
   *
   * @param {import("redux").Dispatch} dispatch
   */
  dispatch => {
    dispatch(addBrands({ [brandUID]: brand }));

    dispatch(addPartnerships(approvedPartnership));

    dispatch(writePurchasedHistories(purchasedHistories));

    dispatch({
      type: SET_INITIAL_BRAND_CONTEXT,
      payload: {
        brandUID,
        approvedPartnershipUIDs: Object.keys(approvedPartnership),
        purchasedHistoryUIDs: Object.keys(purchasedHistories),
      },
    });
  };

/**
 * @typedef {SetInitialBrandContextAction} BrandContextsActions
 */
