import React, { useState } from 'react';
import { Button, Modal, Radio, Col, Row, Spin, Alert } from 'antd';
import { selectors } from 'stores';
import { useSelector } from 'react-redux';
import hasPayOutTiers from 'lib/Common/HasPayOutTiers';
import { adminUpdateInfluencerTier } from 'lib/Firebase/callables/admin';

const EditLevelsButton = ({ influencerUID, children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const influencer = useSelector(selectors.getInfluencer(influencerUID));
  let credits;
  if (!hasPayOutTiers(influencer.payOutTiers)) {
    credits = influencer.isVIP ? { social: 2, content: 2 } : { social: 1, content: 1 };
  }
  const [creatorLevels, setCreatorLevels] = useState({
    social: !hasPayOutTiers(influencer.payOutTiers)
      ? credits.social
      : influencer.payOutTiers.social,
    content: !hasPayOutTiers(influencer.payOutTiers)
      ? credits.content
      : influencer.payOutTiers.content,
  });

  function onButtonChange(e) {
    const propertyName = e.target.name;
    const selectionValue = e.target.value;
    setCreatorLevels({ ...creatorLevels, [propertyName]: selectionValue });
  }

  function showModal() {
    setIsModalVisible(true);
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  async function handleLevelChange() {
    setIsLoading(true);
    setErrMessage('');
    setMessage('');
    try {
      await adminUpdateInfluencerTier(influencerUID, creatorLevels);
      setIsLoading(false);
      setMessage('Success on submission!');
    } catch (e) {
      console.log('error', e.message);
      setErrMessage('Error!');
      setIsLoading(false);
    }
  }

  return (
    <>
      <Button onClick={showModal} type="default" className="trend-btn medium-btn" block>
        {children || 'Edit Levels'}
      </Button>
      <Modal
        title="Edit Levels"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type="primary" danger disabled={isLoading} onClick={handleLevelChange}>
            Submit Changes
          </Button>,
        ]}
      >
        {isLoading ? (
          <Spin />
        ) : (
          <Row style={{ width: '400px' }}>
            {message !== '' && errMessage === '' && (
              <Alert message={message} type="success" showIcon />
            )}
            {message === '' && errMessage !== '' && (
              <Alert message={message} type="error" showIcon />
            )}
            <Col span={10}>
              <b>Content</b>
              <Radio.Group value={creatorLevels.content} name="content" onChange={onButtonChange}>
                <Radio value={0}>Not Approved</Radio>
                <Radio value={1}>Level 1 (1 credit)</Radio>
                <Radio value={2}>Level 2 (2 credit)</Radio>
                <Radio value={3}>Level 3 (3 credit)</Radio>
                <Radio value={4}>Level 4 (4 credit)</Radio>
                <Radio value={5}>Level 5 (5 credit)</Radio>
              </Radio.Group>
            </Col>
            <Col span={10}>
              <b>Social</b>
              <Radio.Group value={creatorLevels.social} name="social" onChange={onButtonChange}>
                <Radio value={0}>Not Approved</Radio>
                <Radio value={1}>Level 1 (1 credit)</Radio>
                <Radio value={2}>Level 2 (2 credit)</Radio>
                <Radio value={3}>Level 3 (3 credit)</Radio>
                <Radio value={4}>Level 4 (4 credit)</Radio>
                <Radio value={5}>Level 5 (5 credit)</Radio>
              </Radio.Group>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default EditLevelsButton;
