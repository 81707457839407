import React, { useState } from 'react';
import { Button, PageHeader, Input } from 'antd';
import { BrandTable } from 'components/Brands';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Promise from 'bluebird';
import { selectors } from 'stores';
import { firebase } from '../../../lib/Firebase';

let searchTimeout;
/**
 * Admin Brand List Page
 *
 * @typedef {{}} ListProps
 * @type {React.FC<ListProps>}
 */
const List = () => {
  const brandUIDs = useSelector(selectors.getAdminBrands());
  const [searchName, setSearchName] = useState('');
  const brands = useSelector(selectors.getBrands());
  const history = useHistory();

  function handleSearchChange(e) {
    const val = e.target.value;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      setSearchName(val);
    }, 500);
  }

  let list = [...brandUIDs];

  if (searchName.length > 0) {
    list = list.filter(
      id => brands[id].name && brands[id].name.toLowerCase().includes(searchName.toLowerCase())
    );
  }

  list = _.sortBy(list, [id => (brands[id].name ? brands[id].name.toLowerCase() : '')]);

  const cardDateFormat = dateUnix => {
    if (dateUnix) return moment(dateUnix * 1000).format('MM/DD/YY');
    return 'N/A';
  };

  const handleExport = async () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent +=
      'Name, Email, Auth Email, Total Campaign, Standard Credits, Last login date, Total number of logins, Total number of logins this month, Total credits purchased\r\n';
    let csvBody = '';
    await Promise.map(list, async uid => {
      const brand = brands[uid];
      const { overallCampaigns = 0 } = brand;
      const {
        name,
        standardCredits = 0,
        authEmail,
        email,
        lifetimePurchasedCredits,
        lastLoginDate,
      } = brand;
      const lastLoginDateTmp = lastLoginDate || null;
      let totalNumberOfLogins = 0;
      let totalNumberOfLoginsThisMonth = 0;
      const totalCreditsPurchased = lifetimePurchasedCredits || 0;
      if (authEmail) {
        // get logs
        const logs = await firebase.firestore
          .collection('user_logs')
          .where('email', '==', authEmail)
          .orderBy('loginAt', 'desc')
          .get();

        if (!logs.empty) {
          totalNumberOfLogins = logs.docs.length;
          logs.docs.map(log => {
            const logTmp = log.data();
            if (
              logTmp.loginAt >=
                moment()
                  .startOf('month')
                  .unix() &&
              logTmp.loginAt <=
                moment()
                  .endOf('month')
                  .unix()
            ) {
              totalNumberOfLoginsThisMonth += 1;
            }
            return log;
          });
        }
      } else if (email) {
        // get logs
        const logs = await firebase.firestore
          .collection('user_logs')
          .where('email', '==', email)
          .orderBy('loginAt', 'desc')
          .get();
        if (!logs.empty) {
          totalNumberOfLogins = logs.docs.length;
          logs.docs.map(log => {
            const logTmp = log.data();
            if (
              logTmp.loginAt >=
                moment()
                  .startOf('month')
                  .unix() &&
              logTmp.loginAt <=
                moment()
                  .endOf('month')
                  .unix()
            ) {
              totalNumberOfLoginsThisMonth += 1;
            }
            return log;
          });
        }
      }

      const row = [
        `"${name}"`,
        email,
        authEmail,
        overallCampaigns,
        standardCredits,
        lastLoginDateTmp ? cardDateFormat(lastLoginDateTmp) : null,
        totalNumberOfLogins,
        totalNumberOfLoginsThisMonth,
        totalCreditsPurchased,
      ].join(',');
      csvBody += `${row}\r\n`;
    });

    const encodedUri = encodeURIComponent(csvBody);
    const link = document.createElement('a');
    link.setAttribute('href', csvContent + encodedUri);
    link.setAttribute('download', 'brands.csv');
    document.body.appendChild(link);

    link.click();
    link.remove();
  };

  return (
    <>
      <PageHeader
        title="Brands"
        extra={[
          <Button key={0} onClick={handleExport}>
            Export CSV
          </Button>,
          <Input
            placeholder="Search Brand"
            key={1}
            style={{ width: '375px' }}
            onChange={handleSearchChange}
          />,
        ]}
        onBack={() => history.push('/admin')}
      />

      <BrandTable ids={list} />
    </>
  );
};

export default List;
