import React, { useEffect, useState } from 'react';
import { Spin, Icon } from 'antd';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { firebase } from '../../lib/Firebase';

const ImageUpload = ({ children, onChange, value }) => {
  const [image, setImage] = useState(value);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setImage(value);
  }, [value]);

  const handleFileUpload = e => {
    setLoading(true);
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;
    const storageRef = ref(firebase.storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      error => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          setImage(downloadURL);
          onChange(downloadURL);
          setLoading(false);
        });
      }
    );
  };

  return (
    <div className="product-uploader">
      {loading && <p className="white">{loading}</p>}
      <Spin tip={`${progress}%`} spinning={loading} style={{ marginTop: '140px' }} />
      {image && !loading && (
        <div>
          <img src={image} className="product-image" alt={image} />
          <label className="ant-btn trend-btn edit-btn change-photo-label">
            <span>Change Photo</span>
            <input
              onChange={handleFileUpload}
              accept="image/*"
              type="file"
              style={{ visibility: 'hidden' }}
            />
          </label>
        </div>
      )}
      {!image && !loading && (
        <label className="product-label">
          {!image && <Icon type="plus" className="icon" />}
          <input
            onChange={handleFileUpload}
            accept="image/*"
            type="file"
            style={{ visibility: 'hidden' }}
          />
          {children}
        </label>
      )}
    </div>
  );
};

export default ImageUpload;
