import { ADD_POSTS, UPDATE_POSTS } from 'stores/actions/entities/posts';

/**
 * @typedef {Record<string, import('types').Post>} PostsReducer
 */
const initialState = {};

/**
 * @param {PostsReducer} state
 * @param {import("stores/actions/entities/posts").EntityPostActions} action
 * @returns
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POSTS:
    case UPDATE_POSTS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
