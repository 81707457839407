import { ADD_PARTNERSHIPS, UPDATE_PARTNERSHIPS } from 'stores/actions/entities/partnerships';

/**
 * @typedef {Record<string, import("types").Partnership>} PartnershipsReducer
 */
const initialState = {};
/**
 * Entity Partnership Reducer
 *
 * @param {PartnershipsReducer} state
 * @param {import('stores/actions/entities/partnerships').EntityPartnershipActions} action
 */
const partnerships = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTNERSHIPS:
    case UPDATE_PARTNERSHIPS: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default partnerships;
