export const REJECT_REASONS = [
  [
    `Account doesn't fit our brand`,
    `Unfortunately, we are looking for an account more tailored to our type of product. Stay updated on Trend as we would love to work with you in the future on a campaign better suited for your feed.`,
  ],
  [
    `General Rejection`,
    `Unfortunately, we decided to go another direction. Please stay updated on Trend as we would love to work with you in the future!`,
  ],
  [
    `Does not accept international applicants`,
    `Unfortunately, we are not currently accepting international accounts for this campaign. Please stay updated on Trend as we would love to work with you in the future!`,
  ],
  [
    `Will work together in the future`,
    `You've got a great account. We've currently filled our campaign for this month, but would love to work with you down the road. Please apply again in the future.`,
  ],
  [`Custom Message`, ``],
];
