import ExampleList from './ExampleList';
import Product from './Product';
import AdminProductCard from './AdminProductCard';

const Loading = {
  Product,
  ExampleList,
  AdminProductCard,
};

export default Loading;
