import { ADD_INFLUENCER_REVIEWS } from 'stores/actions/entities/influencerReviews';

/**
 * @param {InfluencerReviewsReducer} state
 * @param {string} influencerUID
 * @param {string[]} reviewUIDs
 * @returns {InfluencerReviewsReducer}
 */
const addInfluencerReviews = (state, influencerUID, reviewUIDs) => {
  const newState = { ...state };
  const currentReviews = newState[influencerUID] || [];
  newState[influencerUID] = [...new Set([...currentReviews, ...reviewUIDs])];
  return newState;
};
/**
 * @typedef {Record<string, string[]>} InfluencerReviewsReducer
 * @type {EntityInfluencersReducer}
 */
const initialState = {
  // [influencerUID]: reviewUID[]
};
/**
 *
 * @param {InfluencerReviewsReducer} state
 * @param {import('stores/actions/entities/influencerReviews').EntityInfluencerReviewsActions} action
 * @returns {InfluencerReviewsReducer}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INFLUENCER_REVIEWS: {
      const { influencerUID, reviewUIDs } = action.payload;
      return addInfluencerReviews(state, influencerUID, reviewUIDs);
    }

    default:
      return state;
  }
};

export default reducer;
