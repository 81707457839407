import { SET_ADMIN_BRANDS } from 'stores/actions/adminPages';

/**
 * Admin Pages Reducer
 *
 * @typedef {{ brandUIDs: string[] }} AdminPagesReducer
 * @param {AdminPagesReducer} state
 * @param {import("stores/actions/adminPages").AdminPagesActions} action
 */
const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ADMIN_BRANDS: {
      return { ...state, brandUIDs: action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
