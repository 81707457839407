import moment from 'moment';

export const calculateDateDifference = (startDate, endDate, unit) => {
  let timeConversion = 60 * 1000;
  if (unit === 'day') {
    timeConversion = timeConversion * 60 * 24;
  }
  return Math.round(Math.abs(endDate - startDate) / timeConversion);
};

// https://stackoverflow.com/a/19700358/9954196
export const msToTime = (
  /** Duration in seconds */
  duration
) => {
  if (duration < 0) {
    return '00:00:00';
  }
  let hours = Math.floor(duration / (60 * 60));
  let minutes = Math.floor((duration / 60) % 60);
  let seconds = Math.floor(duration % 60);

  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${hours}:${minutes}:${seconds}`; // + '.' + milliseconds`;
};

export const getCountdown = futureDate => {
  const currentTime = moment().unix();
  const approvalDiff = futureDate - currentTime;
  const timeToAutoApproval = msToTime(approvalDiff);
  return timeToAutoApproval;
};
