import axios from 'axios';
import { getAccessToken } from 'utils/cookies';
import { firebase } from 'lib/Firebase';
import ApiUrls from '../constants/apiUrls';
/**
 * Request Shipment Details API
 *
 * @param {string} carrierCode
 * @param {string} trackingNumber
 */
export const requestShipmentDetails = async (carrierCode, trackingNumber) => {
  // FIXME: This is broken in production
  // - Replace with API call to Trend API
  const token = await firebase.auth.currentUser.getIdToken(true);
  try {
    const response = await axios.get(ApiUrls.shipengine, {
      params: { carrierCode, trackingNumber },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = response;
    let actualDeliveryDate = data.actual_delivery_date;
    let shipDate = data.ship_date;

    if (data.status_code === 'DE') {
      actualDeliveryDate = data.events[0].occurred_at;
      shipDate = data.events[data.events.length - 1].occurred_at;
    }

    return {
      actualDeliveryDate,
      shipDate,
      estimatedDeliveryDate: data.estimated_delivery_date,
      events: data.events,
    };
  } catch (e) {
    console.log('Shipment details Error', e.message);
    return {};
  }
};

//
/**
 * Brand Create Shipment
 * @param {string} partnershipUID
 * @param {{ nonShippable: boolean, carrierCode: string, trackingNumber: string }} params
 */
export const postBrandCreateShipment = async (partnershipId, shipmentDetails) => {
  const shipmentDto = {
    partnershipId,
    ...shipmentDetails,
  };
  const url = `${process.env.REACT_APP_TREND_API_URL}/shipment/create`;
  return axios.post(url, shipmentDto, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

/**
 * Brand Update Shipment
 * @param {string} partnershipUID
 * @param {{ nonShippable: boolean, carrierCode: string, trackingNumber: string, productShipmentUID: string }} params
 */
export const patchBrandUpdateShipment = async (
  partnershipId,
  { productShipmentUID: shipmentId, ...shipmentDetails }
) => {
  const shipmentDto = {
    partnershipId,
    shipmentId,
    ...shipmentDetails,
  };
  const url = `${process.env.REACT_APP_TREND_API_URL}/shipment/update`;
  return axios.patch(url, shipmentDto, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};
