import { ADD_INFLUENCERS_PARTNERSHIPS } from 'stores/actions/entities/influencersPartnerships';

const add = (state, influencerUID, partnershipUID) => {
  const influencerPartnershipUIDs = state[influencerUID] || [];

  return {
    ...state,
    [influencerUID]: [...new Set([...influencerPartnershipUIDs, ...partnershipUID])],
  };
};
/**
 * @typedef {string} influencerUID
 * @typedef {string[]} partnershipUIDs
 * @typedef {Record<influencerUID, partnershipUIDs>} InfluencersPartnershipsReducer
 */
const initialState = {};
/**
 * Influencers Partnerships Reducer
 *
 * @param {InfluencersPartnershipsReducer} state
 * @param {import("stores/actions/entities/influencersPartnerships").InfluencersPartnershipsActions} action
 * @return {InfluencersPartnershipsReducer}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INFLUENCERS_PARTNERSHIPS: {
      const { influencerUID, partnershipUIDs } = action.payload;
      return add(state, influencerUID, partnershipUIDs);
    }
    default:
      return state;
  }
};

export default reducer;
