import {
  patchAdminApproveBrand,
  patchAdminApproveCreator,
  patchAdminBumpCampaign,
  patchAdminRejectBrand,
  patchAdminRejectCreator,
} from 'api/admin';
import { firebaseService } from '../context';

const firebase = firebaseService;
/**
 * Admin Login As Brand Callable
 *
 * @param {import('types').Brand} params
 */
export const adminLoginAsBrand = async params => {
  try {
    const func = firebase.callable('admin');

    const response = await func({ action: 'loginAsBrand', data: params });

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Admin Send Influencer Email Callable
 *
 * @param {string} influencerUID
 * @param {string} message
 */
export const adminSendInfluencerEmail = async (influencerUID, message) => {
  try {
    const func = firebase.callable('admin');

    const response = await func({
      action: 'sendInfluencerEmail',
      data: { influencerUID, message },
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Admin Suspend Influencer Callable
 *
 * @param {string} productUID
 * @param {Record<string, any>} params
 */
export const adminSuspendInfluencer = async influencerUID => {
  try {
    const func = firebase.callable('admin');
    await func({ action: 'suspendInfluencer', data: influencerUID });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Admin Suspend Influencer Callable
 *
 * @param {string} productUID
 * @param {Record<string, any>} params
 */
export const adminUnuspendInfluencer = async influencerUID => {
  try {
    const func = firebase.callable('admin');
    await func({ action: 'unsuspendInfluencer', data: influencerUID });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const adminBumpActiveCampaign = async productUID => {
  try {
    await patchAdminBumpCampaign(productUID);
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Admin Accept Brand Review Callable
 *
 * @param {string} productUID
 * @param {Record<string, any>} params
 */
export const adminAcceptBrandReview = async (brandUID, reviewUID) => {
  try {
    const func = firebase.callable('admin');
    await func({ action: 'acceptBrandReview', data: { brandUID, reviewUID } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Admin Approve Referral Callable
 *
 * @param {string} productUID
 * @param {Record<string, any>} params
 */
export const adminApproveReferral = async (brandUID, referralUID) => {
  try {
    const func = firebase.callable('admin');
    await func({ action: 'approveBrandReferral', data: { brandUID, referralUID } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Admin Approve Brand Callable
 *
 * @param {string} brandUID,
 */
export const adminApproveBrand = async (brandUID, params) => {
  try {
    await patchAdminApproveBrand({ brandId: brandUID, ...params });

    const brandDocument = (await firebase.firestore
      .collection('brands')
      .doc(brandUID)
      .get()).data();

    const { utm = {}, fullName, email, createdAt } = brandDocument;
    const cleanFullName = fullName ? fullName.trim().replace(/^[0-9\s]*|[+*\r\n]/g, '') : '';

    const firstName = brandDocument.firstName
      ? brandDocument.firstName
      : cleanFullName
          .split(' ')
          .slice(0, -1)
          .join(' ');

    const lastName = brandDocument.lastName
      ? brandDocument.lastName
      : cleanFullName
          .split(' ')
          .slice(-1)
          .join(' ');

    window.dataLayer = window.dataLayer || [];
    // UTM params issues
    const { fbc, fbp, gclid } = utm;
    console.log({ fbc, fbp, gclid, ...utm });
    const approvalPayload = {
      event: 'AdminApprovesBrand',
      email,
      firstName,
      lastName,
      external_id: brandUID,
      registration_date: createdAt,
      fbc,
      fbp,
      gclid,
    };
    window.dataLayer.push(approvalPayload);

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const adminRejectBrand = async brandId => {
  try {
    await patchAdminRejectBrand(brandId);
    return true;
  } catch (e) {
    throw new Error(e.message);
  }
};

/**
 * Admin Return Expired Credits Callable
 *
 * @param {string} expiredCreditUID,
 */
export const adminReturnExpiredCredits = async expiredCreditUID => {
  try {
    const func = firebase.callable('admin');
    const response = await func({ action: 'returnExpiredCredits', data: expiredCreditUID });

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

/**
 * Admin Approve Influencer Callable
 *
 * @param {string} influencerUID,
 * @param {boolean} isVIP
 */
export const adminApproveInfluencer = async approvalPayload => {
  try {
    const response = await patchAdminApproveCreator(approvalPayload);
    return response;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const adminRejectInfluencer = async rejectionPayload => {
  try {
    await patchAdminRejectCreator(rejectionPayload);
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const adminResendPayout = async partnershipUID => {
  try {
    const func = firebase.callable('admin');
    await func({ action: 'resendPayout', data: { partnershipUID } });

    return true;
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const adminCompletePartnership = async (partnershipUID, withPayout = true) => {
  try {
    const func = firebase.callable('admin');
    await func({
      action: 'completePartnership',
      data: {
        partnershipUID,
        withPayout,
      },
    });
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};

export const adminUpdateInfluencerTier = async (influencerUID, payOutTiers) => {
  try {
    const func = firebase.callable('admin');
    await func({
      action: 'adminUpdateInfluencerTier',
      data: {
        influencerUID,
        payOutTiers,
      },
    });
  } catch (e) {
    console.log(e);
    throw new Error(e.message);
  }
};
