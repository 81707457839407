import React from 'react';
import { Icon } from 'antd';
import { ReactComponent as SqiggleArrow } from '../../images/ContentShowcase/SqiggleArrow.svg';

/**
 *
 * @typedef {{}} VideoArrowProps
 * @type {React.FC<VideoArrowProps>}
 */

const VideoArrow = () => {
  return (
    <div id="info-grid">
      <p>How does Trend work?</p>
      <Icon component={SqiggleArrow} style={{ fontSize: '68px' }} />
    </div>
  );
};

export default VideoArrow;
