import React, { useState } from 'react';
import { Col, Row } from 'antd';
import ImageKitImage from 'components/Common/ImageKitImage';
import Video from 'components/Common/Video';
import VideoPreviewModal from 'components/Common/VideoPreviewModal';
import { STYLE_GUIDE_TYPE } from 'constants/campaigns';

/**
 * Styleguide Component
 *
 * @typedef {{ product: import('components/Session/Context').Product }} StyleguideProps
 * @type {React.FC<StyleguideProps>}
 */
const Styleguides = ({ product }) => {
  const { styleGuide = {}, styleGuideLink = [] } = product;
  const [previewModal, setPreviewModal] = useState(false);
  const [videoLink, setVideoLink] = useState();

  const list = Object.keys(styleGuide).map((uid, idx) => {
    const guide = styleGuide[uid];
    const isImg = guide.type !== STYLE_GUIDE_TYPE.VIDEO;
    const id = `${uid}-${idx}`;
    return (
      <Col key={id} className={isImg ? 'image' : 'video'} span={6}>
        {isImg ? null : (
          <div
            role="button"
            tabIndex="0"
            className="video-mask"
            onClick={() => {
              setVideoLink(guide.url);
              setPreviewModal(true);
            }}
          />
        )}
        {isImg ? (
          <ImageKitImage path={guide.url} alt={`style guide ${uid}`} />
        ) : (
          <Video src={guide.url} />
        )}
      </Col>
    );
  });

  return (
    <div id="styleGuide" className="styleguide">
      <div className="header-text">Style Guide</div>

      {styleGuideLink.length === 0 ? null : (
        <div className="guideline">
          <div className="sub-header-text">Web Links</div>
          <div className="link-list">
            {styleGuideLink.map(link => (
              <div
                key={link}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `<div class="style-guide-container"><a href=${link} target="_blank"  rel="noopener noreferrer">${link}</a></div>`,
                }}
              />
            ))}
          </div>
        </div>
      )}
      {list.length > 0 && (
        <div>
          <div className={`sub-header-text ${styleGuideLink.length !== 0 && 'adjust-margin'}`}>
            Photos / Videos
          </div>
          <Row gutter={12}>{list}</Row>
          <VideoPreviewModal
            setVisible={setPreviewModal}
            visible={previewModal}
            videoLink={videoLink}
          />
        </div>
      )}
    </div>
  );
};

export default Styleguides;
