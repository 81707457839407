import Promise from 'bluebird';
import moment from 'moment';
import Brands, { useBrand } from 'components/Brands';
import Container from 'components/Common/Container';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions, selectors } from 'stores';
import { useFirebase } from 'lib/Firebase';

const epochDate = date => {
  const pDate = moment(date);
  if (pDate.year() < 2000) {
    return pDate.valueOf();
  }

  return pDate.unix();
};

const RenderBrand = () => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const partnerships = useSelector(selectors.getPartnerships());
  const [rejecteds, setRejecteds] = useState([]);
  const influencers = useSelector(selectors.getInfluencers());
  const purchasedHistories = useSelector(selectors.getPurchasedHistories());

  const { approvedPartnershipUIDs, brandUID, purchasedHistoryUIDs } = useBrand();
  const brand = useSelector(selectors.getBrand(brandUID));

  async function fetchRejectedData() {
    const approvedRejectedSnap = await firebase.firestore
      .collection('influencersPartnerships')
      .where('status', '==', 'rejected')
      .where('brandUID', '==', brandUID)
      .get();
    const approvedRejected = {};

    approvedRejectedSnap.forEach(doc => {
      const { needsShippingAt } = doc.data();
      if (needsShippingAt) {
        approvedRejected[doc.id] = doc.data();
      }
    });

    const newInfluencers = {};
    await Promise.map(Object.keys(approvedRejected), async uid => {
      const { influencerUID } = approvedRejected[uid];

      const influencerDoc = await firebase.firestore
        .collection('influencers')
        .doc(influencerUID)
        .get();
      newInfluencers[influencerUID] = influencerDoc.data();
    });

    dispatch(actions.entities.influencers.addInfluencers(newInfluencers));
    dispatch(actions.entities.partnerships.addPartnerships(approvedRejected));
    setRejecteds(Object.keys(approvedRejected));
  }

  useEffect(() => {
    fetchRejectedData();
  }, []);

  let creditsUsed = 0;
  const approvedP = approvedPartnershipUIDs
    .sort((a, b) => partnerships[b].createdAt - partnerships[a].createdAt)
    .map(uid => {
      const { influencerUID, status, createdAt, payoutAmount } = partnerships[uid];
      const { username } = influencers[influencerUID];

      const cost = parseInt(payoutAmount, 10) > 65 ? 2 : 1;
      creditsUsed += cost;

      return (
        <div key={uid}>
          <div>
            <div>{moment.unix(createdAt).format('MMM DD, YYYY HH:mm')}</div>
            <div>
              [{status}] Partnership: [{uid}] - {username}[{influencerUID}]
            </div>
          </div>
          <div>Cost Credit: {cost}</div>
          ------
        </div>
      );
    });

  let creditsPurchased = 0;
  const purchasedHistoryData = purchasedHistoryUIDs
    .sort((a, b) => purchasedHistories[b].purchasedDate - purchasedHistories[a].purchasedDate)
    .map(uid => {
      const { purchasedDate, totalAmount, standardCredits } = purchasedHistories[uid];
      creditsPurchased += standardCredits;

      return (
        <div key={uid}>
          <span>
            {moment.unix(purchasedDate).format('MMM DD, YYYY HH:mm')} - {standardCredits} :{' '}
            {totalAmount}
          </span>
        </div>
      );
    });

  const renderRejecteds = rejecteds
    .sort((a, b) => partnerships[b].rejectedAt - partnerships[a].rejectedAt)
    .map(uid => {
      const { influencerUID, payoutAmount, rejectedAt } = partnerships[uid];
      const { username } = influencers[influencerUID];
      const cost = parseInt(payoutAmount, 10) > 65 ? 2 : 1;
      return (
        <div key={uid}>
          <div>{moment.unix(epochDate(rejectedAt)).format('MMM DD, YYYY HH:mm')}</div>
          <div>
            <div>Credits: {cost}</div>
            PartnershipUID: [{uid}] - {username}[{influencerUID}]
          </div>
          -----
        </div>
      );
    });

  return (
    <div>
      <h1>Brand: {brand.name}</h1>
      <div>Credits: {brand.standardCredits}</div>
      -----
      <div>Purchase Credits: {creditsPurchased}</div>
      <div>Spend Credit: {creditsUsed}</div>
      <h2>Approved Partnerships</h2>
      <div>{approvedP}</div>
      <h2>Returned Credits</h2>
      <div>
        <div>{renderRejecteds}</div>
      </div>
      <h2>Purchase Credits</h2>
      <div>{purchasedHistoryData}</div>
    </div>
  );
};

/**
 * Admin V2 Brand Show Page
 *
 * @type {React.FC}
 */
const ShowPage = () => {
  const { brandUID } = useParams();

  return (
    <Container width={940}>
      <Brands.Provider brandUID={brandUID}>
        <RenderBrand />
      </Brands.Provider>
    </Container>
  );
};

export default ShowPage;
