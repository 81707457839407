import React, { useEffect } from 'react';
import { useCheckSession, useSession } from 'components/Session';
import { Redirect, Route } from 'react-router-dom';
// import { MANAGE_CREDITS_PATH } from 'constants/routes';

const PrivateRoute = ({ component: Component, layout: Layout, authUser, ...rest }) => {
  const hasSession = useCheckSession();
  useEffect(() => {}, [hasSession]);

  if (!hasSession) {
    return <Redirect to="/showcase" />;
  }

  const { activeProductUIDs, completedProductUIDs, brand } = useSession();
  const { isAdmin } = brand;

  if (
    !isAdmin &&
    activeProductUIDs.length === 0 &&
    completedProductUIDs.length === 0
    // && rest.path !== MANAGE_CREDITS_PATH
  ) {
    return <Redirect to="/showcase" />;
  }

  const renderComponent = props => {
    if (Layout) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={renderComponent} />;
};

export default PrivateRoute;
