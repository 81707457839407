import { createContext } from 'react';

/**
 *
 * @typedef {{
 *  productUID: string,
 *  approvedPartnershipUIDs: string[],
 *  submittedPartnershipUIDs: string[],
 *  invitedInfluencerUIDs: string[],
 * 	postUIDs: {
 *    approved: string[],
 *    rejected: string[],
 *    submitted: string[],
 *    pending: string[],
 *  },
 * 	creatorContentUIDs: {
 * 	 	approved: string[],
 *    rejected: string[],
 *    submitted: string[],
 *    pending: string[],
 * 	},
 *  messageUIDs: string[],
 *  listenPosts?: boolean,
 *  listenPartnerships?: boolean,
 *  listenMessage?: boolean,
 *  listenCreatorContents?: boolean
 * }} ProductContext
 *
 * @type {React.Context<ProductContext>}
 */
const Context = createContext();

export default Context;
