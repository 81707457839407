import React from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { ReactComponent as ArrowIcon } from 'images/components/Products/RecentPosts/Arrow.svg';

const PostItem = ({ post }) => {
  const { influencerUID, type = 'image' } = post;
  const influencer = useSelector(selectors.getInfluencer(influencerUID));

  const { fullResImage, thumbnail } = post;
  let postImg = fullResImage || thumbnail;

  if (type === 'story') {
    postImg = thumbnail || fullResImage;
  }

  return (
    <>
      <img className="post-image" src={postImg} alt="" />
      <div className="creatorName">@{influencer.username}</div>
    </>
  );
};

const PostEmpty = () => {
  return (
    <>
      <div className="empty-box with-border" />
      <div className="creatorName">@username</div>
    </>
  );
};

/**
 * Recent Post Component
 *
 * @typedef {{posts: (import('./Context').Post & { username: string, uid: string })[]}} RecentPostsProps
 * @type {React.FC<RecentPostsProps>}
 */
const RecentPosts = ({ posts }) => {
  let counter = 0;

  const postList = [...Array(2)].map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className="row-container" key={i} style={{ marginBottom: i === 0 ? '13px' : 0 }}>
      {[...Array(2)].map((__, ii) => {
        const post = posts[counter];
        const PostCard = post ? <PostItem post={post} /> : <PostEmpty />;

        counter += 1;

        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={ii}
            role="button"
            tabIndex="0"
            style={{ marginRight: ii === 0 ? '9.58px' : 0 }}
            onClick={() => {
              if (post) {
                window.open(post.link, '_blank');
              }
            }}
          >
            {PostCard}
          </div>
        );
      })}
    </div>
  ));

  return (
    <div id="campaignRecentPosts">
      <div>
        <Row gutter={0} style={{ marginBottom: '13px' }}>
          <Col span={14}>
            <div className="side-header-text">Latest Content</div>
          </Col>
          <Col span={10} className="see-all">
            See All
            <ArrowIcon style={{ marginLeft: '6px', width: '14px', height: '8.84px' }} />
          </Col>
        </Row>
        {postList}
      </div>
    </div>
  );
};

export default RecentPosts;
