// eslint-disable-next-line import/prefer-default-export
export const imageDimensionValidator = ({
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  width,
  height,
  // eslint-disable-next-line consistent-return
}) => (rule, value, callback) => {
  if (!value) return callback();

  const img = new Image();
  img.src = window.URL.createObjectURL(value);
  img.onload = () => {
    try {
      const commonErrorMessage = 'Invalid image dimension.';
      if (width && img.width !== width)
        throw new Error(`${commonErrorMessage} Width must be equal to ${width}`);
      if (height && img.height !== height)
        throw new Error(`${commonErrorMessage} Height must be equal to ${height}`);
      if (maxWidth && img.width > maxWidth)
        throw new Error(`${commonErrorMessage} Maximum width is ${maxWidth}`);
      if (maxHeight && img.height > maxHeight)
        throw new Error(`${commonErrorMessage} Maximum height is ${maxHeight}`);
      if (minWidth && img.width < minWidth)
        throw new Error(`${commonErrorMessage} Minium width is ${minWidth}`);
      if (minHeight && img.height < minHeight)
        throw new Error(`${commonErrorMessage} Minium height is ${minHeight}`);

      callback();
    } catch (err) {
      callback(err.message);
    }
  };
};
