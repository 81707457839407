import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, Alert, Button, message, Descriptions, Popconfirm } from 'antd';
import Promise from 'bluebird';
import { adminResendPayout } from 'lib/Firebase/callables/admin';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { firebase } from '../../../lib/Firebase';

const DeniedTab = () => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const partnerships = useSelector(selectors.getPartnerships());
  const columns = [
    {
      key: 'influencerUID',
      dataIndex: 'username',
      title: 'Username',
    },
    {
      key: 'productUID',
      dataIndex: 'productName',
      title: 'Product Name',
    },
    {
      key: 'actions',
      title: 'actions',
      render: ({ resend, loading }) => {
        return (
          <Popconfirm onConfirm={resend} title="Are you sure you wan't to resend a new payout?">
            <Button type="primary" loading={loading}>
              Resend
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const expandedRow = ({ productName, username, completedAt, payoutStatusMessage }) => (
    <div className="partnership-details">
      <Alert message={payoutStatusMessage} type="error" />
      <Descriptions title="Partnership" bordered>
        <Descriptions.Item label="Product">{productName}</Descriptions.Item>
        <Descriptions.Item label="Influencer">{username}</Descriptions.Item>
        <Descriptions.Item label="Completed At">
          {completedAt && moment.unix(completedAt).format('MMM DD, YYYY')}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );

  function setLoadingStatus(uid, isLoading = true) {
    setData(state => {
      const newData = state.map(d => {
        if (d.uid === uid) {
          return { ...d, loading: isLoading };
        }
        return d;
      });
      return newData;
    });
  }

  async function handleResend(partnershipUID) {
    setLoadingStatus(partnershipUID, true);
    try {
      await adminResendPayout(partnershipUID);
      const partnership = partnerships[partnershipUID];

      dispatch(
        actions.entities.partnerships.updatePartnerships({
          [partnershipUID]: {
            ...partnership,
            payoutStatus: 'processing',
            payoutStatusMessage: null,
          },
        })
      );

      await Promise.delay(3000);
      setData(state => {
        const newData = state.filter(d => d.uid !== partnershipUID);
        return newData;
      });
      setLoadingStatus(partnershipUID, false);
      message.success('Processing a new batch payment');
    } catch (e) {
      message.error('Unauthorized access, please switch to an admin brand');
    }
  }

  async function fetchData() {
    setFetching(true);
    const partnershipsSnap = await firebase.firestore
      .collection('influencersPartnerships')
      .where('payoutStatus', '==', 'denied')
      .get();

    const newState = await Promise.map(partnershipsSnap.docs, async doc => {
      const { influencerUID, productUID } = doc.data();
      const { username } = (await firebase.firestore
        .collection('influencers')
        .doc(influencerUID)
        .get()).data();
      const { name } = (await firebase.firestore
        .collection('products')
        .doc(productUID)
        .get()).data();

      return {
        key: doc.id,
        uid: doc.id,
        username,
        productName: name,
        ...doc.data(),
        resend: () => {
          handleResend(doc.id);
        },
      };
    });

    setData([...data, ...newState]);
    setFetching(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="denied-tab">
      <Table
        dataSource={data}
        columns={columns}
        expandedRowRender={expandedRow}
        pagination={false}
        loading={fetching}
      />
    </div>
  );
};

export default DeniedTab;
