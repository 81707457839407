import React, { useEffect, useState } from 'react';
import Promise from 'bluebird';
import { Spin } from 'antd';
import { firebase } from '../../../lib/Firebase';

const OverdueCampaigns = ({ overduePartnershipUIDs = [] }) => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  async function retrieveOverdueCampaigns() {
    setLoading(true);

    const products = await Promise.all(
      overduePartnershipUIDs.map(async partnershipUID => {
        const partnershipRef = await firebase.firestore
          .collection('influencersPartnerships')
          .doc(partnershipUID)
          .get();

        const partnership = partnershipRef.data();

        const productRef = await firebase.firestore
          .collection('products')
          .doc(partnership.productUID)
          .get();

        const product = productRef.data();

        return { ...product, uid: productRef.id };
      })
    );

    setCampaigns(products);

    setLoading(false);
  }

  useEffect(() => {
    retrieveOverdueCampaigns();
  }, []);

  if (loading) return <Spin className="admin-influencer-active-campaigns" />;

  const list = campaigns.map(campaign => {
    return <p key={campaign.uid}>{campaign.name}</p>;
  });

  return (
    <div className="admin-influencer-active-campaigns">
      {(list.length && list) || 'This creator has no overdue partnerships :)'}
    </div>
  );
};

export default OverdueCampaigns;
