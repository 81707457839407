import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

const Stats = ({ reviews = {} }) => {
  const influencerReview = reviews;
  const { avgContentQuality = 5, avgAttitudeProfessionalism = 5 } = influencerReview;
  return (
    <div className="influencer-review-stats">
      <div className="review-stat">
        <label className="review-stat-label">Content Quality</label>
        <div className="review-stat-value">{numeral(avgContentQuality).format('0.0')}</div>
        <div className="review-stat-rate">
          <div
            className="review-stat-rate-bar"
            style={{ width: `${(avgContentQuality / 5) * 100}%` }}
          />
        </div>
      </div>
      <div className="review-stat">
        <div>
          <label className="review-stat-label">Attitude and professionalism</label>
          <div className="review-stat-value">
            {numeral(avgAttitudeProfessionalism).format('0.0')}
          </div>
        </div>
        <div className="review-stat-rate">
          <div
            className="review-stat-rate-bar"
            style={{ width: `${(avgAttitudeProfessionalism / 5) * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { influencerUID }) => ({
  influencer: state.entities.influencers[influencerUID],
});

export default connect(mapStateToProps)(Stats);
