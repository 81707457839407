import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { adminUnuspendInfluencer } from '../../../lib/Firebase/callables/admin';

const { confirm } = Modal;

const UnsuspendButton = ({ influencerUID, children, onUnsuspend }) => {
  const [loading, setLoading] = useState(false);

  function showConfirmation() {
    confirm({
      title: 'Are you sure you want to unsuspend this creator?',
      async onOk() {
        setLoading(true);
        try {
          await adminUnuspendInfluencer(influencerUID);
          message.success('Influencer is now unsuspended');
        } catch (e) {
          message.error(e.message);
        }

        onUnsuspend(influencerUID);
        setLoading(false);
      },
    });
  }

  return (
    <Button
      loading={loading}
      onClick={() => showConfirmation()}
      type="default"
      className="action-approve"
      block
    >
      {children || 'Unsuspend'}
    </Button>
  );
};

export default UnsuspendButton;
