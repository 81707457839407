import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { version } from '../../package.json';

const getReleaseStage = () => {
  if (process.env.STORYBOOK) {
    return 'storybook';
  }
  return process.env.REACT_APP_VERCEL_ENV || process.env.NODE_ENV;
};
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  // otherOptions: value,
  appVersion: version,
  releaseStage: getReleaseStage(),
  enabledReleaseStages: ['production', 'preview', 'development'],
});

export default Bugsnag;
