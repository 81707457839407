import React from 'react';
import { Select, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { CAMPAIGN_STATS } from '../../constants/campaigns';

const sortBy = brands => (a, b) => {
  const brandA = brands[a].name ? brands[a] : brands[a].details;
  const brandB = brands[b].name ? brands[b] : brands[b].details;

  if (brandA.name < brandB.name) {
    return -1;
  }
  if (brandA.name > brandB.name) {
    return 1;
  }
  return 0;
};

/**
 * Product Admin Filter Component
 *
 * @type {React.FC}
 */
const AdminFilters = ({ value, onChange }) => {
  const brandUIDs = useSelector(selectors.getAdminBrands());
  const brands = useSelector(selectors.getBrands());
  const categoryOptions = Object.keys(CAMPAIGN_STATS).map(stat => (
    <Select.Option key={CAMPAIGN_STATS[stat]} value={CAMPAIGN_STATS[stat]}>
      {stat}
    </Select.Option>
  ));

  const validBrandUIDs = brandUIDs
    .filter(x => {
      return (
        brands[x] &&
        brands[x].name &&
        !brands[x].isDisabled &&
        brands[x].lastLoginDate &&
        brands[x].isActive
      );
    })
    .sort(sortBy(brands));

  const brandOptions = validBrandUIDs.map(uid => {
    const brand = brands[uid].name ? brands[uid] : brands[uid].details;

    return (
      <Select.Option key={uid} value={uid}>
        {brand.name}
      </Select.Option>
    );
  });

  const handleChange = type => val => {
    const newState = {
      ...value,
      [type]: val,
    };

    if (onChange) {
      onChange({
        ...newState,
      });
    }
  };

  const { gender, category, health, brand, type } = value;
  return (
    <Row style={{ width: '100%' }} gutter={10}>
      <Col md={4}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Type: Any"
          value={type}
          onChange={handleChange('type')}
        >
          <Select.Option value="social">Social</Select.Option>
          <Select.Option value="image">Image</Select.Option>
          <Select.Option value="video">Video</Select.Option>
        </Select>
      </Col>
      <Col md={5}>
        <Select
          allowClear
          showArrow={false}
          className="trend-select"
          placeholder="Gender: Any"
          value={gender}
          onChange={handleChange('gender')}
        >
          <Select.Option value="male">Male Only</Select.Option>
          <Select.Option value="female">Female Only</Select.Option>
        </Select>
      </Col>
      <Col md={5}>
        <Select
          allowClear
          value={category}
          showArrow={false}
          className="trend-select"
          placeholder="Category: All"
          onChange={handleChange('category')}
        >
          {categoryOptions}
        </Select>
      </Col>
      <Col md={5}>
        <Select
          allowClear
          showArrow={false}
          value={health}
          className="trend-select"
          placeholder="Status: All"
          onChange={handleChange('health')}
        >
          <Select.Option value={1}>Poor</Select.Option>
          <Select.Option value={2}>In Range</Select.Option>
          <Select.Option value={3}>Great</Select.Option>
        </Select>
      </Col>
      <Col md={5}>
        <Select
          allowClear
          showArrow={false}
          value={brand}
          className="trend-select"
          placeholder="Brand: All"
          onChange={handleChange('brand')}
        >
          {brandOptions}
        </Select>
      </Col>
    </Row>
  );
};

export default AdminFilters;
