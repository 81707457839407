import React from 'react';
import { Modal as AntModal, Button } from 'antd';
import mixpanel from 'lib/Analytics/Mixpanel';
import './AddCreditsModal.less';
import ProductExamples from 'images/AddCreditsModal/example-content.png';
import * as routes from 'constants/routes';

/**
 * Add Credits Modal To Block New Campaign Creation
 *
 * @typedef {{
 * onClose: () => Promise<any>,
 * show: boolean, onClose: () => any
 * }} ModalProps
 * @type {React.FC<ModalProps>}
 */
const AddCreditsModal = ({ show, onClose }) => {
  const handleAddCredits = () => {
    if (mixpanel) {
      mixpanel.track('Brand Visits Add Credits');
    }
    routes.toAddCredits();
  };

  return (
    <AntModal
      closable={false}
      className="add-credits-modal"
      visible={show}
      onCancel={onClose}
      footer={false}
      width={560}
      style={{ top: '16%' }}
    >
      <img src={ProductExamples} width={300} alt="Product examples" />
      <h2 className="black text-center">Add creator credits to start a new project! </h2>
      <div className="button-row">
        <Button onClick={onClose} className="cancel-btn" type="default">
          Cancel
        </Button>
        <Button onClick={handleAddCredits} className="submit-btn" type="primary">
          Add Credits
        </Button>
      </div>
    </AntModal>
  );
};

export default AddCreditsModal;
