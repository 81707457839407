import Promise from 'bluebird';
import { firebase } from 'lib/Firebase';
import React, { useEffect } from 'react';
import { actions, selectors } from 'stores';
import { useDispatch, useSelector } from 'react-redux';
import { APPROVE_PARTNERSHIP_STATUSES, PARTNERSHIP_STATUS } from 'constants/partnerships';
import { useSession } from 'components/Session';
import Loading from 'components/Loading';
import Context from './Context';
import {
  useListenProduct,
  useListenProductMessage,
  useListenProductPartnerships,
  useListenProductPosts,
  useListenProductCreatorContents,
} from './listeners';

/**
 * Fetch Creator Contents
 *
 * @param {string} productUID
 * @param {string[]} influencerUIDs
 * @returns
 */
const fetchCreatorContents = async productUID => {
  const creatorContents = {};

  const creatorContentsSnap = await firebase.firestore
    .collection('creatorContents')
    .where('productUID', '==', productUID)
    .get();

  creatorContentsSnap.docs.forEach(doc => {
    creatorContents[doc.id] = doc.data();
  });

  return creatorContents;
};

/**
 * Fetch Message
 *
 * @param {string} productUID
 * @param {string[]} influencerUIDs
 * @returns
 */
const fetchMessages = async (productUID, influencerUIDs) => {
  const messageSnap = await firebase
    .messages()
    .orderByChild('details/partnershipUID')
    .equalTo(productUID)
    .once('value');

  /**
   * @type {Record<string, import('types').Message>}
   */
  const messages = messageSnap.val() || {};

  const approvedMessages = {};

  Object.keys(messages).forEach(messageUID => {
    const message = messages[messageUID];

    const [influencerUID] = Object.keys(message.users).filter(
      x => message.users[x].type === 'influencer'
    );

    if (influencerUIDs.includes(influencerUID)) {
      approvedMessages[messageUID] = message;
    }
  });

  return approvedMessages;
};

/**
 * @param {string} productUID
 * @returns {Promise<Record<string, import('types').Post>>}
 */
const fetchPosts = async productUID => {
  const postDoc = await firebase.firestore
    .collection('posts')
    .where('productUID', '==', productUID)
    .get();

  const posts = {};

  postDoc.forEach(doc => {
    posts[doc.id] = doc.data();
  });

  return posts;
};

/**
 * @param {string} productUID
 * @returns {Promise<{
 * invitedPartnerships: Record<string, import('types').Partnership>
 * approvedPartnerships: Record<string, import('types').Partnership>
 * submittedPartnerships: Record<string, import('types').Partnership>
 * }>}
 */
const fetchPartnerships = async productUID => {
  const submittedPartnerships = {};
  const approvedPartnerships = {};
  const invitedPartnerships = {};
  // Keep track of influencers
  const influencerUIDs = new Set();
  const partnershipDocs = await firebase.firestore
    .collection('influencersPartnerships')
    .where('productUID', '==', productUID)
    .where('status', '!=', 'rejected')
    .get();

  partnershipDocs.docs
    .sort((firstElement, secondElement) => {
      return secondElement.data().createdAt - firstElement.data().createdAt;
    })
    .forEach(doc => {
      /**
       * @type {import('types').Partnership}
       */
      const partnership = doc.data();
      if (partnership.status === PARTNERSHIP_STATUS.SUBMITTED) {
        submittedPartnerships[doc.id] = { uid: doc.id, ...partnership };
      } else if (APPROVE_PARTNERSHIP_STATUSES.includes(partnership.status)) {
        const influencerMatch = influencerUIDs.has(partnership.influencerUID);
        if (!influencerMatch) {
          approvedPartnerships[doc.id] = { uid: doc.id, ...partnership };
          influencerUIDs.add(partnership.influencerUID);
        }
      } else if (partnership.status === PARTNERSHIP_STATUS.INVITED) {
        invitedPartnerships[doc.id] = { uid: doc.id, ...partnership };
      }
    });

  return { approvedPartnerships, submittedPartnerships, invitedPartnerships };
};

/**
 *
 * @param {Record<string, import('types').Partnership>} partnerships
 * @returns {Promise<Record<string, import('types').Influencer>>}
 */
const fetchInfluencers = async partnerships => {
  const influencerUIDs = Object.keys(partnerships).map(x => partnerships[x].influencerUID);
  const influencers = {};
  if (influencerUIDs.length > 0) {
    await Promise.map(
      influencerUIDs,
      async influencerUID => {
        const influencerDoc = await firebase.firestore
          .collection('influencers')
          .doc(influencerUID)
          .get();
        influencers[influencerDoc.id] = influencerDoc.data();
      },
      { concurrency: 3 }
    );
    return influencers;
  }

  return {};
};

/**
 * @typedef {{ productUID: string, renderLoading?: any, isAdmin: boolean }} ProviderProps
 * @type {React.FC<ProviderProps>}
 */
const Provider = ({ children, productUID, renderLoading, isAdmin }) => {
  const { activeProductUIDs, completedProductUIDs } = useSession();
  const dispatch = useDispatch();
  const productData = useSelector(selectors.getProductPage(productUID));
  const creatorContentUIDs = useSelector(selectors.getProductCreatorContents(productUID));
  const postUIDs = useSelector(selectors.getProductPosts(productUID));

  const ownedProduct = [...activeProductUIDs, ...completedProductUIDs].includes(productUID);

  useListenProduct(productUID, isAdmin || ownedProduct);
  useListenProductPartnerships(productUID, isAdmin || ownedProduct);
  useListenProductPosts(productUID, isAdmin || ownedProduct);
  useListenProductMessage(productUID, isAdmin || ownedProduct);
  useListenProductCreatorContents(productUID, isAdmin || ownedProduct);

  async function fetchProductData() {
    const productDoc = await firebase.firestore
      .collection('products')
      .doc(productUID)
      .get();

    const product = productDoc.data();

    const posts = await fetchPosts(productUID);

    const {
      approvedPartnerships,
      submittedPartnerships: allSubmittedPartnerships,
      invitedPartnerships,
    } = await fetchPartnerships(productUID);

    let messages = {};
    const influencers = await fetchInfluencers({
      ...approvedPartnerships,
      ...allSubmittedPartnerships,
    });
    // Filter suspended influencers from applications
    const submittedPartnerships = Object.keys(allSubmittedPartnerships).reduce((acc, key) => {
      const { influencerUID } = allSubmittedPartnerships[key];
      const influencer = influencers[influencerUID];
      const isSuspended = influencer && influencer.isSuspended;
      if (!isSuspended) {
        acc[key] = allSubmittedPartnerships[key];
      }
      return acc;
    }, {});

    if (Object.keys(influencers).length > 0) {
      // Only fetch message if owned Products
      if (ownedProduct) {
        try {
          messages = await fetchMessages(productUID, Object.keys(influencers));
        } catch (error) {
          console.error('Failed to fetch messages');
          console.error(error);
        }
      }
    }

    const creatorContents = await fetchCreatorContents(productUID);
    // TODO: Add creator info to submitted partnerships to prevent crash from missing data when partnership is approved
    dispatch(
      actions.productContexts.setInitialProduct(productUID, {
        product,
        messages,
        approvedPartnerships,
        submittedPartnerships,
        invitedPartnerships,
        posts,
        creatorContents,
        influencers,
      })
    );
  }

  useEffect(() => {
    if (!productData) {
      fetchProductData();
    }
  }, [productUID]);

  if (!productData) {
    return renderLoading || <Loading.Product />;
  }

  return (
    <Context.Provider
      value={{
        ...productData,
        creatorContentUIDs,
        postUIDs, // NOTE: postUIDs should not be used anywhere
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
