import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button, Avatar } from 'antd';
import { calculatePartnershipCreditCost } from 'utils/content';
import { useSession } from 'components/Session';
import mixpanel from 'lib/Analytics/Mixpanel';
import { firebase } from 'lib/Firebase';
import * as routes from 'constants/routes';
import { postCreateStripeCustomer } from 'api/brand';

/**
 * @typedef {{
 *  partnershipUID?: string,
 *  show: boolean,
 *  onSubmit: () => void,
 *  onHide: () => void
 * }} ApproveApplicationModal
 *
 * @type {React.FC<ApproveApplicationModal>}
 */

const ApproveApplicationModal = ({ show, modalDetails, onSubmit, onHide }) => {
  const [submitting, setSubmitting] = useState(false);
  const {
    brand,
    brand: { standardCredits, stripeCustomerId },
  } = useSession();
  let creatorCost = 0;
  let difference = 0;

  if (modalDetails && modalDetails.influencer) {
    creatorCost = calculatePartnershipCreditCost(modalDetails.influencer);
  }

  if (creatorCost > standardCredits) {
    difference = creatorCost - standardCredits;
  }

  useEffect(() => {
    const createStripeCustomer = async () => {
      await postCreateStripeCustomer();
      const firebaseBrand = await firebase.firestore
        .collection('brands')
        .doc(brand.uid)
        .get();
      const brandStripeId = firebaseBrand.data().stripeCustomerId;
      return brandStripeId;
    };

    if (!stripeCustomerId) {
      createStripeCustomer();
    }
  }, []);

  async function handleSubmit() {
    setSubmitting(true);

    await onSubmit();

    setSubmitting(false);
  }

  function navigateToAddCreditsPage() {
    if (mixpanel) {
      mixpanel.track('Brand Visits Add Credits');
    }
    routes.toAddCredits();
  }

  const cantApprove = difference > 0;
  return (
    <Modal
      visible={show}
      closable={false}
      className="trend-modal creator-approval-modal"
      footer={null}
      width={548}
    >
      {modalDetails && (
        <div className="type-sfpro-regular">
          <Col style={{ textAlign: 'center' }}>
            <Row style={{ padding: '39px 48px 0' }}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Avatar src={modalDetails.influencer.image} size={90} />
                <div
                  className="influencer-info-username text-black size-18px lh-18px"
                  style={{ marginTop: '11px', marginBottom: '19px', fontWeight: 'bold' }}
                >
                  {modalDetails.influencer.username === '' ? (
                    <div style={{ textTransform: 'capitalize' }}>
                      {modalDetails.influencer.name}
                    </div>
                  ) : (
                    <div>@{modalDetails.influencer.username}</div>
                  )}
                </div>
              </Col>
            </Row>
            <Row style={{ padding: '0 48px' }}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <h2 className="black text-center" style={{ margin: '0px' }}>
                  Confirm Creator Selection
                </h2>
                <div
                  className="text-black text-center size-16px lh-24px"
                  style={{
                    margin: 'auto',
                    width: '401px',
                    textAlign: 'justify',
                    fontWeight: '400',
                  }}
                >
                  After approval, this creator will be ready to execute on your project. Make sure
                  you ship them your product if needed.
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <div
                  style={{
                    margin: '25px auto 30px',
                    width: '400px',
                    objectFit: 'contain',
                    borderRadius: '4px',
                    backgroundColor: '#F1F5FD',
                  }}
                >
                  <Row style={{ marginTop: '29px' }}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <div
                        className="type-sfpro-heavy"
                        style={{
                          fontSize: '60px',
                          marginTop: '16px',
                          color: '#3c3c3c',
                        }}
                      >
                        {creatorCost}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div
                        className="type-sfpro-heavy"
                        style={{
                          fontSize: '15px',
                          color: '#3c3c3c',
                          textAlign: 'center',
                          marginBottom: '34px',
                          textTransform: 'uppercase',
                        }}
                      >
                        Creator Credit Cost
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row gutter={15} style={{ padding: '10px 74px 58px' }} key="row-multi-action">
              <Col span={12}>
                <Button
                  key="cancel"
                  disabled={submitting}
                  className="trend-btn medium-btn secondary"
                  onClick={onHide}
                  block
                  style={{
                    fontFamily: 'SFProDisplay',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '18px',
                    borderRadius: '5px',
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  key="approve"
                  type="primary"
                  className="trend-btn medium-btn"
                  loading={submitting}
                  onClick={() => (cantApprove ? navigateToAddCreditsPage() : handleSubmit())}
                  block
                  style={{
                    fontFamily: 'SFProDisplay',
                    fontWeight: '700',
                    fontSize: '16px',
                    lineHeight: '18px',
                    borderRadius: '5px',
                  }}
                >
                  {cantApprove ? `Add Credits` : 'Approve'}
                </Button>
              </Col>
            </Row>
          </Col>
        </div>
      )}
    </Modal>
  );
};

export default ApproveApplicationModal;
