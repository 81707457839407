import React from 'react';
import { Row, Col } from 'antd';
import NoCampaignImage from 'images/no-campaign@2x.png';

const SummaryExample = () => {
  return (
    <Row type="flex" justify="center" className="example-campaign" style={{ marginTop: '133.5px' }}>
      <Col span={16} style={{ textAlign: 'center' }}>
        <img src={NoCampaignImage} alt="no campaign" />
        <h1>Create a Campaign to Get Started</h1>

        <Row type="flex" justify="center">
          <Col style={{ width: '426px' }}>
            <p>
              Create a campaign in the navigation to the left to start generating posts and <br />
              content for your brand. If you have questions along the way, you can always reach us{' '}
              <br />
              through the chat bubble in the bottom right.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SummaryExample;
