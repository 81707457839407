import React from 'react';
import CampaignTypeSection from './ProjectTypeSections/CampaignTypeSection';

/**
 *
 * @typedef {{}} CampaignsSideMenuProps
 * @type {React.FC<CampaignsSideMenuProps>}
 */

const CampaignsSideMenu = () => {
  const types = ['image', 'video', 'social'];
  return (
    <>
      {types.map((type, idx) => {
        const id = `${type}-${idx}`;
        return (
          <React.Fragment key={id}>
            <CampaignTypeSection type={type} />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CampaignsSideMenu;
