import { Avatar as AntAvatar } from 'antd';
import ImageKitImage from 'components/Common/ImageKitImage';
import { useMediaViewer } from 'components/Common/MediaViewer';
import { CREATOR_CONTENT_CONTENTS_STATUS } from 'constants/creatorContents';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

/**
 * Creator Content Avatar Component
 *
 * @typedef {{creatorContentUID: string, contentUID: string }} AvatarProps
 * @type {React.FC<AvatarProps>}
 */
const ContentAvatar = ({ creatorContentUID, contentUID }) => {
  const creatorContent = useSelector(selectors.getCreatorContent(creatorContentUID));
  const { setModal } = useMediaViewer();
  const [img, setImg] = useState('');
  const { contents = {}, type } = creatorContent;
  /**
   * @type {import('types').Content}
   */
  const content = contents[contentUID] || {};
  const { status } = content;

  useEffect(() => {
    if (status === CREATOR_CONTENT_CONTENTS_STATUS.APPROVED) {
      setImg(content.standardResolutionImage);
    }
  }, [content]);

  const handleError = () => {
    return content.highResolutionImage;
  };

  function handleViewMedia() {
    if (status === CREATOR_CONTENT_CONTENTS_STATUS.APPROVED) {
      const { highResolutionImage, highResolutionVideo, type: contentType } = content;

      setModal({
        src: highResolutionVideo || highResolutionImage,
        type: contentType,
        show: true,
      });
    }
  }

  return (
    <div
      className={`creator-content-avatar ${status || 'pending'} ${creatorContentUID}`}
      role="presentation"
      onClick={handleViewMedia}
    >
      <AntAvatar size={50} shape="square">
        {status === CREATOR_CONTENT_CONTENTS_STATUS.APPROVED && (
          <ImageKitImage
            path={img}
            transformation={[
              {
                width: '60',
                height: '60',
              },
            ]}
            onError={handleError}
          />
        )}
      </AntAvatar>
      <div className={`creator-content-type type-${type}`}>{type}</div>
    </div>
  );
};

export default ContentAvatar;
