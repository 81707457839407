import React from 'react';
import { Layout, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'images/trend-logo-web.svg';
import { ReactComponent as BackIcon } from 'images/back-arrow-white.svg';
import './adminlayout.less';
import Container from 'components/Common/Container';
import AdminNav from './AdminNav';

const { Header, Content } = Layout;

const AdminLayout = ({ children }) => (
  <Layout id="admin-layout" className="layout">
    <Header>
      <div className="back">
        <Link to="/admin">
          <Icon component={BackIcon} />
          Return to Admin Section
        </Link>
      </div>
      <div className="logo">
        <Logo width="140px" height="60px" />
      </div>
    </Header>
    <Content>
      <div style={{ background: '#f6f7fb' }}>
        <AdminNav />
        <Container id="admin-layout" width="100%">
          {children}
        </Container>
      </div>
    </Content>
  </Layout>
);

export default AdminLayout;
