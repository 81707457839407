export const WRITE_CREATOR_CONTENTS = 'WRITE_CREATOR_CONTENTS';

/**
 * @param {Record<string, import("types").CreatorContent>} creatorContents
 * @returns {{ type: WRITE_CREATOR_CONTENTS, payload: creatorContents}}
 */
export const writeCreatorContents = creatorContents => ({
  type: WRITE_CREATOR_CONTENTS,
  payload: creatorContents,
});

/**
 * @typedef {ReturnType<writeCreatorContents>} CreatorContentActions
 */
