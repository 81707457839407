import { combineReducers } from 'redux';
import productContexts from './productContexts';
import entities from './entities';
import session from './session';
import exampleCampaigns from './exampleCampaigs';
import adminPages from './adminPages';
import brandContexts from './brandContexts';

/**
 * Main Reducer
 *
 * @typedef {ReturnType<mainReducer>} MainReducer
 */
const mainReducer = combineReducers({
  entities,
  productContexts,
  session,
  exampleCampaigns,
  adminPages,
  brandContexts,
});

export default mainReducer;
