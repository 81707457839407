import Promise from 'bluebird';
import { firebase } from 'lib/Firebase';

const uploadProcess = process =>
  new Promise((resolve, reject) => {
    process.on(
      'state_changed',
      () => {},
      error => {
        reject(error);
      },
      async () => {
        const url = process.snapshot.ref.getDownloadURL();
        resolve(url);
      }
    );
  });

const capture = async video => {
  const w = video.videoWidth;
  const h = video.videoHeight;
  const canvas = document.createElement('canvas');
  canvas.width = w;
  canvas.height = h;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, w, h);
  const file = await resolveFile(canvas);

  return file;
};

const resolveFile = canvas => {
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      const file = new File([blob], `thumb-${new Date().getTime()}`, {
        type: blob.type,
      });
      resolve(file);
    });
  });
};

export const uploadMessageMedia = async (messageUID, file) => {
  const metadata = {
    contentType: file.type,
  };
  const fileName = `${messageUID}-${file.name}-${new Date().getTime()}`;
  const storageRef = firebase.storage.ref('brands').child(`/messages/${messageUID}`);

  const uploadTask = storageRef.child(fileName).put(file, metadata);
  const url = await uploadProcess(uploadTask);

  let thumbFile;
  if (file.type.includes('video')) {
    const video = document.getElementById(`video-item-${file.name}`);
    thumbFile = await capture(video);
  }
  let thumbnail;
  if (thumbFile) {
    const thumbMetadata = {
      contentType: thumbFile.type,
    };
    const uploadThumbnailTask = storageRef.child(thumbFile.name).put(thumbFile, thumbMetadata);
    thumbnail = await uploadProcess(uploadThumbnailTask);
  }

  return {
    type: file.type,
    url,
    thumbnail,
  };
};
