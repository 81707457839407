import React from 'react';
import UpCaseFirstLetter from 'lib/Common/UpCaseFirstLetter';

/**
 *
 * @typedef {{ product: import("components/Session/Context").Product}} GenderProps
 * @type {React.FC<GenderProps>}
 */
const Gender = ({ product }) => {
  const { gender } = product;
  return (
    <div style={{ marginTop: '17px ' }}>
      <div className="side-sub-header-text">Gender</div>
      <div className="side-text-description">{UpCaseFirstLetter(gender || 'Any')}</div>
    </div>
  );
};

export default Gender;
