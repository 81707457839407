/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Spin } from 'antd';
import { brandGetShowcase } from 'lib/Firebase/callables/brands';
import mixpanel from 'lib/Analytics/Mixpanel';
import NavButton from 'components/ContentShowcase/NavButton';
import VideoArrow from 'components/ContentShowcase/VideoArrow';
import FAQSection from 'components/ContentShowcase/FAQSection';
import ShowcaseSection from 'components/ContentShowcase/ShowcaseSection';
import { ReactComponent as TrendLogo } from 'images/ContentShowcase/trend-logo-mobile-dark.svg';
import { useSession } from 'components/Session';
import * as routes from 'constants/routes';
import { useHistory } from 'react-router-dom';

/**
 *
 * @typedef {{}} ContentShowcaseProps
 * @type {React.FC<ContentShowcaseProps>}
 */

const categories = [
  'Product Photography',
  'Lifestyle Photos',
  'Product In Action Videos',
  'Unboxing Videos',
  'Testimonial Videos',
  'Portrait Style Photos',
];

const arrayTitles = [
  'productPhotography',
  'lifestyleOrActionPhotography',
  'actionOrProductInUseVideo',
  'unboxingExplanationVideo',
  'productReviewOrTestimonialVideo',
  'portraitStylePhotography',
];

const ContentShowcase = () => {
  const [contentChoice, setContentChoice] = useState([categories[0], arrayTitles[0]]);
  const [isFetching, setIsFetching] = useState(false);
  const [contentData, setContentData] = useState();
  const isMobileScreen = isMobile || window.innerWidth <= 700;
  const history = useHistory();
  const session = useSession(false);
  const hasSession = Boolean(session);
  const queryParams = window !== undefined && window.location.search.replace('?', '');

  let ownedCampaigns;
  if (hasSession) {
    ownedCampaigns = session.activeProductUIDs;
  }

  const hasCreatedCampaign = hasSession && ownedCampaigns.length !== 0;

  useEffect(() => {
    if (queryParams === 'latest_campaign') {
      history.push(routes.CAMPAIGN_PATH);
    }
    if (hasSession && hasCreatedCampaign && window.location.pathname === routes.PUBLIC_SHOWCASE) {
      history.push(routes.CONTENT_SHOWCASE_PATH);
    }
  }, [hasSession, hasCreatedCampaign, history, queryParams]);

  function onTabClick(category) {
    const index = categories.indexOf(category, 0);
    setContentChoice([category, arrayTitles[index]]);
  }

  function whatandWhyText() {
    switch (contentChoice[0]) {
      case 'Testimonial Videos':
        return (
          <p>
            <b>What?</b> Nothing beats real, authentic positive words about your product. These
            videos are honest show depictions of people's positive experiences using your product.
            <br />
            <b>Why?</b> These videos are worth their weight in gold. New customers can relate to
            people who look and think similarly to the way they do. This social proof provides an
            effective conversion increase for your product pages and social accounts.
          </p>
        );
      case 'Product In Action Videos':
        return (
          <p>
            <b>What?</b> These videos answer any question a customer could have about what your
            product is and how it works. Highlight all of your best features in one simple video.
            <br />
            <b>Why?</b> Giving your customers a step-by-step walkthrough of your product in action
            does a better job explaining how your product can improve their lives than any text ever
            could.
          </p>
        );
      case 'Product Photography':
        return (
          <p>
            <b>What?</b> Your product is the star of the show. Capture your product in its best
            possible light with creative staging. These photos bring your product and brand to life.
            <br />
            <b>Why?</b> You need fresh product photography to match the seasons on your websites and
            social channels. Request holiday specific styling to be prepared year round.
          </p>
        );
      case 'Lifestyle Photos':
        return (
          <p>
            <b>What?</b> Your product is made to be used in the real world. Show your customers how
            much better and easier life is with your product. Capture real people, in real settings
            and situations where your product shines.
            <br />
            <b>Why?</b> A picture is worth 1,000 words. These photos tell the story of how your
            customers' world improves with your product in their lives.
          </p>
        );
      case 'Portrait Style Photos':
        return (
          <p>
            <b>What?</b> People give soul to any great brand. When a brand develops its soul through
            loving customers, it becomes bigger than any given product. Portrait photography is your
            path to awaken the soul of your brand.
            <br />
            <b>Why?</b> Give your customer the chance to form connections with the people in the
            photos on your site. A product without a customer is not a product and these images both
            validate and mature your brand.
          </p>
        );
      default:
        return (
          <p>
            <b>What?</b> Showcase the most important moment in your relationship with your customer:
            the moment they fall in love with your product.
            <br />
            <b>Why?</b> Shoppers want to envision themselves owning and unboxing the product so they
            can fall in love too.
          </p>
        );
    }
  }

  function isSelected(category) {
    return category === contentChoice[0];
  }

  function scrollOnClick() {
    const titleElement = document.getElementById('faq-subsection');
    titleElement.scrollIntoView({ behavior: 'smooth' });
  }

  function toCreateCampaign(e) {
    e.preventDefault();
    if (mixpanel) {
      mixpanel.track('Onboarding from Content Showcase', { first: hasSession });
    }
    routes.toNewCampaign();
  }

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      const data = await brandGetShowcase();
      if (data) {
        setIsFetching(false);
        setContentData(data);
        if (mixpanel) {
          mixpanel.track('User visits Content Showcase');
        }
      }
    }
    fetchData();
  }, []);

  let contentSection;
  if (contentData !== undefined && isFetching === false) {
    contentSection = contentData[contentChoice[1]];
  }

  return (
    <div className={`${isMobileScreen ? 'content-showcase-page mobile' : 'content-showcase-page'}`}>
      <div
        className={`${
          isMobileScreen ? 'content-showcase-page-header mobile' : 'content-showcase-page-header'
        }`}
      >
        <div
          style={
            isMobileScreen ? {} : { paddingBottom: '40px', marginLeft: '25px', marginTop: '40px' }
          }
        >
          {isMobileScreen && (
            <>
              <div className="trend-logo mobile">
                <TrendLogo width="71px" height="12.96px" />
              </div>
              <div className="video-section">
                <iframe
                  className="info-video mobile"
                  src="https://www.youtube.com/embed/sdy9oIkdrUM?controls=0&mute=1&showinfo=0&rel=0&autoplay=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </>
          )}
          {/* TODO: Media Queries instead of isMobile */}
          <div className={`header-title ${isMobile ? 'mobile' : ''}`}>
            Create projects & get the content you need. Examples of what you can expect shown below
          </div>
          <p className={`header-text ${isMobile ? 'mobile' : ''}`}>
            Connect with content creators who will deliver the content you need with 100% licensing
            rights. We've compiled a list of{' '}
            <span id="faq-scroll" role="link" onClick={scrollOnClick} tabIndex={0}>
              frequently asked questions
            </span>{' '}
            below but please don’t hesitate to{' '}
            <a
              href="https://calendly.com/tati-e/trend-strategy"
              target="_blank"
              rel="noopener noreferrer"
            >
              schedule time with us
            </a>{' '}
            if you have more questions.
          </p>
          {!hasCreatedCampaign && (
            <div id="link-container">
              <button
                type="button"
                className="onboarding-link"
                style={{ border: 'none' }}
                onClick={toCreateCampaign}
              >
                Create Content Brief
              </button>
            </div>
          )}
        </div>

        {/* column 2 */}
        {!isMobileScreen && (
          <div className="video-section">
            <div className="content-stack">
              <div className="content-stack_item--top">
                <VideoArrow />
              </div>
              <div className="content-stack_item--bottom">
                <iframe
                  className="info-video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/sdy9oIkdrUM?controls=1&mute=1&showinfo=0&rel=0&autoplay=1&autohide=0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <nav
        className={`${
          isMobileScreen ? 'content-showcase-nav mobile' : 'content-showcase-nav center'
        }`}
      >
        <ul>
          {categories.map(category => (
            <li key={category}>
              <NavButton text={category} tabClick={onTabClick} selected={isSelected(category)} />
            </li>
          ))}
        </ul>
      </nav>
      <div className="content-choice-details">{whatandWhyText()}</div>
      {contentData && !isFetching ? (
        <ShowcaseSection
          isMobile={isMobileScreen}
          contentType={contentChoice[1]}
          contentItems={contentSection}
        />
      ) : (
        <div style={{ height: '100px' }}>
          <Spin />
        </div>
      )}
      <FAQSection isMobile={isMobileScreen} />
    </div>
  );
};

export default ContentShowcase;
