import React from 'react';
import moment from 'moment';
import Countdown from 'react-countdown';
import { toAddCredits } from '../../../constants/routes';

const AddCreditNotification = ({ unixDate, expired }) => {
  const hoursLater = moment(unixDate * 1000).add(24, 'hours');
  const Notification = () => {
    if (!expired) {
      return (
        <p>
          You will collect applications for another{' '}
          <span className="countdown">
            <Countdown date={hoursLater} daysInHours />
          </span>
          Add creator credits to accept your applications{' '}
          <span className="countdown">
            <Countdown date={hoursLater} daysInHours />
          </span>
          Add credits keep your listing live forever
          <span className="countdown">
            <Countdown date={hoursLater} daysInHours />
          </span>
        </p>
      );
    }
    return (
      <p>
        Add creator credits to hire current applicants and collect new applications from the network
      </p>
    );
  };

  return (
    <div
      className={`add-credit-message ${expired && 'expired'}`}
      role="presentation"
      onClick={() => toAddCredits()}
    >
      {[1, 2, 3, 4, 6, 7, 8].map(id => (
        <Notification key={id} />
      ))}
    </div>
  );
};

export default AddCreditNotification;
