import { useContext } from 'react';
import Context from './Context';

/**
 * Product Data Hook
 *
 * @returns {import('./Context').ProductContext}
 */
const useProductData = () => {
  const productData = useContext(Context);

  return productData;
};

export default useProductData;
