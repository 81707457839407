// Hubspot
import { useEffect, useState } from 'react';
import useCheckSession from '../Session/useCheckSession';
import { hubspotIdentify } from '../../api/brand';

function Hubspot() {
  const hasSession = useCheckSession();
  const [identification, setIdentification] = useState(null);

  useEffect(() => {
    window.hsConversationsSettings = { loadImmediately: false };

    const script = document.createElement('script');

    script.src = `https://js-na1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_TRACKING_CODE}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    if (hasSession) {
      hubspotIdentify(controller.signal)
        .then(data => {
          if (
            typeof data.identificationEmail === 'string' &&
            typeof data.identificationToken === 'string'
          ) {
            setIdentification({
              identificationEmail: data.identificationEmail,
              identificationToken: data.identificationToken,
            });
          } else {
            throw new Error('Invalid HubSpot identification data');
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error('Problem with identifying HubSpot user', e);
          setIdentification(null);
        });
    }

    return () => {
      controller.abort();
    };
  }, [hasSession]);

  useEffect(() => {
    function loadOrRefreshWidget() {
      const status =
        window.HubSpotConversations &&
        window.HubSpotConversations.widget &&
        window.HubSpotConversations.widget.status
          ? window.HubSpotConversations.widget.status()
          : {
              loaded: false,
            };

      if (
        status.loaded &&
        window.HubSpotConversations &&
        window.HubSpotConversations.widget &&
        window.HubSpotConversations.widget.refresh
      ) {
        window.HubSpotConversations.widget.refresh();
      } else if (
        !status.loaded &&
        window.HubSpotConversations &&
        window.HubSpotConversations.widget &&
        window.HubSpotConversations.widget.load
      ) {
        window.HubSpotConversations.widget.load();
      }
    }

    if (identification) {
      window.hsConversationsSettings = {
        loadImmediately: false,
        identificationEmail: identification.identificationEmail,
        identificationToken: identification.identificationToken,
      };

      if (window.HubSpotConversations) {
        loadOrRefreshWidget();
      } else {
        window.hsConversationsOnReady = [loadOrRefreshWidget];
      }
    }

    return () => {
      if (
        window.HubSpotConversations &&
        window.HubSpotConversations.widget &&
        window.HubSpotConversations.widget.remove
      ) {
        window.HubSpotConversations.widget.remove();
      }
    };
  }, [identification]);

  return null;
}

export default Hubspot;
