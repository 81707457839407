import { WRITE_PURCHASED_HISTORIES } from 'stores/actions/entities/purchasedHistories';

/**
 * @typedef {Record<string, import('types').PurchasedHistory>} PurchasedHistoriesReducer
 */
const initialState = {};

/**
 *
 * @param {PurchasedHistoriesReducer} state
 * @param {import('stores/actions/entities/purchasedHistories').EntityPurchasedHistoryActions} action
 * @returns
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WRITE_PURCHASED_HISTORIES: {
      return { ...state, ...action.payload };
    }

    default:
      return state;
  }
};

export default reducer;
