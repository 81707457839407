import React, { useState } from 'react';
import { Alert, Layout, Row } from 'antd';
import Logo from 'components/Common/Logo';
import { useSession } from 'components/Session';
import MinLayout from 'components/Common/MinLayout';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import Review from 'components/Review';
import PromoBanner from 'components/Common/PromoBanner';
import AddCreditNotification from './SideNav/AddCreditNotification';
import ContentCredit from './SideNav/ContentCredit';
import SideMenus from './SideNav/SideMenus/SideMenus';
import ReturnButton from './ReturnButton';
import ContentShowcaseTab from './SideNav/ContentShowcaseTab';
import ContentShowcase from '../../pages/Campaigns/ContentShowcase';
import MobileView from './MobileView';

const { Sider, Content } = Layout;

/**
 * @typedef {{
 * firebase: import('lib/Firebase').firebase
 * }} Props
 *
 * @extends {React.Component<Props>}
 */
const SiderLayout = ({ children }) => {
  const today = moment();
  const bannerDeadline = moment('2024-09-03');
  const bannerExtendedDeadline = moment('2024-09-07');

  const [cookies] = useCookies(['returnToken']);
  const { brand, activeProductUIDs: activeProducts } = useSession();
  const products = useSelector(selectors.getProducts());
  const campaignById = products;
  const ownedCampaigns = activeProducts;
  const [layoutConfig, setLayoutConfig] = useState({
    squishedNav: false,
  });

  const isMobileScreen = isMobile;

  const adminAlert = () => (
    <Row type="flex" justify="space-around">
      <MinLayout>
        <ReturnButton /> You are in admin mode! Logged in as &nbsp;
        <strong>{brand.name}</strong>.
      </MinLayout>
    </Row>
  );

  const { squishedNav } = layoutConfig;
  const {
    standardCredits = 0,
    addedStandardCredits = 0,
    status,
    lastPurchaseDate,
    approvedAt,
    uid: brandUID,
  } = brand;

  const { returnToken } = cookies;
  const tmpApprovedAt = approvedAt;
  const firstCampaign = campaignById[ownedCampaigns[0]];
  const firstCampaignCompleted = firstCampaign;
  const onBoarding = status && !lastPurchaseDate;
  const onBoardingApproved = onBoarding && status === 'approved';
  const onBoardingApprovedComplete = onBoardingApproved && firstCampaignCompleted;
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;

  let approvedAtDisplay =
    firstCampaign && firstCampaign.extendedTrialDate ? firstCampaign.extendedTrialDate : approvedAt;

  if (!approvedAtDisplay) {
    approvedAtDisplay =
      firstCampaign && firstCampaign.extendedTrialDate
        ? firstCampaign.extendedTrialDate
        : tmpApprovedAt;
  }

  const expiredOnBoardingCampaign =
    onBoardingApprovedComplete && moment.unix(approvedAtDisplay).add(24, 'hours') < moment();

  const extendedOnBoardingCampaign =
    onBoardingApprovedComplete &&
    firstCampaign &&
    firstCampaign.extendedTrialDate &&
    moment(firstCampaign.extendedTrialDate * 1000).add(24, 'hours') > moment();

  function showContentShowcase() {
    return window && window.location.pathname === '/campaigns/showcase';
  }

  if (isMobile) {
    return <MobileView />;
  }

  return (
    <div>
      <PromoBanner
        promoDeadline={bannerDeadline.format('Y-MM-DD')}
        extendedDeadline={bannerExtendedDeadline.format('Y-MM-DD')}
      />
      {(!isMobile || !isMobileScreen) && (
        <Layout className={today <= bannerExtendedDeadline ? 'with-top-banner' : null}>
          <Sider
            width={210}
            style={{
              overflow: 'hidden',
              height: '100%',
              position: 'fixed',
              zIndex: '99',
              left: '0',
              minHeight: '100%',
            }}
            breakpoint="lg"
            onBreakpoint={broken => {
              setLayoutConfig(state => ({ ...state, squishedNav: broken }));
            }}
          >
            {!squishedNav && <Logo />}
            <ContentCredit standardCredits={standardCredits + addedStandardCredits} />
            <ContentShowcaseTab isActive={showContentShowcase()} />
            <SideMenus squishedNav={squishedNav} brand={brand} />
          </Sider>

          <Layout
            id="trend-content-layout"
            className="with-top-banner"
            style={{ marginLeft: squishedNav ? '80px' : '210px' }}
          >
            <Content>
              {returnToken && (
                <Alert
                  className="admin-warning"
                  description={adminAlert(brand)}
                  type="warning"
                  style={{ paddingLeft: squishedNav ? '20px' : '110px' }}
                />
              )}
              {showContentShowcase() ? <ContentShowcase /> : children}
            </Content>
          </Layout>
          {!returnToken && <Review.PendingModal brandUID={brandUID} />}

          {showMarqueNotif && (
            <AddCreditNotification
              unixDate={approvedAtDisplay}
              expired={expiredOnBoardingCampaign && !extendedOnBoardingCampaign}
            />
          )}
        </Layout>
      )}

      {isMobileScreen && (
        <Layout>
          <Layout id="trend-content-layout">
            <Content>{showContentShowcase() ? <ContentShowcase /> : children}</Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
};

export default SiderLayout;
