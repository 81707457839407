import { Divider, Layout, Row, Col } from 'antd';
import MinLayout from 'components/Common/MinLayout';
import Product, { useProductData } from 'components/Products';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { selectors } from 'stores';
import { countApprovedPostsStats, countRequiredPost, countCreators } from 'helpers/counters';
// TODO: Can ExampleCampaigns be removed?

const { Content } = Layout;

/**
 *
 * @param {string[]} approvedPosts postUIDs
 * @param {Record<string, import('components/Products/Context').Post>} posts
 * @returns {(import('components/Products/Context').Post & { uid: string, username: string })[]}
 */
const getRecentPost = (approvedPosts, posts) => {
  return approvedPosts.slice(0, 3).map(postUID => ({
    ...posts[postUID],
    uid: postUID,
  }));
};

const Page = ({ productUID }) => {
  const { approvedPartnershipUIDs, approvedPostUIDs } = useProductData();
  const product = useSelector(selectors.getProduct(productUID));

  const partnerships = useSelector(selectors.getPartnerships());
  const posts = useSelector(selectors.getPosts());

  const [likeCount, commentCount] = countApprovedPostsStats(approvedPostUIDs, posts);
  const recentPosts = getRecentPost(approvedPostUIDs, posts);
  const requiredPosts = countRequiredPost(approvedPartnershipUIDs, partnerships);

  return (
    <div>
      <Content>
        <div className="campaign-content">
          <div className="nav-header">
            <Row type="flex" justify="space-around">
              <MinLayout>
                <Product.ExampleHeader productUID={productUID} />
              </MinLayout>
            </Row>
            <div style={{ borderBottom: '1px solid #dbdfe8' }} />
          </div>
        </div>
        <div style={{ paddingBottom: '79px', background: '#ffffff' }}>
          <Row type="flex" justify="space-around">
            <MinLayout>
              <Row gutter={{ lg: 64, md: 32, sm: 12 }}>
                <Col md={16} style={{ background: '#ffffff' }}>
                  <Product.Image image={product.image} />
                  <Product.Stats
                    creators={countCreators(partnerships)}
                    activePosts={approvedPostUIDs.length}
                    requiredPosts={requiredPosts}
                    likes={likeCount}
                    comments={commentCount}
                  />
                  <Product.PostGuidelines product={product} />
                  {Object.keys(product.styleGuide).length > 0 && (
                    <Product.Styleguides product={product} />
                  )}
                </Col>
                <Col md={8} className="pl-0">
                  <Product.RecentPosts posts={recentPosts} />
                  <Divider />
                  <Product.Details product={product} />
                  <Divider />
                  <Product.PostRequirements product={product} />
                  <Divider />
                  <Product.State product={product} />
                  <Divider />
                  <Product.Gender product={product} />
                </Col>
              </Row>
            </MinLayout>
          </Row>
        </div>
      </Content>
    </div>
  );
};

/**
 * Example Campaign Show Page
 *
 * @typedef {{}} ShowPageProps
 * @type {React.FC<ShowPageProps>}
 */
const ShowPage = () => {
  const { campaignId } = useParams();
  const product = useSelector(selectors.getProducts(campaignId));

  if (!product) {
    return <Redirect to="/" />;
  }

  return (
    <Product.Provider productUID={campaignId} product={product}>
      <Page productUID={campaignId} />
    </Product.Provider>
  );
};

export default ShowPage;
