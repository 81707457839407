import {
  ADD_ACTIVE_PRODUCTS_SESSION,
  ADD_COMPLETED_PARTNERSHIP_INFLUENCERS,
  ADD_COMPLETED_PRODUCTS_SESSION,
  INITIALIZE_SESSION,
  REMOVE_ACTIVE_PRODUCTS_SESSION,
  REMOVE_COMPLETED_PRODUCTS_SESSION,
  UPDATE_BRAND_SESSION,
  UPDATE_NOTIFICATION_SESSION,
  REMOVE_SESSION,
} from 'stores/actions/session';

/**
 * @param {SessionReducer} state
 * @param {string[]} productUIDs
 */
const addActiveProducts = (state, productUIDs) => {
  const activeProductUIDs = state.activeProductUIDs || [];
  const newState = { ...state };
  newState.activeProductUIDs = [...new Set([...activeProductUIDs, ...productUIDs])];
  return newState;
};

/**
 * @param {SessionReducer} state
 * @param {string[]} productUIDs
 */
const addCompletedProducts = (state, productUIDs) => {
  const completedProductUIDs = state.completedProductUIDs || [];
  const newState = { ...state };
  newState.completedProductUIDs = [...new Set([...completedProductUIDs, ...productUIDs])];
  return newState;
};

/**
 * @param {SessionReducer} state
 * @param {string[]} influencerUIDs
 */
const addCompltedPartnershipInfluencers = (state, influencerUIDs) => {
  const completeUIDs = state.completedPartnershipInfluencerUIDs || [];
  const newState = { ...state };
  newState.completedPartnershipInfluencerUIDs = [...new Set([...completeUIDs, ...influencerUIDs])];
  return newState;
};

/**
 * @param {SessionReducer} state
 * @param {string[]} productUIDs
 */
const removeCompletedProducts = (state, productUIDs) => {
  const completedProducts = state.completedProductUIDs;
  return {
    ...state,
    completedProductUIDs: completedProducts.filter(x => !productUIDs.includes(x)),
  };
};

/**
 * @param {SessionReducer} state
 * @param {string[]} productUIDs
 */
const removeActiveProducts = (state, productUIDs) => {
  const activeProducts = state.activeProductUIDs;
  return {
    ...state,
    activeProductUIDs: activeProducts.filter(x => !productUIDs.includes(x)),
  };
};

/**
 * @typedef {{
 *   brand: import("types").Brand,
 *   activeProductUIDs: string[],
 *   completedProductUIDs: string[],
 *   completedPartnershipInfluencerUIDs: string[],
 *   notification: import("types").Notification,
 *   categories: string[],
 * }} SessionReducer
 *
 * @type {SessionReducer}
 */
const initialState = null;

/**
 *
 * @param {SessionReducer} state
 * @param {import("stores/actions/session").SessionActions} action
 * @returns {SessionReducer}
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_SESSION: {
      return action.payload;
    }

    case UPDATE_BRAND_SESSION: {
      return {
        ...state,
        brand: action.payload,
      };
    }

    case ADD_ACTIVE_PRODUCTS_SESSION: {
      return addActiveProducts(state, action.payload);
    }

    case ADD_COMPLETED_PRODUCTS_SESSION: {
      return addCompletedProducts(state, action.payload);
    }

    case ADD_COMPLETED_PARTNERSHIP_INFLUENCERS: {
      return addCompltedPartnershipInfluencers(state, action.payload);
    }

    case REMOVE_COMPLETED_PRODUCTS_SESSION: {
      return removeCompletedProducts(state, action.payload);
    }

    case REMOVE_ACTIVE_PRODUCTS_SESSION: {
      return removeActiveProducts(state, action.payload);
    }

    case UPDATE_NOTIFICATION_SESSION: {
      return { ...state, notification: action.payload };
    }

    case REMOVE_SESSION: {
      return null;
    }

    default:
      return state;
  }
};

export default reducer;
