import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

const styles = {
  campaignTitle: {
    fontSize: '26',
    lineHeight: '30px',
  },
  userAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  userInfo: {
    marginLeft: '20px',
  },
};

/**
 * Product Header
 *
 * @typedef {{ productUID: string }} HeaderProps
 * @type {React.FC<HeaderProps>}
 */
const Header = ({ productUID } = {}) => {
  const product = useSelector(selectors.getProduct(productUID));
  let { name } = product || {};

  if (productUID === 'example') {
    name = 'Welcome to Trend';
  }

  return (
    <Row
      className="campaign-header"
      styles={styles.bannerWrapper}
      type="flex"
      justify="space-between"
    >
      <Col md={11} xs={20} style={{ paddingBottom: '19px' }}>
        <h1 style={styles.campaignTitle} className="mb-0">
          {name}
        </h1>
      </Col>
    </Row>
  );
};

export default Header;
