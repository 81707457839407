import React from 'react';
import { Button, notification } from 'antd';

const CopyProfileButton = ({ creatorUsername, isSuspended }) => {
  const handleCopyProfile = () => {
    const profileLink = `${process.env.REACT_APP_CREATOR_WEB_URL}/profile/${creatorUsername}`;
    navigator.clipboard.writeText(profileLink);
    notification.success({
      message: 'Link Copied',
      description: 'Creator profile link has been copied to clipboard',
    });
  };

  return (
    <Button
      onClick={handleCopyProfile}
      disabled={isSuspended}
      type="default"
      className="trend-btn medium-btn"
      block
    >
      Copy Profile Link
    </Button>
  );
};

export default CopyProfileButton;
