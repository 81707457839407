import { Empty } from 'antd';
import React from 'react';

const CustomEmpty = ({ description, descriptionStyle = {}, ...other } = {}) => (
  <Empty
    {...other}
    description={<span style={{ color: '#ddd', ...descriptionStyle }}>{description}</span>}
  />
);

export default CustomEmpty;
