import React from 'react';
import { Modal, Button } from 'antd';
import { ReactComponent as TruckIcon } from '../../../images/shipping-truck-red.svg';

const NonShippableConfirmationModal = ({
  visible = false,
  handleNonShippableConfirmation,
  handleNonShippableCancel,
}) => {
  return (
    <Modal
      className="non-shippable-modal"
      visible={visible}
      closable={false}
      onOk={handleNonShippableConfirmation}
      onCancel={handleNonShippableCancel}
      footer={[
        <React.Fragment key="non-shippable-footer">
          <Button
            key="submit"
            type="secondary"
            className="non-shippable-confirm-button"
            onClick={handleNonShippableConfirmation}
          >
            Continue Without Tracking Code
          </Button>

          <Button
            key="back"
            type="primary"
            className="non-shippable-cancel-button"
            onClick={handleNonShippableCancel}
          >
            Add Tracking Code
          </Button>
        </React.Fragment>,
      ]}
    >
      <TruckIcon />
      <h2>Do you have a tracking code?</h2>
      <p>If you do not add a tracking number your content could be subject to delay.</p>
      <p>
        Content delivery dates are set the moment your package is delivered to a creator. The only
        way for our system to know if a package is delivered is if you add a tracking code.
      </p>
    </Modal>
  );
};

export { NonShippableConfirmationModal };
