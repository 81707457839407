import { Alert, Modal, Row, Col, Button, Divider, Radio, Input } from 'antd';
import { REJECT_REASONS } from 'constants/influencers';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { calculatePartnershipCreditCost } from 'utils/content';

const { Group: RadioGroup } = Radio;
const { TextArea } = Input;
const Reasons = REJECT_REASONS.map(([reasonShow, reasonMessage]) => (
  <Radio
    key={reasonShow}
    style={{ display: 'block', height: '30px', lineHeight: '30px' }}
    value={reasonMessage}
  >
    {reasonShow}
  </Radio>
));

/**
 * Reject Creator Modal
 * NOTE: Is actually remove creator modal
 *
 * @typedef {{show: boolean, onClose: () => any, onConfirm: () => any }} RejectCreatorModalProps
 * @type {React.FC<RejectCreatorModalProps>}
 */
const RejectCreatorModal = ({ partnershipUID, show, onClose, onConfirm }) => {
  const [reason, setReason] = useState(REJECT_REASONS[0][1]);
  const [customReason, setCustomReason] = useState();
  const { influencerUID } = useSelector(selectors.getPartnership(partnershipUID)) || {};
  const influencer = useSelector(selectors.getInfluencer(influencerUID)) || {};
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    submitting: false,
    hasSubmitted: false,
  });
  const credits = calculatePartnershipCreditCost(influencer);

  async function handleConfirm() {
    setConfirmModal(state => ({ ...state, submitting: true, hasSubmitted: true }));
    const rejectMessage = reason || customReason;
    if (rejectMessage) {
      console.log('Reason: ', rejectMessage);
      await onConfirm(partnershipUID, rejectMessage);
      onClose();
      setConfirmModal(state => ({ ...state, show: false }));
    }
    setConfirmModal(state => ({ ...state, submitting: false }));
  }

  function closeAll() {
    onClose();
    setConfirmModal(s => ({ ...s, show: false }));
  }

  const { submitting, hasSubmitted } = confirmModal;

  return (
    <>
      <Modal
        visible={show && !confirmModal.show}
        closable={false}
        className="trend-modal"
        footer={null}
      >
        <span className="label">
          Are you sure you want to&nbsp;
          <span className="highlighted">remove this Creator?</span>
          Creators should only be removed in an emergency situation.
        </span>

        <Row style={{ marginTop: '20px' }} gutter={20} key="reject-modal">
          <Col span={12}>
            <Button
              key="cancel"
              onClick={closeAll}
              className="trend-btn medium-btn secondary"
              block
            >
              Cancel
            </Button>
          </Col>
          <Col span={12}>
            <Button
              key="submit"
              onClick={() => setConfirmModal(s => ({ ...s, show: true }))}
              className="trend-btn medium-btn"
              block
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={confirmModal.show}
        onOk={handleConfirm}
        onCancel={onClose}
        className="trend-modal"
        footer={[
          <Row gutter={20} key="reject-modal">
            <Col span={12}>
              <Button
                key="cancel"
                disabled={submitting}
                onClick={closeAll}
                className="trend-btn medium-btn secondary"
                block
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                key="submit"
                loading={submitting}
                onClick={handleConfirm}
                className="trend-btn medium-btn reject"
                block
              >
                Confirm Reject
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <h2 className="text-black mb-2 type-ebold leading-24px">Cancel partnership with creator</h2>
        <Divider />
        <div>
          You're about to remove this creator from your project. You will get back {credits}{' '}
          credits.
        </div>
        <RadioGroup
          onChange={val => setReason(val.target.value)}
          value={reason}
          style={{ marginTop: '10px' }}
        >
          {Reasons}
        </RadioGroup>
        {!reason && (
          <TextArea
            rows={4}
            onChange={val => setCustomReason(val.target.value)}
            name="rejectCustomReason"
            value={customReason}
          />
        )}
        {!reason && !customReason && hasSubmitted && (
          <Alert
            message="Please enter a reason to explain to the creator why they are being rejected."
            type="warning"
            style={{ marginTop: '5px' }}
          />
        )}
      </Modal>
    </>
  );
};

export default RejectCreatorModal;
