/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import { Icon, Col, Row, Spin } from 'antd';
import { ReactComponent as PlayButtonSVG } from 'images/play-btn.svg';
import { isFileImage } from 'helpers/isImage';
import MediaViewerContext from './Context';
import Image from '../Image';
/**
 * Media Viewer Content Component
 *
 * @type {React.FC<{ type: 'image' | 'video', src: string }>}
 */
const Content = ({ type, src, overrideHandleClick }) => {
  const { setModal } = useContext(MediaViewerContext);
  const [loaded, setLoaded] = useState(false);
  const [isImage, setIsImage] = useState(isFileImage(src));
  const [hidePlayButton, setHidePlayButton] = useState('auto');

  function handleClick() {
    setModal({ show: true, type, src });
  }

  const handleImageError = () => {
    if (isFileImage(src)) {
      setIsImage(false);
    }
    return 'https://via.placeholder.com/300';
  };

  const handleVideoError = () => {
    if (!isFileImage(src)) {
      setIsImage(true);
    }
  };

  return (
    <div
      className="media-viewer container"
      tabIndex={0}
      role="button"
      onClick={overrideHandleClick || handleClick}
    >
      {isImage ? (
        <Image src={src} onError={handleImageError} />
      ) : (
        <div className="video-loader">
          {loaded && (
            <Row
              justify="center"
              align="middle"
              type="flex"
              className="video-player-actions"
              onMouseOver={() => setHidePlayButton('none')}
              style={{ display: hidePlayButton }}
            >
              <Col>
                <Icon component={PlayButtonSVG} style={{ width: '100%' }} />
              </Col>
            </Row>
          )}
          <video
            onLoadedData={() => setLoaded(true)}
            onError={handleVideoError}
            loop
            muted
            onMouseOver={event => {
              event.target.play();
            }}
            onMouseOut={event => {
              event.target.pause();
              // small delay till mouse is over
              setTimeout(() => {
                setHidePlayButton('flex');
              }, 100);
            }}
          >
            <source src={src} />
          </video>
          {!loaded && <Spin />}
        </div>
      )}
    </div>
  );
};

export default Content;
