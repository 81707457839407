import React, { useState } from 'react';
import { Row } from 'antd';
import Container from 'components/Common/Container';
import CreatorProfileDrawer, {
  PrefetchCreatorProfilePages,
} from 'components/Influencers/Drawer/CreatorProfileDrawer';
import { useProductData } from 'components/Products';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCampaignContents } from 'api/content';
import { toContentCollection } from 'constants/routes';
import ApprovedContentTable from './ApprovedContentTable/ApprovedContentTable';
import ApprovalCard from './ApprovalCard/ApprovalCard';

/**
 * Campaign Post Approval Page
 *
 * @type {React.FC}
 */
const Approvals = () => {
  /** State */
  const { messageUIDs, invitedInfluencerUIDs, productUID } = useProductData();
  const { data, isLoading } = useCampaignContents(productUID);
  const messages = useSelector(selectors.getMessages());
  const history = useHistory();
  const { params } = useRouteMatch();

  /** Managed State */
  const [influencerDrawer, setInfluencerDrawer] = useState({
    influencerUID: null,
    show: false,
  });

  /** Action Handlers */
  function handleViewProfile(influencerUID) {
    setInfluencerDrawer(s => ({ ...s, influencerUID, show: true }));
  }

  const handleCloseProfileDrawer = () => setInfluencerDrawer(state => ({ ...state, show: false }));

  function handleViewMessage(influencerUID) {
    const [messageUID] = messageUIDs.filter(x => messages[x].users[influencerUID]);
    history.push(`/campaigns/${params.campaignId}/messages/${messageUID}`);
  }

  function onApprovalCardClick(creatorId) {
    toContentCollection(params.campaignId, creatorId);
  }

  if (isLoading || !data) {
    return <></>;
  }

  const { submissions, approvals } = data;
  const displaySubmissions = submissions.sort((a, b) => {
    if (a.status !== b.status) {
      return a.status === 'pending' ? -1 : 1;
    }
    if (a.approvalAt && b.approvalAt) {
      return a.approvalAt - b.approvalAt;
    }
    if (a.approvalAt || b.approvalAt) {
      return a.approvalAt ? -1 : 1;
    }
    return a.submittedAt - b.submittedAt;
  });

  return (
    <>
      <Row id="influencers-page">
        <div id="creator-content-approval-content">
          <div
            className="submitted-contents"
            style={{ overflowX: submissions.length > 0 ? 'scroll' : 'hidden' }}
          >
            <Container width={742}>
              {displaySubmissions.length === 0 ? (
                <p className="empty">NO NEW CONTENT TO APPROVE</p>
              ) : (
                <div style={{ minWidth: '100%', width: (219 + 20) * displaySubmissions.length }}>
                  {displaySubmissions.map(
                    ({ creatorId, previewContent, numNewContent, approvalAt, status }) => (
                      <ApprovalCard
                        key={creatorId}
                        creatorId={creatorId}
                        previewContent={previewContent}
                        numNewContent={numNewContent}
                        approvalAt={approvalAt}
                        status={status}
                        onClick={() => onApprovalCardClick(creatorId)}
                      />
                    )
                  )}
                </div>
              )}
            </Container>
          </div>
          <div className="page-header">
            <Container width={742}>
              <h2>Content Approval</h2>
              <p>
                Creators work hard to represent your brand and provide you with relatable content
                you need to grow through your social channels, websites, and paid ads. Once you
                approve this content it's yours to keep for ever with 100% usage rights. Make sure
                you communicate with creators BEFORE they shoot content to make sure you're aligned
                on the shoot.
              </p>
            </Container>
          </div>
          <ApprovedContentTable
            approvals={approvals}
            handleViewProfile={handleViewProfile}
            onApprovalCardClick={onApprovalCardClick}
          />
          <PrefetchCreatorProfilePages influencerUIDs={invitedInfluencerUIDs} />
          {influencerDrawer.show && (
            <CreatorProfileDrawer
              {...influencerDrawer}
              drawerType="message"
              handleMessageCreator={handleViewMessage}
              handleClose={handleCloseProfileDrawer}
            />
          )}
        </div>
      </Row>
    </>
  );
};

export default Approvals;
