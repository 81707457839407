import React, { useState } from 'react';
import { Button } from 'antd';
import { ReactComponent as FilledHeart } from 'images/FavoritingIcons/Heart_Filled.svg';
import { ReactComponent as EmptyHeart } from 'images/FavoritingIcons/Heart_Empty.svg';

const FavoriteFilterButton = ({ onChange }) => {
  const [isActive, setIsActive] = useState(false);

  function handleFavoritesChange(type) {
    onChange({ [type]: !isActive });
    setIsActive(!isActive);
  }

  const buttonClass = isActive ? 'filter-favorites-button active' : 'filter-favorites-button';
  const icon = isActive ? <FilledHeart /> : <EmptyHeart />;
  return (
    <div
      style={{
        borderRight: '1px solid #A9B0B9',
        width: '109px',
        display: 'inline',
        paddingRight: '10px',
      }}
    >
      <Button
        onClick={() => handleFavoritesChange('isFavorite')}
        style={{
          width: 98,
          marginRight: '12px',
        }}
        className={buttonClass}
      >
        <span>
          {icon}{' '}
          <div id="button-text" style={{ padding: '0 0 0 6px' }}>
            Favorites
          </div>
        </span>
      </Button>
    </div>
  );
};

export default FavoriteFilterButton;
