/* eslint-disable */
import { Row } from 'antd';
import React from 'react';

/**
 * @type {React.FC}
 */
const PrivacyPolicy = () => ((
  <Row type="flex" justify="space-around">
    <div className="min-layout-content" style={{ textAlign: 'justify', color: 'white' }}>
      <div class="terms-header-wrapper">
        <div class="contact-header-content">
          <div class="contact-heading-wrapper">
            <h1 style={{ color: 'white' }}>Trend.io Privacy Policy</h1>
          </div>
        </div>
        <div class="text-rich-text w-richtext">
          <p>
            This Privacy Policy describes how Yeuma Inc. (dba “Trend”) and our subsidiaries and
            affiliates (collectively, “<strong>Trend.io</strong>”, “<strong>we</strong>”, “
            <strong>us</strong>”, or “<strong>our</strong>”) collect, use, and share your personal
            information in connection with our website at{' '}
            <a href="http://www.trend.io/?">www.trend.io</a>, our related services, email and other
            electronic communications, social media pages, and marketing activities (collectively,
            our “<strong>website</strong>”), and the rights and choices available to individuals
            with respect to their personal information.
          </p>
          <p>
            <strong>Collection of Information</strong>
          </p>
          <p>We may collect various types of information, such as:</p>
          <ul role="list">
            <li>
              Voluntarily provided information which may include your name, address, email address,
              billing and/or credit card information etc., which may be used when you purchase
              products and/or services and to deliver the services you have requested.
            </li>
            <li>
              Information automatically collected when visiting our website, which may include
              cookies, third party tracking technologies and server logs.
            </li>
          </ul>
          <p>
            Trend.io may have the occasion to collect anonymous demographic information, such as
            age, gender, household income, political affiliation, race and religion at a later time.
            We may also gather information about the type of browser you are using, IP address or
            type of operating system to assist us in providing and maintaining superior quality
            service, and other device and online activity data.
          </p>
          <p>
            It is highly recommended and suggested that you review the privacy policies and
            statements of any website you choose to use or frequent as a means to better understand
            the way in which other websites garner, make use of and share information collected.
          </p>
          <p>
            <strong>Use of Information Collected</strong>
          </p>
          <p>
            Trend.io may collect and may make use of personal information to assist in the operation
            of our website and business, to process your transactions, to communicate with you about
            the website, to respond to inquiries and requests, for compliance, fraud prevention, and
            protection purposes, and to ensure delivery of the services you need and request.
          </p>
          <p>
            We may also use personal information to understand and analyze the usage trends
            &nbsp;and preferences of our users to make our website and other offerings better,
            diagnose technical issues, and develop new features and functionality.
          </p>
          <p>
            At times, we may &nbsp;use personal information for marketing and promotional
            communications as a means to keep you informed of other possible products and/or
            services that may be available to you from Trend.io and our partners. Trend.io may also
            be in contact with you with regards to completing surveys and/or research questionnaires
            related to your opinion of current or potential future services that may be offered.
          </p>
          <p>
            We, our business partners, and our third party advertising partners may also collect and
            use your personal information for advertising purposes. For example, we may contract
            with third-party advertising companies and social media companies to help us advertise
            our services, identify potential customers, and display ads for Trend.io on other sites
            and services, including through the use of interest-based advertising. These companies
            may use cookies and similar technologies to collect information about you over time
            across our website and other sites and services, and use that information to serve ads
            that they think will interest you and/or use hashed customer lists that we share with
            them to deliver ads to you and to similar users on their platforms. Trend.io may deem it
            necessary to follow websites and/or pages that our users may frequent in an effort to
            gleam what types of services and/or products may be the most popular to customers or the
            general public.
          </p>
          <p>
            <strong>Sharing of Information </strong>
          </p>
          <p>
            Trend.io may disclose your personal information as described in this Privacy Policy or
            at the time of collection of your personal information, including:
          </p>
          <ul role="list">
            <li>
              To our service providers, including individuals and third parties &nbsp;who perform
              function on our behalf or help us run our business and website;
            </li>
            <li>
              To related companies, such as affiliates and subsidiaries that we may have now or in
              the future;
            </li>
            <li>To other users or the public, such as when you post a project on our website;</li>
            <li>
              To business transaction participants, such as if we sell all or part of its business
              or make a sale or transfer of assets or are otherwise &nbsp;involved in a corporate
              divestiture, financing, merger, consolidation, acquisition, reorganization, sale,
              spin-off, &nbsp;or other disposition of all or any portion of the business or assets
              of, or equity interests in, Trend.io or our related companies (including, in
              connection with a bankruptcy or similar proceedings), we may transfer your personal
              information to a third party as part of that transaction, including at the negotiation
              stage;
            </li>
            <li>
              To our advertising partners, such as when we share personal information with
              third-party advertising companies that collect personal information about your
              activity on our website and other online services to help us advertise our services,
              and/or use hashed customer lists that we share with them to deliver ads to them and
              similar users on their platforms.
            </li>
            <li>
              If required to do so in accordance with applicable laws and/or in a good faith belief
              that such action is deemed necessary or is required in an effort to:
            </li>
            <li>
              Remain in conformance with any decrees, laws and/or statutes or in an effort to comply
              with any process which may be served upon Trend.io and/or our website;
            </li>
            <li>
              Maintain, safeguard and/or preserve all the rights and/or property of Trend.io; and
            </li>
            <li>
              Perform under demanding conditions in an effort to safeguard the personal safety of
              users of www.trend.io and/or the general public.
            </li>
          </ul>
          <p>
            <strong>Children Under Age of 13</strong>
          </p>
          <p>
            Trend.io does not knowingly collect personal identifiable information from children
            under the age of thirteen (13) without verifiable parental consent. If it is determined
            that such information has been inadvertently collected on anyone under the age of
            thirteen (13), we shall immediately take the necessary steps to ensure that such
            information is deleted from our system's database. Anyone under the age of thirteen (13)
            must seek and obtain parent or guardian permission to use this website.
          </p>
          <p>
            <strong>Unsubscribe or Opt-Out</strong>
          </p>
          <p>
            All users and/or visitors to our website have the option to discontinue receiving
            communication from us and/or reserve the right to discontinue receiving communications
            by way of email or newsletters. To discontinue or unsubscribe to our website please send
            an email that you wish to unsubscribe to Support@trend.io. If you wish to unsubscribe or
            opt-out from any third party websites, you must go to that specific website to
            unsubscribe and/or opt-out.
          </p>
          <p>
            <strong>Links to Other Web Sites</strong>
          </p>
          <p>
            Our website does contain links to affiliate and other websites. Trend.io does not claim
            nor accept responsibility for any privacy policies, practices and/or procedures of other
            such websites. Therefore, we encourage all users and visitors to be aware when they
            leave our website and to read the privacy statements of each and every website that
            collects personally identifiable information. The aforementioned Privacy Policy
            Agreement applies only and solely to the information collected by our website.
          </p>
          <p>
            <strong>Security</strong>
          </p>
          <p>
            Trend.io shall endeavor and shall take every precaution to maintain adequate physical,
            procedural and technical security with respect to our offices and information storage
            facilities so as to prevent any loss, misuse, unauthorized access, disclosure or
            modification of the user's personal information under our control.
          </p>
          <p>
            The company also uses Secure Socket Layer (SSL) for authentication and private
            communications in an effort to build users' trust and confidence in the internet and
            website use by providing simple and secure access and communication of credit card and
            personal information.
          </p>
          <p>
            <strong>Changes to Privacy Policy Agreement</strong>
          </p>
          <p>
            Trend.io reserves the right to update and/or change the terms of our privacy policy, and
            as such we will post those changes to our website homepage at www.trend.io, so that our
            users and/or visitors are always aware of the type of information we collect, how it
            will be used, and under what circumstances, if any, we may disclose such information. If
            at any point in time Trend.io decides to make use of any personally identifiable
            information on file, in a manner vastly different from that which was stated when this
            information was initially collected, the user or users shall be promptly notified by
            email. Users at that time shall have the option as to whether or not to permit the use
            of their information in this separate manner.
          </p>
          <p>
            <strong>Giveaway Terms</strong>
            <br />
            By providing your email address, you agree to our Privacy Policy and Terms and
            Conditions TERMS: NO PURCHASE NECESSARY. ELIGIBILITY: Must be eighteen (18) (or age of
            majority in resident state) or older to enter. This Giveaway is open only to individuals
            who are residents of the United States of America and the District of Columbia
            (excluding residents of overseas military installations, Puerto Rico, and other U.S.
            Territories). WINNER NOTIFICATION: Winner be will be chosen at random. Winners will be
            notified via email. CONDITIONS: Prizes are non-transferable and non-refundable. No cash
            redemption or substitution will be allowed. By entering the sweepstakes, I agree to sign
            up to receive emails, texts, and special offers from Trend. The sweepstakes is subject
            to federal, state, municipal, and local laws and regulations and is void where
            prohibited.
          </p>
          <p>
            <strong>Acceptance of Terms</strong>
          </p>
          <p>
            Through the use of this website, you are hereby accepting the terms and conditions
            stipulated within the aforementioned Privacy Policy Agreement. If you are not in
            agreement with our terms and conditions, then you should refrain from further use of our
            sites. In addition, your continued use of our website following the posting of any
            updates or changes to our terms and conditions shall mean that you are in agreement and
            acceptance of such changes.
          </p>
          <p>
            <strong>How to Contact Us</strong>
          </p>
          <p>
            If you have any questions or concerns regarding the Privacy Policy Agreement related to
            our website, please feel free to contact us at the following email, telephone number or
            mailing address.
          </p>
          <p>
            <strong>Email:</strong> support@trend.io
          </p>
          <p>
            <strong>Telephone Number:</strong>(512) 790-6764
          </p>
          <p>
            <strong>Mailing Address:</strong>
          </p>
          <p>Trend</p>
          <p>701 Brazos St</p>
          <p>Austin, Texas 78701</p>
        </div>
      </div>
    </div>
  </Row>
));

export default PrivacyPolicy;
