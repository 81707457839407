/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Tabs, Drawer, Avatar, Row, Icon, Col, Button, message } from 'antd';
import hasPayOutTiers from 'lib/Common/HasPayOutTiers';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { setCreatorBypassIsOverdueFlag } from 'api/creators';
import RecentPost from './RecentPost';
import Stats from './Stats';
import Biography from '../Biography';
import { ReactComponent as CloseIcon } from '../../../images/close-x.svg';
import Reviews, { Stats as ReviewStats } from '../Reviews';
import Badge from '../Badge';
import ActiveCampaign from './ActiveCampaign';
import CompletedCampaign from './CompletedCampaign';
import { firebase } from '../../../lib/Firebase';
import Socials from './Socials';
import CopyProfileButton from './CopyProfileButton';

const { TabPane } = Tabs;

const InfluencerDrawer = ({ influencerUID, actionButtons, onHide, show }) => {
  const influencer = useSelector(selectors.getInfluencer(influencerUID));
  const partnerships = useSelector(selectors.getPartnerships());
  const partnershipUIDs = useSelector(selectors.getInfluencerPartnerships(influencerUID)) || [];
  const [submittingFixData, setSubmittingFixData] = useState(false);
  const [resettingOverdueStatus, setResettingOverdueStatus] = useState(false);
  const dispatch = useDispatch();

  if (!influencer) return <div />;

  function handleHide() {
    if (onHide) {
      onHide();
    }
  }

  if (!actionButtons) {
    return <div />;
  }

  const {
    avgCommentCount = 0,
    avgLikeCount = 0,
    images = [],
    biography,
    followedByCount,
    engagementRate,
    authEmail,
    paypalEmail,
    phoneNumber,
    state,
    username,
    ageRange,
    star,
    stats = {},
    avgAttitudeProfessionalism = 5,
    avgContentQuality = 5,
    image,
    payOutTiers,
    socials,
    isVIP,
    isOverdue,
    bypassIsOverdueFlag,
    isSuspended,
  } = influencer;
  const creatorUsername = influencer.creatorUsername || influencer.creatorId;

  let buttons = actionButtons(influencerUID, influencer && !isSuspended);
  const colspan = 24 / buttons.length;

  buttons = buttons.map((action, index) => {
    return (
      <Col span={colspan} key={index}>
        {action}
      </Col>
    );
  });

  const lastWeek = moment()
    .subtract(1, 'week')
    .format('YYYY-w');

  const reviews = {
    avgAttitudeProfessionalism,
    avgContentQuality,
  };

  const fixData = async () => {
    setSubmittingFixData(true);
    try {
      await firebase.fixInfluencersData({ mainInfluencerUID: influencerUID });
      setSubmittingFixData(false);
    } catch (e) {
      setSubmittingFixData(false);
    }
  };

  const resetOverdueStatus = async () => {
    setResettingOverdueStatus(true);
    const result = await setCreatorBypassIsOverdueFlag(influencerUID, true);

    if (result) {
      dispatch(
        actions.entities.influencers.addInfluencers({
          [influencerUID]: { ...influencer, bypassIsOverdueFlag: true },
        })
      );
      handleHide();
      message.success('Successfully reseted the overdue status');
    } else {
      message.error('Could not reset the overdue status');
    }

    setResettingOverdueStatus(false);
  };

  const activePartnerships = partnershipUIDs
    .filter(x =>
      ['active', 'shipped', 'needsShipping', 'postSubmitted'].includes(partnerships[x].status)
    )
    .map(partnershipUID => <ActiveCampaign key={partnershipUID} partnershipUID={partnershipUID} />);

  const completedPartnerships = partnershipUIDs
    .filter(x => partnerships[x].status === 'completed')
    .map(partnershipUID => (
      <CompletedCampaign key={partnershipUIDs} partnershipUID={partnershipUID} />
    ));

  let credits;
  if (!hasPayOutTiers(payOutTiers)) {
    credits = isVIP ? { social: 2, content: 2 } : { social: 1, content: 1 };
  } else {
    credits = payOutTiers;
  }

  return (
    <Drawer
      id="influencer-drawer-admin"
      placement="right"
      width={328}
      visible={show}
      onClose={handleHide}
      closable={false}
      maskClosable
    >
      <div className="ant-drawer-header-no-title">
        <button onClick={handleHide} type="button" aria-label="Close" className="ant-drawer-close">
          <Icon component={CloseIcon} />
        </button>
      </div>
      <div className="influencer-info">
        <div className="influencer-info-avatar">
          <a href={`https://instagram.com/${username}`} target="_blank" rel="noopener noreferrer">
            <Avatar size={90} src={image} />
          </a>
          <Badge level={star} />
        </div>
        <h1 className="influencer-info-name">@{username}</h1>
        <div className="influencer-info-address">
          {state} | Age: {ageRange}
          <br />
          Paypal: {paypalEmail}
          <br />
          Email: {authEmail}
          <br />
          Phone: {phoneNumber}
        </div>
      </div>
      <Row>
        <Socials socials={socials} />
      </Row>
      <Row className="influencer-levels">
        <p className="admin-credit-text text-center">Social {credits.social} credits</p>
        <p className="admin-credit-text text-center">Content {credits.content} credits</p>
      </Row>
      <Row className="influencer-info-actions" gutter={8}>
        {buttons}
      </Row>
      <Row className="influencer-info-actions" gutter={8}>
        <Button
          loading={submittingFixData}
          onClick={() => fixData()}
          type="default"
          className="trend-btn"
          block
        >
          Fix Data
        </Button>
        {isOverdue && (
          <Button
            loading={resettingOverdueStatus}
            onClick={() => resetOverdueStatus()}
            type="ghost"
            block
            disabled={bypassIsOverdueFlag}
          >
            {!bypassIsOverdueFlag ? 'RESET OVERDUE STATUS' : 'OVERDUE STATUS RESETED'}
          </Button>
        )}
        <CopyProfileButton creatorUsername={creatorUsername} isSuspended={isSuspended} />
      </Row>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Profile" key="1">
          <Stats
            followedByCount={followedByCount}
            avgCommentCount={avgCommentCount}
            avgLikeCount={avgLikeCount}
            stats={stats[lastWeek]}
            engagementRate={engagementRate}
          />
          <ReviewStats reviews={reviews} />
          <Reviews influencerUID={influencerUID} />
          <RecentPost images={images} />
          <Biography text={biography} />
        </TabPane>
        <TabPane tab="Active" key="2">
          <div className="influencer-campaigns">{activePartnerships}</div>
        </TabPane>
        <TabPane tab="History" key="3">
          <div className="influencer-campaigns">{completedPartnerships}</div>
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default InfluencerDrawer;
