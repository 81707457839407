/**
 * @typedef
 * {ReturnType<addPosts> |
 * ReturnType<updatePosts>}
 * EntityPostActions
 */
export const ADD_POSTS = 'ADD_POSTS';

/**
 * @param {Record<string, import("types").Post>} posts
 * @returns {{ type: ADD_POSTS, payload: Record<string, import("types").Post> }}
 */
export const addPosts = posts => ({
  type: ADD_POSTS,
  payload: posts,
});

export const UPDATE_POSTS = 'UPDATE_POSTS';

/**
 * @param {Record<string, import("types").Post>} posts
 * @returns {{ type: UPDATE_POSTS, payload: Record<string, import("types").Post> }}
 */
export const updatePosts = posts => ({
  type: UPDATE_POSTS,
  payload: posts,
});
