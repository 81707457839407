import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import moment from 'moment';
import { brandCreateReferral, brandGetReferrals } from 'lib/Firebase/callables/brands';
import Container from '../../components/Common/Container';
import GiantCheckImg from '../../images/Giant_Check@2x.png';
import ReferImg from '../../images/Referred_by_friend@2x.png';

const REFERRAL_STATUS_MESSAGES = {
  approved: 'Referral Confirmed!',
  pending: 'Pending Trend Approval...',
  rejected: 'Rejected',
};

const ReferPage = ({ form }) => {
  const [referrals, setReferrals] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const { getFieldDecorator, getFieldError, validateFieldsAndScroll } = form;

  useEffect(() => {
    const fetch = async () => {
      try {
        const list = await brandGetReferrals();
        setReferrals(list);
      } catch (e) {
        console.error(e);
      }
    };

    fetch();
  }, []);

  const onSubmit = e => {
    e.preventDefault();

    validateFieldsAndScroll(async (error, fields) => {
      if (error) {
        return;
      }

      setSubmitting(true);

      try {
        const referral = {
          ...fields,
          contactName: fields.contactEmail,
          status: 'pending',
          submittedAt: moment().unix(),
        };

        await brandCreateReferral(referral);
        const list = await brandGetReferrals();
        setReferrals(list);

        setSubmitting(false);

        form.resetFields();
        message.success('Successfully adding referral', 3);
      } catch (e) {
        message.error(e.message, 3);
        setSubmitting(false);
      }
    });
  };

  const referrralList = referrals.map(referral => {
    return (
      <div
        key={referral.uid || `${referral.companyName}.${new Date().getTime()}`}
        className="referral"
      >
        {referral.status === 'approved' && (
          <div className="referral-icon">
            <img src={GiantCheckImg} alt="approved" />
          </div>
        )}
        <div className="referral-details">
          <div className="referral-details-brand">{referral.companyName}</div>
          <div className={`referral-details-status ${referral.status}`}>
            {REFERRAL_STATUS_MESSAGES[referral.status]}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div id="refer-brand-page">
      <div className="tab-header">
        <Container width={733}>
          <h1>
            Earn 3 Credits ($300 Value)
            <br />
            <small>Refer a brand</small>
            <img src={ReferImg} alt="refer" />
          </h1>
        </Container>
      </div>
      <div className="tab-content">
        <Container width={733}>
          <h2>Earn 3 credits for every brand you refer</h2>
          <p>
            Do you have any friends that work for brands who could use Trend? If you refer them and
            they become a customer, we’ll give you 3 credits for your effort. Thank you in advance.
          </p>
          <Form onSubmit={onSubmit} hideRequiredMark>
            <Form.Item
              label="Company Name you Referred"
              validateStatus={getFieldError('companyName') ? 'error' : ''}
            >
              {getFieldDecorator('companyName', {
                rules: [
                  {
                    required: true,
                    message: 'Company Name is required!',
                  },
                ],
              })(<Input type="text" size="large" name="email" placeholder="Company name" />)}
            </Form.Item>
            <Form.Item
              label="Contact at this Company"
              validateStatus={getFieldError('contactEmail') ? 'error' : ''}
            >
              {getFieldDecorator('contactEmail', {
                rules: [
                  {
                    required: true,
                    message: 'Contact Email is required!',
                  },
                  {
                    type: 'email',
                    message: 'Invalid Email!',
                  },
                ],
              })(<Input type="text" size="large" name="email" placeholder="Contact email" />)}
            </Form.Item>

            <Button loading={submitting} htmlType="submit" type="submit" className="trend-btn">
              Submit Referral
            </Button>
          </Form>

          <div className="referrals">{referrralList}</div>
        </Container>
      </div>
    </div>
  );
};

const WrappedReferPage = Form.create({ name: 'refer_form' })(ReferPage);

export default WrappedReferPage;
