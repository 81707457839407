import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  imgUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '211px',
    height: '140px',
    border: '1px solid #D6D6D6',
    borderRadius: '2px',
    cursor: 'pointer',
  },
  box: ({ color }) => ({
    marginTop: '22px',
    marginBottom: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '52px',
    height: '52px',
    backgroundColor: color,
    borderRadius: '2px',
  }),
  preview: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
});

const Upload = ({ title, subTitle, color, onChange, value }, ref) => {
  const classes = useStyles({ color });
  const containerRef = useRef();
  const inputRef = useRef();
  const [file, setFile] = useState();

  useEffect(() => {
    const handleClick = () => {
      inputRef.current.click();
    };
    const handleKeyup = e => {
      if (e.key === ' ') handleClick();
    };

    const container = containerRef.current;

    container.addEventListener('click', handleClick);
    container.addEventListener('keyup', handleKeyup);

    return () => {
      if (container) {
        container.removeEventListener('click', handleClick);
        container.removeEventListener('keyup', handleKeyup);
      }
    };
  }, []);

  useEffect(() => {
    if (!value) {
      inputRef.current.value = null;
    }
    setFile(value ? URL.createObjectURL(value) : undefined);
  }, [value]);

  const handleFileChange = e => {
    if (e.target.files[0]) {
      onChange(e.target.files[0]);
    }
  };

  const setInputRef = useCallback(
    element => {
      inputRef.current = element;
      ref(element);
    },
    [ref]
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={classes.imgUploadContainer} ref={containerRef} tabIndex={0}>
      {file ? (
        <img src={file} alt="upload" className={classes.preview} />
      ) : (
        <>
          <div className={classes.box}>
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.6461 1V20.2924" stroke="black" strokeLinecap="round" />
              <path d="M20.2924 10.6465H1" stroke="black" strokeLinecap="round" />
            </svg>
          </div>

          <div style={{ fontWeight: 400, fontSize: '15px', textAlign: 'center' }}>{title}</div>

          <small>{subTitle}</small>
        </>
      )}

      <input
        type="file"
        ref={setInputRef}
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default forwardRef(Upload);
