import Header from './Header';
import Image from './Image';
import Stats from './Stats';
import PostGuidelines from './PostGuidelines';
import Styleguides from './Styleguides';
import RecentPosts from './RecentPosts';
import Details from './Details';
import Provider from './Provider';
import PostRequirements from './PostRequirements';
import State from './State';
import Gender from './Gender';
import Applications from './Applications';
import ApproveApplicationModal from './ApproveApplicationModal';
import RejectApplicationModal from './RejectApplicationModal';
import Creators from './Creators';
import RejectCreatorModal from './RejectCreatorModal';
import ShippingForm from './ShippingForm';
import ShippingHistory from './ShippingHistory';
import Settings from './Settings';
import ExampleList from './ExampleList';
import ExampleHeader from './ExampleHeader';
import AdminCard from './AdminCard';
import AdminFilters from './AdminFilters';
import AdminModal from './AdminModal';
import StyleCategory from './StyleCategory';

export { default as useProductData } from './useProductData';
export { default as useProduct } from './useProduct';

const Product = {
  Applications,
  Header,
  Image,
  Stats,
  PostGuidelines,
  Styleguides,
  RecentPosts,
  Details,
  Provider,
  PostRequirements,
  State,
  Gender,
  ApproveApplicationModal,
  RejectApplicationModal,
  Creators,
  RejectCreatorModal,
  ShippingForm,
  ShippingHistory,
  Settings,
  ExampleList,
  ExampleHeader,
  AdminCard,
  AdminFilters,
  AdminModal,
  StyleCategory,
};

export default Product;
