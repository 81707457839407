import axios from 'axios';
import JSZip from 'jszip';
import Promise from 'bluebird';
import { saveAs } from 'file-saver';
import { setContentUrlParams, isNewContentServiceUrl } from 'utils/content';

export const retrieveFile = async (url, filename = '') => {
  // NOTE: When logging this function I found it was called several times for a single download request
  try {
    if (isNewContentServiceUrl(url)) {
      const downloadUrl = setContentUrlParams(url);
      return forceDownload(downloadUrl, `${getFilenameFromPath(downloadUrl)}`);
    }
    const blob = await getFileBlob(url);
    if (!blob) {
      throw new Error('Could not download blob');
    }
    const type = fileTypes[blob.type];
    const blobUrl = window.URL.createObjectURL(blob);
    return forceDownload(blobUrl, `${filename}.${type}`);
  } catch (error) {
    console.error(error);
    throw new Error(`Data resources was not retrieve: ${url}`);
  }
};

export const forceDownload = (blobUrl, filename, newTab = false) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blobUrl;
  if (newTab) {
    // NOTE: For some reason Admin was opening the download in the page. This is a workaround so that they don't lose their place in the content gallery
    a.target = '_blank';
  }
  document.body.appendChild(a);
  a.click();
  a.remove();
};

const getFileBlob = async url => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      resolve(xhr.response);
    };
    xhr.onerror = () => {
      reject(new Error('could not download file'));
    };
    xhr.send();
  });
};

export const getFilenameFromPath = path => {
  // TODO: remove %2F from old filenames
  // TODO: Convert to reqex
  const filenameIndex = path.lastIndexOf('/');
  const queryIndex = path.lastIndexOf('?');
  return path.substring(filenameIndex + 1, queryIndex > 0 ? queryIndex : undefined);
};

/* ZIP - Download */
const fileTypes = {
  'image/jpg': 'jpg',
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'video/mp4': 'mp4',
  'video/quicktime': 'mov',
};

const requestResource = url => {
  return axios({
    method: 'get',
    url,
    responseType: 'blob',
  });
};

/**
 *
 * @param {{ filename: string, url: string, fallbackUrl: string }[]} urls
 * @param {string} filename
 * @returns
 */
export const retrieveAndCompressFiles = async (urls = [], zipName = '') => {
  const zip = new JSZip();

  return Promise.map(urls, async ({ filename, url, fallbackUrl }) => {
    if (isNewContentServiceUrl(url)) {
      const downloadUrl = setContentUrlParams(url);
      const blob = await getFileBlob(downloadUrl);
      zip.file(getFilenameFromPath(downloadUrl), blob, { binary: true });
    } else {
      try {
        try {
          const response = await requestResource(url);
          const type = fileTypes[response.headers['content-type']];
          if (!type) {
            throw new Error('Some of the files attached is not supported');
          }
          zip.file(`${filename}.${type}`, response.data, { binary: true });
        } catch {
          const response1 = await requestResource(fallbackUrl);
          const type1 = fileTypes[response1.headers['content-type']];
          if (!type1) {
            throw new Error('Some of the files attached is not supported');
          }
          zip.file(`${filename}.${type1}`, response1.data, { binary: true });
        }
      } catch (e) {
        console.error(`Couldn't download file: ${filename}`);
        console.error(e);
      }
    }
  })
    .then(() => {
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, zipName.replace('.', '-'));
      });
    })
    .catch(e => {
      console.error('Cant retrieve image', e);
    });
};
