import FirebaseContext, {
  FirebaseProvider,
  firebaseService,
  useFirebase,
  withFirebase,
} from './context';
import influencersSearch from './influencers/search';
import UserToken from './userToken';

/**
 * Note: Direct use of this instance is highly discouraged inside a component.
 * The better option is to use the `useFirebase` hook or the `withFirebase` HOC.
 */
const firebase = firebaseService;

export {
  FirebaseContext,
  FirebaseProvider,
  UserToken,
  withFirebase,
  firebase,
  useFirebase,
  influencersSearch,
};
