export const ADD_BRANDS = 'ADD_BRANDS';

/**
 * @param {Record<string, import("types").Brand>} brands
 * @returns {{ type: ADD_BRANDS, payload: brands}}
 */
export const addBrands = brands => ({
  type: ADD_BRANDS,
  payload: brands,
});

/**
 * @typedef {ReturnType<addBrands>} BrandActions
 */
