/**
 * Get session Selector
 *
 */
export const getSession = () =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   * @returns {import("stores/reducers/session").SessionReducer}
   */
  state => {
    return state.session;
  };
