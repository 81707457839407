import React, { useState, useEffect } from 'react';
import { Row, Button } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Confetti from 'components/Confetti';
import { useSession } from 'components/Session';
import Container from 'components/Common/Container';
import qs from 'query-string';
import { mixpanelRegister, mixpanelTrack } from 'lib/Analytics/Mixpanel';
import { trackGtagEvent } from 'lib/Analytics/gtag';
// TODO: Remove this file
const logo =
  'https://static.wixstatic.com/media/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.png/v1/fill/w_218,h_40,al_c,q_80,usm_1.20_1.00_0.01/2ba213_71d75f77807444218e8b44e2e827c78e~mv2.webp';

const SuccessPurchasePage = () => {
  const history = useHistory();

  const [particles, setParticles] = useState([1]);
  const { activeProductUIDs, brand } = useSession();
  const location = useLocation();

  useEffect(() => {
    if (particles.length !== 0) {
      setTimeout(() => {
        setParticles([]);
      }, 10000);
    }
  }, [particles]);

  useEffect(() => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });

    const transactionAmount = Number(params.total);
    const selectedCredits = Number(params.credits);
    const costPerCredit = Math.floor(transactionAmount / selectedCredits);
    const transactionId = params.session_id;
    const brandId = brand.uid;

    const { completedProductUIDs, lastPurchaseDate } = brand;

    const isFirstPurchase =
      activeProductUIDs &&
      activeProductUIDs.length === 1 &&
      completedProductUIDs &&
      completedProductUIDs.length === 0 &&
      !lastPurchaseDate;

    try {
      const firstName = brand.firstName
        ? brand.firstName
        : (brand.fullName || '')
            .split(' ')
            .slice(0, -1)
            .join(' ');
      const lastName = brand.lastName
        ? brand.lastName
        : (brand.fullName || '')
            .split(' ')
            .slice(-1)
            .join(' ');
      const brandPayload = {
        firstName,
        lastName,
        email: brand.email || brand.authEmail || null,
        country: brand.country || null,
        state: brand.state || null,
        city: brand.city || null,
        ipAddress: brand.ipAddress || null,
      };
      const cleanBrandPayload = Object.fromEntries(
        Object.entries(brandPayload).filter(([, v]) => v != null)
      );
      const purchasePayload = {
        event: 'Purchase',
        ...cleanBrandPayload,
        transaction_id: transactionId,
        external_id: brandId,
        currency: 'USD',
        Credits: selectedCredits,
        value: transactionAmount,
        isFirstPurchase,
      };
      trackGtagEvent(purchasePayload);
    } catch (error) {
      console.error(error);
    }

    mixpanelRegister({ brandId });
    mixpanelTrack('Brand Purchase Confirmation', {
      brandId,
      transactionAmount,
      selectedCredits,
      costPerCredit,
      isFirstPurchase,
      transactionId,
    });
  }, []);

  return (
    <div className="success-purchase">
      {particles.map(id => {
        return <Confetti key={`confetti-${id}`} count={Math.floor(window.innerWidth / 10)} />;
      })}

      <Container width={700}>
        <Row type="flex" justify="center">
          <img src={logo} alt="logo" className="logo" width="70px" />
        </Row>

        <Row type="flex" justify="center" className="header">
          Congratulations!
          <br />
          New credits have been added
          <br />
          to your account!
        </Row>

        <Row type="flex" justify="center" className="description">
          Now that you’ve added more credits to your account, you’ll be able to
          <br />
          work with more of our creators. Do you need to start a new campaign or
          <br />
          accept creators in an existing campaign?
        </Row>

        <Row type="flex" justify="center" className="btn-section">
          <Button
            className="start-new-campaign-btn"
            type="primary"
            block
            onClick={() => {
              history.replace('/campaigns/new');
            }}
          >
            Start A New Campaign
          </Button>

          <Button
            className="accept-creators-btn"
            type="primary"
            block
            onClick={() => {
              history.replace(`/campaigns/${activeProductUIDs[0]}/summary`);
            }}
            disabled={activeProductUIDs.length === 0}
          >
            Accept Creators For Existing Campaign
          </Button>
        </Row>
      </Container>
    </div>
  );
};

export default SuccessPurchasePage;
