import { destroyCookie, parseCookies, setCookie } from 'nookies';

const AUTH_TOKEN = {
  ACCESS: 'access_token',
  REFRESH: 'refresh_token',
  CUSTOM_TOKEN: 'trend_custom_auth',
};

export const COOKIE_KEYS = {
  ...AUTH_TOKEN,
  IS_ADMIN: 'isAdmin',
  IS_CONTENT_ADMIN: 'isContentAdmin',
};

const TOKEN_EXPIRATIONS = {
  [AUTH_TOKEN.ACCESS]: 60 * 5, // 5 Minutes
  [AUTH_TOKEN.REFRESH]: 60 * 60 * 24 * 7, // 1 Week
  [AUTH_TOKEN.CUSTOM]: 60 * 5,
};

export const getAccessToken = () => {
  const cookies = parseCookies();
  const authToken = cookies[AUTH_TOKEN.ACCESS];
  return authToken;
};

export const getCustomToken = () => {
  const cookies = parseCookies();
  const authToken = cookies[AUTH_TOKEN.CUSTOM_TOKEN];
  return authToken;
};

// export const getAdminCookies = () => {
//   const cookies = parseCookies();
//   const isAdmin = cookies[COOKIE_KEYS.IS_ADMIN] === 'true';
//   const isContentAdmin = cookies[COOKIE_KEYS.IS_CONTENT_ADMIN] === 'true';
//   return { isAdmin, isContentAdmin };
// };

export const setAccessToken = token => {
  setCookie(null, AUTH_TOKEN.ACCESS, token, {
    maxAge: TOKEN_EXPIRATIONS[AUTH_TOKEN.ACCESS],
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};
export const setRefreshToken = token => {
  setCookie(null, AUTH_TOKEN.REFRESH, token, {
    maxAge: TOKEN_EXPIRATIONS[AUTH_TOKEN.REFRESH],
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

export const setAdminCookies = brand => {
  if (brand.isAdmin) {
    setCookie(null, COOKIE_KEYS.IS_ADMIN, true, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  } else {
    destroyCookie(null, COOKIE_KEYS.IS_ADMIN, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }

  if (brand.isContentAdmin) {
    setCookie(null, COOKIE_KEYS.IS_CONTENT_ADMIN, true, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  } else {
    destroyCookie(null, COOKIE_KEYS.IS_CONTENT_ADMIN, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }
};

export const clearAuthCookies = () => {
  Object.values(COOKIE_KEYS).forEach(tokenKey => {
    destroyCookie(null, tokenKey, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  });
  // destroyCookie(null, 'isAdmin', { path: '/' });
};
