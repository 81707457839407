import React, { useEffect, useState } from 'react';
import { Table, Button, Spin } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Container from 'components/Common/Container';
import { firebase } from 'lib/Firebase';
import { selectors } from 'stores';
import { adminAcceptBrandReview } from '../../lib/Firebase/callables/admin';

const columns = [
  {
    key: 'brand',
    title: 'Brand',
    render: ({ brand }) => {
      if (!brand) return <Spin />;
      return brand && brand.details && brand.details.name;
    },
  },
  {
    key: 'submitedDate',
    title: 'Submitted Date',
    render: ({ submittedAt }) => moment(submittedAt * 1000).format('MMM DD, YYYY'),
  },
  {
    key: 'source',
    title: 'Source',
    dataIndex: 'source',
  },
  {
    key: 'url',
    dataIndex: 'url',
    title: 'URL',
    render: val => (
      <a href={val} target="_blank" rel="noopener noreferrer">
        {val}
      </a>
    ),
  },
  {
    key: 'actions',
    name: 'actions',
    render: ({ uid, brandUID, onAccept, onReject, loading, status }) => {
      if (status !== 'pending') {
        return status.toUpperCase();
      }
      return (
        <>
          <Spin spinning={loading}>
            <Button type="primary" onClick={() => onAccept(uid, brandUID)}>
              Accept
            </Button>

            <Button
              type="danger"
              onClick={() => onReject(uid, brandUID)}
              style={{ marginLeft: '10px' }}
            >
              Reject
            </Button>
          </Spin>
        </>
      );
    },
  },
];

/**
 * Admin V2 Reviews Tab
 *
 * @type {React.FC}
 */
const ReviewsPage = () => {
  const initialPagination = { page: 1, limit: 20 };
  const [pagination, setPagination] = useState(initialPagination);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState([]);
  const [lastDoc, setLastDoc] = useState({});
  const brands = useSelector(selectors.getBrands());

  const fetch = async (lastDocParam = null) => {
    let reviewsSnap = firebase.firestore
      .collection('trendReviews')
      .orderBy('submittedAt', 'desc')
      .limit(20);

    if (lastDocParam) {
      reviewsSnap = reviewsSnap.startAfter(lastDocParam);
    }

    reviewsSnap = await reviewsSnap.get();

    if (!reviewsSnap.empty) {
      const list = [];
      let data = null;
      reviewsSnap.docs.forEach(reviewDoc => {
        const docData = reviewDoc.data();
        list.push({ uid: reviewDoc.id, ...docData });
        data = reviewDoc;
      });

      setReviews(prevState => {
        return [...prevState, ...list];
      });
      setLastDoc(data);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  async function handleAccept(reviewUID, brandUID) {
    setLoading(state => [...state, reviewUID]);

    await adminAcceptBrandReview(brandUID, reviewUID);

    const [selectedReview] = reviews.filter(x => x.uid === reviewUID);
    const selectedIndex = reviews.indexOf(selectedReview);
    reviews[selectedIndex].status = 'approved';
    setReviews([...reviews]);

    setLoading(state => [...state.filter(x => x !== reviewUID)]);
  }

  async function handleReject(reviewUID) {
    setLoading(state => [...state, reviewUID]);
    await firebase.firestore
      .collection('trendReviews')
      .doc(reviewUID)
      .update({
        status: 'rejected',
        rejectedAt: moment().unix(),
      });

    const [selectedReview] = reviews.filter(x => x.uid === reviewUID);
    const selectedIndex = reviews.indexOf(selectedReview);
    reviews[selectedIndex].status = 'rejected';
    setReviews([...reviews]);

    setLoading(state => [...state.filter(x => x !== reviewUID)]);
  }

  const dataSource = reviews.map(review => {
    return {
      key: review.uid,
      ...review,
      brand: brands[review.brandUID],
      loading: loading.includes(review.uid),
      onAccept: handleAccept,
      onReject: handleReject,
    };
  });

  function loadMore() {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });

    fetch(lastDoc);
  }

  const showLimit = pagination.limit * pagination.page - pagination.page;
  return (
    <Container width={940} id="admin-business-approval">
      <Table dataSource={dataSource} columns={columns} pagination={false} showHeader />
      <div className="text-center loadmore">
        {dataSource.length > showLimit && (
          <Button className="load-more" onClick={loadMore}>
            LOAD MORE
          </Button>
        )}
      </div>
    </Container>
  );
};

export default ReviewsPage;
