import React, { useState } from 'react';
import './ApprovedContentTable.less';
import InfluencerAvatar from 'components/Influencers/Avatar';
import { CAMPAIGN_STYLE_CATEGORIES } from 'constants/campaigns';
import ContentPreviewModal from 'components/Common/ContentPreviewModal';
import { getContentUrlFromRecord } from 'utils/content';
/**
 * Creator Approval Content Table
 *
 * @typedef {{
 * approvals: [
 *			{
 *				creatorId: string,
 *				totalContentApproved: number,
 *				numNewContent: number,
 *				contentSubmitted: boolean,
 *				requiredContent: {
 *					[styleCategory: string]: {
 *						submissionPreviews: [string],
 *						numContentRequired: number
 *					}
 *				}
 *			}
 *		];
 * handleViewProfile: (creatorId: string) => void;
 * onApprovalCardClick: (creatorId: string) => void;
 * }} ApprovedContentTableProps
 * @type {React.FC<ApprovedContentTableProps>}
 */
const ApprovedContentTable = ({ approvals, handleViewProfile, onApprovalCardClick }) => {
  return (
    <div className="approval-table">
      <div className="approval-table-header">
        <div className="header-width-container">
          <div className="creator-name-column">
            <p>Creator</p>
          </div>
          <div className="required-content-column">
            <p>Required Content Approved</p>
          </div>
          <div className="content-table-action-column">
            <div />
          </div>
        </div>
      </div>
      <div className="approval-table-body">
        {approvals.map(
          ({
            creatorId,
            totalContentApproved,
            requiredContent,
            numNewContent,
            contentSubmitted,
          }) => (
            <div key={creatorId} className="approval-table-row">
              <div className="row-width-container">
                <div className="creator-name-column">
                  <InfluencerAvatar
                    influencerUID={creatorId}
                    onClick={() => handleViewProfile(creatorId)}
                  />
                </div>
                <div className="required-content-column">
                  <RequiredContent creatorId={creatorId} requiredContent={requiredContent} />
                </div>
                <div className="content-table-action-column">
                  {contentSubmitted && (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div className="action-card" onClick={() => onApprovalCardClick(creatorId)}>
                        {numNewContent > 0 && (
                          <div className="info-container">
                            <span className="info-title">{numNewContent} NEW</span>
                          </div>
                        )}
                        <div className="review-content">
                          <>
                            <p>Review All Content</p>
                            <img src="/images/backarrow.svg" alt="arrow" className="icon-style" />
                          </>
                        </div>
                        <div className="content-approved-count">
                          <p>{totalContentApproved} content pieces approved</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ApprovedContentTable;

/**
 * Required Content
 * @typedef {{
 * 	requiredContent: {
 * 		[styleCategory: string]: {
 * 			submissionPreviews: [string],
 * 			numContentRequired: number
 * 		}
 * 	},
 * 	creatorId: string
 * }} RequiredContentProps
 * @type {React.FC<RequiredContentProps>}
 * @param {RequiredContentProps} props
 * @returns
 */
const RequiredContent = ({ requiredContent, creatorId }) => {
  const categories = Object.keys(requiredContent);
  const [selectedContent, setSelectedContent] = useState('');

  const toggleContent = id => {
    setSelectedContent(selectedContent === id ? '' : id);
  };
  return (
    <>
      {categories.map(category => {
        const { submissionPreviews = [], numContentRequired } = requiredContent[category];
        const type = category.includes('Video') ? 'video' : 'image';
        const numApproved = submissionPreviews.length;
        const numPreviews = numContentRequired;
        let additionalPreviews = 0;
        if (numApproved > 5) {
          additionalPreviews = numApproved - 5;
        }

        const previews = Array.from(
          { length: numPreviews },
          (_, index) => submissionPreviews[index] || { uid: `key-${index}`, approvedAt: 0 }
        ).sort((a, b) => (a.approvedAt === 0 ? 1 : a.approvedAt - b.approvedAt));

        return (
          <div key={`${creatorId}-${category}`} className="required-content-style">
            <p>
              {numApproved < numContentRequired ? numApproved : numContentRequired}/
              {numContentRequired} {STYLE_CATEGORY_TO_TITLE[category]}
            </p>
            <div className="content-preview-row">
              {previews.map(preview => {
                const { uid, standardResolutionImage } = preview;
                return (
                  <div key={uid} className="required-content-preview">
                    {!uid.startsWith('key') && (
                      <ContentPreviewModal
                        contentType={type}
                        url={getContentUrlFromRecord(preview)}
                        thumbnailUrl={standardResolutionImage}
                        alt="approved content preview"
                        visible={selectedContent === uid}
                        setVisible={() => toggleContent(uid)}
                      />
                    )}
                  </div>
                );
              })}
              {additionalPreviews > 0 && (
                <div className="required-content-preview additional-previews">
                  <p>+{additionalPreviews}</p>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

const STYLE_CATEGORY_TO_TITLE = {
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_PHOTOGRAPHY]: 'Product Photos',
  [CAMPAIGN_STYLE_CATEGORIES.LIFE_STYLE_OR_ACTION_PHOTOGRAPHY]: 'Lifestyle Photos',
  [CAMPAIGN_STYLE_CATEGORIES.PORTRAIT_STYLE_PHOTOGRAPHY]: 'Portrait Photos',
  [CAMPAIGN_STYLE_CATEGORIES.ACTION_OR_PRODUCT_IN_USE_VIDEO]: 'Product in Action Videos',
  [CAMPAIGN_STYLE_CATEGORIES.UNBOXING_EXPLANATION_VIDEO]: 'Unboxing Videos',
  [CAMPAIGN_STYLE_CATEGORIES.PRODUCT_REVIEW_OR_TESTIMONIAL_VIDEO]: 'Testimonial Videos',
};
