/**
 * Document Snapshot Callback Parser
 *
 * @param {(dataChanges: {
 *  added?: Record<string, import('types').Partnership>,
 *  modified?: Record<string, import('types').Partnership>,
 *  removed?: Record<string, import('types').Partnership>
 * }) => void} callback
 */
export const docCallbackListenerParser = callback =>
  /**
   * @param {import("firebase").default.firestore.QuerySnapshot} snapshot
   */
  snapshot => {
    /**
     * @type {{
     *  added?: Record<string, import('types').Partnership>,
     *  modified?: Record<string, import('types').Partnership>,
     *  removed?: Record<string, import('types').Partnership>
     * }}
     */
    const changes = {};

    snapshot.docChanges().forEach(change => {
      changes[change.type] = {
        ...changes[change.type],
        [change.doc.id]: { ...change.doc.data(), uid: change.doc.id },
      };
    });

    callback(changes);
  };
