/**
 * @typedef {ReturnType<writePurchasedHistories>} EntityPurchasedHistoryActions
 */

export const WRITE_PURCHASED_HISTORIES = 'WRITE_PURCHASED_HISTORIES';
/**
 *
 * @param {Record<string, import("types").PurchasedHistory>} purchasedHistories
 * @returns {{ type: WRITE_PURCHASED_HISTORIES, payload: Record<string, import("types").PurchasedHistory>}}
 */
export const writePurchasedHistories = purchasedHistories => ({
  type: WRITE_PURCHASED_HISTORIES,
  payload: purchasedHistories,
});
