import { Spin } from 'antd';
import React, { useState } from 'react';

/**
 * Image Component
 * @typedef {
 *  src: string;
 *  alt?: string;
 *  onError: () => string;
 *  extra?: any; // TODO: This should be children or something; Also 60% sure it can be removed
 * }: ImageProps
 * @type {React.FC<ImageProps>}
 */
const Image = ({ src, alt = 'image', extra, onError }) => {
  const [loaded, setLoaded] = useState(false);
  const [fallbackImage, setFallbackImage] = useState();

  function handleError() {
    if (onError) {
      const image = onError();
      setFallbackImage(image);
    }
  }

  return (
    <div className="image-loader">
      <img
        src={fallbackImage || src}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={handleError}
        alt={alt}
      />
      {!loaded && <Spin />}
      <div className="image-extra">{extra}</div>
    </div>
  );
};

export default Image;
