/* eslint-disable react/no-did-update-set-state */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import moment from 'moment';
import { actions, selectors } from 'stores';
import { firebase } from '../../../lib/Firebase';

const initialState = {
  reviews: {},
  loadmore: true,
  fetching: true,
  paginate: {
    count: 1,
    lastSnap: null,
  },
};

/**
 * @typedef {{influencerUID: string}} ReviewProps
 * @type {React.FC<ReviewsProps>}
 */
const Reviews = ({ influencerUID }) => {
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  const influencerReviews = useSelector(selectors.getInfluencerReviews(influencerUID));
  const reviews = useSelector(selectors.getReviews());
  const reviewUIDs = influencerReviews || [];

  async function fetchData() {
    const { paginate } = state;

    if (reviewUIDs.length !== 0 && reviewUIDs.length >= paginate.count) {
      return;
    }

    let q = firebase
      .reviews()
      .where('influencerUID', '==', influencerUID)
      .orderBy('completedAt', 'desc');

    if (paginate.lastSnap) {
      q = q.startAfter(paginate.lastSnap);
    }

    const reviewsSnap = await q.limit(1).get();
    if (reviewsSnap.empty) {
      return;
    }

    const [reviewSnap] = reviewsSnap.docs;
    const review = reviewSnap.data();

    if (review.comments) {
      dispatch(
        actions.entities.influencerReviews.addInfluencerReviews(influencerUID, {
          [reviewSnap.id]: review,
        })
      );

      setState(s => ({
        ...s,
        fetching: false,
        loadmore: reviewUIDs.length + 1 >= s.paginate.count,
        paginate: {
          ...s.paginate,
          lastSnap: reviewSnap,
        },
      }));
    }
  }

  async function loadMore() {
    setState(s => ({
      ...s,
      fetching: true,
      paginate: {
        ...s.paginate,
        count: s.paginate.count === 1 ? 4 : s.paginate.count + 2,
      },
    }));

    setTimeout(async () => {
      await this.retrieve();
    }, 1000);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const { loadmore, fetching, paginate } = state;

  if (reviewUIDs.length <= 0) {
    return <div />;
  }

  const count = paginate.count !== 1 ? paginate.count - 1 : paginate.count;

  const renderReviews = influencerReviews.slice(0, count).map(reviewUID => {
    const review = reviews[reviewUID];
    return (
      <div key={reviewUID} className="influencer-review">
        <div className="review-content">
          <p className="review-comment">{review.comments}</p>
          <div className="review-reviewer">
            <span className="reviewer-name">{review.reviewer}</span> |{' '}
            <span className="reviewed-date">{moment(review.completedAt).format('MMMM DD')}</span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="influencer-reviews">
      <div className="influencer-info-label">Additional Reviews</div>
      <div className="influencer-reviews-wrapper">{renderReviews}</div>
      {loadmore && (
        <div className="load-more-reviews">
          <Button loading={fetching} block onClick={loadMore}>
            MORE REVIEWS
          </Button>
        </div>
      )}
    </div>
  );
};

export default Reviews;
