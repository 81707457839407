import urlRegex from 'url-regex';

export default function urlify(text) {
  return text.replace(urlRegex({ strict: false }), url => {
    let tmpUrl = url;
    if (!url.includes('https://')) {
      tmpUrl = `https://${url}`;
    }
    return `<a href="${tmpUrl}" target="_blank">${url}</a>`;
  });
}
