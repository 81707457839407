/**
 * @typedef {ReturnType<addReviews>} EntityReviewActions
 */

export const ADD_REVIEWS = 'ADD_REVIEWS';
/**
 *
 * @param {Record<string, import("types").Review>} reviews
 * @returns {{ type: ADD_REVIEWS, payload: Record<string, import("types").Review>}}
 */
export const addReviews = reviews => ({
  type: ADD_REVIEWS,
  payload: reviews,
});
