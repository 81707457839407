import { Select, Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

/**
 * Creator Content Filters Components
 *
 * @typedef {{ partnershipUIDs: string[], onChange: ({ influencerUID }) => any }} FiltersProps
 * @type {React.FC<FiltersProps>}
 */
const Filters = ({ partnershipUIDs, onChange }) => {
  const [filters, setFilters] = useState({
    influencerUID: null,
  });

  const partnerships = useSelector(selectors.getPartnerships());
  const influencers = useSelector(selectors.getInfluencers());

  const renderOptions = partnershipUIDs.map(uid => {
    const { influencerUID } = partnerships[uid];
    const { name, firstName, lastName } = influencers[influencerUID];
    const creatorName = name || `${firstName} ${lastName}`;

    return (
      <Select.Option key={uid} value={influencerUID}>
        {creatorName}
      </Select.Option>
    );
  });

  function handleChange(e) {
    onChange({ ...filters, influencerUID: e });
    setFilters(x => ({ ...x, influencerUID: e }));
  }

  return (
    <Row id="mediaFilters">
      <Select
        allowClear
        placeholder="All Creators"
        style={{
          width: '97px',
          marginRight: '9px',
        }}
        className="trend-select"
        showArrow={false}
        onChange={handleChange}
      >
        {renderOptions}
      </Select>
      {/* <Select
        allowClear
        placeholder="All Content"
        style={{
          width: '100px',
          marginRight: '9px',
        }}
        className="trend-select"
        showArrow={false}
      />
      <Select
        allowClear
        className="trend-select"
        placeholder="Most Recent"
        showArrow={false}
        style={{
          width: '103px',
          marginRight: '9px',
        }}
      /> */}
    </Row>
  );
};

export default Filters;
