import React, { useState } from 'react';
import MiniSearch from 'minisearch';
import { Button, Icon, Select, Row, Col, AutoComplete } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import STATES from '../../../constants/states';
import { firebase } from '../../../lib/Firebase';
import debounce from '../../Helpers/useDebounce';
import { ReactComponent as DownloadIcon } from '../../../images/download.svg';

const states = { ...STATES };
delete states.All;

const { Option } = AutoComplete;
const Filters = ({ onChange, onDownload, onInfluencersDownload }) => {
  const [filters, setFilters] = useState({});
  const [downloading, setDownloading] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [searching, setSearching] = useState(false);

  function handleSelectChange(type) {
    return val => {
      setFilters(state => {
        const newState = { ...state, [type]: val };
        onChange(newState);
        return newState;
      });
    };
  }

  function getInfluencerDataForSearchEngine(influencerDoc) {
    const {
      username,
      usernameLow,
      name,
      firstName,
      lastName,
      authEmail,
      tikTokUsername,
    } = influencerDoc.data();

    return {
      id: influencerDoc.id,
      username,
      usernameLow,
      name,
      firstName,
      lastName,
      authEmail,
      tikTokUsername,
    };
  }

  async function handleSearchChange(val) {
    if (val) {
      const searchUsername = (val || '').toLowerCase();

      setSearching(true);
      const usernameSnap = await firebase.firestore
        .collection('influencers')
        .orderBy('usernameLow', 'asc')
        .startAt(searchUsername)
        .endAt(`${searchUsername}\uf8ff`)
        .limit(5)
        .get();

      const emailSnap = await firebase.firestore
        .collection('influencers')
        .orderBy('authEmail', 'asc')
        .startAt(searchUsername)
        .endAt(`${searchUsername}\uf8ff`)
        .limit(5)
        .get();

      const nameSnap = await firebase.firestore
        .collection('influencers')
        .orderBy('name', 'asc')
        .startAt(searchUsername)
        .endAt(`${searchUsername}\uf8ff`)
        .limit(5)
        .get();

      const firstNameSnap = await firebase.firestore
        .collection('influencers')
        .orderBy('firstName', 'asc')
        .startAt(searchUsername)
        .endAt(`${searchUsername}\uf8ff`)
        .limit(5)
        .get();

      const lastNameSnap = await firebase.firestore
        .collection('influencers')
        .orderBy('lastName', 'asc')
        .startAt(searchUsername)
        .endAt(`${searchUsername}\uf8ff`)
        .limit(5)
        .get();

      const tikTokSnap = await firebase.firestore
        .collection('influencers')
        .orderBy('tikTokUsername', 'asc')
        .startAt(searchUsername)
        .endAt(`${searchUsername}\uf8ff`)
        .limit(5)
        .get();

      const miniSearch = new MiniSearch({
        fields: [
          'username',
          'usernameLow',
          'name',
          'firstName',
          'lastName',
          'authEmail',
          'tikTokUsername',
        ],
        searchOptions: {
          fuzzy: 0.3,
          prefix: true,
        },
      });

      const uniqueDocs = _.uniqBy(
        [
          ...usernameSnap.docs.map(d => getInfluencerDataForSearchEngine(d)),
          ...emailSnap.docs.map(d => getInfluencerDataForSearchEngine(d)),
          ...nameSnap.docs.map(d => getInfluencerDataForSearchEngine(d)),
          ...firstNameSnap.docs.map(d => getInfluencerDataForSearchEngine(d)),
          ...lastNameSnap.docs.map(d => getInfluencerDataForSearchEngine(d)),
          ...tikTokSnap.docs.map(d => getInfluencerDataForSearchEngine(d)),
        ],
        'id'
      );

      miniSearch.addAll(uniqueDocs);

      const results = miniSearch.search(searchUsername);

      const resultIds = results.map(x => x.id);
      const filteredDocs = uniqueDocs.filter(x => resultIds.includes(x.id));

      setSearchList(filteredDocs);
      setSearching(false);
    } else {
      setSearchList([]);
      setSearching(false);
    }
  }

  function handleSearchSelect(textOrId) {
    setFilters(state => {
      const newState = { ...state, id: textOrId };
      onChange(newState);
      return newState;
    });
  }

  async function downloadCSV() {
    setDownloading(true);
    await onDownload();
    setDownloading(false);
  }

  async function downloadInfluencers() {
    setDownloading(true);
    await onInfluencersDownload();
    setDownloading(false);
  }

  const weekAgo = moment()
    .subtract(1, 'week')
    .unix();
  const monthAgo = moment()
    .subtract(1, 'month')
    .unix();
  const threeMonthsAgo = moment()
    .subtract(3, 'months')
    .unix();

  const stateOptions = Object.keys(states).map(key => (
    <Select.Option key={key} value={key}>
      {states[key]}
    </Select.Option>
  ));

  const getInfluencerLabelForSearchList = influencer => {
    const label = influencer.username
      ? `@${influencer.username.trim()}`
      : `${influencer.authEmail.trim()}`;
    const nameLabel = influencer.name ? ` (${influencer.name.trim()})` : '';

    return `${label}${nameLabel}`;
  };

  const searchListOptions = searchList.map(x => {
    const label = getInfluencerLabelForSearchList(x);
    return (
      <Option key={x.id} value={x.id}>
        {label}
      </Option>
    );
  });

  return (
    <>
      <Col md={7} xs={24}>
        <Row className="search" type="flex" align="middle">
          {searching ? <Icon type="loading" /> : <Icon type="search" />}
          <AutoComplete
            onChange={debounce(handleSearchChange, 500)}
            placeholder="Search"
            onSelect={handleSearchSelect}
            allowClear
          >
            {searchListOptions}
          </AutoComplete>
        </Row>
      </Col>
      <Col md={17} xs={24}>
        <Row type="flex" justify="end" gutter={8} align="middle">
          <Col md={5} className="text-right">
            <Button
              block
              className="download-csv"
              loading={downloading}
              onClick={downloadInfluencers}
            >
              <Icon component={DownloadIcon} />
              <span>
                Download <br /> Influencers
              </span>
            </Button>
          </Col>
          <Col md={5} className="text-right">
            <Button block className="download-csv" loading={downloading} onClick={downloadCSV}>
              <Icon component={DownloadIcon} />
              <span>
                Download
                <br /> Contact CSV
              </span>
            </Button>
          </Col>
          <Col md={5}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Last Login"
              onChange={handleSelectChange('lastLoginDate')}
            >
              <Select.Option value={weekAgo}>1 Week</Select.Option>
              <Select.Option value={monthAgo}>1 Month</Select.Option>
              <Select.Option value={threeMonthsAgo}>3 Month</Select.Option>
            </Select>
          </Col>
          <Col md={5}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Completed"
              value={filters.completedPartnershipCount}
              onChange={handleSelectChange('completedPartnershipCount')}
            >
              <Select.Option value={1}>{'Completed: < 1'}</Select.Option>
              <Select.Option value={3}>{'Completed: < 3'}</Select.Option>
              <Select.Option value={10}>{'Completed: < 10'}</Select.Option>
              <Select.Option value={30}>{'Completed: < 30'}</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Level"
              onChange={handleSelectChange('star')}
            >
              <Select.Option value={1}>1</Select.Option>
              <Select.Option value={2}>2</Select.Option>
              <Select.Option value={3}>3</Select.Option>
            </Select>
          </Col>
          <Col md={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Location: All"
              onChange={handleSelectChange('state')}
            >
              {stateOptions}
            </Select>
          </Col>
        </Row>
        <Row gutter={8} type="flex" justify="end">
          <Col span={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Gender"
              onChange={handleSelectChange('gender')}
            >
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Col>
          <Col span={4}>
            <Select
              allowClear
              showArrow={false}
              className="trend-select"
              placeholder="Type"
              onChange={handleSelectChange('type')}
            >
              <Select.Option value="standard">Standard</Select.Option>
              <Select.Option value="vip">VIP</Select.Option>
            </Select>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Filters;
