/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Row } from 'antd';
import * as routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IconBack } from 'images/icon-back-arrow-white.svg';
import { ReactComponent as TrendLogoWeb } from 'images/trend-logo-web.svg';
import SignOutButton from 'components/Signout';
import { Route, Switch, Link, useHistory, Redirect } from 'react-router-dom';
import { useSession } from 'components/Session';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { postCreateStripeCustomer } from 'api/brand';
import Company from './Company';
import Tabs from './Tabs';
import EmailNotifications from './EmailNotifications';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

// constant for the stripe key and the new service-payment url
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

/**
 * Account
 *
 *
 * @typedef {{}} EditPageProps
 * @type {React.FC<EditPageProps>}
 */
const Account = () => {
  const history = useHistory();
  const { brand, activeProductUIDs: campaigns } = useSession();
  const { authEmail } = brand;
  const [sessionId, setSession] = useState();
  // eslint-disable-next-line no-unused-vars
  const [stripeId, setStripeId] = useState();

  const updatePaymentDetails = async () => {
    try {
      const stripeRedirect = await stripePromise;
      await stripeRedirect.redirectToCheckout({
        sessionId,
      });
    } catch (error) {
      // Do something with error
      console.log('checkout error: ', error);
    }
  };

  useEffect(() => {
    const fetchStripe = async () => {
      try {
        let { stripeCustomerId } = brand;
        if (!stripeCustomerId) {
          stripeCustomerId = await postCreateStripeCustomer();
          setStripeId(stripeCustomerId);
        }
        const session = await axios.get(
          `${process.env.REACT_APP_PAYMENT_SERVICE_STRIPE_URL}session/${stripeCustomerId}/payment-card`
        );
        setSession(session.data.id);
        return sessionId;
      } catch (error) {
        console.log('STRIPE ERROR: ', error);
      }
    };
    fetchStripe();
  }, []);

  return (
    <div>
      <div
        className="pt-7 mb-8"
        style={{
          background: '#0f0f0f',
          borderBottom: '1px solid #3c3c3c',
        }}
      >
        <div className="action-link clickable" align="center">
          <div className="back">
            <div
              onClick={() => {
                if (campaigns[0]) {
                  history.push(`/campaigns/${campaigns}/summary`);
                } else {
                  history.goBack();
                }
              }}
              role="presentation"
              style={{ color: '#ffb917' }}
            >
              <IconBack />
              <span className="white d-block type-sbold bsize-4">Back</span>
            </div>
          </div>
          <div className="sign-out">
            <SignOutButton />
          </div>
        </div>
        <div align="center">
          <TrendLogoWeb className="logo d-iblock" width="120px" height="60px" />
          <h1 className="ht-6 pt-4 text-white">{authEmail}</h1>
          <div className="pt-2">
            <a
              role="button"
              styling="link"
              onClick={updatePaymentDetails}
              className="action-btn mr-1"
            >
              Add / Edit Payment Details
            </a>
            <Link to={routes.ACCOUNT_UPDATE_EMAIL_PATH} className="action-btn mr-2">
              Change Email
            </Link>
            <Link to={routes.ACCOUNT_CHANGE_PASSWORD} className="action-btn">
              Change Password
            </Link>
          </div>
        </div>
        <div className="pt-7" align="center">
          <Row>
            <Tabs />
          </Row>
        </div>
      </div>
      <Row>
        <Switch>
          <Route path={routes.ACCOUNT_EDIT_PATH} component={Company} />
          <Route path={routes.ACCOUNT_EMAIL_NOTIFICATIONS_PATH} component={EmailNotifications} />
          <Route path={routes.ACCOUNT_PRIVACY_PATH} component={PrivacyPolicy} />
          <Route path={routes.ACCOUNT_TOS_PATH} component={TermsOfService} />
          <Redirect to={routes.ACCOUNT_EMAIL_NOTIFICATIONS_PATH} component={EmailNotifications} />
        </Switch>
      </Row>
    </div>
  );
};

export default Account;
