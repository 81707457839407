import { ADD_INFLUENCERS } from 'stores/actions/entities/influencers';

/**
 * @param {InfluencersReducer} state
 * @param {Record<string, import("types").Influencer>} influencers
 */
const addInfluencers = (state, influencers) => {
  const newState = { ...state };
  Object.keys(influencers).forEach(influencerUID => {
    newState[influencerUID] = influencers[influencerUID];
  });

  return newState;
};
/**
 * @typedef {Record<string, import("types").Influencer>} InfluencersReducer
 * @type {EntityInfluencersReducer}
 */
const initialState = {};
/**
 *
 * @param {InfluencersReducer} state
 * @param {import("stores/actions/entities/influencers").EntityInfluencersActions} action
 * @returns {InfluencersReducer}
 */
const influencers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INFLUENCERS: {
      return addInfluencers(state, action.payload);
    }

    default:
      return state;
  }
};

export default influencers;
