import axios from 'axios';
import { getAccessToken } from '../utils/cookies';

export async function patchBrandBumpCampaign(campaignId) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/${campaignId}/bump`;

  const response = await axios.patch(url, null, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return response.data;
}

export async function patchBrandArchiveCampaign(campaignId) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/${campaignId}/archive`;

  const response = await axios.patch(url, null, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return response.data;
}

export async function patchBrandReactivateCampaign(campaignId) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/${campaignId}/reactivate`;

  const response = await axios.patch(url, null, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return response.data;
}

export async function patchBrandRelistCampaign(campaignId) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/${campaignId}/relist`;

  const response = await axios.patch(url, null, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return response.data;
}

export async function patchBrandUnlistCampaign(campaignId) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/${campaignId}/unlist`;

  const response = await axios.patch(url, null, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return response.data;
}

export async function patchBrandIncrementDownload(campaignId, body) {
  const url = `${process.env.REACT_APP_TREND_API_URL}/campaign/${campaignId}/increment-download`;

  const response = await axios.patch(url, body, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  return response.data;
}
