import { createContext } from 'react';

/**
 * Influencer Rehire Context
 *
 * @typedef {{ influencerUID: string, show: boolean }} RehireModal
 * @typedef {RehireModal & { showInvitationModal: () => any }} RehireContext
 * @type {React.Context<RehireContext>}
 */
const RehireContext = createContext();

export default RehireContext;
