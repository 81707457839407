import React from 'react';
import useSession from './useSession';

/**
 * Access Session data via Context using a function children
 *
 * @param {{
 * children: (session: import('./Context').Session) => React.ReactElement
 * }} param0
 */
const Wrapper = ({ children }) => {
  const session = useSession();

  return <>{children(session)}</>;
};

export default Wrapper;
