const { useSelector } = require('react-redux');
const { selectors } = require('stores');

const useProduct = productUID => {
  const product = useSelector(selectors.getProduct(productUID));

  return product;
};

export default useProduct;
