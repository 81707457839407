import { Spin, Button, Card, Avatar } from 'antd';
import { ReactComponent as IconDownload } from 'images/icon-download.svg';
import Image from 'components/Common/Image';
import Video from 'components/Common/Video';
import { useMediaViewer } from 'components/Common/MediaViewer';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';

const { Meta } = Card;
/**
 * Creator Content Admin Card Component
 *
 * @typedef {{ creatorContentUID: string, contentUID: string, onDownload: (creatorContentUID:string, contentUID: string) => Promise<any> }} AdminCardProps
 * @type {React.FC<AdminCardProps>}
 */
const AdminCard = ({ creatorContentUID, contentUID, onDownload }) => {
  const creatorContent = useSelector(selectors.getCreatorContent(creatorContentUID));
  const influencer = useSelector(selectors.getInfluencer(creatorContent.influencerUID)) || {};
  const content = creatorContent.contents[contentUID];
  const [downloading, setDownloading] = useState(false);
  const { setModal } = useMediaViewer();

  async function handleDownload() {
    setDownloading(true);
    await onDownload(creatorContentUID, contentUID);
    setDownloading(false);
  }

  const iconActions = [
    <Spin spinning={downloading}>
      <div className="spin-btn">
        <Button
          className="download"
          type="link"
          onClick={e => {
            e.stopPropagation();
            handleDownload();
          }}
          size="small"
        >
          <span className="caps-heading">DOWNLOAD</span>
          <IconDownload />
        </Button>
      </div>
    </Spin>,
  ];

  return (
    <>
      <Card
        cover={
          content.type === 'image' ? (
            <Image
              src={content.highResolutionImage}
              onError={() => content.standardResolutionImage}
            />
          ) : (
            <Video src={content.highResolutionVideo} noPlay />
          )
        }
        actions={iconActions}
        className="post-card trend-post-card-content"
        onClick={() => {
          setModal({
            src:
              content.type === 'video' ? content.highResolutionVideo : content.highResolutionImage,
            type: content.type,
            show: true,
          });
        }}
      >
        <a
          href={`https://www.instagram.com/${influencer.username}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Meta
            avatar={<Avatar src={influencer.image} style={{ width: '35px', height: '35px' }} />}
            title={<span title={`@${influencer.username}`}>@{influencer.username}</span>}
            description={influencer.state}
            style={{ height: 'auto' }}
          />
        </a>
      </Card>
    </>
  );
};

export default AdminCard;
