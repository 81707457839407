import { useSession } from 'components/Session';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, Row } from 'antd';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import { toEditCampaign } from 'constants/routes';
import Container from 'components/Common/Container';
import useProductData from '../useProductData';
import Unlist from './Unlist';
import Relist from './Relist';
import Archive from './Archive';
import Reactivate from './Reactivate';

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    background: '#f5f9fe',
    padding: '14px 0px 15px 210px',
    borderTop: '1px solid #dbdfe8',
    width: '100%',
    left: 0,
    '@media (min-width: 990px)': {
      padding: '14px 0px 15px 210px',
    },
    '@media (max-width: 990px)': {
      padding: '14px 0px 15px 80px',
    },
  },
  notFree: {
    bottom: '0px',
  },
  button: {
    marginRight: '3px',
  },
});

/**
 * @typedef {{
 * onArchive: () => Promise<void>,
 * onRelist: () => Promise<void>,
 * onUnlist:  () => Promise<void>,
 * onReactivate: () => Promise<void>
 * }} SettingsProps
 * @type {React.FC<SettingsProps>}
 */
const Settings = ({ onArchive, onReactivate, onRelist, onUnlist }) => {
  const { productUID } = useProductData();
  const { brand } = useSession();
  const { status, lastPurchaseDate } = brand;
  const { timestampCompleted, isListed, type } = useSelector(selectors.getProduct(productUID));
  const showMarqueNotif = status === 'approved' && !lastPurchaseDate;
  const onBoardingCompleted = status === 'approved' && lastPurchaseDate;
  const isCompleted = !!timestampCompleted;

  const actionItems = [];
  if (!status || onBoardingCompleted) {
    if (!isCompleted) {
      if (isListed) {
        actionItems.push(<Unlist key="remove-listing" onClick={onUnlist} />);
      } else {
        actionItems.push(<Relist key="add-listing" onClick={onRelist} />);
      }

      actionItems.push(<Archive key="archive" onClick={onArchive} />);
    } else {
      actionItems.push(<Reactivate key="reactivate" onClick={onReactivate} />);
    }
  }

  const handleEditCampaign = () => {
    toEditCampaign(productUID);
  };

  return (
    <>
      <div
        className={`${css(styles.container, styles.notFree)} ${
          showMarqueNotif ? 'with-notification' : ''
        }`}
      >
        <Row type="flex" justify="space-around">
          <Container width={742}>
            <Button
              onClick={handleEditCampaign}
              style={{ borderColor: '#a9b0b9' }}
              className="trend-btn secondary mr-2 type-firme-italic bg-white"
              disabled={type === 'social'}
            >
              Edit&nbsp;<span className="hidden-xmobile">Campaign</span>
            </Button>
            <span>{actionItems}</span>
          </Container>
        </Row>
      </div>
    </>
  );
};

export default Settings;
