import Provider from './Provider';
import Button from './Button';
import InvitationModal from './InvitationModal';
import AwaitingModal from './AwaitingModal';
import AcceptedModal from './AcceptedModal';

const RehireInfluencer = {
  Provider,
  Button,
  InvitationModal,
  AwaitingModal,
  AcceptedModal,
};

export default RehireInfluencer;
