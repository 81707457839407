import { createContext } from 'react';

/**
 * @typedef {{
 *  name: string,
 *  status: "active" | "completed",
 *  brandUID: string,
 *  isListed: boolean,
 *  image: string,
 *  postGuidelines: string[],
 *  guidelines?: string,
 *  styleGuide?: Record<string, { url: string, position: number, type: "image" | "video"}>
 *  styleCategory?: string[]
 *  category: string,
 *  value: number,
 *  url: string,
 *  brandtags: string[],
 *  hashtags: string[]
 * }} Product
 *
 * @typedef {{
 *  brandUID: string,
 *  email: string,
 *  company: string,
 *  createdAt: Date,
 *  createdAtFirstCampaign: Date,
 *  createdAtUnix: number,
 *  credits: number,
 *  descriptioon: string,
 *  email: string,
 *  firstCampaign: boolean,
 *  fullName: string,
 *  isFreeTrial: boolean,
 *  isPushNotificationsEnabled: boolean,
 *  lastLoginDate: Date,
 *  lastLoginHealth: number,
 *  lastPurchaseDate?: number,
 *  name: string,
 *  standardCredits: number,
 *  status: "completed" | "pending" | "active",
 *  storeImage: string,
 *  stripeCustomerId: string
 * }} Brand
 *
 * @typedef {{
 *  newApplication: boolean,
 *  newMessage: boolean,
 *  newPost: boolean,
 *  token: string[]
 * }} Notification
 *
 * @typedef {{
 *  brand: Brand,
 *  products: Record<string,Product>,
 *  activeProducts: string[],
 *  completedProducts: string[],
 *  completedPartnershipInfluencerUIDs: string[],
 *  notification: Notification
 *  ambassadorUIDs: string[],
 * }} Session
 *
 * @type {React.Context<Session|void>}
 */
const SessionContext = createContext();

export default SessionContext;
