import React, { useState } from 'react';
import { Layout } from 'antd';
import { useSession } from 'components/Session';
import { Switch, Route, useParams, Redirect } from 'react-router-dom';
import {
  CAMPAIGN_APPLICATION_PATH,
  CAMPAIGN_CREATORS_PATH,
  CAMPAIGN_SUMMARY_PATH,
  CAMPAIGN_MESSAGES_PATH,
  CAMPAIGN_APPROVALS_PATH,
  CAMPAIGN_GALLERY_PATH,
} from 'constants/routes';
import Product, { useProductData } from 'components/Products';
import Container from 'components/Common/Container';
import RehireInfluencer from 'components/RehireInfluencer';
import * as MediaViewer from 'components/Common/MediaViewer';
import { brandInvitesCreator } from 'api/partnerships';
import Summary from './Summary';
import SummaryExample from './SummaryExample';
import Application from './Application';
import Tabs from './Tabs';
import Creators from './Creators';
import Messages from './Messages';
import Approvals from './Approvals';
import Gallery from './Gallery';
import CampaignListingBanner from './CampaignListingBanner';

const { Content } = Layout;

/**
 * @type {React.FC}
 */
const PageHeader = () => {
  const { productUID } = useProductData();

  return ((
    <div className="nav-header">
      <Container width={742}>
        <Product.Header productUID={productUID} />
      </Container>
      <div style={{ borderBottom: '1px solid #dbdfe8' }}>
        <Container width={742}>
          <Tabs />
        </Container>
      </div>
    </div>
  ));
};

const Page = () => {
  const {
    activeProductUIDs,
    completedProductUIDs,
    brand: { standardCredits = 0 },
  } = useSession();
  const [showPullListingBanner, setShowPullListingBanner] = useState(false);

  const { campaignId: productUID } = useParams();
  const ownedProduct = [...activeProductUIDs, ...completedProductUIDs].includes(productUID);
  const isExample = productUID === 'example';

  if (!ownedProduct && !isExample) {
    return <Redirect to="/" />;
  }

  async function handleRehireSubmit(val) {
    if (standardCredits === 0) {
      throw new Error('Not enough credits to spend');
    }
    await brandInvitesCreator(val);
  }

  return (
    <Product.Provider productUID={productUID}>
      <RehireInfluencer.Provider onSubmit={handleRehireSubmit}>
        <MediaViewer.Provider>
          <div id="campaign-page">
            <Content>
              <div className="campaign-content">
                <PageHeader />
              </div>
              <div
                className={`router-container ${
                  showPullListingBanner ? 'showPullListingBanner' : ''
                }`}
              >
                <Switch>
                  <Route
                    path={CAMPAIGN_SUMMARY_PATH}
                    component={isExample ? SummaryExample : Summary}
                  />
                  <Route path={CAMPAIGN_APPLICATION_PATH} component={Application} />
                  <Route path={CAMPAIGN_CREATORS_PATH} component={Creators} />
                  <Route path={CAMPAIGN_MESSAGES_PATH} component={Messages} />
                  <Route path={CAMPAIGN_APPROVALS_PATH} component={Approvals} />
                  <Route path={CAMPAIGN_GALLERY_PATH} component={Gallery} />
                </Switch>
                <CampaignListingBanner
                  showBanner={showPullListingBanner}
                  setShowBanner={setShowPullListingBanner}
                />
              </div>
            </Content>
          </div>
          <RehireInfluencer.AcceptedModal show={false} partnershipUIDs={['ENg9PKMbV4ooRNmX8W3g']} />
        </MediaViewer.Provider>
      </RehireInfluencer.Provider>
    </Product.Provider>
  );
};

export default Page;
