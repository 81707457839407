const { addProducts } = require('./entities/products');

export const SET_EXAMPLE_CAMPAIGNS = 'SET_EXAMPLE_CAMPAIGNS';

/**
 * Set Example Campaign Action
 *
 * @param {Record<string, import("types").Product>} products
 * @returns {{ type: SET_EXAMPLE_CAMPAIGNS, payload: string[] }}
 */
export const setExampleCampaigns = products =>
  /**
   * @param {import("redux").Dispatch}
   */
  dispatch => {
    const productUIDs = Object.keys(products);

    dispatch(addProducts(products));

    dispatch({
      type: SET_EXAMPLE_CAMPAIGNS,
      payload: productUIDs,
    });
  };

/**
 * @typedef { ReturnType<setExampleCampaigns> } ExampleCampaignActions
 */
