/**
 * Selector for Brand Context
 *
 * @param {string} brandUID
 */
export const getBrandContext = brandUID =>
  /**
   * @param {import("stores/reducers").MainReducer} state
   */
  state => {
    return state.brandContexts[brandUID];
  };
