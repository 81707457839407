import React from 'react';
import { List, Tag, Icon, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from 'stores';
import BrandLoginButton from './BrandLoginButton';

const BrandTable = ({ ids = [], loading }) => {
  const brands = useSelector(selectors.getBrands());
  if (loading) {
    return (
      <div style={{ textAlign: 'center', margin: '70px 0 70px' }}>
        <Spin indicator={<Icon type="loading" />} />
      </div>
    );
  }

  return (
    <List
      dataSource={ids}
      renderItem={brandId => {
        const brand = brands[brandId];
        const { name, overallCampaigns = 0, stripeCustomerId, isFreeTrial, isContentAdmin } = brand;

        const actions = [
          <Link className="ant-btn ant-btn-primary" to={`/admin/brands/${brandId}`}>
            Edit
          </Link>,
          <BrandLoginButton brandId={brandId} />,
        ];

        return (
          <List.Item actions={actions}>
            <List.Item.Meta
              title={name || '[ NO NAME ]'}
              description={
                <div>
                  {stripeCustomerId && <Tag color="green">Stripe Id: {stripeCustomerId}</Tag>}
                  {isFreeTrial && <Tag color="orange">Free Trial</Tag>}
                  {overallCampaigns > 0 && <Tag color="volcano">{overallCampaigns} Campaign/s</Tag>}
                  {isContentAdmin && <Tag color="cyan">Content Admin</Tag>}
                </div>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

export default BrandTable;
