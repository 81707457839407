import React, { useState } from 'react';
import { DatePicker, Divider, Form, Row, Button, message } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { patchAdminUpdateBrand } from 'api/admin';

const dateFormat = 'YYYY/MM/DD';

const BrandCollectionFields = ({ brand }) => {
  const { id: brandUID } = useParams();

  const [submitting, setSubmitting] = useState(false);
  const [lastPurchaseDate, setLastPurchaseDate] = useState(null);

  const handleDatePicker = date => {
    if (date) {
      setLastPurchaseDate(date.unix());
    } else {
      setLastPurchaseDate(null);
    }
  };

  const onSubmit = async () => {
    setSubmitting(true);

    let brandArgs = {};

    if (lastPurchaseDate) {
      brandArgs = {
        ...brandArgs,
        lastPurchaseDate,
        expirationDateOfPurchaseCredit: moment(lastPurchaseDate * 1000)
          .add(6, 'months')
          .unix(),
      };
      try {
        await patchAdminUpdateBrand({ brandUID, ...brandArgs });
        message.success('Brand successfully updated');
      } catch (e) {
        message.error(e.message);
      }
    }

    setSubmitting(false);
  };

  const datePickerArg = {};

  if (brand.lastPurchaseDate) {
    datePickerArg.defaultValue = moment.unix(brand.lastPurchaseDate);
  }

  return (
    <Row>
      <Divider orientation="left">Brand Collection</Divider>
      <Form>
        <Form.Item label="Last Purchase Date">
          <DatePicker
            allowClear={false}
            format={dateFormat}
            onChange={handleDatePicker}
            {...datePickerArg}
          />
        </Form.Item>
        <Form.Item>
          <Button loading={submitting} type="primary" onClick={onSubmit}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default BrandCollectionFields;
