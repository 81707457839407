import React from 'react';
import { Row, Col, notification } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import Product, { useProduct, useProductData } from 'components/Products';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from 'stores';
import { countApprovedPostsStats, countRequiredPost } from 'helpers/counters';
import {
  brandArchiveProduct,
  brandReactivateProduct,
  brandUnlistProduct,
  brandRelistProduct,
} from 'lib/Firebase/callables/brands';
import {
  CAMPAIGN_HISTORY_PATH,
  toCampaignApprovalCompletedMediaPath,
  toCampaignApprovalCompletedPath,
} from 'constants/routes';
import Container from 'components/Common/Container';
import { CAMPAIGN_TYPE } from 'constants/campaigns';
import CreatorContent from 'components/CreatorContent';
import { ReactComponent as ArrowIcon } from 'images/components/Products/RecentPosts/Arrow.svg';
import { useSession } from 'components/Session';
import { PLACEHOLDER_IMG_URL } from 'constants/common';

/**
 *
 * @param {string[]} uids
 * @param {Record<string, import('types').CreatorContent>} creatorContents
 */
const getRecentContents = (uids, creatorContents) => {
  return uids
    .sort((a, b) => creatorContents[b].approvedAt - creatorContents[a].approvedAt)
    .slice(0, 4);
};

/**
 * Summary
 *
 * @type {React.FC<{}>}
 */
const Summary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const creatorContents = useSelector(selectors.getCreatorContents());
  const { campaignId } = useParams();
  const {
    approvedPartnershipUIDs,
    creatorContentUIDs: { approved: approvedCreatorContentUIDs },
    postUIDs: { approved: approvedPostUIDs },
  } = useProductData();
  const product = useProduct(campaignId);
  const {
    brand: { standardCredits },
  } = useSession();

  const partnerships = useSelector(selectors.getPartnerships());
  const posts = useSelector(selectors.getPosts());

  const [likeCount, commentCount] = countApprovedPostsStats(approvedPostUIDs, posts);
  const requiredPosts = countRequiredPost(approvedPartnershipUIDs, partnerships);

  async function handleArchive() {
    try {
      await brandArchiveProduct(campaignId);
      dispatch(actions.session.addCompletedProductsSession({ [campaignId]: product }));

      notification.success({
        message: 'Campaign',
        description: 'Removed Campaign from archive.',
        duration: 2,
      });
      history.push(CAMPAIGN_HISTORY_PATH);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
        duration: 3,
      });
    }
  }

  async function handleReactivate() {
    try {
      await brandReactivateProduct(campaignId);
      dispatch(actions.session.addActiveProductsSession({ [campaignId]: product }));

      notification.success({
        message: 'Campaign',
        description: 'Campaign added back to store.',
        duration: 2,
      });
      history.push(`/campaigns/${campaignId}/summary`);
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
        duration: 3,
      });
    }
  }

  async function handleUnlist() {
    try {
      await brandUnlistProduct(campaignId);
      notification.success({
        message: 'Campaign',
        description: 'Campaign removed to store.',
        duration: 2,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
        duration: 2,
      });
    }
  }

  async function handleRelist() {
    try {
      await brandRelistProduct(campaignId);
      notification.success({
        message: 'Campaign',
        description: 'Campaign added back to store.',
        duration: 2,
      });
    } catch (e) {
      notification.error({
        message: 'Error',
        description: e.message,
        duration: 3,
      });
    }
  }

  const { type = CAMPAIGN_TYPE.SOCIAL } = product;
  const recentContents = getRecentContents(approvedCreatorContentUIDs, creatorContents);

  function summaryContent() {
    product.image = product.image || PLACEHOLDER_IMG_URL;
    const isPlaceholder = product.image.indexOf('/assets/images/') !== -1;
    // Only show if isListed or hasCredits & is not a trial image
    if (!isPlaceholder || product.isListed || standardCredits > 0) {
      return (
        <div id="image-container">
          <img src={product.image} alt="brand" style={{ objectFit: 'cover' }} />
        </div>
      );
    }
    return (
      <>
        <iframe
          width="474px"
          height="272px"
          src="https://www.youtube.com/embed/H9hfX-QfX_Q?controls=1&mute=1&showinfo=0&rel=0&autoplay=1&autohide=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <p className="video-text">
          Your project will be approved by our team momentarily and you will receive applications
          from content creators today!
        </p>
      </>
    );
  }

  return (
    <div id="campaignSummary" style={{ marginTop: 30 }}>
      <Container width={742}>
        <Row gutter={{ lg: 64, md: 32, sm: 12 }}>
          <Col md={16} style={{ background: '#ffffff' }}>
            {summaryContent()}
            {type === CAMPAIGN_TYPE.SOCIAL ? (
              <Product.Stats
                creators={approvedPartnershipUIDs.length}
                activePosts={approvedPostUIDs.length}
                requiredPosts={requiredPosts}
                likes={likeCount}
                comments={commentCount}
              />
            ) : (
              <Product.StyleCategory product={product} />
            )}

            <Product.PostGuidelines product={product} />
            {product.styleGuide && Object.keys(product.styleGuide).length > 0 && (
              <Product.Styleguides product={product} />
            )}
          </Col>
          <Col md={8} className="pl-0">
            <div id="campaignRecentPosts">
              <Row gutter={0} style={{ marginBottom: '13px' }}>
                <Col span={14}>
                  <div className="side-header-text">Latest Content</div>
                </Col>
                <Col span={10} className="see-all">
                  <Link
                    to={
                      type === CAMPAIGN_TYPE.SOCIAL
                        ? toCampaignApprovalCompletedPath(campaignId)
                        : toCampaignApprovalCompletedMediaPath(campaignId)
                    }
                  >
                    See All
                    <ArrowIcon style={{ marginLeft: '6px', width: '14px', height: '8.84px' }} />
                  </Link>
                </Col>
              </Row>
              <Row gutter={10}>
                {recentContents.map(uid => (
                  <Col xs={12}>
                    <CreatorContent.RecentCard creatorContentUID={uid} />
                  </Col>
                ))}
              </Row>
            </div>
            <Product.Details product={product} />
            {type === CAMPAIGN_TYPE.SOCIAL && <Product.PostRequirements product={product} />}
          </Col>
        </Row>
      </Container>
      <Product.Settings
        onArchive={handleArchive}
        onReactivate={handleReactivate}
        onUnlist={handleUnlist}
        onRelist={handleRelist}
      />
    </div>
  );
};

export default Summary;
